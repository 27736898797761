import { 
  takeLatest, 
  put, 
  select 
} from 'redux-saga/effects';
import { sendMessage } from '../actions';
import { 
  ErrorFlow, 
  ErrorObject 
} from '../entities/errorObject';
import { sendError } from './../resolver/errorResolver';

export const GET_REQUEST_HISTORY = 'GET_REQUEST_HISTORY';
export const SAVE_REQUEST_TO_NATIVE = 'SAVE_REQUEST_TO_NATIVE';
export const UPDATE_REQUEST_IN_NATIVE = 'UPDATE_REQUEST_IN_NATIVE';

export const GET_PAYMENT_HISTORY = 'GET_PAYMENT_HISTORY';
export const SAVE_PAYMENT_TO_NATIVE = 'SAVE_PAYMENT_TO_NATIVE';
export const UPDATE_PAYMENT_IN_NATIVE = 'UPDATE_PAYMENT_IN_NATIVE';

export const getRequestHistory = () => ({
  type: GET_REQUEST_HISTORY,
});

export const saveRequestToNative = (request) => ({
  type: SAVE_REQUEST_TO_NATIVE,
  request
});

export const updateRequestInNative = (reqDetails) => ({
  type: UPDATE_REQUEST_IN_NATIVE,
  reqDetails,
});

export const getPaymentHistory = () => ({
  type: GET_PAYMENT_HISTORY,
});

export const savePaymentToNative = (payment) => ({
  type: SAVE_PAYMENT_TO_NATIVE,
  payment
});

export const updatePaymentInNative = (payDetails) => ({
  type: UPDATE_PAYMENT_IN_NATIVE,
  payDetails,
});

function* getRequests() {
  try {
    yield put(sendMessage({ type: 'storage', get: 'history', kind: 'request' }));
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error sending message for getting requests', error, ErrorFlow.mobileStorage)}));
  }
}

function* saveRequest({ request }) {
  try {
    yield put(sendMessage({ type: 'storage', set: 'history', kind: 'request', value: JSON.stringify(request) }));
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error sending message for saving requests', error, ErrorFlow.mobileStorage)}));
  }
}

function* updateRequest({ reqDetails }) {
  const request = yield select(state => (state.history.requests.find(req => req.ids.paymentRequestId === reqDetails.ids.paymentRequestId)));
  yield put(saveRequestToNative(request));
}

function* getPayments() {
  try {
    yield put(sendMessage({ type: 'storage', get: 'history', kind: 'payment' }));
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error sending message for getting payments', error, ErrorFlow.mobileStorage)}));
  }
}

function* savePayment({ payment }) {
  try {
    yield put(sendMessage({ type: 'storage', set: 'history', kind: 'payment', value: JSON.stringify(payment) }));
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error sending message for saving payments', error, ErrorFlow.mobileStorage)}));
  }
}

function* updatePayment({ payDetails }) {
  const payment = yield select(state => state.history.payments.find(pay => pay.id === payDetails.id));
  yield put(savePaymentToNative(payment));
}

export function* historyWatcher() {
  yield takeLatest(GET_REQUEST_HISTORY, getRequests);
  yield takeLatest(SAVE_REQUEST_TO_NATIVE, saveRequest);
  yield takeLatest(UPDATE_REQUEST_IN_NATIVE, updateRequest);
  yield takeLatest(GET_PAYMENT_HISTORY, getPayments);
  yield takeLatest(SAVE_PAYMENT_TO_NATIVE, savePayment);
  yield takeLatest(UPDATE_PAYMENT_IN_NATIVE, updatePayment);
}
