import React from 'react'
import { useDispatch } from 'react-redux';

import WarningIcon from "../../assets/images/warning.svg";
import Close from '../../assets/images/close-icon.svg';
import Logo from '../../assets/images/bopp-logo.svg';

import './style.scss'
import { changeAccountDetailsScreen, removeAccountDetailsUrl } from '../../actions';

import HeaderUniversal from '../../components/HeaderUniversal';

function AddBankError({ error, closeScreen, onTryAgain = null }) {
  const dispatch = useDispatch()

  const closeErrorScreen = () => {
    dispatch(removeAccountDetailsUrl())
    dispatch(changeAccountDetailsScreen({ addBankError: null }))
  }

  const handleClose = () => {
    closeScreen();
    closeErrorScreen();
  };

  return (
    <div className="add-account-error">
      <HeaderUniversal
        logo={Logo}
        rightButtonIcon={Close}
        rightButtonEvent={handleClose}
        customStylesRightBtn={{ width: '32px', height: '32px', top: '33px' }}
        customStylesLogo={{top: '30px'}}
      />

      <div className="add-account-error__body">
        <div>
          <img src={WarningIcon} className="add-account-error__image" alt="cross" />
          <div className="add-account-error__title">{error.title}</div>
          <div className="add-account-error__message">{error.message}</div>
        </div>

        <div className="add-account-error__text-button" onClick={onTryAgain ? onTryAgain : closeErrorScreen }>Try Again</div>
      </div>

    </div>
  )
}

export default AddBankError
