import React, { useEffect , useRef, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { cssVhToPx, isDocumentLoaded } from '../../helpers';
import './index.scss';
import { PlatformType } from '../../static/CommonDefinitions';

const BankAccountName = ({ name, isBold = true, alwaysSmallFont = false }) => {
  const platform = useSelector(state => state.settings.platform);
  const containerRef = useRef(null);
  const invisibleTextRef = useRef(null);
  const [useSmallFont, setUseSmallFont] = useState(false);
  const [textToDisplay, setTextToDisplay] = useState('');
  const [pageIsLoaded, setPageIsLoaded] = useState(false);

  useEffect(() => {
    if (isDocumentLoaded()) {
      setPageIsLoaded(true);
    }
    else {
      window.addEventListener('load', () => setPageIsLoaded(true));
    }
  }, []);

  const getNumberOfCharsInARow = () => {
    const charWidth = invisibleTextRef.current.clientWidth / name.length;
    const availableWidth = containerRef.current.clientWidth;
    const charsInARow = Math.floor(availableWidth / charWidth);

    return charsInARow;
  }

  const getRowsText = () => {
    const charsInARow = getNumberOfCharsInARow();
    const splittedName = name.split(' ');

    let firstRowText = '';
    let secondRowText = '';

    for(const word of splittedName) {
      const firstRowTmpText = firstRowText ? (firstRowText + ' ' + word) : word;

      if (firstRowTmpText.length > charsInARow) {
        const secondRowTmpText = secondRowText ? (secondRowText + ' ' + word) : word;

        if (secondRowTmpText.length <= charsInARow) {
          secondRowText = secondRowTmpText;
        }
        else {
          break;
        }
      }
      else if (!secondRowText.length) {
        firstRowText = firstRowTmpText;
      }
    }

    return {
      firstRowText,
      secondRowText,
    }
  }

  useEffect(() => {
    if (!containerRef.current || !invisibleTextRef.current || !pageIsLoaded || !name) {
      return;
    }

    const charsInARow = getNumberOfCharsInARow();
    const { firstRowText, secondRowText } = getRowsText();
    let finalText = secondRowText ? (firstRowText + ' ' + secondRowText) : firstRowText;

    setUseSmallFont(secondRowText.length > 0);

    if (name.length > charsInARow * 2) {
      finalText = finalText.slice(0, -3) + '...';
    }

    setTextToDisplay(finalText);
  }, [containerRef.current, invisibleTextRef.current, name, pageIsLoaded]);

  const finalClassName = classNames([
    'account-name',
    {'account-name--desktop': platform === PlatformType.Desktop},
    {'account-name--bold': isBold},
    {'account-name--small-font': useSmallFont || alwaysSmallFont,},
    {'account-name--regular-font': !useSmallFont && !alwaysSmallFont},
  ])

  const containerStyles = (useSmallFont || alwaysSmallFont) ? styles.smallFont : styles.regularFont

  return <div ref={containerRef} className={finalClassName} style={platform === PlatformType.Desktop ? {} : containerStyles} >
    <div className={'account-name__invisible'} ref={invisibleTextRef}>
      {name}
    </div>
    { textToDisplay }
  </div>
}

const styles = {
  regularFont: {
    fontSize: cssVhToPx(3.3),
  },
  smallFont: {
    fontSize: cssVhToPx(2.7),
  },
}

export default BankAccountName;
