import React from 'react'

import WarningIcon from "../../assets/images/warning.svg";
import Close from '../../assets/images/close-icon.svg';
import Logo from '../../assets/images/bopp-logo.svg';

import HeaderUniversal from '../../components/HeaderUniversal';
import './style.scss'

function InvalidEnvironmentFileError({ closeScreen }) {
  return (
    <div className="invalid-env-error">
      <HeaderUniversal
        logo={Logo}
        rightButtonIcon={Close}
        rightButtonEvent={closeScreen}
        customStylesRightBtn={{ width: '32px', height: '32px', top: '33px' }}
        customStylesLogo={{top: '30px'}}
      />

      <div className="invalid-env-error__body">
        <div>
          <img src={WarningIcon} className="invalid-env-error__image" alt="cross" />
          <div className="invalid-env-error__title">Environment not setup properly</div>
        </div>
      </div>
    </div>
  )
}

export default InvalidEnvironmentFileError
