import React from 'react';
import Collapsible from 'react-collapsible';
import './style.scss';

const PaymentSettingsItem = (props) => {
    const {
        title,
        img,
        description,
        openTriggerDescription,
        differenceSettings
    } = props;

    const triggerProps = {
        img,
        differenceSettings,
        title
    }

    return (
        <Collapsible
            trigger={<Trigger {...triggerProps} description={description} />}
            className="settings-item"
            triggerWhenOpen={<Trigger {...triggerProps} description={openTriggerDescription} isOpen />}
        >
            {props.children}
        </Collapsible>
    )
};

export default PaymentSettingsItem;

const Trigger = ({ description, img, title, isOpen = false, differenceSettings }) => {
    return (
        <div className={`settings-item__header ${isOpen ? 'settings-item__header-open' : ''}`}>
            <div><img src={img} alt='' className="settings-item__icon"/></div>
            <div className="settings-item-title">
                <p className={`settings-item-title-text ${differenceSettings ? 'settings-item-title-text--difference' : ''}`}>{title}</p>
                <span>{description}</span>
            </div>
        </div>
    )
  }
