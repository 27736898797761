export class PaylinkUrlParameters {
    paylinkUrl;
    amount = null;
    paymentReference = null;
    params;

    constructor(url) {
        this.paylinkUrl = url;

        this.initContentUrl()
        this.initAmount()
        this.initReference()
    }

    initContentUrl() {
        const url = new URL(this.paylinkUrl);
        this.params = url.hash && url.hash.length>1 ? new URLSearchParams(url.hash.substr(1)) : url.searchParams;
    }

    initAmount() {
        if(this.params.get('value') != null) {
            this.amount = this.params.get('value').replace(/£/g, '');
            this.amount = this.amount.replace(/,/g, '');
        }
    }

    initReference() {
        if(this.params.get('reference') != null) {
            this.paymentReference = this.params.get('reference')
        }
    }
}