import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

import {
  openNewPage,
  showAccountDeactivated,
  setCheckUserAuthCodeStatus,
  changeAmount,
  clearPaylink,
  setStatusBarColor,
} from '../../actions';
import colors from '../../assets/style.theme.scss';
import './style.scss';

import { AuthCodeStatuses } from '../../reducers/kycReducer';
import CloseIcon from '../../assets/images/close-icon.svg';
import WarningIcon from "../../assets/images/warning.svg";
import Logo from '../../assets/images/bopp-logo.svg';

import FormSteps from '../KYCForm/FormSteps';
import HeaderUniversal from '../../components/HeaderUniversal';
import Button from '../../components/Button';
import { AccountType } from '../../static/CommonDefinitions';

const AccountDeactivated = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const bank = useSelector(state => state.payment.bank);

  const messages = {
    [AccountType.Business]: 'The BOPP account on this device has been deactivated. Please enter your new activation code or visit our help page.',
    [AccountType.Personal]: 'The BOPP account on this device has been deactivated.',
  }

  useEffect(() => {
    dispatch(setStatusBarColor(colors.defaultBar));

    return () => {
      dispatch(changeAmount('0.00'));
      dispatch(showAccountDeactivated(null));
      dispatch(clearPaylink());
    }
  }, [])

  const closeScreenIcon = () => {
    history.push('next');
  }

  const onUpdateAuthCode = () => {
    dispatch(setCheckUserAuthCodeStatus(AuthCodeStatuses.notChecked));
    history.push('/kyc-form', FormSteps.activateBusiness);
  }

  const handleLearnMoreClick = () => {
    if (bank.subscriptionType === AccountType.Business) {
      dispatch(openNewPage("https://help.bopp.io/portal/en/kb/articles/why-is-my-account-showing-as-deactivated"));
    }
    else {
      dispatch(openNewPage("https://help.bopp.io/portal/en/kb/articles/why-is-my-account-showing-as-deactivated"));
    }
  }

  return (
    <div className="account-deactivated-screen">
      <HeaderUniversal
        logo={Logo}
        rightButtonIcon={CloseIcon}
        rightButtonEvent={closeScreenIcon}
        customStylesRightBtn={{ width: '32px', height: '32px', top: '33px' }}
        customStylesLogo={{top: '30px'}}
      />

      <div className="messages">
        <img className="image" src={WarningIcon} alt="Failure icon" />

        <p className="title">
          Account Deactivated
        </p>

        <p className="text">
          {messages[bank.subscriptionType]}
        </p>

        <p onClick={handleLearnMoreClick} className="link">
          Learn More
        </p>
      </div>

      {
        bank.subscriptionType === AccountType.Business &&
          <div className="bottom-part">
            <Button title="Update activation code" handleClick={onUpdateAuthCode} color="black" />
          </div>
      }
    </div>
  )
}

export default AccountDeactivated;
