// outsource dependencies
import React, { 
    useState, 
    useEffect 
} from 'react';
import { useHistory } from "react-router-dom";
import { 
    useDispatch, 
    useSelector 
} from "react-redux";
import classNames from 'classnames';
import nacl from 'tweetnacl';
import {
    decodeUTF8,
    encodeBase64,
    decodeBase64
} from 'tweetnacl-util';
import config from "../../config";
import ChooseBank from "../ChooseBank";
import {
    cssVhToPx, 
    isInApp, 
    isPaymentReferenceValid, 
    lastCharacterIsSpace
} from '../../helpers';
import TipModal from "../Modal/TipModal";
import ReviewPaymentDetailsModal from "../Modal/ReviewPaymentDetailsModal";
import Button from "../../components/Button";
import Lock from "../../assets/images/lock-icon.svg";
import SelectionModal from "../Modal/SelectionModal";
import AmountInput from "../../components/AmountInput";
import {payerSteps} from '../../reducers/paymentReducer';
import colors from '../../assets/style.theme.scss';
import CheckInsideApp from "../../containers/CheckInsideApp";
import CloseIcon from "../../assets/images/close-icon.svg";
import BackArrow from "../../assets/images/back-arrow.svg";
import GiftAidIcon from "../../assets/images/gift-aid.svg";
import InfoIcon from "../../assets/images/info-icon-black.svg";
import EditIcon from '../../assets/images/edit-with-bg-grey.svg';
import PaymentInfoDetails from "../Modal/PaymentInfoDetails";
import HeaderUniversal from "../../components/HeaderUniversal";
import ChooseBankPanel from "../../components/ChooseBankPanel";
import {savePaymentToNative} from '../../sagas/historyWatcher';
import BankUnavailableModal from "../Modal/BankUnavailableModal";
import AccountDeactivatedModal from "../Modal/AccountDeactivatedModal";
import {
    Home,
    PlatformType,
    ReferenceSettingType,
    Status,
    TermType
} from "../../static/CommonDefinitions";
import {
    changeAmount,
    changePaymentStatusScreen,
    clearPaylinkUrl,
    createPaymentInstruction,
    getUsedBankList,
    handlePaymentStatus,
    openCallBackPage,
    openNewPage,
    passBankUrlToNative,
    setHome,
    showAccountDeactivated,
    setStatusBarColor,
    showLimitReached,
    setPayerStep,
    setFixedAmount,
    getBankList,
} from "../../actions";
import {
    addHistoryPendingPaymentToHistory, 
    setHistoryPendingPayment
} from '../../reducers/historyReducer';
import './style.scss';
import LimitReachedModal from '../Modal/LimitReachedModal';
import {getKeyPair} from '../../helpers/KeyController'
import Popup from '../../components/Popup';
import BankAccountName from '../../components/BankAccountName';
import useMobileKeyboardDetection from '../../hooks/useMobileKeyboardDetection';
import {setPaymentRequest} from '../../reducers/paymentPersistReducer';
import { notifyWentToBankMessageAction } from '../../resolver/payment/boppButtonNotifier';

let timerId = null;

const GiftAid = (
    {
        setInfoDetailsPopUp,
        onCloseButtonClick,
        onCheckboxesChange,
        changeGiftAidConfirmMoney,
        changeGiftAidConfirmDonation,
        giftAidConfirmMoney,
        giftAidConfirmDonation
    }
) => {
    const dispatch = useDispatch();
    const platform = useSelector(state => state.settings.platform);
    const settings = useSelector(state => state.paymentPersist.paymentRequest.settings);

    useEffect(() => {
        onCheckboxesChange(false);
    }, []);

    const handleNextButtonClick = () => {
        changeGiftAidConfirmMoney(true);
        changeGiftAidConfirmDonation(true);

        dispatch(setPayerStep(payerSteps.setGiftAidInformation));
    }

    const handleSkipButtonClick = () => {
        changeGiftAidConfirmMoney(false);
        changeGiftAidConfirmDonation(false);

        dispatch(setPayerStep(payerSteps.setPaymentDetails));
    }

    const footerClassName = classNames([
        {'additional-info__footer_desktop': platform === PlatformType.Desktop},
        {'additional-info__footer additional-info__footer--gift-aid': platform !== PlatformType.Desktop},
    ])

    const hasAdditionalInfoScreen = 
        settings.reference.type === ReferenceSettingType.SetByPayer ||
        settings.notes.enabled ||
        settings.requestEmail.enabled;

    return(
        <>
            <div className={platform === PlatformType.Desktop ? 'payment-details__desktop-background' : "background-left-top-gradient"}>
                <div className={platform === PlatformType.Desktop ? "payment-details__desktop-wrapper" : "additional-info"}>
                    <div className="additional-info__content">
                        {platform !== PlatformType.Desktop && <HeaderUniversal
                            rightButtonIcon={CloseIcon}
                            leftButtonIcon={BackArrow}
                            classForCustomStyles={"header-uni"}
                            customStylesTitle={{top: '40px', fontSize: '18px', fontWeight: '700'}}
                            leftButtonEvent={() => dispatch(setPayerStep(hasAdditionalInfoScreen ? payerSteps.setAdditionalInfo : payerSteps.setAmount))}
                            rightButtonEvent={onCloseButtonClick}/>}
                        {platform === PlatformType.Desktop && (
                            <>
                                <img src={BackArrow}
                                     onClick={() => dispatch(setPayerStep(hasAdditionalInfoScreen ? payerSteps.setAdditionalInfo : payerSteps.setAmount))}
                                     className="payment-details__desktop-backArrow"
                                />
                            </>
                        )}
                        <img className="additional-info__content-img" src={GiftAidIcon} alt="Gift Aid"/>
                        <p className="additional-info__content-title">Add Gift Aid to your payment</p>
                        <p className="additional-info__content-subtitle">If you are a UK Tax payer your chosen charity can reclaim 25% extra at no additional cost to you. All you need to do is to provide a few additional details and voila!. For more information click <a className={"additional-info__content-subtitle_link"} href='https://help.bopp.io/portal/en/kb/articles/what-is-gift-aid' target='_blank'>here</a>.</p>
                        <div className="additional-info--input-block-checkboxes">
                            <div className={platform === PlatformType.Desktop ? "additional-info--input-block-checkbox_desktop" : "additional-info--input-block-checkbox"}>
                                <div>
                                    <input
                                        id="additional-info--input-block-checkbox_custom_moneyConfirm"
                                        type="checkbox"
                                        checked={giftAidConfirmMoney}
                                        className="additional-info--input-block-checkbox_custom"
                                        onClick={() => {
                                            onCheckboxesChange(!giftAidConfirmMoney || giftAidConfirmDonation);
                                            changeGiftAidConfirmMoney(!giftAidConfirmMoney);
                                        }}
                                    />
                                    <label htmlFor="additional-info--input-block-checkbox_custom_moneyConfirm"></label>
                                </div>
                                <p>
                                    I confirm this is my own money
                                </p>
                            </div>
                            <div className={platform === PlatformType.Desktop ? "additional-info--input-block-checkbox_desktop" : "additional-info--input-block-checkbox"}>
                                <div>
                                    <input
                                        id="additional-info--input-block-checkbox_custom_receiveConfirm"
                                        type="checkbox"
                                        checked={giftAidConfirmDonation}
                                        className="additional-info--input-block-checkbox_custom"
                                        onClick={() => {
                                            onCheckboxesChange(giftAidConfirmMoney || !giftAidConfirmDonation);
                                            changeGiftAidConfirmDonation(!giftAidConfirmDonation);
                                        }}
                                    />
                                    <label htmlFor="additional-info--input-block-checkbox_custom_receiveConfirm"></label>
                                </div>
                                <p>
                                    I confirm that this donation is not part of a sweepstake, raffle or lottery and I will not receive anything in return for it
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={footerClassName}>
                        <Button
                            handleClick={handleNextButtonClick}
                            title="Next"
                            disabled={!giftAidConfirmMoney || !giftAidConfirmDonation}
                            color="black"/>
                        <Button
                            handleClick={handleSkipButtonClick}
                            title="Skip"
                            color="transparent"/>
                    </div>
                </div>
            </div>
        </>
    )
};

const PaymentDetailsScreen = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const platform = useSelector(state => state.settings.platform)
    const pendingPayment = useSelector(state => state.history.pendingPayment);
    const isAccountDeactivated = useSelector(state => state.payment.isAccountDeactivated);
    const isLimitReached = useSelector(state => state.payment.isLimitReached);
    const clickPayViaBank = useSelector((state) => state.payment.paymentStatusScreen.clickPayViaBank);
    const status = useSelector(state => state.payment.status);
    const paymentRequest = useSelector(state => state.paymentPersist.paymentRequest);
    const amount = useSelector(state => state.payment.amount);
    const [amountSetByUser, setAmountSetByUser] = useState('');
    const boppButton = useSelector(state => state.paymentPersist.boppButton);
    const minAmount = useSelector(state => state.paymentPersist.paymentRequest?.minAmount);
    const maxAmount = useSelector(state => state.paymentPersist.paymentRequest?.maxAmount);
    const currentPayerStep = useSelector(state => state.payment.currentPayerStep)
    const fixedAmount = useSelector(state => state.payment.fixedAmount)
    const iframeRedirectUrl = useSelector(state => state.payment.iframeRedirectUrl)

    const [popUpQuestion, setPopUp] = useState(false);
    const [timeModal, showTimeModal] = useState(false);
    const [unavailableBank, setUnavailableBank] = useState(false);
    const [clickChange, setClickChange] = useState(false);
    const [isInsideApp, setIsInsideApp] = useState(false);
    const [isOpenChooseBank, setChooseBankModal] = useState(false);
    const [chooseFlowPopup, setChooseFlowPopup] = useState(false);
    const [infoDetailsPopUp, setInfoDetailsPopUp] = useState(false);
    const [accountDeactivatedModal, setAccountDeactivatedModal] = useState(false);
    const [limitReachedModal, setLimitReachedModal] = useState(false);
    const [note, setNote] = useState(paymentRequest?.settings.notes.notesValue);
    const [email, setEmail] = useState("");
    const [reference, setReference] = useState("");
    const [fullName, setFullName] = useState("");
    const [address, setAddress] = useState("");
    const [postCode, setPostCode] = useState("");
    const [giftAidConfirmMoney, setGiftAidConfirmMoney] = useState(false);
    const [giftAidConfirmDonation, setGiftAidConfirmDonation] = useState(false);
    const [giftAidCheckboxesChanged, setGiftAidCheckboxesChanged] = useState(false);
    const [incorrectAmount, setIncorrectAmount] = useState(false);
    const [userAgreement, setUserAgreement] = useState(false);
    const [errorNote, setErrorNote] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorReference, setErrorReference] = useState(false);
    const [errorFullName, setErrorFullName] = useState(false);
    const [errorAddress, setErrorAddress] = useState(false);
    const [errorPostCode, setErrorPostCode] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [showCancelPopup, setShowCancelPopup] = useState(false);
    const keyboardIsVisible = useMobileKeyboardDetection();

    useEffect(() => {
        return () => dispatch(setPayerStep(payerSteps.setAmount));
    }, []);

    useEffect(() => {
        dispatch(getBankList())
        dispatch(getUsedBankList());

        if (isInApp(["FBAN", "FBAV", "Instagram"]) && isInApp(["Android"]) && !isInApp(["iPhone", "iOS"])) setIsInsideApp(true);

        if (paymentRequest?.amountTerm === TermType.AnyAmount && paymentRequest?.amount === '0') {
            //amount not set
            dispatch(setStatusBarColor(colors.mainYellow));

        } else if (paymentRequest?.amountTerm === TermType.FixedAmount) {
            dispatch(setFixedAmount(true));
        } else {
            //amount set
            dispatch(setStatusBarColor(colors.defaultBar));
            setFixedAmount(true)
        }

        setReference(paymentRequest?.reference || '');

        dispatch(changeAmount(parseFloat(paymentRequest?.amount).toFixed(2).toString() || '0.00'));
        setAmountSetByUser(parseFloat(paymentRequest?.amount).toFixed(2).toString() || '0.00');
    }, []);

    useEffect(() => {
        if (paymentRequest?.authUrl) {
            setClickChange(false);
            localStorage.setItem('timestamp', JSON.stringify(new Date().getTime() + 480000));
            runTimer()
        }
    }, [paymentRequest?.authUrl]);

    const handleCloseButtonClick = () => {
        const settings = paymentRequest.settings;

        const referenceEdited = settings.reference.type === ReferenceSettingType.SetByPayer && reference;
        const notesEdited = settings.notes.enabled && settings.notes.type === "SetByPayer" && note;
        const emailEdited = settings.requestEmail.enabled && (email || userAgreement);
        const giftAidEdited = settings.giftAid?.enabled && (fullName || address || postCode || giftAidCheckboxesChanged);
        const amountEdited = (!fixedAmount && (+amount && +amount !== +amountSetByUser || !+amount && +amountSetByUser));

        if (referenceEdited || notesEdited || emailEdited || giftAidEdited || amountEdited) {
            setShowCancelPopup(true);
        }
        else {
            if(iframeRedirectUrl) {
                window.parent.postMessage({
                    source:'BOPPAPP',
                    action:'close',
                }, "*")
            } else {
                closePaymentDetails();
            }
        }
    }

    const createPaymentInctruction = async (api, amount) => {
        if (paymentRequest != null) {
            let giftAidData = paymentRequest?.settings?.giftAid

            if(paymentRequest?.settings?.giftAid?.enabled) {
                if (giftAidConfirmMoney && giftAidConfirmDonation) {
                    let payer = await getKeyPair()
                    giftAidData = pack({
                        confirmMoney: giftAidConfirmMoney,
                        confirmDonation: giftAidConfirmDonation,
                        fullName,
                        address,
                        postCode
                    }, paymentRequest.settings.giftAid, payer)
                }
                else{
                   giftAidData = {
                       skippedByPayer: true
                   }
                }
            }

            dispatch(createPaymentInstruction({
                amount: amount,
                paylink: paymentRequest.paylinkUrl,
                serviceId: api,
                name: paymentRequest.name,
                paymentReference: reference,
                endToEndIdentification:boppButton ? 'BOPPButton' : 'BOPP App',
                metainfo: JSON.stringify({
                    encryption: paymentRequest.settings.encryption,
                    reference: { ...paymentRequest.settings.reference, ...{ value: reference }},
                    notes: { ...paymentRequest.settings.notes, ...{notesValue: note}},
                    requestEmail: { ...paymentRequest.settings.requestEmail, ...{ email, userAgreement }},
                    thankYouNote: paymentRequest.settings.thankYouNote,
                    giftAid: giftAidData,
                    successRedirectUrl: paymentRequest.settings.successRedirectUrl,
                    failRedirectUrl: paymentRequest.settings.failRedirectUrl,
                    cancelRedirectUrl: paymentRequest.settings.cancelRedirectUrl,
                    internalReference: paymentRequest.settings.internalReference
                })
            }))
        }

    };

    const pack = (message, metainfo, payer) => {
        if (!metainfo.payee?.publicKey){
            return null
        }
        let nonce = nacl.randomBytes(nacl.secretbox.nonceLength);
        let payeePublicKey = decodeBase64(metainfo.payee.publicKey)
        let messageAB = decodeUTF8(JSON.stringify(message));
        let packedMessage = nacl.box(messageAB, nonce, payeePublicKey, payer.secretKey)
        let giftaid = {
            payee: {
                publicKey: metainfo.payee.publicKey
            },
            payer: {
                publicKey: encodeBase64(payer.publicKey)
            },
            payloadBase64: encodeBase64(packedMessage),
            nonceBase64: encodeBase64(nonce),

        }

        return giftaid
    }

    const runTimer = () => {
        if (timerId != null) {
            clearInterval(timerId);
        }

        let timestamp = JSON.parse(localStorage.getItem('timestamp'));
        timerId = setInterval(() => {
                if (timestamp < new Date().getTime()) {
                    showTimeModal(true);
                    clearInterval(timerId);
                    localStorage.removeItem('timestamp');
                }
            }
            , 5000);
    }

    useEffect(() => {
        if (clickPayViaBank || clickChange) {
            clearInterval(timerId);
            localStorage.removeItem('timestamp');
            showTimeModal(false);
        }
    }, [clickPayViaBank, clickChange]);

    useEffect(() => {
        if (isAccountDeactivated) {
            setAccountDeactivatedModal(true);
        }
    }, [isAccountDeactivated]);

    useEffect(() => {
        if (isLimitReached) {
            setLimitReachedModal(true);
        }
    }, [isLimitReached]);

    const closePaymentDetails = () => {
        dispatch(setPaymentRequest(null))
        dispatch(changeAmount('0.00'));
        history.push('/home-screen');
    };

    const setPaymentAmount = () => {
        if (amountSetByUser > 0) {
            dispatch(setPayerStep(payerSteps.setAdditionalInfo))
            setIncorrectAmount(false);
            dispatch(setPaymentRequest({...paymentRequest, amount: amountSetByUser}))
            if (paymentRequest?.payerBank?.api) {
                if (paymentRequest?.amount) {
                    if (paymentRequest.amount != amountSetByUser) {
                        dispatch(setPaymentRequest({
                            ...paymentRequest,
                            isAuthUrlRequestSent: true,
                            authUrl: null,
                            amount: amountSetByUser
                        }))
                        createPaymentInctruction(paymentRequest.payerBank.api, amountSetByUser)
                    }
                }
            }
        } else {
            setIncorrectAmount(true);
        }
    };

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 2
    });

    const openAgreement = () => {
        if (platform !== PlatformType.Web && platform !== PlatformType.Desktop) {
            return dispatch(openNewPage('https://bopp.io/user-agreement'));
        }
        return window.open('https://bopp.io/user-agreement', '_blank');
    };

    const changeInfoDetailsPopup = () => {
        setInfoDetailsPopUp(state => !state)
    };

    const refreshPage = () => {
        if (paymentRequest?.payerBank?.api) {
            dispatch(setPaymentRequest({...paymentRequest, isAuthUrlRequestSent: true, authUrl: null}))
            createPaymentInctruction(paymentRequest.payerBank.api, paymentRequest.amount)
        }

        showTimeModal(false);
    };

    const handlePay = (flow) => {
        dispatch(passBankUrlToNative(paymentRequest.authUrl));

        if(platform === PlatformType.Web) {
            dispatch(changePaymentStatusScreen({waitingForConsent: true, transferringToBank: false, clickPayViaBank: true, clickCancelPayment: false}));
        } else {
            dispatch(changePaymentStatusScreen({transferringToBank: true, clickPayViaBank: true, clickCancelPayment: false, targetBank: paymentRequest.payerBank.accountName}));
        }

        dispatch(addHistoryPendingPaymentToHistory())
        dispatch(savePaymentToNative(pendingPayment));
        dispatch(setHistoryPendingPayment(undefined))

        dispatch(setHome(Home.Pay));

        if(boppButton) {
            dispatch(notifyWentToBankMessageAction({paylink: paymentRequest.paylinkUrl}));
        }

        if(flow === "DEV"){
            dispatch(openCallBackPage(paymentRequest.authUrl));
        } else {
            window.location.replace(paymentRequest.authUrl);
        }
    };

    useEffect(() => {
        if(status === Status.Success || status === Status.Failed || status === Status.Cancelled) {
            clearInterval(timerId);
        } else if(status === Status.Unavailable) {
            setUnavailableBank(true);
        }
    }, [status]);

    const chooseBank = () => {
        setPopUp(false);
        setChooseBankModal(true);
    };

    const closeAccountDeactivatedModal = () => {
        setAccountDeactivatedModal(false);
        dispatch(showAccountDeactivated(null));
        closePaymentDetails();
    };

    const closeLimitReachedModal = () => {
        setLimitReachedModal(false);
        dispatch(showLimitReached(null));
        closePaymentDetails();
    };

    const closeUnavailableBank = () => {
        closePaymentDetails()
    };

    const openAnotherChooseBank = () => {
        setUnavailableBank(false);
        dispatch(setPaymentRequest({...paymentRequest, payerBank:null, isAuthUrlRequestSent:false, authUrl:null}))
        dispatch(handlePaymentStatus({status: ''}));
        setChooseBankModal(true);
    };

    const handleOpenChooseBankModal = () => {
        setChooseBankModal(true);
        setClickChange(true);
    };

    const closeChooseBankModal = (selectedBank) => {
        if(selectedBank){
            if(paymentRequest?.payerBank?.api) {
                if(paymentRequest.payerBank.api != selectedBank.api) {
                    dispatch(setPaymentRequest({...paymentRequest, payerBank:selectedBank, isAuthUrlRequestSent:true, authUrl:null}))
                    createPaymentInctruction(selectedBank.api, paymentRequest.amount)
                }
            } else {
                dispatch(setPaymentRequest({...paymentRequest, payerBank:selectedBank, isAuthUrlRequestSent:true}))
                createPaymentInctruction(selectedBank.api, paymentRequest.amount)
            }
        }
        setChooseBankModal(false);
    };

    const changeBank = () => {
        if(iframeRedirectUrl) {
            setChooseBankModal(true);
        } else {
            if (!localStorage.getItem('bankingTipShown')) {
                setPopUp(true);
            } else {
                setChooseBankModal(true);
            }
        }
    };

    const handleClickPay = () => {
        if(iframeRedirectUrl) {
            window.parent.postMessage({
                source:'BOPPAPP',
                action:'authUrl',
                data:paymentRequest?.authUrl,
            }, "*")
         } else {
            if((boppButton && platform === PlatformType.Web) || platform === PlatformType.Desktop) {
                handlePay("DEV")
                return
            }
    
            config.appEnvironment === "DEV" ? setChooseFlowPopup(true) : handlePay("STANDARD");  
         }
    };

    const cancelConsent = () => {
        dispatch(clearPaylinkUrl());
        history.push('/next');
    };

    const isButtonDisabled = () => {
        return !paymentRequest?.authUrl;
    }

    const renderAmountScreen = () => {
        if (fixedAmount) {
            dispatch(setPayerStep(payerSteps.setAdditionalInfo))
        }

        const contentTitleStyle = platform === PlatformType.Desktop ? {} : amountScreenStyles['content--title'];
        const unsettledAmountStyle = platform === PlatformType.Desktop ? {} : amountScreenStyles['content--unsettled-amount'];
        return(
            <>
                <div className={`amount-entry ${platform === PlatformType.Desktop ? 'amount-entry--desktop' : ''}`}>
                    <div className="amount-entry__content">
                        {
                            platform !== PlatformType.Desktop &&
                            <img onClick={handleCloseButtonClick} className="close-button" style={amountScreenStyles['close-button']} src={CloseIcon} alt="close" />
                        }
                        <div className="amount-entry__content-wrapper">
                            <div>
                                <p className="amount-entry__content--title" style={contentTitleStyle}>Payment to</p>
                                <div className='amount-entry__content-bank-wrap'>
                                    <BankAccountName
                                        alwaysSmallFont
                                        isBold={false}
                                        name={paymentRequest?.name}
                                    />
                                </div>
                                <div className="amount-entry__content--amount">
                                    <p className="amount-entry__content--unsettled-amount" style={unsettledAmountStyle}>
                                        Set payment amount
                                    </p>
                                    <div className='amount-entry__content--input-amount'>
                                        <AmountInput
                                            controlledAmount={{
                                                value: amountSetByUser,
                                                onChange: setAmountSetByUser,
                                            }}
                                            payee={true}
                                            showAmountSettings={true}
                                            fixedAmount={true}
                                            numpad={platform !== PlatformType.Desktop}
                                            error={incorrectAmount}
                                            hideLabel
                                        />
                                    </div>
                                </div>
                            </div>
                                {platform === PlatformType.Desktop &&
                                <div className='amount-entry__desktop-footer'>
                                    <Button title="Next" disabled={+amountSetByUser < +minAmount || amountSetByUser === '0.00' || +amountSetByUser > +maxAmount} color="black" handleClick={setPaymentAmount}/>
                                </div>
                                }
                        </div>
                    </div>
                    {platform !== PlatformType.Desktop &&
                    <div className="amount-entry__footer">
                        <Button
                            title="Next"
                            style={amountScreenStyles['footer__button']}
                            disabled={+amountSetByUser < +minAmount || amountSetByUser === '0.00' || +amountSetByUser > +maxAmount}
                            color="black"
                            handleClick={setPaymentAmount}
                        />
                    </div>
                    }
                </div>
            </>
        )
    }

    const renderAdditionalInformation = () => {
        const paymentRequestReference = paymentRequest.settings.reference;
        const notes = paymentRequest.settings.notes;
        const paymentRequestEmail = paymentRequest.settings.requestEmail;
        const giftAid = paymentRequest?.settings?.giftAid?.enabled;


        if (paymentRequestReference.type !== ReferenceSettingType.SetByPayer && !notes.enabled && !paymentRequestEmail.enabled) {
            dispatch(setPayerStep(giftAid ? payerSteps.setGiftAid : payerSteps.setPaymentDetails));
        }

        const onClickNextButton = () => {
            const notesPattern = /^[\w\s.,:;()\"\'\&\#\!\?\%]{0,200}$/
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const referencePattern = /^[a-zA-Z0-9 ]{1,18}$/;
            const patternTests = [];
            let tempError = false;

            if (notes.enabled) {
                patternTests.push({data: note, stateControl: setErrorNote, pattern: notesPattern});
            }
            if (paymentRequestEmail.enabled && (paymentRequestEmail.mandatory || email)) {
                patternTests.push({data: email, stateControl: setErrorEmail, pattern: emailPattern});
            }
            if (paymentRequestReference.type === ReferenceSettingType.SetByPayer) {
                patternTests.push({data: reference.trim().replace(/\s\s+/g, ' '), stateControl: setErrorReference, pattern: referencePattern});
            }

            patternTests.forEach(({data, pattern, stateControl}) => {
                if (!pattern.test(data)) {
                    tempError = true;
                    stateControl(true);
                }
            });
            if (!tempError) {
                if (giftAid) {
                    dispatch(setPayerStep(payerSteps.setGiftAid));
                } else {
                    dispatch(setPayerStep(payerSteps.setPaymentDetails));
                }
            }
        }

        const renderPayeeNote = () => {
            return (
                <div className={`additional-info--input-block ${platform === PlatformType.Desktop && " additional-info--input-block_desktop"}` }>
                    <div className="additional-info--payee-note">
                        <img src={InfoIcon}/>
                        <p>Notes from requester</p>
                    </div>
                    <p className="additional-info--payee-note-text">{paymentRequest.settings.notes.notesValue}</p>
                </div>
            )
        }

        const renderOptionalNote = () => {
            const handleNoteChange = (e) => {
                const notesPattern = /^[\w\s.,:;()\"\'\&\#\!\?\%]{0,200}$/
                if (notesPattern.test(e.target.value)) {
                    setNote(e.target.value)
                } else {
                    setErrorNote(false)
                }
            }

            return (
                <div className={`additional-info--input-block ${platform === PlatformType.Desktop && " additional-info--input-block_desktop"}` }>
                    {notes.makeNotesMandatory ? (
                        <p className='additional-info--input-block-label'>{notes.notesCaption ? notes.notesCaption : "Notes"}
                            <span className="additional-info--input-block-asterisk"> *</span>
                        </p>
                    ):(
                        <p>{notes.notesCaption ? notes.notesCaption : "Notes"}</p>
                    )}
                    <textarea
                        value={note}
                        onChange={handleNoteChange}
                        style={errorNote ? {
                            border: "1px solid #fe655b"
                        } : null}
                    />
                    <p className='additional-info--input-block-caption'>Max 200 chars</p>
                </div>
            )
        }

        const renderOptionalEmail = () => {
            const handleChangeUserAgreement = () => {
                if (email) {
                    setUserAgreement(!userAgreement)
                }
            }

            const handleChangeUserEmail = (e) => {
                setEmail(e.target.value)
                if(errorEmail) {
                    setErrorEmail(false)
                }
            }

            return (
                <div className={`additional-info--input-block ${platform === PlatformType.Desktop && " additional-info--input-block_desktop"}` }>
                    <p className='additional-info--input-block-label'>Your email
                        {paymentRequestEmail.mandatory && (<span className="additional-info--input-block-asterisk"> *</span>)}
                    </p>
                    <input
                        className="additional-info--input-block_email-input"
                        value={email}
                        onChange={handleChangeUserEmail}
                        style={errorEmail ? {
                            border: "1px solid #fe655b"
                        } : null}
                    />
                    {paymentRequestEmail.displayMarketingOptIn && (
                        <div className="additional-info--input-block-checkbox">
                            <div>
                                <input
                                    id="additional-info--input-block-checkbox_custom"
                                    type="checkbox"
                                    className="additional-info--input-block-checkbox_custom"
                                    checked={userAgreement}
                                    onChange={() => handleChangeUserAgreement()}
                                />
                                <label htmlFor="additional-info--input-block-checkbox_custom"></label>
                            </div>
                            <p>
                                I’m happy for BOPP to pass my contact details to {paymentRequestEmail.organizationName || paymentRequest.name} so they can keep in touch with me by email.
                            </p>
                        </div>
                    )}
                </div>
            )
        }

        const renderPaymentReference = () => {
            const handleReferenceChange = (e) => {
                if(!isPaymentReferenceValid(e.target.value)) {
                    return;
                }

                setReference(e.target.value);

                if (errorReference) {
                    setErrorReference(false);
                }
            }

            const handleReferenceBlur = () => {
                if (lastCharacterIsSpace(reference)) {
                    setReference(reference.substring(0, reference.length - 1));
                }
            }

            return (
                <div className={`additional-info--input-block ${platform === PlatformType.Desktop && " additional-info--input-block_desktop"}` }>
                    <p className='additional-info--input-block-label'>Payment reference
                        <span className="additional-info--input-block-asterisk"> *</span>
                    </p>
                    <input
                        value={reference}
                        onChange={handleReferenceChange}
                        onBlur={handleReferenceBlur}
                        style={errorReference ? {
                            border: "1px solid #fe655b"
                        } : null}
                    />
                    <p className='additional-info--input-block-caption'>Max 18 chars - only letters, numbers and spaces allowed.</p>
                </div>
            )
        }

        const renderAdditionalInfoContent = () => {
            const fields = []

            if (paymentRequestReference.type === ReferenceSettingType.SetByPayer) {
                fields.push(renderPaymentReference())
            }
            if (notes.enabled && (notes.type === "SetByMe")) {
                fields.push(renderPayeeNote());
            } else if (notes.enabled) {
                fields.push(renderOptionalNote());
            }
            if (paymentRequestEmail.enabled) {
                fields.push(renderOptionalEmail());
            }
            return fields;
        }

        const footerClassName = classNames([
            {'payment-details__footer': platform !== PlatformType.Desktop},
            {'payment-details__desktop-button': platform === PlatformType.Desktop},
        ])

        const containerClassName = classNames([
            {'payment-details__desktop-wrapper': platform === PlatformType.Desktop},
            {'additional-info': platform !== PlatformType.Desktop},
            {'additional-info--space-between': platform !== PlatformType.Desktop && !keyboardIsVisible},
        ])

        return (
            <>
                <div className={platform === PlatformType.Desktop ? 'payment-details__desktop-background' : "background-left-top-gradient"}>
                    <div className={containerClassName}>
                        <div className="additional-info__content">
                            {platform !== PlatformType.Desktop && <HeaderUniversal
                                title={'Additional Information'}
                                rightButtonIcon={CloseIcon}
                                leftButtonIcon={BackArrow}
                                classForCustomStyles={"header-uni"}
                                customStylesTitle={{top: '40px', fontSize: '18px', fontWeight: '700'}}
                                rightButtonEvent={handleCloseButtonClick}
                                leftButtonEvent={(() => dispatch(setPayerStep(payerSteps.setAmount)))}
                            />}
                            {platform === PlatformType.Desktop && (
                                <>
                                    <img src={BackArrow}
                                         onClick={() => dispatch(setPayerStep(payerSteps.setAmount))}
                                         className="payment-details__desktop-backArrow"

                                    />
                                    <p className="payment-details__desktop-title">Additional Information</p>
                                </>
                            )}
                            <p className={platform === PlatformType.Desktop ? "payment-details__desktop-description" : "additional-info--description"}>Before you proceed please review and supply
                                additional information where requested.</p>
                            {renderAdditionalInfoContent()}
                        </div>
                        <div className={footerClassName}>
                            {<Button
                                title="Next"
                                disabled={
                                    !note && notes.makeNotesMandatory ||
                                    !email && paymentRequestEmail.mandatory && email.enabled ||
                                    !reference ||
                                     errorNote ||
                                     errorEmail ||
                                     errorReference
                                }
                                color="black"
                                handleClick={onClickNextButton}/>}
                        </div>
                    </div>
                </div>
            </>
        )
    };

    const renderGiftAidInformation = () => {
        const renderFullName = () => {
            const handleFullNameChange = (e) => {
                setFullName(e.target.value)
                if (errorFullName) {
                    setErrorFullName(false);
                }
            }

            return(
                <div className={`additional-info--input-block ${platform === PlatformType.Desktop && " additional-info--input-block_desktop"}` }>
                    <p>Full name
                        <span className="additional-info--input-block-asterisk"> *</span>
                    </p>
                    <input
                        value={fullName}
                        onChange={handleFullNameChange}
                        style={errorFullName ? {
                            border: "1px solid #fe655b"
                        } : null}
                    />
                </div>
            )
        }

        const renderAddress = () => {
            const handleAddressChange = (e) => {
                setAddress(e.target.value)
                if (errorAddress) {
                    setErrorAddress(false);
                }
            }

            return(
                <div className={`additional-info--input-block ${platform === PlatformType.Desktop && " additional-info--input-block_desktop"}` }>
                    <p>Address
                        <span className="additional-info--input-block-asterisk"> *</span>
                    </p>
                    <input
                        value={address}
                        onChange={handleAddressChange}
                        style={errorAddress ? {
                            border: "1px solid #fe655b"
                        } : null}
                    />
                </div>
            )
        }

        const renderPostCode = () => {
            const handlePostCodeChange = (e) => {
                setPostCode(e.target.value)
                if (errorPostCode) {
                    setErrorPostCode(false);
                }
            }

            return(
                <div className={`additional-info--input-block ${platform === PlatformType.Desktop && " additional-info--input-block_desktop"}` }>
                    <p>Post code
                        <span className="additional-info--input-block-asterisk"> *</span>
                    </p>
                    <input
                        value={postCode}
                        onChange={handlePostCodeChange}
                        style={errorPostCode ? {
                            border: "1px solid #fe655b"
                        } : null}
                    />
                </div>
            )
        }

        const checkDataBeforeDispatch = () => {
            const fullNamePattern = /^[a-zA-Z0-9 "'-]{1,150}$/;
            const addressPattern = /^[a-zA-Z0-9 #'/№,.\/?-]{1,256}$/;
            const postCodePattern = /^[a-zA-Z0-9 ]{6,8}$/;
            let patternTests = [];
            let tempError = false;

            patternTests.push({data: fullName, stateControl: setErrorFullName,pattern: fullNamePattern});
            patternTests.push({data: address, stateControl: setErrorAddress,pattern: addressPattern})
            patternTests.push({data: postCode, stateControl: setErrorPostCode,pattern: postCodePattern})

            patternTests.forEach(({data, pattern, stateControl}) => {
                if (!pattern.test(data)) {
                    tempError = true;
                    stateControl(true);
                }
            })

            if (!tempError) {
                dispatch(setPayerStep(payerSteps.setPaymentDetails));
            }
        }

        const footerClassName = classNames([
            {'additional-info__footer_desktop': platform === PlatformType.Desktop},
            {'additional-info__footer': platform !== PlatformType.Desktop},
            {'additional-info__footer--absolute': platform !== PlatformType.Desktop && !keyboardIsVisible},
        ])

        return(
            <div className={platform === PlatformType.Desktop ? 'payment-details__desktop-background' : "background-left-top-gradient"}>
                <div className={platform === PlatformType.Desktop ? "payment-details__desktop-wrapper" : "additional-info"}>
                    <div className="additional-info__content">
                        {platform !== PlatformType.Desktop && <HeaderUniversal
                            title="Add Gift Aid Information"
                            rightButtonIcon={CloseIcon}
                            leftButtonIcon={BackArrow}
                            classForCustomStyles={"header-uni"}
                            customStylesTitle={{top: '40px', fontSize: '18px', fontWeight: '700'}}
                            rightButtonEvent={handleCloseButtonClick}
                            leftButtonEvent={() => dispatch(setPayerStep(payerSteps.setGiftAid))}/>}
                        {platform === PlatformType.Desktop && (
                            <>
                                <img src={BackArrow}
                                     onClick={() => dispatch(setPayerStep(payerSteps.setGiftAid))}
                                     className="payment-details__desktop-backArrow"

                                />
                                <p className="payment-details__desktop-title">Add Gift Aid Information</p>
                            </>
                        )}
                        <div className={platform === PlatformType.Desktop ? "additional-info__content-inputs-block_desktop" : "additional-info__content-inputs-block"}>
                            {renderFullName()}
                            {renderAddress()}
                            {renderPostCode()}
                        </div>
                    </div>
                    <div className={footerClassName}>
                        <Button
                            handleClick={checkDataBeforeDispatch}
                            disabled={
                                !fullName ||
                                !address ||
                                !postCode ||
                                errorFullName ||
                                errorAddress ||
                                errorPostCode
                            }
                            title="Next"
                            color="black"/>
                    </div>
                </div>
            </div>
        )
    }

    const renderPaymentDetails = () => {
        const paymentRequestSettingsReference = paymentRequest?.settings.reference;
        const notes = paymentRequest?.settings.notes;
        const paymentRequestSettingsEmail = paymentRequest?.settings.requestEmail;
        const giftAid = paymentRequest?.settings.giftAid;

        const referenceMessage = () => {
            switch (paymentRequestSettingsReference?.type) {
                case "SetByMe":
                    return paymentRequest.reference;
                case "Autonumber":
                    return paymentRequest.reference;
                default:
                    return reference;
            }
        }

        const hideDetailsButton =  paymentRequest?.amountTerm === TermType.FixedAmount && paymentRequestSettingsReference.type !== "SetByPayer" && !paymentRequestSettingsEmail.enabled && !notes.enabled && !giftAid?.enabled;
        const refClassName = classNames([
            "payment-details__content--ref_desktop",
            {"payment-details__content--ref-with-border": !hideDetailsButton}
        ]);

        const renderFeeNotes = (platform) => {
            if (!giftAid?.enabled) {
                return <></>
            }

            if (platform === PlatformType.Desktop) {
                return (
                    <span className='payment-details__fee_notes' style={{marginTop: hideDetailsButton ? 7 : 0}}>
                        BOPP will charge 0.5% transaction fee up to a max of 50p.
                    </span>
                );
            }

            return (
                <span
                    className='payment-details-mobile__fee_notes'
                    style={detailsScreenStyles['payment-details__fee_notes']}
                >
                    BOPP will charge 0.5% transaction fee up to a max of 50p.
                </span>
            )
        }

        if (platform === PlatformType.Desktop) {
            return(
                <>
                    <div className='payment-details__desktop-background'>
                        <div className="payment-details__desktop-wrapper">
                            <div>
                            <div className="payment-details__content--request_desktop">
                                <p
                                    className="payment-details__content--title_desktop"
                                >
                                    {
                                        giftAid?.enabled
                                            ? 'You are about to donate'
                                            : 'Please select your bank and click \'Pay\' to pay'
                                    }
                                </p>
                                <div className="payment-details__content--bank">
                                    <BankAccountName name={paymentRequest?.name} />
                                </div>
                                <div
                                    className="payment-details__content--amount_desktop"
                                >
                                    <p>{formatter.format(amountSetByUser)}</p>
                                </div>
                                <p className={refClassName}>{referenceMessage()}</p>
                                { hideDetailsButton ?
                                    null :
                                    <Button
                                        title='Review payment details'
                                        color='lightDarkDesktop'
                                        handleClick={() => setShowModal(true)}
                                        className='payment-details__review_button'
                                    />
                                }
                                { renderFeeNotes(platform) }
                            </div>
                        </div>
                            <div className="payment-details__footer">
                                <div className="payment-details__choose-bank">
                                    <ChooseBankPanel openModal={changeBank} openChooseBankModal={handleOpenChooseBankModal}/>
                                </div>
                                <div
                                    className="payment-details__footer--terms-text_desktop"
                                >
                                    By clicking on 'Pay' you are agreeing to the BOPP
                                    {' '}
                                    <u><a onClick={openAgreement}>User Agreement</a></u>
                                </div>
                                <div className="payment-details__footer--pay">
                                    <Button
                                        className='payment-details__footer_pay_button'
                                        color="black"
                                        disabled={isButtonDisabled()}
                                        handleClick={handleClickPay}
                                        title={<span className="title"><img src={Lock} alt="lock" className="button-lock"/>Pay</span>}
                                    />
                                    <div
                                        className="payment-details__footer--terms-text_desktop"
                                    >
                                        {paymentRequest?.taglineText}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    { isOpenChooseBank && <ChooseBank closeChooseBankModal={closeChooseBankModal} payer={true} pispDisabled={false}/>}
                </>
            )
        }

        const cardClassName = classNames([
            "payment-details-mobile__card",
            {"payment-details-mobile__card--half-rounded": !hideDetailsButton},
        ]);

        const renderDetailsButton = () => {
            if (hideDetailsButton) {
                return <></>
            }

           return (
                <div
                    className='payment-details-mobile__review_button'
                    style={detailsScreenStyles['payment-details__review_button']}
                    onClick={() => setShowModal(true)}
                >
                    <img
                        src={EditIcon}
                        className='payment-details-mobile__review_button_icon'
                        style={detailsScreenStyles['payment-details__review_button_icon']}
                    />
                    <span
                        className='payment-details-mobile__review_button_title'
                        style={detailsScreenStyles['payment-details__review_button_title']}
                    >
                        Review payment details
                    </span>
                </div>
           )
        }

        const renderPayButtonTitle = () => {
            return (
                <span
                    className="payment-details-mobile__pay_button_title"
                    style={detailsScreenStyles['payment-details__pay_button_title']}
                >
                    <img
                        src={Lock}
                        alt="lock"
                        className='payment-details-mobile__pay_button_icon'
                        style={detailsScreenStyles['payment-details__pay_button_icon']}
                    />
                    Pay
                </span>
            )
        }

        return (
            <>
                <div className="payment-details-mobile">
                    <HeaderUniversal
                        rightButtonIcon={CloseIcon}
                        customStylesRightBtn={customStylesHeaderButton}
                        classForCustomStyles={'payment-details__header'}
                        rightButtonEvent={handleCloseButtonClick}
                    />
                    <div
                        className='payment-details-mobile__inner'
                        style={detailsScreenStyles['payment-details__inner']}
                    >
                        <span
                            className="payment-details-mobile__title"
                            style={detailsScreenStyles['payment-details__title']}
                        >
                            {
                                giftAid?.enabled
                                ? 'You are about to donate'
                                : 'Please select your bank and click \'Pay\' to pay'
                            }
                        </span>
                        <div
                            className={cardClassName}
                            style={detailsScreenStyles['payment-details__card']}
                        >
                            <BankAccountName alwaysSmallFont name={paymentRequest?.name} />
                            <span
                                className='payment-details-mobile__amount'
                                style={detailsScreenStyles['payment-details__amount']}
                            >
                                {formatter.format(amountSetByUser)}
                            </span>
                            <span
                                className='payment-details-mobile__reference'
                                style={detailsScreenStyles['payment-details__reference']}
                            >
                                {referenceMessage()}
                            </span>
                        </div>
                        { renderDetailsButton() }
                        { renderFeeNotes() }
                        <div className='payment-details-mobile__bottom'>
                            <ChooseBankPanel openModal={changeBank} openChooseBankModal={handleOpenChooseBankModal}/>
                            <span
                                className='payment-details-mobile__terms_text'
                                style={detailsScreenStyles['payment-details__terms_text']}
                            >
                                By clicking on 'Pay' you are agreeing to the BOPP <a onClick={openAgreement}>User Agreement</a>
                            </span>
                            <Button
                                color="black"
                                disabled={isButtonDisabled()}
                                handleClick={handleClickPay}
                                title={renderPayButtonTitle()}
                                className='payment-details-mobile__pay_button'
                                style={detailsScreenStyles['payment-details__pay_button']}
                            />
                            <span
                                className='payment-details-mobile__terms_text'
                                style={detailsScreenStyles['payment-details__powered_by_text']}
                            >
                                {paymentRequest?.taglineText}
                            </span>
                        </div>
                    </div>
                </div>
                { isOpenChooseBank && <ChooseBank closeChooseBankModal={closeChooseBankModal} payer={true} pispDisabled={false}/>}
            </>
        )
    }

    const changeGiftAidConfirmMoney = (bool) => {
        setGiftAidConfirmMoney(bool);
    }

    const changeGiftAidConfirmDonation = (bool) => {
        setGiftAidConfirmDonation(bool);
    }

    const renderContent = () => {
        switch (currentPayerStep) {
            case payerSteps.setAmount:
                return renderAmountScreen();
            case payerSteps.setAdditionalInfo:
                return renderAdditionalInformation();
            case payerSteps.setGiftAid:
                return (
                    <GiftAid
                        setInfoDetailsPopUp={setInfoDetailsPopUp}
                        onCloseButtonClick={handleCloseButtonClick}
                        onCheckboxesChange={(changed) => setGiftAidCheckboxesChanged(changed)}
                        changeGiftAidConfirmMoney={changeGiftAidConfirmMoney}
                        changeGiftAidConfirmDonation={changeGiftAidConfirmDonation}
                        giftAidConfirmMoney={giftAidConfirmMoney}
                        giftAidConfirmDonation={giftAidConfirmDonation}
                    />
                );
            case payerSteps.setGiftAidInformation:
                return renderGiftAidInformation();
            case payerSteps.setPaymentDetails:
                return renderPaymentDetails();
        }
    }

    const renderCancelPopup = () => {
        return showCancelPopup &&
        <Popup
            title='Cancel Payment'
            description='Are you sure you want to cancel payment?'
            bottomContent={
                <div className='payment-details__cancel_popup'>
                    <Button
                        title='Back to page'
                        color='black'
                        className='payment-details__cancel_popup_button'
                        handleClick={() => setShowCancelPopup(false)}
                    />
                    <span className='payment-details__cancel_popup_link' onClick={closePaymentDetails}>Cancel Payment</span>
                </div>
            }
        />
    }

    return (
        <>
            { popUpQuestion && <TipModal gotItCallback={chooseBank} tipMessage={"bankAppTip"}/> }
            { infoDetailsPopUp && <PaymentInfoDetails onClickCallback={changeInfoDetailsPopup}/> }
            { accountDeactivatedModal && <AccountDeactivatedModal closeModal={closeAccountDeactivatedModal} onClickCallback={changeInfoDetailsPopup}/> }
            { showModal &&
            <ReviewPaymentDetailsModal
                closeModal={() => setShowModal(false)}
                showModal={showModal}
                note={note}
                email={email}
                reference={reference}
                fullName={fullName}
                address={address}
                postCode={postCode}
                amount={amountSetByUser}
            /> }
            { limitReachedModal && <LimitReachedModal closeModal={closeLimitReachedModal} /> }
            { unavailableBank && <BankUnavailableModal closeModal={closeUnavailableBank} handleAnotherChooseBank={openAnotherChooseBank} />}
            { timeModal &&
            <SelectionModal
                title={"Sorry, this page has timed out."}
                optionTop={'REFRESH PAGE'}
                optionBottom={'CANCEL PAYMENT'}
                selectOptionTop={refreshPage}
                selectOptionBottom={cancelConsent}
            />
            }
            { chooseFlowPopup &&
            <SelectionModal
                title={"Choose Flow"}
                optionTop={"Dev flow"}
                optionBottom={"Standard Flow"}
                selectOptionBottom={()=>{setChooseFlowPopup(false); handlePay("STANDARD")}}
                selectOptionTop={()=>{setChooseFlowPopup(false); handlePay("DEV")}}
            />
            }
            {renderContent()}
            { renderCancelPopup() }
            { isInsideApp && <CheckInsideApp/> }
        </>
    )
};

export default PaymentDetailsScreen;

const customStylesHeaderButton = {
    top: cssVhToPx(3),
    width: cssVhToPx(3.6),
    height: cssVhToPx(3.6),
};

const amountScreenStyles = {
    ['close-button']: {
        width: cssVhToPx(3.6),
        height: cssVhToPx(3.6),
    },
    ['content--title']: {
        fontSize: cssVhToPx(2.1),
    },
    ['content--unsettled-amount']: {
        fontSize: cssVhToPx(2.1),
    },
    ['footer__button']: {
        height: cssVhToPx(8.1),
    },
}

const detailsScreenStyles = {
    ['payment-details__inner']: {
        paddingTop: cssVhToPx(13.5),
    },
    ['payment-details__title']: {
        fontSize: cssVhToPx(2.1),
    },
    ['payment-details__card']: {
        marginTop: cssVhToPx(1.5),
        padding: `${cssVhToPx(3)} 0`,
    },
    ['payment-details__amount']: {
        fontSize: cssVhToPx(6),
        margin: `${cssVhToPx(0.6)} 0`,
    },
    ['payment-details__reference']: {
        fontSize: cssVhToPx(2.7),
    },
    ['payment-details__review_button']: {
        padding: `${cssVhToPx(1.8)} 0`,
    },
    ['payment-details__review_button_icon']: {
        width: cssVhToPx(3),
        height: cssVhToPx(3),
    },
    ['payment-details__review_button_title']: {
        fontSize: cssVhToPx(2.1),
    },
    ['payment-details__fee_notes']: {
        fontSize: cssVhToPx(2.1),
        marginTop: cssVhToPx(2.25),
    },
    ['payment-details__terms_text']: {
        fontSize: cssVhToPx(2.1),
        margin: `${cssVhToPx(1.5)} 0 ${cssVhToPx(2.7)}`,
    },
    ['payment-details__powered_by_text']: {
        fontSize: cssVhToPx(2.1),
        margin: `${cssVhToPx(1.5)} 0`,
    },
    ['payment-details__pay_button']: {
        height: cssVhToPx(8.4),
    },
    ['payment-details__pay_button_title']: {
        fontSize: cssVhToPx(2.7),
    },
    ['payment-details__pay_button_icon']: {
        height: cssVhToPx(2.4),
        width: cssVhToPx(2.4),
    },
}
