import { common } from ".";
import { createId } from './../helpers'

interface CancelAccountDetailsRequest extends common.Activation {
    previousId: string,
    entityType: string,
}

export class CancelAccountDetails {
    accountDetailsId: string

    constructor(accountDetailsId: string) {
        this.accountDetailsId = accountDetailsId
    }

    createRequest() {
        return this.getRequestParams()
    }

    getRequestParams() : CancelAccountDetailsRequest {
        return  {
            "@type": "https://dvschema.io/activation#CancelRequest",
            id: createId(),
            previousId: this.accountDetailsId,
            entityType: "https://miapago.io/ob/accountdetails#AccountDetails",
            timestamp: new Date(),
            possibleResend: false,
        }
    }
}