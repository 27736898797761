import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router'

import 'typeface-muli';

import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.scss';

import { store, persistor , history} from './store';

ReactDOM.render(
  <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
