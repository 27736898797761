import { PermissionType, PermissionState } from '../static/CommonDefinitions'

const PERMISSIONS_SET_STATUS = 'permissions/set/status';

export const setPermissionStatus = (permission, status) => ({
    type: PERMISSIONS_SET_STATUS,
    payload: {
        permission,
        status
    }
});

export const initialState = {
    location : PermissionState.Denied,
    bluetooth : PermissionState.Denied,
    camera : PermissionState.Denied,
    none : PermissionState.Granted // "none" needed to handle failure cases and it will always should have "granted" state as it means that we shouldn't ask anything
};

export const permissionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case PERMISSIONS_SET_STATUS:
            if(action.payload.permission === PermissionType.Bluetooth) {
                return {
                    ...state,
                    bluetooth: action.payload.status,
                };
            } else if(action.payload.permission === PermissionType.Location) {
                return {
                    ...state,
                    location: action.payload.status,
                };
            } else if(action.payload.permission === PermissionType.Camera) {
                return {
                    ...state,
                    camera: action.payload.status,
                };
            } else {
                return {
                    ...state
                };
            }
        default:
        return {
            ...state,
        };
    }
};

export default permissionsReducer;