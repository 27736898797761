import React, {useState, useRef} from 'react';
import clsx from 'clsx';
import './styles.scss';

const DraggableList = ({className, list, onOrderChanged, itemKey, render}) => {
  const elements = useRef([])
  const placeholder = useRef(null)
  const [activeIndex, setActiveIndex] = useState(null)
  const [activeElementHeight, setActiveElementHeight] = useState(null)
  const [startY, setStartY] = useState(0)
  const [offsetY, setOffsetY] = useState(0)

  const dragStart = (index) => (event) => {
    event.stopPropagation()
    const element = elements.current[index]
    element.style.width = `scale(1.04)`
    placeholder.current = document.createElement('li')
    placeholder.current.style.height = `${element.clientHeight}px`
    placeholder.current.style.visibility = 'hidden'
    element.parentElement.insertBefore(placeholder.current, element.nextSibling)

    const [touch] = event.touches
    setStartY(touch.clientY)
    const elementComputedStyle = getComputedStyle(element)
    setActiveElementHeight(
      Number.parseInt(elementComputedStyle.getPropertyValue('margin-top')) +
      Number.parseInt(elementComputedStyle.getPropertyValue('height')) +
      Number.parseInt(elementComputedStyle.getPropertyValue('margin-bottom'))
    )
    setActiveIndex(index)
  }

  const drag = ({touches}) => {
    if (activeIndex === null) return;
    const [touch] = touches
    const currentOffsetY = touch.clientY - startY
    const element = elements.current[activeIndex]

    const affectedElements = Math.floor(Math.abs(currentOffsetY / element.clientHeight))
    if (affectedElements > 0) {
      const direction = Math.sign(currentOffsetY)
      for (let index = 1; index <= affectedElements; ++index) {
        elements.current[activeIndex + index * direction].style.transform = `translateY(${activeElementHeight * direction * -1}px)`
      }
    }
    const unaffectedElements = Math.floor(Math.abs(offsetY / element.clientHeight)) - affectedElements
    if (unaffectedElements > 0) {
      const direction = Math.sign(currentOffsetY)
      for (let index = 1; index <= unaffectedElements; ++index) {
        elements.current[activeIndex + affectedElements * direction +  index * direction].style.transform = null
      }
    }

    element.style.transform = `scale(1.04) translateY(${currentOffsetY}px)`
    setOffsetY(currentOffsetY)
  }

  const dragEnd = () => {
    if (activeIndex === null) return;
    placeholder.current.remove()
    setActiveIndex(null)
    elements.current.forEach((element) => {
      if(!element) return;
      element.style.transition = null
      element.style.transform = null
    })
    onOrderChanged(activeIndex, activeIndex + Math.floor(Math.abs(offsetY / elements.current[activeIndex].clientHeight)) * Math.sign(offsetY))
    setStartY(0)
    setOffsetY(0)
  }

  return (
    <ul className={clsx('draggable-list', className)}>
      {list.map((item, index) => (
        <li
          key={itemKey(item)}
          ref={element => elements.current[index] = element}
          className={clsx('draggable-list__item', activeIndex === index && 'draggable-list__item_active')}
          onTouchMove={drag}
          onTouchEnd={dragEnd}
          onTouchCancel={dragEnd}
        >
          {render({item, index, drag: dragStart(index)})}
        </li>
      ))}
    </ul>
  );
}

export default DraggableList;
