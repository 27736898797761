import React, {useEffect, useState, useMemo} from 'react';
import { useSelector } from 'react-redux';

import PlusIcon from '../../assets/images/plus-icon.svg';
import Preloader from "../../assets/images/spinner-black.svg";

import './styles.scss';
import config from '../../config';
import { cssVhToPx, getCircleLogo, onImageError } from '../../helpers';
import { PlatformType } from '../../static/CommonDefinitions';

const ChooseBankPanel = ({ openChooseBankModal, openModal, bankAccount = false }) => {
  const [selectedBank, setSelectedBank] = useState({});
  const paymentRequest = useSelector(state => state.paymentPersist.paymentRequest);
  const accountDetailsBank = useSelector((state) => state.payment.accountDetails.bank);
  const accountDetails = useSelector(state => state.payment.accountDetails);
  const platform = useSelector(state => state.settings.platform);

  useEffect(() => {
    if(bankAccount){
      setSelectedBank(accountDetailsBank);
    } else {
      setSelectedBank(paymentRequest?.payerBank);
    }
  }, [accountDetailsBank, bankAccount, paymentRequest?.payerBank]);

  const renderChangeButton = () => {
    if(bankAccount && ((!accountDetails.state || !accountDetails.state.authorizationURL) && accountDetails.bank)){
      return <img className="spinner" src={Preloader} alt="" style={useInlineStyles ? styles['spinner'] : {}} />;
    }
    if(!bankAccount && !paymentRequest?.authUrl && paymentRequest?.isAuthUrlRequestSent){
      return <img className="spinner" src={Preloader} alt="" style={useInlineStyles ? styles['spinner'] : {}}/>;
    }

    return (
      <div className="choose-title" style={useInlineStyles ? styles['choose-title'] : {}}>
       { selectedBank?.accountName ? 'Change' : 'Choose Bank' }
      </div>
    )
  }

  const useInlineStyles = platform !== PlatformType.Desktop;

  const changeButtonIcon = useMemo(() => {
    return !selectedBank
      ? <img src={PlusIcon} alt="" style={useInlineStyles ? styles['plus-icon'] : {}} />
      : <img
          src={getCircleLogo(selectedBank.logo_uri)}
          onError={(e)=>{onImageError(e, true)}}
          alt="bank-logo"
          className="bank-logo"
        />
  }, [accountDetails.state, selectedBank])

  const className = platform === PlatformType.Desktop ? 'choose-bank-block-desktop' : 'choose-bank-block';

  return (
    <div onClick={()=>{
      if(!(!bankAccount && paymentRequest?.isAuthUrlRequestSent && !paymentRequest?.authUrl) //block selecting bank for PISP while auth url not received
      &&
      !((bankAccount && ((!accountDetails.state || !accountDetails.state.authorizationURL) && accountDetails.bank)))) { //block selecting bank for AISP while auth url not received
        if(selectedBank?.accountName) {
          openChooseBankModal()
        } else {
          openModal()
        }
      }
    }} className={className}>
      <div style={useInlineStyles ? styles['current-bank-image'] : {}} className={`current-bank-image ${!selectedBank?.logo_uri ? 'border' : ''}`}>
        {changeButtonIcon}
      </div>
      <div className="bank-name-block">
        {renderChangeButton()}
      </div>
    </div>
  )
};

const styles = {
  ['current-bank-image']: {
    width: cssVhToPx(7.5),
    height: cssVhToPx(7.5),
  },
  ['plus-icon']: {
    width: cssVhToPx(2.4),
    height: cssVhToPx(2.4),
  },
  ['choose-title']: {
    fontSize: cssVhToPx(2.7),
  },
  ['spinner']: {
    width: cssVhToPx(6),
    height: cssVhToPx(6),
  },
}

export default ChooseBankPanel;
