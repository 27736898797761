import { resolveMessage } from "../resolver/messageResolver";
import { MessageType } from "../static/CommonDefinitions";
import { store } from "../store";

export class StaticSocket {
    private static instance: StaticSocket;
    websocketUrl:any;
    onMessage:any
    socket:any
    data:any
    key:any
    dataToSend:any[]

    /**
     * The Singleton's constructor should always be private to prevent direct
     * construction calls with the `new` operator.
     */
    private constructor() {
        this.dataToSend = []
    }

    /**
     * The static method that controls the access to the singleton instance.
     *
     * This implementation let you subclass the Singleton class while keeping
     * just one instance of each subclass around.
     */
    public static getInstance(): StaticSocket {
        if (!StaticSocket.instance) {
            StaticSocket.instance = new StaticSocket();
        }

        return StaticSocket.instance;
    }

    public isSocketReady (): boolean {
        if(this.websocketUrl && this.key) {
            return true;
        } else {
            return false;
        }
    }

    public setupSocket(websocketUrl: string, apiKey:string) {
        this.websocketUrl = websocketUrl;
        this.key = apiKey;
    }

    private initSocket() {
        this.socket = new WebSocket(this.websocketUrl + '?x-bopp-api-key=' + this.key);
        this.socket.isClosed = true;
        this.socket.onopen = () => {
            this.socket.isClosed = false;
            console.log('Connection opened to: ' + this.websocketUrl + '?x-bopp-api-key=' + this.key);
            if (this.dataToSend.length > 0) {
                this.dataToSend.forEach(element => {
                    this.socket.send(element)    
                    console.log('Sending ' + element)
                });

                this.dataToSend = []
            }
        };

        this.socket.onmessage = (data:any) => {
            if (!data) {
                return;
            }

            const message = JSON.parse(data.data.toString());
            console.log('Received')
            console.log(message)
            
            resolveMessage({socketUrl: this.socket.url, message: {message}}, MessageType.Server, store.dispatch)
        }

        this.socket.onclose = () => {
            this.socket.isClosed = true;
            console.log('log chanel close' + this.socket.url);
        };
    }

    public send (data: string) {
        if(!this.socket || (this.socket && this.socket.readyState === this.socket.CLOSED)) {
            this.dataToSend.push(data);
            this.initSocket();
            return;
        } else if(this.socket.readyState === this.socket.CONNECTING) {
            this.dataToSend.push(data);
        } else {
            console.log('Sending ' + data)
            return this.socket.send(data);
        }
    };

    public setAPIKey (key: string) {
        if(this.key !== key) {
            this.key = key
            if (this.socket) {
                this.initSocket();
            }
        }
    };

    public close (key: string) {
        if (this.socket) {
            this.socket.close();   
        }
    };
}