import * as actionType from '../actions/actionTypes';

export const payerSteps = {
  setAmount: "SET_AMOUNT",
  setAdditionalInfo: "SET_ADDITIONAL_INFO",
  setGiftAid: "SET_GIFT_AID",
  setGiftAidInformation: "SET_GIFT_AID_INFO",
  setPaymentDetails: "SET_PAYMENT_DETAILS",
}

export const initialState = {
  amount: '0.00',
  fixedAmount: false,
  amountSettings: {
    unsettledAmount: false,
    suggestedAmount: '',
    minAmount: '',
  },
  orderNumber: '',
  status: '',
  statusAccountDetails: '',
  paymentStatusScreen: {
    paymentInProcess: false,
    waitingForConsent: false,
    transferringToBank: false,
    consentFromBank: false,
    clickPayViaBank: false,
    clickCancelPayment: false,
    waitingForCancel: false,
    targetBank: null,
  },
  accountDetailsScreen: {
    savingAccountDetails: false,
    transferringToBank: false,
    waitingForConsent: false,
    clickConfirm: false,
    clickCancelConsent: false,
    waitingForCancel: false,
    addBankError: null,
    targetBank: null,
  },
  isAccountDeactivated: null,
  isLimitReached: null,
  loading: false,
  wrongScreen: false,
  bank: {},
  payerBank: {},
  bankList: [],
  usedBankList: [],
  paymentAction: '',
  isNullAmount: false,
  isAddBankAccount: false,
  accountDetails: {
    id: '',
    bank: ''
  },
  activeSession: '',
  transactionCounter: false,
  paylinkUrl: '',
  pendingAccept: false,
  nextRequestId: 0,
  paylink: null,
  otp: null,
  numericModalShown: false,
  isClickedBackButton: false,
  qrErrorModalShowed: false,
  paylinkErrorModalShowed:false,
  error: {
    code: '',
    message: ''
  },
  callbakcURL:'',
  accountLimit: null,
  paymentPayeeSubscriberIds: {
    payee: '',
    subscriber: '',
    giftAidPublicKey: '',
    apiKey:''
  },
  currentPayerStep: payerSteps.setAmount,
  paymentCustomUrlSettings: {
    amount: null,
    reference: null
  },
  refreshSubscriptionRequestStatus:'none',
  failReasonText:null,
  isPaymentSocketClosed:true,
  latestChangeRequest:null,
  iframeRedirectUrl:null,
};

export const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_IFRAME_REDIRECT_URL:
      return {
        ...state,
        iframeRedirectUrl: action.payload,
      };
    case actionType.SET_REFRESH_SUBSCRIPTION_REQUEST_STATUS:
      if(state.refreshSubscriptionRequestStatus != 'sent' && action.payload === 'complete') {
        return {
          ...state
        };
      } else {
        return {
          ...state,
          refreshSubscriptionRequestStatus: action.payload
        };
      }
    case actionType.SET_LATEST_CHANGE_REQUEST:
      return {
        ...state,
        latestChangeRequest: action.payload,
      };
    case actionType.SET_IS_PAYMENT_SOCKET_CLOSED:
      return {
        ...state,
        isPaymentSocketClosed: action.payload,
      };
    case actionType.SET_FAIL_REASON_TEXT:
      return {
        ...state,
        failReasonText: action.payload,
      };
    case actionType.SET_ACCOUNT_LIMIT:
      return {
        ...state,
        accountLimit: action.payload,
      };
    case actionType.SET_PAYEE_SUBSCRIBER_IDS:
      return {
        ...state,
        paymentPayeeSubscriberIds: {
          ...state.paymentPayeeSubscriberIds, ...action.payload
        },
      };
    case actionType.SET_CUSTOM_URL_PAYMENT_SETTINGS:
      return {
        ...state,
        paymentCustomUrlSettings: action.payload,
      };
    case actionType.SET_CALLBACK_URL:
      return {
        ...state,
        callbakcURL: action.payload,
      };
    case actionType.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case actionType.SET_AMOUNT:
      return {
        ...state,
        amount: action.payload,
      };
    case actionType.SET_ORDER_NUMBER:
      return {
        ...state,
        orderNumber: action.payload,
        bank: {
          ...state.bank,
          orderNumber: action.payload,
        },
      };
    case actionType.HANDLE_PAYMENT_STATUS:
      return {
        ...state,
        status: action.payload.status,
        failReasonText: null,
        paymentStatusScreen: {
          ...state.paymentStatusScreen,
          paymentInProcess: false,
        }
      };
    case actionType.HANDLE_ACCOUNT_DETAILS_STATUS:
      return {
        ...state,
        statusAccountDetails: action.payload.statusAccountDetails,
        accountDetailsScreen: {
          ...state.accountDetailsScreen,
          savingAccountDetails: false,
        }
      };
    case actionType.CLEAR_ACCOUNT_DETAILS_STATUS:
      return {
        ...state,
        statusAccountDetails: '',
      };
    case actionType.SELECT_PAYMENT_REQUEST:
      return {
        ...state,
        paymentRequestSelector: action.payload,
      };
    case actionType.UPDATE_ACCOUNT_DETAILS:
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          id: action.payload.id,
        },
      };
    case actionType.UPDATE_ACCOUNT_DETAILS_BANK:
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          bank: action.payload,
        },
      };
    case actionType.REMOVE_ACCOUNT_DETAILS:
      return {
        ...state,
        accountDetails: {}
      };
    case actionType.REMOVE_ACCOUNT_DETAILS_AUTH_URL:
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          state: {
            ...state.accountDetails.state,
            authorizationURL: null
          }
        }
      };
    case actionType.SELECT_BANK:
      return {
        ...state,
        payerBank: action.payload,
      };
    case actionType.CLEAR_PAYER_BANK:
      return {
        ...state,
        payerBank: {},
      };
    case actionType.ADD_BANK:
      return {
        ...state,
        bank: {
          ...state.bank,
          ...action.payload,
        }
      };
    case actionType.REMOVE_BANK:
      return {
        ...state,
        bank: {}
      };
    case actionType.REMOVE_AUTH_URL_PAYER_BANK:
      return {
        ...state,
        payerBank: {
          ...state.payerBank,
          state: {
            ...state.payerBank.state,
            paymentState: {
              ...state.payerBank.state.paymentState,
              authorizationURL: null
            }
          },
        },
      };
    case actionType.SELECT_BANK_ACCEPTED:
      return {
        ...state,
        payerBank: {
          ...state.payerBank,
          state: action.payload,
        },
      };
    case actionType.SELECT_BANK_ACCOUNT_DETAILS:
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          state: action.payload,
        },
      };
    case actionType.SET_PAYMENT_ACTION:
      return {
        ...state,
        activeSession: action.payload.id,
        paymentAction: action.payload.paymentAction,
      };
    case actionType.SET_PAYMENT_REQUEST_METHOD:
      return {
        ...state,
        requestMethod: action.payload,
      };
    case actionType.SET_PAYMENT_INSTRUCTION_METHOD:
      return {
        ...state,
        instructionMethod: action.payload,
      };
    case actionType.CHANGE_ISNULL_AMOUNT:
      return {
        ...state,
        isNullAmount: action.payload,
      };
    case actionType.SET_BANK_LIST:

      return {
        ...state,
        bankList: action.payload,
      };
    case actionType.PUT_TRANSACTION_COUNTER:
      return {
        ...state,
        transactionCounter: action.payload,
      };
    case actionType.CHANGE_PAYMENT_STATUS_SCREEN:
      return {
        ...state,
        paymentStatusScreen: {
          ...state.paymentStatusScreen,
          ...action.payload
        },
      };
    case actionType.CHANGE_ACCOUNT_DETAILS_SCREEN:
      return {
        ...state,
        accountDetailsScreen: {
          ...state.accountDetailsScreen,
          ...action.payload
        },
      };
    case actionType.CHANGE_IS_ADD_BANK_ACCOUNT:
      return {
        ...state,
        isAddBankAccount: action.payload,
      };
    case actionType.PAYLINK_REQUEST_ACCEPTED:
      return {
        ...state,
        paylink: action.payload.paylink,
        otp: action.payload.otp
      };
    case actionType.CLEAR_PAYLINK:
      return {
        ...state,
        paylink: null,
        otp: null
      };
    case actionType.OTP_CALCULATED:
      if (state.paylink && state.paylink === action.payload.reference) {
        return {
          ...state,
          otp: {
            ...state.otp,
            password: action.payload.password,
            expiry: action.payload.expiry,
            createdTime: action.payload.createdTime
          }
        }
      }
      return {
        ...state
      };
    case actionType.CHANGE_PENDING_ACCEPT:
      return {
        ...state,
        pendingAccept: action.payload
      };
    case actionType.CHANGE_WRONG_SCREEN:
      return {
        ...state,
        wrongScreen: action.payload
      };
    case actionType.SAVE_PAYLINK_URL:
      return {
        ...state,
        paylinkChange: !state.paylinkChange,
        paylinkUrl: action.payload
      };
    case actionType.CLEAR_PAYLINK_URL:
      return {
        ...state,
        paylinkUrl: null
      };
    case actionType.SET_USED_BANK_TO_LIST:
      return {
        ...state,
        usedBankList: action.payload || []
      };
    case actionType.CLEAR_USED_LIST_BANK:
      return {
        ...state,
        usedBankList: []
      };
    case actionType.REMOVE_USED_BANK_FROM_LIST:
      return {
        ...state,
        usedBankList: state.usedBankList.filter((bank, index) => index !== action.payload.index)
      };
    case actionType.INVALID_PAYMENT_ERROR:
        return {
          ...state,
          paylinkErrorModalShowed: action.payload
        };
    case actionType.CLICK_BACK_BUTTON:
      return {
        ...state,
        isClickedBackButton: action.payload
      };

    case actionType.SHOW_MESSAGE_ACCOUNT_DEACTIVATED:
      return {
        ...state,
        isAccountDeactivated: action.payload
      };
    case actionType.SHOW_MESSAGE_LIMIT_REACHED:
      return {
        ...state,
        isLimitReached: action.payload
      };
    case actionType.CHANGE_FIXED_AMOUNT:
      return {
        ...state,
        fixedAmount: action.payload
      };
    case actionType.SET_PAYER_STEP:
      return {
        ...state,
        currentPayerStep: action.payload
      };
    case actionType.SET_FIXED_AMOUNT:
      return {
        ...state,
        fixedAmount: action.payload
      };
    default:
      return {
        ...state,
      };
  }
};

export default paymentReducer;
