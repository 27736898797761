import { createId } from "../helpers";
import { common } from ".";

interface statusRequest extends common.Activation {
    entitySelector:common.EntitySelector
}

export interface StatusRequestPayload {
    paylink?:string
    otp?:string
    isPayment?:boolean
}

export class StatusRequest {
    payload:StatusRequestPayload
    id: string
    
    constructor(payload: StatusRequestPayload) {
        this.payload = payload;
        this.id = createId();
    }

    createRequest() {
        return this.getRequestParams()
    }

    getRequestParams() : statusRequest {
        return  {
            "@type": "https://dvschema.io/activation#StatusRequest",
            id: this.id,
            entitySelector: {
                entityType: "https://miapago.io/paylink/request#PaylinkRequest",
                propertiesSelector: {
                    "@type": "https://miapago.io/paylink/request#PropertiesSelector"
                },
                stateSelector: {
                    "@type": "https://miapago.io/paylink/request#StateSelector",
                    paylink: this.payload.paylink,
                    otp: this.payload.otp
                }
            },
            timestamp: new Date(),
            possibleResend: false
        }
    }
}