enum PermissionState {Unavailable = "unavailable", Denied = "denied", Granted = "granted", Blocked = "blocked", Undefined = "undefined" }
enum PermissionType {Bluetooth = "bluetooth", Location = "location", Camera = "camera", Microphone = "microphone", None = "none"}
enum PlatformType {Ios = "ios", Android = "android", Undefined = "undefined", Web = "web", Desktop='desktop'}
enum ScreenType {QrScanner = "qr_scanner", BluetoothGetPaid = "bluetooth_get_paid", BluetoothPayOthers = "bluetooth_pay_others", Undefined = "undefined"}
enum AdapterType {Location = "location", Bluetooth = "bluetooth", None = "none"}
enum AdapterState {Unknown = "Unknown", Unsupported = "Unsupported", Unauthorized = "Unauthorized", Resetting = "Resetting", PoweredOff = "PoweredOff", PoweredOn = "PoweredOn"}
enum KYCVerificationStatus {None = "none", Sent = "sent", Verified = "verified"}
enum AccountType {Personal = "Personal", Business = "Business", Charity = "Charity", Unknown = "Unknown"}
enum MessageType {Server = "Server", App = "App", Ulink = "Ulink"}
enum TermType {FixedAmount = "FixedAmount", AnyAmount = "AnyAmount", SuggestedAmount = "SuggestedAmount", MinAmount = "MinAmount", MaxAmount = "MaxAmount"}
enum PaymentTrigger {OnInstruction = "OnInstruction", EachInstruction = "EachInstruction", XInstructions = "XInstructions"}
enum Status {Unavailable = "unavailable", Success = "success", Failed = "failed", Declined = "declined", Cancelled = "cancelled"}
enum StatusBank {Unhealthy="unhealthy", Healthy="healthy"}
enum Home {Pay = "pay", Request = "request"}
enum PaymentMethod {Otp = "otp", Paylink = "paylink", Qr = "qr", Link = "link"}
enum StatusAccountDetails {Unavailable = "unavailable", Success = "success", Failed = "failed"}
enum InstructionMethod {Otp = "otp", Paylink = "paylink", Qr = "qr", Bluetooth = "bluetooth"}
enum PaymentAction {ClaimedCancel = "claimed-cancel", SendingCancel = "sending-cancel", Sending = "sending", Claimed = "claimed", Unpaired = "unpaired"}
enum AispActivationState {AuthURL = "Pending:Initialised:PendingAccept", AccountDetails = "Activated:Processed:NonePending", AccountCanceled = "Cancelled:Initialised:NonePending", AccountRejected = "Rejected"}
enum BankAccountScheme {SortCodeAccountNumber = "UK.OBIE.SortCodeAccountNumber"}
enum PaymentRequestStatus {Open = "Open", Closed = "Closed"}
enum PaymentRequestStatusReason {DeactivatedByBOPP = "Deactivated by BOPP", Cancelled = "Cancelled", ClosedAutomatically = "Closed Automatically"}
enum PaymentReportState {Activated  = "Activated", Received = "Received", PendingAccept = "PendingAccept", Created = "Created", Confirmed = "Confirmed", Cancelled = "Cancelled", CancelledOnBank = "CancelledOnBank"}
enum PaymentDirection {InCome = "InCome", OutCome = "OutCome"}
enum ReferenceSettingType {SetByMe = "SetByMe", SetByPayer = "SetByPayer", Autonumber = "Autonumber"}
enum NotesSettingType {SetByMe = "SetByMe", SetByPayer = "SetByPayer"}
enum AmountLabelType {Simple = "Simple", Solid = "Solid", None = "None"}
enum PostMessageType {OpenNewTab = "openNewTab", Permission = "permission", Bluetooth = "bluetooth", LocationAdapter = "locationAdapter", Platform = "platform", IsAppLaunchedFirstTime = "isAppLaunchedFirstTime", Storage = "storage", AppState = "appState", BankMessage = "bankMessage", OpenedFromULink = "openedFromULink", ScanQR = "scanQR", AndroidBackButton = "androidBackButton", AppVersion = "appVersion", PinProtection = "pinProtection", Custom = "custom"}
enum PaymentRequestType {Standard = "Standard", Recurring = "Recurring"}
enum BOPPButtonNotificationType {QRScanned = "QRScanned", BankSelected = "BankSelected"}
enum ActivationState {Cancelled = "Cancelled", Activated = "Activated"}

export {
    PermissionState,
    PermissionType,
    PlatformType,
    ScreenType,
    AdapterState,
    AdapterType,
    KYCVerificationStatus,
    AccountType,
    MessageType,
    TermType,
    Status,
    PaymentTrigger,
    Home,
    PaymentMethod,
    InstructionMethod,
    PaymentAction,
    StatusBank,
    StatusAccountDetails,
    AispActivationState,
    BankAccountScheme,
    PaymentRequestStatus,
    PaymentReportState,
    PaymentDirection,
    PaymentRequestStatusReason,
    ReferenceSettingType,
    NotesSettingType,
    AmountLabelType,
    PostMessageType,
    PaymentRequestType,
    BOPPButtonNotificationType,
    ActivationState
}
