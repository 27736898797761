import React, { 
    useEffect, 
    useState 
} from "react";
import {
    useDispatch, 
    useSelector
} from "react-redux";
import LoadingSpinnerComponent from "../../components/LoadingSpinnerComponent";
import { 
    setStatusBarColor, 
    changeAmount 
} from "../../actions";
import colors from '../../assets/style.theme.scss';
import './style.scss';
import {PlatformType} from './../../static/CommonDefinitions'
import { setPaymentRequest } from "../../reducers/paymentPersistReducer";
import { sendStatusRequestMessageAction } from "../../resolver/payment/statusRequestProcessor";

export default function LoadingPaymentDetails() {
    const dispatch = useDispatch();

    const paymentRequest = useSelector(state => state.paymentPersist.paymentRequest);
    const platform = useSelector(state => state.settings.platform);
    const [paymentDataRefreshed, setPaymentDataRefreshed] = useState(false);

    useEffect(() => {
        dispatch(setPaymentRequest(null));
        setPaymentDataRefreshed(true);
    }, []);

    useEffect(() => {
        if (!paymentDataRefreshed) {
            return;
        }

        dispatch(setStatusBarColor(colors.defaultBar));

        if(paymentRequest === null) {
            dispatch(changeAmount('0.00'));
            let paylink = `paylink://bopp.link/${window.location.pathname.split('/').pop()}`;

            dispatch(setPaymentRequest({
                paylinkUrl: paylink,
                detailsLoaded : false
            }))
        } else {
            if(!paymentRequest?.detailsLoaded && paymentRequest?.paylinkUrl) {
                dispatch(sendStatusRequestMessageAction({ paylink: paymentRequest.paylinkUrl, isPayment:true }));
            }
        }
    }, [paymentRequest, paymentDataRefreshed]);

    return (
        <div className={`loading-screen zi-25 ${platform === PlatformType.Desktop ? 'loading-screen zi-25 desktop' : 'grey-background'}`}>
            <div className={`preloader-block`}>
                <LoadingSpinnerComponent />
                <span>Loading ...</span>
            </div>
        </div>
    );
}