import React from "react";
import "./style.scss";
import Switcher from "../../components/Switcher";
import TickboxBlock from "../TickboxBlock";

const PaymentSetting = (props) => {
  const {type, name, value} = props;

  switch(type) {
    case 'radio':
      const {radioOptions, handleRadioChange} = props;
      return (
        <div className="payment-setting payment-setting--radio">
          {Array.isArray(radioOptions) && radioOptions.map((option, i) => {
            const checked = value === option.value;
            return (
              <div className="payment-setting__option" key={i}>
                <SettingCheckbox
                    key={option.value}
                    name={name}
                    checked={checked}
                    onChange={handleRadioChange}
                    label={option.label}
                    value={option.value}
                    showSubtitleOnlyIfChecked={option.showSubtitleOnlyIfChecked}
                />
                {checked && option.checkedContent}
              </div>
            )
            })}
        </div>
      );
    case "switch": {
      const { switchOptions, handleSwitchChange } = props;
      const className = `payment-setting payment-setting--switch ${value ? 'payment-setting--switch--enabled' : '' }`;
      const icon = switchOptions.icon;

      return <div className={className}>
        <div className={"payment-setting__header " + (switchOptions.headerClassName || '')}>
          { icon && <img className="payment-setting__icon" src={icon} /> }
          <div className={`${icon ? 'payment-setting__right-side' : 'payment-setting__header-top'} payment-setting__header-right-side`}>
            <span className="payment-setting__title">{switchOptions.title}</span>
            <Switcher enabled={value} onChange={handleSwitchChange} className="payment-setting__switcher" id={switchOptions.id}/>
          </div>
        </div>
        {value && (switchOptions.description || switchOptions.content) &&
          <div className="payment-setting__content payment-setting__right-side">
            <div className="payment-setting__description">{switchOptions.description}</div>
            {switchOptions.content}
          </div>
        }
      </div>
    }
    case "tickbox": {
      const { tickboxOptions } = props;
      return (
        <div className="payment-setting payment-setting--tickbox">
          {Array.isArray(tickboxOptions) && tickboxOptions.map((option, i) => {
            return (
              <div className="payment-setting__option" key={i}>
                <TickboxBlock
                  checked={option.checked}
                  id={option.id}
                  onChange={option.onChange}
                  title={option.title}
                  description={option.description}
                  checkedContent={option.checkedContent}
                  checkContainerClassName="payment-setting__check-container"
                  titleClassName="payment-setting__title"
                  descriptionClassName="payment-setting__description"
                />
              </div>
            )
          })}
        </div>
      )
    }
  }
}

export default PaymentSetting;

const SettingCheckbox = ({ onChange, value, checked, name, label, showSubtitleOnlyIfChecked = false }) => {
  const [main, sub] = label;
  return (
      <div className="payment-setting__checkbox">
          <input
              type='radio'
              name={name}
              id={name + value}
              value={value}
              onChange={onChange}
              checked={checked}
          />
          <div className="radio-button"><label htmlFor={name + value} className="label"/></div>
          <label htmlFor={name + value}>
              <span>{main}</span>
              {(!showSubtitleOnlyIfChecked || checked) && sub}
          </label>
      </div>
  )
}
