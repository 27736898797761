import React from 'react';

import '../style.scss';

const BackButtonModal = ({ saveAccountDetails, deleteAccountDetails }) => {

  return (
    <>
      <div className="gray-background" />
      <div className="modal-window">
        <div className="background-color background-color__base" />
        <div className={`modal-window-content modal-window-content`}>
            <div className="modal-title modal-title__question modal-title__lite">
                Are you sure you want to leave this page? Your bank account details won’t be saved.
            </div>
            <div className="modal-description-buttons">
                <button className="modal-buttons" onClick={saveAccountDetails} >
                    stay
                </button>
                <button className="modal-buttons modal-buttons__cancel" onClick={deleteAccountDetails} >
                    leave
                </button>
            </div>
        </div>
      </div>
    </>
  );
};

export default BackButtonModal;
