import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";

import PinBlock from '../../components/PinBlock';
import CheckBox from '../../components/CheckBox';

import CloseIcon from '../../assets/images/close-icon.svg';
import LockIcon from '../../assets/images/lock-icon.svg';
import LockIconBlack from '../../assets/images/lock-icon-black.svg';

import './style.scss';
import colors from '../../assets/style.theme.scss';
import RemoveItemModal from '../Modal/RemoveItemModal';
import {
    sendBankAccount,
    sendActivity,
    setLaunch,
    removePinItems,
    setStatusBarColor,
} from "../../actions";

export default function PinProtection() {
    const history = useHistory();
    const dispatch = useDispatch();

    // const protection = useSelector((state) => state.settings.protection);
  const pinSet = useSelector((state) => state.settings.pinSet);
  const [activity, setActivity] = useState(JSON.parse(localStorage.getItem('activity')) || false);
  const [boppLaunch, setBoppLaunch] = useState(JSON.parse(localStorage.getItem('bopp-launch')) || false);
  const [bankAccount, setBankAccount] = useState(JSON.parse(localStorage.getItem('bank-account')) || false);

    const [buttonName, setButtonName] = useState('set PIN');
    const [styles, setStyleWhenHasPin] = useState({});
    const [disabled, setActiveToggle] = useState(true);
    const [numPanel, showNumPanel] = useState(false);
    const [removeModal, showRemoveModal] = useState(false);
    const [activeButton, setActiveButton] = useState(false);
    const [checkPinCode, pinCodeHas] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('pinCode') && !JSON.parse(localStorage.getItem('checkedPin'))) {
            showNumPanel(true);
        }
        if (localStorage.getItem('pinCode') && !checkPinCode) {
            checked();
            localStorage.setItem('checkedPin', false);
        }
    }, [checkPinCode, numPanel]);

    useEffect(() => {
        if (!numPanel) {
            dispatch(setStatusBarColor(colors.lightGrayBg));
        }
    }, [numPanel]);

    const checked = () => {
        pinCodeHas(true);
        setButtonName('Change PIN');
        setStyleWhenHasPin({ background: colors.mainYellow, color: colors.lightDark });
    };

    const checkPin = () => {
        const pin = localStorage.getItem('pinCode');
        return pin ? setActiveToggle(false) : setActiveButton(true);
    };

    const removePin = () => {
        showRemoveModal(true);
    };

    const closePinBlock = () => {
        checked();
        showNumPanel(false);
    };

    const closeModal = () => {
        pinCodeHas(false);
        setButtonName('set PIN');
        setStyleWhenHasPin({});
        setActiveButton(false);
        setActiveToggle((prevState) => !prevState);
        setActivity(false);
        setBankAccount(false);
        setBoppLaunch(false);
    };

    const closeRemoveModal = () => {
        showRemoveModal(false);
    };

    const showNumSection = () => {
        showNumPanel(true);
    };

    const handleChange = (event) => {
        switch (event.target.id) {
            case 'activity':
                setActivity((prevState) => {
                    localStorage.setItem('activity', !prevState);
                    dispatch(sendActivity({
                        checkBoxValue: !prevState,
                        pin: JSON.parse(localStorage.getItem('pinCode')).join('')
                    }));
                    return !prevState;
                });
                break;
            case 'bank-account':
                setBankAccount((prevState) => {
                    localStorage.setItem('bank-account', !prevState);
                    dispatch(sendBankAccount({
                        checkBoxValue: !prevState,
                        pin: JSON.parse(localStorage.getItem('pinCode')).join('')
                    }));
                    return !prevState;
                });
                break;
            default:
                setBoppLaunch((prevState) => {
                    localStorage.setItem('bopp-launch', !prevState);
                    dispatch(setLaunch({
                        checkBoxValue: !prevState,
                        pin: JSON.parse(localStorage.getItem('pinCode')).join('')
                    }));
                    return !prevState;
                });
        }
    };

    const removePinProtection = () => {
        localStorage.removeItem('pinCode');
        localStorage.removeItem('bank-account');
        localStorage.removeItem('bopp-launch');
        localStorage.removeItem('activity');
        dispatch(removePinItems());
        closeModal();
        closeRemoveModal();
  };

  const onClosePinButton = () => (
    JSON.parse(localStorage.getItem('checkedPin')) || JSON.parse(localStorage.getItem('checkedPin')) === null
        ? showNumPanel(false) : history.push('/next')
  );

    return (
        <div className="pin-page">
            <div className="pin-page__title">
                <h2>PIN Protection</h2>
                <img onClick={() => history.push('/next')} src={CloseIcon} alt="CloseIcon"/>
            </div>
            {
                !numPanel ? (
                    <>
                        <button
                            className={`set-pin ${activeButton ? 'active' : 'disabled'}`}
                            style={styles}
                            onClick={showNumSection}
                        >
                            <img src={checkPinCode ? LockIconBlack : LockIcon} alt="LockIcon"/>
                            {buttonName}
                        </button>
                        <div className="pin-page__panel-command">
                            <span className="panel-title">
                                {checkPinCode ? 'Select features to protect:' : 'Create a PIN for added security:'}
                            </span>
                            <div className={`panel-block ${checkPinCode ? 'pin-enable' : ''}`}>
                                <div className="account-group">
                                    <div className="left-side">
                                        <span>Bank Account</span>
                                        <p>
                                            Secure your bank account - PIN is required to view and manage your bank
                                            account.
                                        </p>
                                    </div>
                                    <div className="right-side">
                                        <CheckBox
                                            id="bank-account"
                                            onClick={checkPin}
                                            disabled={disabled}
                                            checked={bankAccount}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="account-group">
                                    <div className="left-side">
                                        <span>Activity</span>
                                        <p>
                                            PIN is required to access the details of your previous payments and payment
                                            requests.
                                            {' '}
                                        </p>
                                    </div>
                                    <div className="right-side">
                                        <CheckBox
                                            id="activity"
                                            onClick={checkPin}
                                            disabled={disabled}
                                            checked={activity}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="account-group">
                                    <div className="left-side">
                                        <span>BOPP Launch</span>
                                        <p>PIN is required at launch of application. Other settings still apply.</p>
                                    </div>
                                    <div className="right-side">
                                        <CheckBox
                                            id="bopp-launch"
                                            onClick={checkPin}
                                            disabled={disabled}
                                            checked={boppLaunch}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            checkPinCode && (
                                <span onClick={removePin} className="remove-pin">Remove PIN Protection</span>)
                        }
                    </>
                ) : (
                    <PinBlock closePinBlock={closePinBlock} onCloseButton={onClosePinButton} />
                )
            }
            {
                removeModal && (
                    <RemoveItemModal removeItem={removePinProtection} closeModal={closeRemoveModal}/>
                )
            }
        </div>
    );
}
