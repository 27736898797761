import React from 'react';
import CloseIcon from '../../../assets/images/close-icon.svg';
import '../style.scss';

const ErrorModal = ({ closeModal, title, content }) => (
    <>
        <div className="gray-background"/>
        <div className="modal-window">
            <div className="background-color background-color__error" />
            <div className="modal-window-content modal-window-content__question">
                <img onClick={closeModal} className="close-modal close-modal-error" src={CloseIcon} alt="CloseIcon" />
                <div className="modal-title modal-title__question modal-title__error-title">
                    {title}
                </div>
                <div className="modal-description error-desc">
                    {content}
                </div>
            </div>
        </div>
    </>
);

export default ErrorModal;
