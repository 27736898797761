import {Base62} from './base62';

export const decodeExecutingEntityId = (reportId) => {
    try {
        let id = reportId;
        if (reportId.includes('cri:')) {
            const splittedId = reportId.split(/:/);
            const bytes = Base62.decode(splittedId[splittedId.length - 1]);
            if(bytes[0] !== 21) {
                return splittedId[splittedId.length - 1]
            }
            id = new TextDecoder('utf-8').decode(bytes.slice(2));
            return id
        }
        throw Error
    } catch (e) {
        return typeof reportId === 'string' ? reportId : '';
    }
}