import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PlusIcon from '../../assets/images/plus-icon.svg';
import Preloader from "../../assets/images/spinner-black.svg";
import './styles.scss';
import { cssVhToPx, getCircleLogo, onImageError } from '../../helpers';
import { PlatformType } from '../../static/CommonDefinitions';

const ChooseBankButton = ({ onPress, bankAccount, loading }) => {
  const platform = useSelector(state => state.settings.platform);

  const renderChangeButton = () => {
    if(loading)
      return <img className="spinner" src={Preloader} alt="" style={useInlineStyles ? styles['spinner'] : {}} />;

    return (
      <div className="choose-title" style={useInlineStyles ? styles['choose-title'] : {}}>
       { bankAccount ? 'Change' : 'Choose Bank' }
      </div>
    )
  }

  const useInlineStyles = platform !== PlatformType.Desktop;

  const changeButtonIcon = useMemo(() => {
    return !bankAccount
      ? <img src={PlusIcon} alt="" style={useInlineStyles ? styles['plus-icon'] : {}} />
      : <img
          src={getCircleLogo(bankAccount.logo_uri)}
          onError={(e)=>{onImageError(e, true)}}
          alt="bank-logo"
          className="bank-logo"
        />
  }, [bankAccount])

  const className = platform === PlatformType.Desktop ? 'choose-bank-block-desktop' : 'choose-bank-block';

  return (
    <div onClick={()=>{
      //if(!loading) {
        onPress()
      //}
    }} className={className}>
      <div style={useInlineStyles ? styles['current-bank-image'] : {}} className={`current-bank-image ${!bankAccount?.logo_uri ? 'border' : ''}`}>
        {changeButtonIcon}
      </div>
      <div className="bank-name-block">
        {renderChangeButton()}
      </div>
    </div>
  )
};

const styles = {
  ['current-bank-image']: {
    width: cssVhToPx(7.5),
    height: cssVhToPx(7.5),
  },
  ['plus-icon']: {
    width: cssVhToPx(2.4),
    height: cssVhToPx(2.4),
  },
  ['choose-title']: {
    fontSize: cssVhToPx(2.7),
  },
  ['spinner']: {
    width: cssVhToPx(6),
    height: cssVhToPx(6),
  },
}

export default ChooseBankButton;
