import React from 'react';
import classNames from 'classnames';

import './style.scss';

  const Input = ({ label, value, name, handleChange, error = false, classForCustomStyles: custom, }) => {
  const inputStyles = classNames(
    'input-field__input',
    {'input-field__input--error': error},
    {[`${custom}--input`]: custom}
  );

  return (
    <label className={classNames("input-field", {[custom]: custom})}>
      {label}
      <input
        type="text"
        name={name}
        value={value}
        className={inputStyles}
        onChange={handleChange}
      />
    </label>
  )
};

export default Input;
