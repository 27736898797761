import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';

import config from './../../../config'
import colors from '../../../assets/style.theme.scss';
import { openNewPage, setStatusBarColor } from '../../../actions';

import './style.scss';
import CloseIcon from "../../../assets/images/close-icon.svg";
import Logo from '../../../assets/images/bopp-logo.svg'
import WrongScreenImg from '../../../assets/images/wrong-screen.svg'
import Info from '../../../assets/images/info-icon.svg'
import HeaderUniversal from "../../../components/HeaderUniversal";

export default function WrongScreen({closeWrongScreen, error}) {
  const dispatch = useDispatch();

  const errorDetails = useSelector((state) => state.payment.error);

  useEffect(() => {
    dispatch(setStatusBarColor(colors.mainYellow));
  }, []);

  const openNewTab = (url) => {
    return dispatch(openNewPage(url));
  };

    return (
      <div className="web-page">
        <HeaderUniversal
          logo={Logo}
          title="Oops, something went wrong..."
          rightButtonIcon={CloseIcon}
          rightButtonEvent={closeWrongScreen}
          customStylesRightBtn={{ width: '24px', height: '24px', top: '41px' }}
        />

        <div className="wrong-screen">
          <div className="wrong-screen-content">
            {config.appEnvironment === "DEV" ?
              <div>
                <span >{errorDetails.code}</span>
                <br />
                <span >{errorDetails.message}</span>
              </div>
              : null}
            {error ? <div className="wrong-error">
              <img src={Info} alt="info" />
              <a>Error code: 400</a>
            </div> : null}
            <img className="wrong-screen-img" src={WrongScreenImg} alt="wrong-screen" />
          </div>
          <div className="wrong-screen-contact">
            <p>For more information visit our <a onClick={() => {openNewTab('https://help.bopp.io/portal/en/kb/bopp')}} className="contact-link">help page</a>.</p>
          </div>
        </div>
      </div>
    )
}