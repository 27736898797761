export default class Socket {
    websocketUrl:string
    onMessage:any
    socket:any
    data:any
    dispatch:any
    
    constructor(url: string, onMessage:any, dispatch:any) {
        this.websocketUrl = url
        this.onMessage = onMessage
        this.dispatch = dispatch
        this.initSocket()
    }

    initSocket() {
        this.socket = new WebSocket(this.websocketUrl);
        this.socket.onopen = () => {
            console.log('Connection opened' + this.websocketUrl);
            if (this.data) {
                console.log('Sending ' + this.data)
                this.socket.send(this.data)
            }
        };

        this.socket.onmessage = (data:any) => {
            if (!data) {
                return;
            }

            const message = JSON.parse(data.data.toString());
            console.log('Received')
            console.log(message)
            this.dispatch(this.onMessage(message))
        }

        this.socket.onclose = () => {
            console.log('log chanel close' + this.socket.url);
        };
    }

    send (data: string) {
        if (!this.socket || this.socket.readyState != this.socket.OPEN) {
            this.data = data;
            this.initSocket();
            return;
        } else {
            console.log('Sending ' + data)
            return this.socket.send(data);
        }
    };

    reconnect (url: string)  {
        if (url !== this.websocketUrl) {
            this.websocketUrl = url
        }
    };

    closeSocket ()  {
        this.socket.close();
    };
}

