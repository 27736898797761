import { 
    takeLatest, 
} from 'redux-saga/effects';
import { createId, wrap } from '../../helpers';
import { BOPPButtonNotificationType } from '../../static/CommonDefinitions';
import { sendWSMessage } from './statusRequestProcessor';

export interface NotifyBOPPButtonPayload {
    paylink:string
};

export const NOTIFY_OPENED_PAYLINK_MESSAGE = 'notify/opened/paylink/message';
export interface NotifyOpenedPaylinkMessage { type: typeof NOTIFY_OPENED_PAYLINK_MESSAGE; payload: NotifyBOPPButtonPayload }
export const notifyOpenedPaylinkAction = (payload: NotifyBOPPButtonPayload): NotifyOpenedPaylinkMessage => ({
    type: NOTIFY_OPENED_PAYLINK_MESSAGE,
    payload
})

export const NOTIFY_WENT_TO_BANK_MESSAGE = 'notify/went/to/bank/message';
export interface NotifyWentToBankMessage { type: typeof NOTIFY_WENT_TO_BANK_MESSAGE; payload: NotifyBOPPButtonPayload }
export const notifyWentToBankMessageAction = (payload: NotifyBOPPButtonPayload): NotifyWentToBankMessage => ({
    type: NOTIFY_WENT_TO_BANK_MESSAGE,
    payload
})

function *notifyBOPPButtonPaylinkOpened(socket: WebSocket, payload: NotifyWentToBankMessage):any {
    yield notifyBOPPButton(socket, payload.payload.paylink, BOPPButtonNotificationType.QRScanned)
}


function* notifyBOPPButtonWentToBank(socket: WebSocket, payload: NotifyWentToBankMessage): any {
    yield notifyBOPPButton(socket, payload.payload.paylink, BOPPButtonNotificationType.BankSelected)
}

function *notifyBOPPButton(socket: WebSocket, paylink: string, action: BOPPButtonNotificationType) {
    yield sendWSMessage(wrap('paylink-initiator', {
        "@type": "https://dvschema.io/activation#StatusReport",
        "id": createId(false),
        "originatorId": "originator",
        "properties": {
            "@type": "https://miapago.io/paylink/request/v0.1.0#LinkStatus",
            "statusType": action,
            "link": paylink
        },
        "timestamp": new Date(),
        "lastReport": true
    }));
}

export function* boppButtonNotifier({paylinkSocket}: any) {
    yield takeLatest(NOTIFY_OPENED_PAYLINK_MESSAGE, notifyBOPPButtonPaylinkOpened, paylinkSocket);
    yield takeLatest(NOTIFY_WENT_TO_BANK_MESSAGE, notifyBOPPButtonWentToBank, paylinkSocket);
}