import { 
  takeLatest,
  put,
  select,
  takeEvery 
} from 'redux-saga/effects';
import { store } from '../store';
import { 
  wrap,
  createId,
  addParamToURL
} from '../helpers';
import * as actionType from '../actions/actionTypes';
import { 
  setFailReasonText,
  setLatestChangeRequest,
  updateAccountDetails
} from '../actions';
import {
  PaymentRequestStatus,
  PaymentRequestStatusReason
} from '../static/CommonDefinitions';
import {
  setLasteHistoryPaymentRequestId,
  setUpdateRequestSent,
  updateHistoryRequest
} from './../reducers/historyReducer';
import { updateRequestInNative } from './../sagas/historyWatcher';
import { CreatePaymentRequest } from './../entities/createRequestObject';
import { InstructionRequest } from './../entities/createInstructionObject';
import { ChangePayment } from './../entities/changeRequest';
import { CancelDomesticPayment } from './../entities/cancelDomesticPaymentRequest';
import { CancelPayment } from './../entities/cancelPaymentRequest';
import { GetPaymentHistory } from "../entities/getPaymentHistory";
import { GetHistoryObjectStatus } from "../entities/getHistoryObjectStatus";
import { CreateAccountDetails } from "../entities/createAccountDetails";
import { CancelAccountDetails } from "../entities/cancelAccountDetails";
import { ChangeAccountDetails } from "../entities/changeAccountDetails";
import { sendError } from './../resolver/errorResolver';
import { 
  addLastPayment,
  setLastPaymentInstructionId 
} from '../reducers/paymentPersistReducer';
import { 
  ErrorFlow, 
  ErrorObject 
} from '../entities/errorObject';
import { sendWSMessage } from '../resolver/payment/statusRequestProcessor';
import { StaticSocket } from '../sockets/StaticSocket';

let timer;

function* createPaymentInstruction({ payload }) {
  try {
    const paymentPayeeSubscriberIds = yield select(state => state.payment.paymentPayeeSubscriberIds);  
    const iframeRedirectUrl = yield select(state => state.payment.iframeRedirectUrl);  
    let paymentInstruction = new InstructionRequest(payload, paymentPayeeSubscriberIds, iframeRedirectUrl)
    let request = paymentInstruction.createRequest()
    yield put(setLastPaymentInstructionId(request.id))

    if(payload.metainfo) {
      let metainfo = JSON.parse(payload.metainfo)
      let statusScreenDetails = paymentInstruction.getStatusScreenDetails()

      statusScreenDetails.instructionId = request.id
      if (metainfo.failRedirectUrl || metainfo.cancelRedirectUrl) {
        let res = addParamToURL(metainfo.failRedirectUrl || metainfo.cancelRedirectUrl, 'instructionId', request.id)
        res = addParamToURL(res, 'paymentReference', statusScreenDetails.orderNumber)
        res = addParamToURL(res, 'paymentAmount', statusScreenDetails.amount)
        statusScreenDetails.redirectUrl = res
      }

      if (metainfo?.internalReference) {
        statusScreenDetails.internalReference = metainfo.internalReference
      }
      yield put(addLastPayment(statusScreenDetails))
    } else {
      yield put(addLastPayment(paymentInstruction.getStatusScreenDetails()))
    }
    
    yield sendWSMessage(wrap('paylink-initiator', request), store.dispatch);
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error creating payment instruction', error, ErrorFlow.payments)}));
  }
}

function* createPaylinkRequest({ payload }) {
  const bank = yield select(state => state.payment.bank);
  const oneTimePaymentSettings = yield select(state => state.paymentRequestSettings.oneTimePaymentSettings);

  const paymentPayeeSubscriberIds = yield select(state => state.payment.paymentPayeeSubscriberIds);

  try {
    let createPaymentRequest = new CreatePaymentRequest({bank, settings:oneTimePaymentSettings, payload, paymentPayeeSubscriberIds})
    const request = createPaymentRequest.getPaymentRequest()
    if(payload.metainfo) {
      let metainfo = JSON.parse(payload.metainfo)
      let statusScreenDetails = request.getStatusScreenDetails()
      
      statusScreenDetails.instructionId = request.id

      if (metainfo.failRedirectUrl || metainfo.cancelRedirectUrl) {
        let res = addParamToURL(metainfo.failRedirectUrl || metainfo.cancelRedirectUrl, 'instructionId', request.id)
        res = addParamToURL(res, 'paymentReference', statusScreenDetails.orderNumber)
        res = addParamToURL(res, 'paymentAmount', statusScreenDetails.amount)
        statusScreenDetails.redirectUrl = res
      }

      if (metainfo?.internalReference) {
        statusScreenDetails.internalReference = metainfo.internalReference
      }
      
      yield put(addLastPayment(statusScreenDetails))
    } else {
      yield put(addLastPayment(createPaymentRequest.getStatusScreenDetails()))
    }

    timer = setInterval(() => {
      sendWSMessage('')
    }, 5000);

    yield sendWSMessage(wrap('paylink-initiator', request),store.dispatch);
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error creating paylink request', error, ErrorFlow.payments)}));
  }
}

function* stopPingPong(socket) {
  clearInterval(timer)
}

function* closePingPongSocket(socket) {
  let ssoc = StaticSocket.getInstance();
  ssoc.close();
}

function* handleChangeDomesticPayment({ payload }) {
  try {
    let changeRequest = new ChangePayment(payload)
    let wrappedRequest = wrap('paylink-initiator', changeRequest.createRequest())
    yield put(setFailReasonText('Awaiting bank confirmation'));
    yield put(setLatestChangeRequest(wrappedRequest))
    yield sendWSMessage(wrappedRequest, store.dispatch);
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error changing domestic payment', error, ErrorFlow.payments)}));
  }
}

function* handleCancelDomesticPayment({ payload }) {
  const lastPaymentInstructionId = yield select((state) => state.paymentPersist.lastPaymentInstructionId);
  try {
    let cancelRequest = new CancelDomesticPayment(payload, lastPaymentInstructionId)
    yield sendWSMessage(wrap('paylink-initiator', cancelRequest.createRequest()), store.dispatch);
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error canceling domestic payment with latest payment instruction id ' + lastPaymentInstructionId + ', and payload ' + JSON.stringify(payload), error, ErrorFlow.payments)}));
  }
}

function* handleCreateAccountDetails(socket, { payload }) {
  try {
    const createRequestId = createId();
    const createAccountDetailsRequest = new CreateAccountDetails(payload.api, createRequestId);

    yield put(updateAccountDetails({ id: createRequestId }));
    if (payload?.api?.includes('general')) return;
    yield socket.send(wrap('DomesticPaymentInitiator', createAccountDetailsRequest.createRequest()));
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error creating account details', error, ErrorFlow.accounts)}));
  }
}

function* handleChangeAccountDetails(socket, { payload }) {
  try {
    const accountDetailsId = yield select((state) => state.payment.accountDetails.id);
    const handleChangeAccountDetailsRequest = new ChangeAccountDetails(payload.code || payload['#code'], accountDetailsId);
    yield socket.send(wrap('DomesticPaymentInitiator', handleChangeAccountDetailsRequest.createRequest()));
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error changing account details', error, ErrorFlow.accounts)}));
  }
}

function* handleCancelAccountDetails(socket, { payload }) {
  try {
    const accountDetailsId = yield select((state) => state.payment.accountDetails.id);
    const handleCancelAccountDetailsRequest = new CancelAccountDetails(accountDetailsId);
    yield socket.send(wrap('DomesticPaymentInitiator', handleCancelAccountDetailsRequest.createRequest()), store.dispatch);
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error canceling account details', error, ErrorFlow.accounts)}));
  }
}

//Query payment request to update payments status. Handle response in "historyUpdate"
function *handleGetHistoryObjectStatus({ payload }) {
  try {
    const getHistoryObjectStatusRequest = new GetHistoryObjectStatus(payload.id, payload.isRequest);
    yield sendWSMessage(wrap('paylink-initiator', getHistoryObjectStatusRequest.createRequest()), store.dispatch);
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error getting history object status', error, ErrorFlow.historyQuery)}));
  }
}

function *cancelPaymentRequest({ payload }) {

  const { requests } = yield select(state => ({requests: state.history.requests}));
  const request = requests.find(element => element.ids.paymentRequestId === payload.requestId);

  if(request.status != PaymentRequestStatus.Closed) {
    let req = {...request, status:PaymentRequestStatus.Closed, statusReason:PaymentRequestStatusReason.Cancelled}
    yield put(updateHistoryRequest(req))
    yield put(updateRequestInNative(req));

    try {
      let cancelRequest = new CancelPayment(payload.id)
      yield sendWSMessage(wrap('paylink-initiator', cancelRequest.createRequest()), store.dispatch);
    } catch (error) {
      yield put(sendError({error:new ErrorObject('Error canceling payment request', error, ErrorFlow.payments)}));
    }
  }
}

function *handleGetPaymentsHistory({ payload }) {
  try {
    const payee = yield select(state => state.payment.paymentPayeeSubscriberIds.payee);
    const lastReceipts = yield select(state => state.history.lastReceipts);

    const sd = getLatestPaymentDate(lastReceipts);
    const id = createId();
    yield put(setLasteHistoryPaymentRequestId(id));
    yield put(setUpdateRequestSent(true));
    const getPaymentsHistoryRequest = new GetPaymentHistory(id, sd, payee);

    yield sendWSMessage(wrap('paylink-initiator', getPaymentsHistoryRequest.createRequest()), store.dispatch);
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error getting payments history', error, ErrorFlow.historyQuery)}));
  }
}

function getLatestPaymentDate(payments) {
  if(payments.length > 0) {
    let pp = payments.reduce((a, b) => {
      return new Date(a.activityTime) > new Date(b.activityTime) ? a : b;
    })
    return new Date(pp.activityTime)
  } else {
    return new Date(+0)
  }
}

export function* webSocketWatcher({accountsSocket}) {
  const sockets = {
    'ob-aisp-initiator': accountsSocket,

  };

  yield takeLatest(actionType.CHANGE_DOMESTIC_PAYMENT, handleChangeDomesticPayment);
  yield takeLatest(actionType.CANCEL_DOMESTIC_PAYMENT, handleCancelDomesticPayment);
  yield takeLatest(actionType.CREATE_ACCOUNT_DETAILS, handleCreateAccountDetails, accountsSocket);
  yield takeLatest(actionType.CHANGE_ACCOUNT_DETAILS, handleChangeAccountDetails, accountsSocket);
  yield takeLatest(actionType.CANCEL_ACCOUNT_DETAILS, handleCancelAccountDetails, accountsSocket);
  yield takeLatest(actionType.CREATE_PAYLINK_REQUEST, createPaylinkRequest);
  yield takeLatest(actionType.CREATE_PAYMENT_INSTRUCTION, createPaymentInstruction);
  yield takeEvery(actionType.GET_HISTORY_OBJECT_STATUS, handleGetHistoryObjectStatus);
  yield takeLatest(actionType.CANCEL_PAYMENT_REQUEST, cancelPaymentRequest);
  yield takeLatest(actionType.STOP_PING_PONG, stopPingPong);
  yield takeLatest(actionType.CLOSE_PING_PONG_SOCKET, closePingPongSocket);
  yield takeLatest(actionType.GET_PAYMENTS_HISTORY, handleGetPaymentsHistory);
}
