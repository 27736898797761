import React from 'react'
import './style.scss';
import {sortCodeRemoveDash} from './../../helpers'

function BankAccountLabel({accountNumber, sortCode, logo, bankAccountName, bankName}) {
    return (
        <div className="bank-account-label">
            <div className="bank-info-header">
                    <div className={`bank-account-names ${bankAccountName?.length > 20 ? 'small-font' : ''}`}>
                        <p className="bank-name">{bankAccountName}</p>
                        <p className="bank-nickname">{bankName}</p>
                    </div>
                    <div className="bank-logo">
                        <img className="bank-log-icon" src={logo} alt="bank-logo"/> 
                    </div>
            </div>
            <div className="info-section">
                <p>Number</p>
                <span>{(String(accountNumber).match(/.{4}/g) || []).join(' ')}</span>
            </div>
            <div className="info-section">
                <p>Sort Code</p>
                <span>{sortCodeRemoveDash(sortCode)}</span>
            </div>
        </div>
    )
}

export default BankAccountLabel
