import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AuthCodeStatuses } from '../../../reducers/kycReducer';
import { AccountType } from '../../../static/CommonDefinitions';
import { cleanTimer, processEnteredAuthCodeAction } from '../../../sagas/kycWatcher';
import { isDeadlineActual } from '../../../helpers';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import FormSteps from '../FormSteps';
import './styles.scss';
import HeaderUniversal from '../../../components/HeaderUniversal';
import Logo from "../../../assets/images/bopp-logo.svg";
import CloseIcon from '../../../assets/images/close-icon.svg';
import BackIcon  from '../../../assets/images/back-arrow.svg';

function ActivateBOPP({ onChangeAccountTextButton, setUserData, userData, changeStep }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const bank = useSelector(state => state.payment.bank);
  const authCodeVerificationStatus  = useSelector(state => state.kyc.authCodeVerificationStatus);
  const timerDeadline = useSelector(state => state.kyc.timerDeadline);

  const isAttemptsLoopActual = isDeadlineActual(timerDeadline?.firstAttemptTimeDeadline);
  const isVerificationSuccess = authCodeVerificationStatus === AuthCodeStatuses.sucessPair;

    useEffect(() => {
      if (!isAttemptsLoopActual) {
        dispatch(cleanTimer());
      }
    }, [])

  const onActivateButton = () => {
    dispatch(processEnteredAuthCodeAction({ authCode: userData.authCode, targertPayeeUniqueIdentifier: bank.id }))
  }

  const handleCode = (e) => {
    setUserData({ authCode: e.target.value });
  };

  useEffect(() => {
    if (authCodeVerificationStatus !== AuthCodeStatuses.notChecked && !isVerificationSuccess) {
      changeStep(FormSteps.businessAccountVerified);
    }
  }, [authCodeVerificationStatus, isVerificationSuccess])

  return (
    <div className="activate-screen">
      <HeaderUniversal
        logo={Logo}
        rightButtonIcon={CloseIcon}
        leftButtonIcon={BackIcon}
        classForCustomStyles="activate-screen__header"
        rightButtonEvent={() => history.push('/home-screen')}
        leftButtonEvent={() => changeStep(FormSteps.choosePlan)}
      />
      <div className="activate-screen__content">
        <div className="messages">
          <p className="title">
            Activate BOPP
          </p>

          <p className="text">
            Please enter your activation code in the box below. For further instructions please visit bopp.io.
          </p>

          <Input
            label="Activation Code"
            value={userData.authCode}
            handleChange={handleCode}
            classForCustomStyles="code-input"
          />
        </div>

        <div className="bottom-part">
        <div className="button-wrapper">
          <Button title="Activate Now" handleClick={onActivateButton} disabled={userData.authCode.length !== 8} color="black black--disabled"/>
        </div>

        { bank.subscriptionType === AccountType.Business &&
        <p className="text-button" onClick={onChangeAccountTextButton}>
          I want to use BOPP for personal purposes
        </p>}
      </div>
      </div>
    </div>
  )
}

export default ActivateBOPP;
