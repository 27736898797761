import React, { useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";

import LoadingSpinnerComponent from "../../components/LoadingSpinnerComponent";

import { setStatusBarColor, hideCamera } from "../../actions";
import colors from '../../assets/style.theme.scss';
import './style.scss';
import {PlatformType} from "../../static/CommonDefinitions";

export default function PaymentInProcess({message = 'Loading..', button = false, buttonName, closeScreen}) {
  const dispatch = useDispatch();

  const boppButton = useSelector((state) => state.paymentPersist.boppButton);
  const platform = useSelector((state) => state.settings.platform);

  useEffect(() => {
    dispatch(setStatusBarColor(colors.defaultBar));
    dispatch(hideCamera())
  }, []);

  return (
    <div className={`loading-screen zi-25 ${platform === PlatformType.Desktop ? 'loading-screen--desktop' : 'grey-background'}`}>
        <div className={`preloader-block`}>
            <LoadingSpinnerComponent />
            <span>{message}</span>
            {button ? <button onClick={closeScreen} className="loading-screen-btn">{buttonName}</button> : null}
        </div>
    </div>
  );

  /*return (
    <div className={`loading-screen zi-25 ${boppButton && platform === PlatformType.Desktop ? 'bopp-button' : ''}`}>
        <img className={`big-logo`} src={Logo} alt="Logo"/>
        <div className={`preloader-block`}>
            <LoadingSpinnerComponent />
            <span>{message}</span>
            {(button && platform === PlatformType.Desktop) ? <button onClick={closeScreen} className="loading-screen-btn">{buttonName}</button> : null}
        </div>
    </div>
  );*/
}
