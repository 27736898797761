import { all } from 'redux-saga/effects';

import { postMessageWatcher } from './postMessageWatcher';
import { paymentWatcher } from './paymentWatcher';
import { historyWatcher } from './historyWatcher';
import { permissionWatcher } from './permissionWatcher';
import { webSocketWatcher } from './webSocketWatcher';
import { pinWatcher } from "./pinWatcher";
import { otpCalculator } from "./otpCalculator";
import { permissonSaga } from "./permissonSaga";
import { hardwareSaga } from "./hardwareSaga";
import { paymentRequestSettingsSaga } from "./paymentRequestSettingsSaga";
import { kycWatcher } from "./kycWatcher";
import { aispProcessor } from "./../resolver/aispProcessor";
import { pispProcessor } from "../resolver/payment/pispProcessor";
import { errorResolver } from './../resolver/errorResolver';
import { historyProcessor } from "./../resolver/historyProcessor";
import { instructionProcessor } from "../resolver/payment/createPaymentInstructionProcessor";
import { changePaymentInstructionProcessor } from "../resolver/payment/changePaymentInstructionProcessor";
import { statusProcessor } from "../resolver/payment/statusRequestProcessor";
import { cancelProcessor } from "../resolver/payment/cancelProcessor";
import { boppButtonNotifier } from "../resolver/payment/boppButtonNotifier";

export default function* rootSaga({ sockets, dispatch }) {
  yield all([
    postMessageWatcher(),
    pinWatcher(),
    otpCalculator(),
    paymentWatcher(),
    historyWatcher(),
    permissionWatcher(),
    webSocketWatcher(sockets),
    permissonSaga(),
    hardwareSaga(),
    paymentRequestSettingsSaga(),
    kycWatcher(),
    aispProcessor(),
    pispProcessor(sockets),
    errorResolver(sockets),
    historyProcessor(dispatch),
    instructionProcessor(sockets),
    changePaymentInstructionProcessor(sockets),
    statusProcessor(sockets),
    boppButtonNotifier(sockets),
    cancelProcessor(sockets),
  ]);
}
