import React from 'react'
import Button from '../Button';
import './style.scss'

function Footer({title, message, button, onLinkClick}) {
    return (
        <div className="footer">
            <div className="footer-section">
                {title && <h2 className="footer-title">{title}</h2>}
                {message && <p>{message}</p>}
                <div className="button-block">
                <Button title={button?.buttonText} handleClick={button?.onButtonClick} color="black" />
                </div>
                {onLinkClick && <div onClick={onLinkClick} className="link-block">
                    Restart Verification
                </div>}
            </div>
        </div>
    )
}

export default Footer
