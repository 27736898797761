import React from "react";
import CheckIcon from "../../assets/images/check-icon-white-rounded.svg";
import "./style.scss";

const TickboxBlock = (props) => {
  const {className = '', titleClassName = '', descriptionClassName = '', checkContainerClassName = ''} = props;
  const {checked, onChange, title, description, checkedContent, id} = props;

  const style = `tickbox-block ${checked ? 'tickbox-block--checked' : ''} ${className}`;

  return (
    <div className={style}>
      <div className={"tickbox-block__check " + checkContainerClassName} onClick={() => onChange(!checked)} id={id}>
        <img src={CheckIcon} />
      </div>
      <div className="tickbox-block__right-side">
        <div className={"tickbox-block__title " + titleClassName}>{title}</div>
        {description && <div className={"tickbox-block__description " + descriptionClassName}>{description}</div>}
        {checked && checkedContent}
      </div>
    </div>
  )
}

export default TickboxBlock;
