import { Encoder } from './basex';

const base62 = new Encoder('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz');

export class Base62 {
  // Decode decodes toDecode into a byte buffer using a
  // base 62 alphabet: 0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz.
  //
  // It returns a non nil error if a character outside of this
  // alphabpet is encountered
  static decode(toDecode) {
    return base62.decode(toDecode);
  }

  static encode(toEncode) {
    return base62.encode(toEncode);
  }
}
