import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { decodeExecutingEntityId } from "../../utils/paymentIdDecoder";
import classNames from 'classnames';
import moment from "moment";
import { toBool } from '../../utils';
import colors from '../../assets/style.theme.scss';
import QrMethod from "../../assets/images/qr-method.svg";
import CopyIcon from "../../assets/images/copy-black.svg";
import BackArrow from "../../assets/images/back-arrow.svg";
import HeaderUniversal from "../../components/HeaderUniversal";
import PaylinkMethod from "../../assets/images/link-method.svg";
import { setStatusBarColor, copyToClipboard } from "../../actions";
import NumericMethod from "../../assets/images/numeric-code-method.svg";
import DefaultLogo from '../../assets/images/banks-logo/defoult-icon-black.png';
import { PaymentDirection, PaymentReportState } from '../../static/CommonDefinitions';
import { getBankDetails, returnDate, returnShortTime, onImageError, checkLength, formatAmount } from '../../helpers';
import './style.scss';

const HistoryPaymentSummary = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { id: paymentID } = useParams();
    const payments = useSelector(state =>state.history.payments);
    const bankList = useSelector(state => state.payment.bankList);
    const [payment, setPayment] = useState({});
    const [paymentFound, setPaymentFound] = useState(false);
    const [clipboardMessageVisible, setClipboardMessageVisible] = useState(false);
    const {
        amount,
        timestamp,
        dirrection,
        executorId,
        reportState,
        paymentMethod,
        payeeServiceId,
        reportId,
        executingEntityId,
    } = payment;
    const isPaymentIncome = dirrection === PaymentDirection.InCome;
    const bank = paymentFound && getBankDetails(executorId, bankList);
    const payeeBank = paymentFound && getBankDetails(payeeServiceId.id, bankList, !isPaymentIncome);
    const isRejected = reportState === PaymentReportState.Cancelled || reportState === PaymentReportState.CancelledOnBank;

    useEffect(() => {
        dispatch(setStatusBarColor(colors.mainYellow));
    }, []);

    useEffect(() => {
        setPayment(payments.find(payment => payment.id === paymentID))
    }, [payments]);

    useEffect(() => {
        setPaymentFound(toBool(Object.keys(payment).length));
    }, [payment]);

    const renderImgPaymentMethod = (method) => {
        switch (method) {
            case 'qr' :
                return <img src={QrMethod} alt="QRBlock"/>;
            case 'otp':
                return <img src={NumericMethod} alt="NumericMethodBlack"/>;
            case 'link':
            case 'paylink':
            default:
                return <img src={PaylinkMethod} alt="PaylinkBlock"/>;
        }
    };

    const handleSeeRequest = () => {
        history.push(`/request-summary/${payment.requestId}`);
    };

    const handleClickBack = () => {
        history.goBack();
    }

    const copyId = () => {
        setClipboardMessageVisible(true);
        setTimeout(() => setClipboardMessageVisible(false), 2000);
        dispatch(copyToClipboard(reportId))
    };

    const renderStatus = () => {
        if(isPaymentIncome) {
            if(reportState === PaymentReportState.Cancelled) {
                return "Rejected"
            } else if(reportState === PaymentReportState.PendingAccept) {
                return "Pending"
            } else {
                return "Received"
            }
        } else {
            if(reportState === PaymentReportState.Cancelled) {
                return "Rejected"
            } else if(reportState === PaymentReportState.PendingAccept) {
                return "Pending"
            } else if(reportState === PaymentReportState.CancelledOnBank) {
                return "Failed"
            } else {
                return "Paid"
            }
        }
    }

    const statusStyles = classNames({
        'payment-summary__status': true,
        'received': isPaymentIncome && !isRejected,
        'failed': isRejected,
        'paid': !isPaymentIncome && !isRejected,
    });

    const renderEmailDetails = () => {
        if(isPaymentIncome && payment.requestEmail?.enabled) {
            return (
                <div  className="payment-summary__payment--item">
                    <p className="payment-summary__label">Email provided</p>
                    <p className="payment-summary__value">{payment.requestEmail.email ? "Yes" : "No"}</p>
                </div>
                )
        } else {
            return null
        }
    };

    const renderGiftAidDetails = () => {
        if(payment.giftAid?.enabled) {
            return (
                <div  className="payment-summary__payment--item">
                    <p className="payment-summary__label">Gift Aid:</p>
                    <p className="payment-summary__value">{payment.giftAid.skippedByPayer ? "No" : "Yes"}</p>
                </div>
            )
        } else {
            return null
        }
    };

    const renderNotesDetails = () => {
        if(payment.notes?.enabled) {
            return (
                <div  className="payment-summary__payment--item">
                    <p className="payment-summary__label">Notes</p>
                    <p className="payment-summary__value">{payment.notes.notesValue || "Not added"}</p>
                </div>
            )
        } else {
            return null
        }
    };

    return(
        <div className="payment-summary">
            <HeaderUniversal
                title="Payment Summary"
                leftButtonIcon={BackArrow}
                leftButtonEvent={handleClickBack}
                customStylesTitle={{top: '40px', fontSize: '22px', fontWeight: '600'}}
            />
            { paymentFound &&
                <div className="payment-summary__content">
                    <div className={statusStyles}><p>{renderStatus()}</p></div>
                    <div className="payment-summary__amount">{(isPaymentIncome ? '+' : '-') + formatAmount(amount.value)}</div>
                    <div className="payment-summary__side">
                        {!isPaymentIncome && renderImgPaymentMethod(paymentMethod)}
                        {isPaymentIncome ? 'Incoming' : 'Outgoing'}
                    </div>
                    <div className="payment-summary__account">
                        <div className="payment-summary__account--bank">
                            <img
                                src={payeeBank?.logo_uri || DefaultLogo}
                                width='40px'height='40px'
                                onError={(e)=>{onImageError(e)}}
                            />
                            <div className="payment-summary__account--title">
                                <p className="payment-summary__label">{ isPaymentIncome ? 'Requested to:' : 'Paid to:' }</p>
                                <p className="payment-summary__value">{payeeServiceId.accountName}</p>
                            </div>
                        </div>
                        <div className="payment-summary__account--reference">
                            <p className="payment-summary__label">Payment reference:</p>
                            <p className="payment-summary__value">{payeeServiceId.reference}</p>
                        </div>
                    </div>
                    <div className="payment-summary__payment">
                        <div  className="payment-summary__payment--item">
                            <p className="payment-summary__label">Payment ID:</p>
                            <p className="payment-summary__value">{checkLength(20, executingEntityId ? decodeExecutingEntityId(executingEntityId) : reportId)}</p>
                        </div>
                        <div  className="payment-summary__payment--item">
                            <p className="payment-summary__label">Date:</p>
                            <p className="payment-summary__value">
                                {
                                    moment().format("DD MMM YYYY") === moment(timestamp).format("DD MMM YYYY") ?
                                    'Today' :
                                    returnDate(timestamp, '/', true)
                                }
                            </p>
                        </div>
                        <div  className="payment-summary__payment--item">
                            <p className="payment-summary__label">Time:</p>
                            <p className="payment-summary__value">{returnShortTime(timestamp)}</p>
                        </div>
                        <div  className="payment-summary__payment--item">
                            <p className="payment-summary__label">Paid from:</p>
                            <p className="payment-summary__value">{bank?.accountName}</p>
                        </div>
                        {renderEmailDetails()}
                        {renderGiftAidDetails()}
                        {renderNotesDetails()}
                       { reportId && <img src={CopyIcon}  onClick={copyId} alt="Press to copy" className="payment-summary__copy-icon"/>}
                    </div>
                    {isPaymentIncome && <div className="payment-summary__button" onClick={handleSeeRequest}>See Request Summary</div>}
                    {clipboardMessageVisible && <div className="payment-summary__clipboard-message">Copied to clipboard</div>}
                </div>
            }
        </div>
    )
};

export default HistoryPaymentSummary;
