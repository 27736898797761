import { useEffect } from 'react';
import { useState } from 'react';

export const useScrollPosition = (element) => {
  const [scroll, setScroll] = useState({x: 0, y: 0});

  useEffect(() => {
    const callback = (e) => {
      setScroll({x: e.target.scrollLeft, y: e.target.scrollTop});
    }

    element && element.addEventListener('scroll', callback);

    return () => element && element.removeEventListener('scroll', callback);

  }, [element]);
  
  return scroll;
}