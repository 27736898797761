import { createAmount, createId } from "../helpers";
import { getPaymentMetainfo } from "../sagas/paymentWatcher";
import { common } from ".";
import config from "../config";

interface PaymentInstructionRequest extends common.Activation {
    entitySelector:common.EntitySelector
    properties: {
        "@type": string
        parties: common.Parties
        amount: common.Amount
        paymentMethod: {
            methodType: string
            properties: {
                "@type": string
                paylink: string
                linkedMethod: {
                    methodType: string
                    properties: LinkedMethodProperties
                }
            }
        }
    }
}    

interface LinkedMethodProperties {
    "@type": string
    serviceId: string
    name: string
    paymentReference: string
    endToEndIdentification: string
    metainfo:string
    callbackURI?:string
    targetCallback?:string
}    

export class InstructionRequest {
    payload:any
    paymentPayeeSubscriberIds:any
    iframeRedirectUrl:any
    amount:any
    
    constructor(payload: any, paymentPayeeSubscriberIds: any, iframeRedirectUrl:any) {
        this.payload = payload
        this.paymentPayeeSubscriberIds = paymentPayeeSubscriberIds
        this.iframeRedirectUrl = iframeRedirectUrl
    }

    createRequest() {
        return this.getRequestParams()
    }

    getRequestParams() : PaymentInstructionRequest {
        return  {
            "@type": "https://dvschema.io/activation#CreateRequest",
            id: createId(),
            entitySelector: {
                entityType: "https://miapago.io/paymentinstruction#PaymentInstruction",
                propertiesSelector: {
                    "@type": "https://miapago.io/paymentinstruction#PropertiesSelector",
                    methodSelectors: [
                        {
                            "methodType": "BoppPaylink"
                        }
                    ]
                }
            },
            properties: {
                "@type": "https://miapago.io/paymentinstruction#Properties",
                "parties": {
                    'payment:Payer': 'cri:NTgRQ2DIfWWuQIlromAFnR',
                },
                "amount": this.getAmount(),
                "paymentMethod": {
                    "methodType": "BoppPaylink",
                    "properties": {
                        "@type": "https://miapago.io/paylink#Properties",
                        "paylink": this.payload.paylink,
                        "linkedMethod": {
                            "methodType": "OpenBankingDomesticPayment",
                            "properties": this.getLinkedMethodProperties()
                        }
                    }
                }
            },
            timestamp: new Date(),
            possibleResend: false
        }
    }

    getLinkedMethodProperties() : LinkedMethodProperties {
        return {
            "@type": "https://miapago.io/ob/domesticpayment#Properties",
            "serviceId": this.payload.serviceId,
            "name": this.payload.name,
            "paymentReference": this.payload.paymentReference,
            "endToEndIdentification": this.payload.endToEndIdentification,
            "metainfo": this.payload.metainfo,
            callbackURI: this.iframeRedirectUrl && config.boppAppUrl + '/ucb',
            targetCallback: this.iframeRedirectUrl && this.iframeRedirectUrl
        }
    }

    getAmount() : common.Amount {
        return  createAmount(this.payload.amount)
    }

    getStatusScreenDetails(){
        let metainfo:any = {
            giftAid: { enabled: false, confirmMoney: false, confirmDonation: false },
            thankYouNote: { enabled: false, message: ''}
        }
  
        if (this.payload.metainfo) {
            metainfo = getPaymentMetainfo(this.payload.metainfo);
        }
  
        return {
            amount: this.payload.amount,
            accountName: this.payload.name,
            orderNumber: this.payload.paymentReference,
            giftAid: metainfo.giftAid,
            thankYouNote: metainfo.thankYouNote,
        }
    }
}