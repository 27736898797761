import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { RootState, store } from '../store';
import { amount } from './pispSlice';

export interface paylinkStatusData {
    isPayment?: boolean;
    paylink?: string;
    otp?: string;
    statusRequestId?: string;
    boppButton?: boolean;
    transactionId?: string;
    details?: {
        payeeName: string;
        paymentReference: string;
        finalPaymentDue: string;
        firstPaymentAmount: amount;
        recurringPaymentAmount: amount;
        finalPaymentAmount: amount;
        frequency: string;
        numberOfPayments: string;
        firstPaymentDue: string;
        firstRecurringPaymentDue: string;
        redirectUrls?:RedirectUrls; 
    }
}

export interface RedirectUrls { 
    success?:string
    fail?:string
    cancel?:string
}

export interface paylinkStatusState {
    paylinks: paylinkStatusData[];
    activePaylink: paylinkStatusData
}

const initialState: paylinkStatusState = {
    paylinks: [],
    activePaylink: {}
};

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const paylinkStatusSlice = createSlice({
    name: 'paylinkStatusSlice',
    initialState,
    reducers: {
        addPaylinkStatusData: (state, action: PayloadAction<paylinkStatusData>) => {
            if(state.paylinks.find(element => element.paylink === action.payload.paylink) === undefined) {
                state.paylinks = [...state.paylinks, {...action.payload}]
            } else {
                state.paylinks = (state.paylinks.length && state.paylinks || []).map((paylink) => {
                    return paylink.paylink === action.payload.paylink ? {...paylink, ...action.payload} : paylink
                })
            }
        },
        updatePaylinkStatusData: (state, action: PayloadAction<paylinkStatusData>) => {
            state.paylinks = (state.paylinks.length && state.paylinks || []).map((paylink) => {
                return paylink.paylink === action.payload.paylink ? {...paylink, ...action.payload} : paylink
            })
        },
        setActivePaylink: (state, action: PayloadAction<paylinkStatusData>) => {
            state.activePaylink = action.payload
        },
        setActivePaylinkTransactionId: (state, action: PayloadAction<string>) => {
            state.activePaylink.transactionId = action.payload
        },
    }
});

export const {
    addPaylinkStatusData,
    updatePaylinkStatusData,
    setActivePaylink,
    setActivePaylinkTransactionId,
} = paylinkStatusSlice.actions;

export const selectPaylinks = (state: typeof RootState) => state.paylink.paylinks;
export const selectActivePaylink = (state: typeof RootState) => state.paylink.activePaylink;

export default paylinkStatusSlice.reducer;