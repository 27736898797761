import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PlatformType } from '../static/CommonDefinitions';

const useMobileKeyboardDetection = () => {
  const platform = useSelector(state => state.settings.platform);
  const [keyboardIsVisible, setKeyboardIsVisible] = useState(false);

  useEffect(() => {
    const handleFocusIn = () => setTimeout(() => setKeyboardIsVisible(true), 0);
    const handleFocusOut = () => setTimeout(() => setKeyboardIsVisible(false), 0);

    document.addEventListener('focusin', handleFocusIn);
    document.addEventListener('focusout', handleFocusOut);

    return () => {
      document.removeEventListener('focusin', handleFocusIn);
      document.removeEventListener('focusout', handleFocusOut);
    }
  }, []);

  return platform !== PlatformType.Desktop && keyboardIsVisible;
}

export default useMobileKeyboardDetection;
