class ResponseObject {
    response:any
    
    constructor(response :any) {
        this.response = response
    }

    getResponseMessage() :any{
        return this.response
    }

    getEntityContext() :any{
        
        let type = this.response.state['@type']
        let parts = type.split('/')
        let subtype = type.substring(0, type.lastIndexOf('#'));
        return subtype
    }
}

export {
    ResponseObject
}