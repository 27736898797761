import { common } from ".";
import { createId } from './../helpers'

interface ChangeAccountDetailsRequest extends common.Activation {
    previousId: string,
    properties: {
        "@type": string,
        consent: string
    },
}

export class ChangeAccountDetails {
    code: string
    accountDetailsId: string

    constructor(code: string, accountDetailsId: string) {
        this.code = code
        this.accountDetailsId = accountDetailsId
    }

    createRequest() {
        return this.getRequestParams()
    }

    getRequestParams() : ChangeAccountDetailsRequest {
        return  {
            "@type": "https://dvschema.io/activation#ChangeRequest",
            id: createId(),
            previousId: this.accountDetailsId,
            properties: {
                "@type": "https://miapago.io/ob/accountdetails#Properties",
                consent: this.code
            },
            timestamp: new Date(),
            possibleResend: false
        }
    }
}