import { 
  takeLatest, 
  put, 
  select 
} from 'redux-saga/effects';
import { sendMessage } from '../actions';
import { 
  ErrorFlow, 
  ErrorObject 
} from '../entities/errorObject';
import { 
  PermissionType, 
  PermissionState 
} from '../static/CommonDefinitions';
import { sendError } from './../resolver/errorResolver';

export const PERMISSIONS_GET_STATUS = 'permissions/get/status';
export const PERMISSIONS_REQUEST = 'permissions/request';
export const PERMISSIONS_OPEN_SETTINGS = 'permissions/open/settings';
export const PERMISSIONS_RESOLVE = 'permissions/resolve';

export const resolvePermission = (permission) => ({
  type: PERMISSIONS_RESOLVE,
  permission
});

export const getPermissionStatus = (permission) => ({
  type: PERMISSIONS_GET_STATUS,
  permission
});

export const requestPermission = (permission) => ({
  type: PERMISSIONS_REQUEST,
  permission
});

export const openAppSettings = (permission) => ({
  type: PERMISSIONS_OPEN_SETTINGS,
  permission
});

function* dispatchGetPermissionStatus(payload) {
  // Request qnly existing permission "none" means that something was wrong and do nothing in this case
  if(payload.permission !== PermissionType.None)
  {
    yield put(sendMessage({ type: 'permission', get: payload.permission }));
  }
}

function* dispatchRequestPermission(payload) {
  // Request qnly existing permission "none" means that something was wrong and do nothing in this case
  if(payload.permission !== PermissionType.None)
  {
    yield put(sendMessage({ type: 'permission', request: payload.permission }));
  }
}

function* dispatchOpenSettings(payload) {
  // Request qnly existing permission "none" means that something was wrong and do nothing in this case
  if(payload.permission !== PermissionType.None)
  {
    yield put(sendMessage({ type: 'permission', open: 'settings' }));
  }
}

function* dispatchResolvePermission(payload) {
  // Request qnly existing permission "none" means that something was wrong and do nothing in this case
  if(payload.permission !== PermissionType.None)
  {
    try {
      const permissionStatus = yield select(state => state.permissions[payload.permission]);
      if(permissionStatus === PermissionState.Denied) {
        yield put(sendMessage({ type: 'permission', request: payload.permission }));
      } else {
        yield put(sendMessage({ type: 'permission', open: 'settings' }));
      }
    } catch (error) {
      yield put(sendError({error:new ErrorObject('Error sending message for resolve permission', error, ErrorFlow.mobileStorage)}));
    }
  }
}

export function* permissonSaga() {
  yield takeLatest(PERMISSIONS_GET_STATUS, dispatchGetPermissionStatus);
  yield takeLatest(PERMISSIONS_REQUEST, dispatchRequestPermission);
  yield takeLatest(PERMISSIONS_OPEN_SETTINGS, dispatchOpenSettings);
  yield takeLatest(PERMISSIONS_RESOLVE, dispatchResolvePermission);
}