import * as actionType from '../actions/actionTypes';

export const AuthCodeStatuses = {
    notChecked: 'notChecked',
    sucessPair: 'sucessPair',
    codeExist: 'codeExist',
    codeLinkedWithAnother: 'codeLinkedWithAnother',
    bankLinkedWithAnother: 'bankLinkedWithAnother',
    codeCombinationError: 'codeCombinationError',
    tooManyAttempts: 'tooManyAttempts',
}

export const initialState = {
    emailVerified: null,
    emailAndRefernceNumberPairExists: null,
    firstName: '',
    lastName: '',
    email: '',
    authCodeVerificationStatus: AuthCodeStatuses.notChecked,
    timerDeadline: {
        endTimeTimer: null,
        firstAttemptTimeDeadline: null,
        attempts: 0,
    },
    userSubscription: null,
}

export const kycReducer = (state=initialState, action) => {
    switch (action.type){
        case actionType.SET_CHECK_EMAIL_STATUS :
            return {
                ...state,
                emailVerified: action.payload.status
            };
        case actionType.SET_EMAIL_REFERENCE_NUMBER_PAIR_CHECK :
            return {
                ...state,
                emailAndRefernceNumberPairExists: action.payload.status
            };
        case actionType.SET_EMAIL :
            return {
                ...state,
                email: action.payload.email
            };
        case actionType.SET_FIRTSNAME :
            return {
                ...state,
                firstName: action.payload.firstName
            };
        case actionType.SET_LASTNAME :
            return {
                ...state,
                lastName: action.payload.lastName
            }
        case actionType.SET_CHECK_AUTH_CODE_STATUS :
            return {
                ...state,
                authCodeVerificationStatus: action.payload.codeStatus,
            };
        case actionType.SET_TIMER_DEADLINE :
            return {
                ...state,
                timerDeadline: action.payload.timerDeadline
            };
        default: return state
    }
}

export default kycReducer
