import { parseCallbackURL } from ".";

export class ConsentUrlObject {
    url = null;
    code = null;
    error = null;
    isCancelled = null;
    state = null;
    previousId = null;
    isAisp = null;
    params = null

    constructor(url) {
        this.url = url;
        this.params = parseCallbackURL({url:this.url})
        this.state = this.params.get('state');
        this.code = this.params.get('code');
        this.error = this.params.get('error');
        this.isCancelled = this.error !== null || this.code === 'reject';
          
        if(this.state != null) {
            this.aisp = this.state.startsWith('aisp-')
            this.previousId = "cri:" + this.state.split('-')[0];
        }
    }
}