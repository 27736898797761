import { createId, getIdDomain } from "../helpers";
import { common } from ".";
import { PaymentRequestType } from "../static/CommonDefinitions";

interface CancelDomesticPaymentRequest extends common.Activation {
    previousId: string
    entitySelector:common.EntitySelector
}    

export class CancelDomesticPayment {
    payload:any
    lastPaymentInstructionId:any
    
    constructor(payload: any, lastPaymentInstructionId:any) {
        if(!payload?.previousId && !lastPaymentInstructionId) {
            throw new Error('Previous id not passed')
        }
        this.payload = payload
        this.lastPaymentInstructionId = lastPaymentInstructionId
    }

    createRequest() {
        return this.getRequestParams()
    }

    getRequestParams() : CancelDomesticPaymentRequest {
        const prevId = this.lastPaymentInstructionId ? this.lastPaymentInstructionId : this.payload.previousId;
        const isRecurringPayment = getIdDomain(prevId) == PaymentRequestType.Recurring
        return  {
            "@type": "https://dvschema.io/activation#CancelRequest",
            id: createId(),
            previousId: prevId,
            entitySelector: {
                entityType: isRecurringPayment ? "https://miapago.io/paymentinstruction/v0.1.0#PaymentInstruction" : "https://miapago.io/paymentinstruction/PaymentInstruction",
                propertiesSelector: {
                    "@type": isRecurringPayment ? "https://miapago.io/paymentinstruction/v0.1.0#PropertiesSelector" : "https://miapago.io/paymentinstruction/PropertiesSelector",
                    methodSelectors: [
                        {
                            methodType: "BoppPaylink"
                        }
                    ]
                }
            },
            timestamp: new Date(),
            possibleResend: false
        }
    }
}

