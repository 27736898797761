import React from 'react';

import './style.scss';

  const CheckBox = ({ id, onChange, onClick, checked, disabled }) => {
  return (
    <div className="checkbox-wrapper">
      <input
          type="checkbox"
          id={id}
          className="checkbox-wrapper__checkbox"
          onChange={onChange}
          checked={checked}
          disabled={disabled}
      />
      <label onClick={onClick} htmlFor={id} className="checkbox-wrapper__label"/>
  </div>
  )
};

export default CheckBox;
