import React from 'react';
import '../../components/CheckBrowser/style.scss';

const UcbPage = () => {
  return (
    <div/>
  )
};

export default UcbPage;
