import { createId } from "../helpers";
import { common } from ".";

interface ChangePaymentRequest extends common.Activation {
    previousId: string
    entitySelector:common.EntitySelector
    properties: {
        "@type": string
        idToken : string
        parties: common.Parties
        paymentMethod: {
            methodType: string
            properties: {
                "@type": string
                linkedMethod: {
                    methodType: string
                    properties: {
                        "@type": string
                        consent: string
                        idToken: string
                    }
                }
            }
        }
    }
}    

export class ChangePayment {
    payload:any
    
    constructor(payload: any) {
        this.payload = payload
    }

    createRequest() {
        return this.getRequestParams()
    }

    getRequestParams() : ChangePaymentRequest {
        return  {
            "@type": "https://dvschema.io/activation#ChangeRequest",
            id: createId(),
            previousId: this.payload.previousId,
            entitySelector: {
                entityType: "https://miapago.io/paymentinstruction#PaymentInstruction",
                propertiesSelector: {
                    "@type": "https://miapago.io/paymentinstruction#PropertiesSelector",
                    methodSelectors: [
                        {
                            "methodType": "BoppPaylink"
                        }
                    ]
                }
            },
            properties: {
                "@type": "https://miapago.io/paymentinstruction#Properties",
                idToken: this.payload.idToken || this.payload.id_token,
                parties: {
                    "payment:Payer": "cri:NTgRQ2DIfWWuQIlromAFnR",
                },
                paymentMethod: {
                    methodType: "BoppPaylink",
                    properties: {
                        "@type": "https://miapago.io/paylink#Properties",
                        linkedMethod: {
                            methodType: "OpenBankingDomesticPayment",
                            properties: {
                                "@type": "https://miapago.io/ob/domesticpayment#Properties",
                                consent: this.payload.code || this.payload['#code'],
                                idToken: "eyJraWQiOiJhZGRkZCIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.7YogrGh7YYTNGYouRog2HHvkEhMiatt9QpBA7UTmTbc"
                            }
                        }
                    }
                }
            },
            timestamp: new Date(),
            possibleResend: false
        }
    }
}