import { sendError } from '../errorResolver';
import { 
    takeLatest, 
    put
} from 'redux-saga/effects';

import { 
    ErrorFlow, 
    ErrorObject 
} from '../../entities/errorObject';
import { getPropertyFrom } from '../../helpers/objectHelper';
import { updateCreateInstructionState } from '../../reducers/pispSlice';
import { createId, wrap } from '../../helpers';
import { IncomingMessagePayload } from '../messageResolver';
import { changeWrongScreen } from '../../actions';
import { setLastPaymentInstructionId } from '../../reducers/paymentPersistReducer';
import { sendWSMessage } from './statusRequestProcessor';
import config from '../../config';

export const RESOLVE_INCOMING_MESSAGE = 'resolve/create/instruction/incoming/message';
export interface ResolveInstructionIncomingMessage { type: typeof RESOLVE_INCOMING_MESSAGE; payload: IncomingMessagePayload }
export const resolveInstructionIncomingMessageAction = (payload: IncomingMessagePayload): ResolveInstructionIncomingMessage => ({
    type: RESOLVE_INCOMING_MESSAGE,
    payload,
})

export const RESOLVE_OUTCOMING_MESSAGE = 'resolve/create/instruction/outcoming/message';
export interface ResolveInstructionOutcomingMessagePayload {
    bankServiceId: string,
    paylink: string,
    iframeRedirectUrl: string
}
export interface ResolveInstructionOutcomingMessage { type: typeof RESOLVE_OUTCOMING_MESSAGE; payload: ResolveInstructionOutcomingMessagePayload }
export const resolveInstructionOutcomingMessageAction = (payload: ResolveInstructionOutcomingMessagePayload): ResolveInstructionOutcomingMessage => ({
    type: RESOLVE_OUTCOMING_MESSAGE,
    payload,
})

function* resolveInstructionIncomingMessage({ payload }: ResolveInstructionIncomingMessage): any {
    try {
        let authUrl = getPropertyFrom(payload, 'state.paymentState', 'authorizationURL', true)

        yield put(updateCreateInstructionState({
            authUrl,
            isAuthUrlRequestSent: false,
            authUrlTimeout: new Date().getTime() + 480000
        }))
    } catch (error) {
        yield put(sendError({error:new ErrorObject('Error resolving PISP Incoming message', error, ErrorFlow.accounts, payload)}));
        yield put(changeWrongScreen(true));
    }
}

function* resolveInstructionOutcomingMessage(socket: WebSocket,  payload: ResolveInstructionOutcomingMessage): any {
    const iframeRedirectUrl = payload.payload.iframeRedirectUrl;
    const serviceId = payload.payload.bankServiceId;
    const paylink = payload.payload.paylink;

    let id = createId(true)
    yield put(setLastPaymentInstructionId(id))
    try {    
        let request = {
            "@type": "https://dvschema.io/activation#CreateRequest",
            "id": id,
            "entitySelector": {
                "entityType": "https://miapago.io/paymentinstruction/v0.1.0#PaymentInstruction",
                "propertiesSelector": {
                    "@type": "https://miapago.io/paymentinstruction/v0.1.0#PropertiesSelector",
                    "methodSelectors": [
                        {
                            "methodType": "BoppPaylink"
                        }
                    ]
                }
            },
            "properties": {
                "@type": "https://miapago.io/paymentinstruction/v0.1.0#Properties",
                "parties": {
                    "payment:Payer": "cri:NTgRQ2DIfWWuQIlromAFnR"
                },
                "paymentTerms": {
                    "@type": "https://miapago.io/paymentterms/v0.1.0#RecurringPayment"
                },
                "paymentMethod": {
                    "methodType": "BoppPaylink",
                    "@type": "https://miapago.io/paylink/v0.1.0#PaymentMethod",
                    paylink,
                    "linkedMethod": {
                        "methodType": "OpenBankingPayment",
                        "@type": "https://miapago.io/ob/paymentmethods/v0.1.0#StandingOrder",
                        serviceId,
                        callbackURI: iframeRedirectUrl && config.boppAppUrl + '/ucb',
                        targetCallback: iframeRedirectUrl && iframeRedirectUrl
                    }
                }
            },
            "timestamp": new Date(),
            "possibleResend": false
        }

        yield sendWSMessage(wrap('paylink-initiator', request));
    } catch (error) {
        yield put(sendError({error:new ErrorObject('Error sending create payment instruction for ' + paylink, error, ErrorFlow.payments, null)}));
    }
}

export function* instructionProcessor({paylinkSocket}: any) {
  yield takeLatest(RESOLVE_INCOMING_MESSAGE, resolveInstructionIncomingMessage);
  yield takeLatest(RESOLVE_OUTCOMING_MESSAGE, resolveInstructionOutcomingMessage, paylinkSocket);
}