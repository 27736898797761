export default {
    bankAccountAdded: "bankAccountAdded",
    choosePlan: "choosePlan",
    emailScreen: "emailScreen",
    ActivationFailed: "ActivationFailed",
    ZohoForm: "ZohoForm",
    identityVerified: "identityVerified",
    activateBusiness: "activateBusiness",
    businessAccountVerified: "businessAccountVerified",
}
