import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import ChoosePlan from './ChoosePlan';
import EmailScreen from './EmailScreen';
import { AuthCodeStatuses } from '../../reducers/kycReducer';
import FormSteps from './FormSteps.js';
import VerifyEmail from '../VerifyEmail';
import {
  removeAccountDetails,
  removeBank,
  saveInApp,
  setEmailAndRefernceNumberPairCheck,
  setCheckUserAuthCodeStatus,
  updateTimerDeadline,
  setEmail,
  setFirstName,
  setLastName,
  setStatusBarColor,
} from '../../actions';

import { isDeadlineActual } from '../../helpers';
import SelectionModal from '../Modal/SelectionModal';
import ActivateBOPP from './ActivateBOPP';
import BankAccountAdded from '../BankAccountAdded';
import { cleanTimer } from '../../sagas/kycWatcher';
import ActivateBOPPWarning from './ActivateBOPPWarning';
import config from './../../config';
import ActivationFailed from "./ActivationFailed/ActivationFailed";
import HeaderUniversal from "../../components/HeaderUniversal";
import WhiteLogo from '../../assets/images/logo-white.svg';
import CloseIcon from '../../assets/images/close-icon.svg';
import BackIcon from '../../assets/images/back-arrow.svg'

import colors from '../../assets/style.theme.scss';
import './styles.scss';
import BoppLogo from "../../assets/images/bopp-logo.svg";
import BackArrow from "../../assets/images/back-arrow.svg";
import classNames from 'classnames';



const KYCForm = () => {
  const history = useHistory(); 
  const dispatch = useDispatch();

  const { bank, emailAndRefernceNumberPairExists, timerDeadline,
    authCodeVerificationStatus } = useSelector(state => ({
      bank: state.payment.bank,
      emailAndRefernceNumberPairExists: state.kyc.emailAndRefernceNumberPairExists,
      timerDeadline: state.kyc.timerDeadline,
      authCodeVerificationStatus: state.kyc.authCodeVerificationStatus,
    }));
  const defaultScreenHeight = useSelector(state => state.settings.defaultScreenHeight);
  const [closeModal, setCloseModal] = useState(false);
  const [accountTypeModal, setAccountTypeModal] = useState(false);
  const [userData, setUserData] = useState({
    selectedPlanOption: '',
    email: '',
    policyCheckbox: false,
    authCode: '',
    firstName: '',
    lastName: ''
  });
  const headerButtonsStyle = { width: '24px', height: '24px', top: '41px' };
  const isVerificationSuccess = authCodeVerificationStatus === AuthCodeStatuses.sucessPair;

  useEffect(() => {
    dispatch(setStatusBarColor(colors.mainYellow));
    dispatch(setCheckUserAuthCodeStatus(AuthCodeStatuses.notChecked));
    return ()=>{
      dispatch(setEmailAndRefernceNumberPairCheck({status: null}));
    }
  }, [])

  const isTimerActive  = isDeadlineActual(timerDeadline?.endTimeTimer) && !timerDeadline?.noAispFlow;
  const isAttemptsLoopActual  = isDeadlineActual(timerDeadline?.firstAttemptTimeDeadline);

  useEffect(() => {
    if(isTimerActive) {
      dispatch(setCheckUserAuthCodeStatus(AuthCodeStatuses.tooManyAttempts));
    }
    if(!isAttemptsLoopActual || isTimerActive) {
      dispatch(updateTimerDeadline({ ...timerDeadline, firstAttemptTimeDeadline: null, attempts: 0 }));
    }
    return () => {
      dispatch(setCheckUserAuthCodeStatus(AuthCodeStatuses.notChecked));
    }
  }, [])

  useEffect(() => {
    if (isVerificationSuccess) {
      history.push('/business-verified');
    }
  }, [isVerificationSuccess]);

  const [steps, setSteps] = useState([
    isTimerActive
    ? FormSteps.businessAccountVerified
    : history.location.state
  ]);

  const handleUserData = (newData) => {
    setUserData(prevData => ({
      ...prevData,
      ...newData,
    }))
  }

  const changeStep = (step) => {
    setSteps(prevSteps => prevSteps.concat(step));
}

  const changeStepBack = () => {
    if(emailAndRefernceNumberPairExists!==null){
      dispatch(setEmailAndRefernceNumberPairCheck({status: null}))
    }
    if(steps.length > 1){
      setSteps(prevSteps => prevSteps.filter(
        (item, index) => index !== prevSteps.length - 1)
      );
  }}

  const prevStep = () => {
    changeStep(FormSteps.emailScreen)
  }

  const closeScreen = () => {
    history.push('/home-screen', 'show-retrive');
  }


  const removeBankAccount = () => {
    dispatch(removeAccountDetails())
    dispatch(removeBank());
    dispatch(saveInApp({key: 'bankAccountDetails', value: {}}))
    dispatch(cleanTimer());
    dispatch(setFirstName({firstName: ''}));
    dispatch(setLastName({lastName: ''}));
    dispatch(setEmail({ email: '' }));

    if (closeModal) {
      setCloseModal(false);
      history.push('/home-screen');
      setSteps([]);
    }
  }

  const changeAccount = () => {
    history.push('/home-screen', 'show-retrive');
    setAccountTypeModal(false);
    removeBankAccount();
    setSteps([]);
  };

  function renderStep() {
    switch (steps[steps.length - 1]) {
      case FormSteps.bankAccountAdded:
        return (
          <div
            className={`form-content ${steps[0] === FormSteps.bankAccountAdded ? 'form-content__bank-added' : ''}`}
          >
            <BankAccountAdded changeStep={changeStep} />
          </div>
        );
      case FormSteps.choosePlan:
        return (
          <div className="form-content__user_data_choose_plan">
            <ChoosePlan changeStep={changeStep} userData={userData} setUserData={handleUserData} />
          </div>
        );

      case FormSteps.emailScreen:
        return (
          <div className="form-content__user_data_confirmation">
            <EmailScreen changeStep={changeStep} userData={userData} setUserData={handleUserData} />
          </div>
        );

      case FormSteps.ActivationFailed:
        return (
            <div className="form-content__user_data_activation_failed">
              <ActivationFailed changeStep={changeStep}/>
            </div>
        )

      case FormSteps.ZohoForm:
        return (
          <div className="zoho-form-screen">
            <div className="zoho-form__header">
              <div>
                <img
                    className="zoho-form__button"
                    src={BackArrow}
                    alt="Back"
                    onClick={prevStep}/>
              </div>
              <div>
                <img src={BoppLogo} alt="BoppLogo"/>
              </div>
              <div>
                <img
                    className="zoho-form__button"
                    src={CloseIcon}
                    alt="Close"
                    onClick={closeScreen}/>
              </div>
            </div>
            <iframe
              title='zoho-form'
              frameBorder='0'
              className="zoho-form"
              src={`${config.zohoFormURL}?consentId=${bank.id}&email=${userData.email}&lastName=${userData.lastName}&FirstName=${userData.firstName}`.replace("+", '\u002B')}
            ></iframe>
          </div>
        );
      case FormSteps.identityVerified:
        return (
          <VerifyEmail gotItCallback={()=>{dispatch(setEmailAndRefernceNumberPairCheck({status: null})); history.push('/home-screen')}} />
        );
      case FormSteps.activateBusiness:
        return (
          <div className="form-content form-content__activate">
            <ActivateBOPP changeStep={changeStep} onChangeAccountTextButton={setAccountTypeModal} userData={userData} setUserData={handleUserData} />
          </div>
        );
      case FormSteps.businessAccountVerified:
        return (
          <ActivateBOPPWarning goBack={changeStepBack} userData={userData} setUserData={handleUserData} />
        );
    }
  }

  const currentStep = steps[steps.length - 1];

  const hideGradient = currentStep === FormSteps.businessAccountVerified || currentStep === FormSteps.identityVerified;
  const isFailed = currentStep === FormSteps.ActivationFailed;

  const finalClassName = classNames([
    "form-container",
    !hideGradient && !isFailed && "form-container--success",
    !hideGradient && isFailed && "form-container--failed",
  ]) 

  return (
    <div className={finalClassName} style={{minHeight: defaultScreenHeight + 'px'}}>
      {closeModal && <SelectionModal optionTop={"Leave Anyway"} optionBottom={"cancel"} selectOptionTop={removeBankAccount} selectOptionBottom={()=>{setCloseModal(false)}} topOptionType={"tip"} bottomOptionType={"cancel-outline"} title={"If you close this screen now your account details will not be saved and you will need to restart the process in order to use BOPP to send payment requests."} />}
      {accountTypeModal && <SelectionModal optionTop={"link personal account"} optionBottom={"cancel"} selectOptionTop={() => changeAccount()} selectOptionBottom={()=>{setAccountTypeModal(false)}} topOptionType={"tip"} bottomOptionType={"cancel-outline"} title={"Business bank accounts can be linked only by companies. You need to link personal bank account to use BOPP for personal purposes (your current bank account will be removed from BOPP)."} />}

      {/*{ steps[steps.length-1] !== FormSteps.identityVerified && steps[steps.length-1] !== FormSteps.businessAccountVerified &&*/}
      {/*<HeaderUniversal logo={WhiteLogo} leftButtonIcon={steps?.length === 1 ? null : BackIcon} customStylesLeftBtn={headerButtonsStyle} leftButtonEvent={changeStepBack} rightButtonIcon={CloseIcon} customStylesRightBtn={headerButtonsStyle} rightButtonEvent={() => setCloseModal(true)} />}*/}

      {renderStep()}
    </div>
  );
};

export default KYCForm;
