import { common } from ".";
import {createId} from './../helpers'

interface GetHistoryObjectStatusRequest extends common.Activation {
    entitySelector:common.EntitySelector
    reportSelector?:{
        entityType: string
        id: string
    }
    visit?: boolean
    compact?: boolean
}

export class GetHistoryObjectStatus {
    id: string
    isRequest: boolean
    requestId: string

    constructor(id: string, isRequest:boolean) {
        this.id = id
        this.isRequest = isRequest
        this.requestId = createId()
    }

    createRequest() {
        return this.getRequestParams()
    }

    getRequestParams(): GetHistoryObjectStatusRequest {
        if(this.isRequest) {
            return {
                "@type": "https://dvschema.io/activation#StatusRequest",
                "id": this.requestId,
                "entitySelector": {
                    "entityType": "https://miapago.io/paylink/request/PaylinkRequest",
                    "id": this.id,
                },
                "timestamp": new Date(),
                "possibleResend": false
              }
        } else {
            return {
                "@type": "https://dvschema.io/activation#StatusRequest",
                "id": this.requestId,
                "entitySelector": {
                  "entityType": "https://miapago.io/paymentreport#PaymentReport"
                },
                "reportSelector": {
                  "entityType": "https://miapago.io/paymentreport#PaymentReport",
                  "id": this.id
                },
                "timestamp": new Date(),
                "possibleResend": false,
                "visit": true,
                "compact": true
            }
        }
    }
}