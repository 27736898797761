import React, {useState, useEffect} from 'react';
import './style.scss';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useSelector } from "react-redux";

var QRCode = require('qrcode.react');

const QRCodeBlock = ({ paylink, otp }) => {

    const [otpTime, setOtpTime] = useState(0);
    const otpCode = useSelector(state => state.payment.otp);
    const qrSize = window.innerHeight/3.5
    const qrLogoHeight = qrSize / 5
    const qrLogowidth = qrLogoHeight * 1.975

    useEffect(() => {
        updateTime(otp)
        let timer = setInterval(() => {
            updateTime(otp)
        }, 1000);
        return () => clearInterval(timer);
    }, [otp]);

    const updateTime = (otp) => {
        if(otp) {
            let timeLeft = (new Date().getTime() - otpCode.createdTime.getTime()) / 1000            
            setOtpTime(timeLeft)
        }
    }

    const plinkurl = process.env.REACT_APP_SETTING_PAYLINK_URL;

    const url = paylink ? plinkurl + '/link' + paylink.split('bopp.link')[1] : '';

    return(
        <div className="qrcode-wrapper">
            <QRCode value={url} size={qrSize}
                     level={'H'}
                     bgColor={'#F5F7FA'}
                     fgColor={'#434343'}
                     imageSettings={
                         {
                             src:require('../../assets/images/bopp-logo.png'),
                             height: qrLogoHeight,
                             width: qrLogowidth,
                             excavate: false,
                         }
                     }
            />
            {/*<div className="qrcode-numeric">
                <span className="use-code">{otp ? `code: ${otp.substring(0,3)} ${otp.substring(3,6)}`:""}</span>
                {otp ?
                <div className="timer_container">
                <div className="timer_container_stroke"/>
                    <CircularProgressbar 
                        value={otpTime} 
                        strokeWidth={50}
                        maxValue={180}
                        background={true}
                        styles={buildStyles({
                            strokeLinecap: "butt",
                            pathColor: '#F5F7FA',
                            trailColor:'#8A8E94',
                            pathTransitionDuration: 0,
                        })}
                    />
                    
                    
                </div>
                : null}
            </div>*/}
        </div>
    )
}

export default QRCodeBlock;