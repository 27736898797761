import React, {useEffect} from 'react';

const KYCSubmitted = () => {
  useEffect(() => {
        if(window.parent){
            window.parent.postMessage("form_submitted", "*")
        }
  }, []);
  
  return (
    <div/>
  );
};

export default KYCSubmitted;
