import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from 'react-router-dom';

import {
    openCallBackPage,
    passBankUrlToNative,
    changeAccountDetailsScreen,
    updateAccountDetailsBank,
    createAccountDetails,
    removeAccountDetailsUrl,
    cancelAccountDetails,
    openNewPage,
    setStatusBarColor,
    setHome
} from "../../actions";
import colors from '../../assets/style.theme.scss';
import ChooseBankPanel from "../../components/ChooseBankPanel";
import SelectionModal from "../Modal/SelectionModal";
import PinSetModal from "../Modal/PinSetModal";

import CloseIcon from "../../assets/images/close-icon.svg";
import InfoIcon from "../../assets/images/info-icon-purple.svg";

import './style.scss';
import config from '../../config';
import Button from '../../components/Button';

import {Home} from './../../static/CommonDefinitions'

let timerId = null;

const RetrieveAccountDetails = ({ closeAccountDetails, openModal, openChooseBankModal }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [infoModal, setInfoModal] = useState(false);
    const [timeModal, showTimeModal] = useState(false);
    const [clickChange, setClickChange] = useState(false);
    const [chooseFlowPopup, setChooseFlowPopup] = useState(false);
    const accountDetails = useSelector(state => state.payment.accountDetails);
    const accountName = useSelector(state => state.payment.accountDetails?.bank?.accountName);
    const clickConfirm = useSelector(state => state.payment.accountDetailsScreen.clickConfirm);
    const authorizationURL = useSelector(state => state.payment.accountDetails &&
      state.payment.accountDetails.state ?
      state.payment.accountDetails.state.authorizationURL : null
    );
    const browserBankingAccountDetails = useSelector(state => state.settings.browserBankingAccountDetails);

    useEffect(() => {
        setClickChange(false);
        localStorage.setItem('timestamp', JSON.stringify(new Date().getTime() + 480000));
        if(accountDetails && authorizationURL && !clickChange) {
            let timestamp = JSON.parse(localStorage.getItem('timestamp'));
            timerId = setInterval(() => {
                if(timestamp < new Date().getTime()){
                    showTimeModal(true);
                    clearInterval(timerId);
                    localStorage.removeItem('timestamp');
                }
              }
              , 5000);
        }
    }, [authorizationURL]);

    useEffect(() => {
        dispatch(setStatusBarColor(colors.lightGrayBg));
    }, [])

    useEffect(() => {
        if(clickConfirm  || clickChange){
            clearInterval(timerId);
            localStorage.removeItem('timestamp');
            showTimeModal(false);
        }
    }, [clickConfirm, clickChange]);

    useEffect(() => {
        if (accountDetails.bank && !authorizationURL) {
            dispatch(updateAccountDetailsBank(accountDetails.bank));
            dispatch(createAccountDetails(accountDetails.bank));
        }
    }, [authorizationURL]);

    const handleRetrieveAccountDetails = (flow) => {
        dispatch(passBankUrlToNative(accountDetails.state.authorizationURL));
        dispatch(changeAccountDetailsScreen({transferringToBank: true, clickConfirm: true, targetBank: accountName}));

        dispatch(setHome(Home.Request));
        if (flow==="DEV") {
            return dispatch(openCallBackPage(accountDetails.state.authorizationURL));
        } else {
            window.location.replace(accountDetails.state.authorizationURL);
        }
    };

    const cancelConsent = () => {
        history.push('/next');
    };

    const refreshPage = () => {
        dispatch(cancelAccountDetails());
        showTimeModal(false);
        dispatch(removeAccountDetailsUrl());
    };

    const handleOpenChooseBankModal = () => {
        openChooseBankModal();
        setClickChange(true);
    };

    const closeInfoModal = () => {
        setInfoModal(false);
    };

    const openInfoModal = () => {
        setInfoModal(true);
    };

    const openNewTab = (url) => {
        return dispatch(openNewPage(url));
    };

    return(
      <>
        {timeModal &&
            <SelectionModal
              title={"Sorry, this page has timed out."}
              optionTop={'REFRESH PAGE'}
              optionBottom={'CANCEL'}
              selectOptionTop={refreshPage}
              selectOptionBottom={cancelConsent}
            />
        }
        {infoModal &&
            <PinSetModal
                closeButton={false}
                bottomButton={true}
                closeModal={closeInfoModal}
                title="Why Do You Need This?"
                content="To ensure that each payment request sent can be trusted as it contains account details provided directly by the bank."
            />
        }
        {chooseFlowPopup &&
        <SelectionModal
            title={"Choose Flow"}
            optionTop={"Dev flow"}
            optionBottom={"Standard Flow"}
            selectOptionBottom={()=>{setChooseFlowPopup(false); handleRetrieveAccountDetails("STANDARD")}}
            selectOptionTop={()=>{setChooseFlowPopup(false); handleRetrieveAccountDetails("DEV")}}
        />}
        <div className="wrapper-details">
            <div className="details-header">
                <div className="details-header-title">Retrieve Account Details</div>
                <img className="details-close-button" onClick={() => closeAccountDetails()} src={CloseIcon} alt="" />
            </div>
            <div className="details-content">
                <div className="top-text">
                    <p>
                        To securely process payments we need to contact your bank.
                        <img className="info-icon" src={InfoIcon} alt="info" onClick={openInfoModal} />
                    </p>
                    <p>We only access the following details:</p>
                </div>
                <div className="details-text">
                    <p>- Full Legal Name</p>
                    <p>- Account name</p>
                    <p>- Account number & sort code</p>
                </div>
                <div className='details-consent-expire'>
                    <p>BOPP will have access to your bank account details for the next 10 mins.</p>
                </div>
                <div className="details-policy">
                    <p>Your details will be used in line with our <a onClick={() => openNewTab('https://bopp.io/legal')}>Privacy Policy</a></p>
                </div>
            </div>
            <div className="select-bank-block">
                <ChooseBankPanel openModal={openModal} openChooseBankModal={handleOpenChooseBankModal} bankAccount={true}/>
            </div>
            <div className="details-bottom">
                <p className="bottom-text">You will be transferred to your bank to authenticate and confirm</p>
                <Button
                    title="Confirm"
                    handleClick={()=>{config.appEnvironment==="DEV"? setChooseFlowPopup(true) : handleRetrieveAccountDetails("STANDARD")}}
                    color="black"
                    disabled={!accountDetails.state || !accountDetails.state.authorizationURL}
                />
            </div>
        </div>
      </>
    );
};

export default RetrieveAccountDetails;