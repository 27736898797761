import React, {useState} from 'react'

function AddBankAccountDetailsModal({modalDetailsObject}) {
    const [input, setInput] = useState(modalDetailsObject.inputValue)

    const handleChange = (event) => {
        if(modalDetailsObject?.title === 'Default Payment Reference' || modalDetailsObject?.title === 'Edit Reference') {
            let reg = new RegExp("[^a-zA-Z0-9 ]+", "g");
            if(event.target.value.length <= 18 && !reg.test(event.target.value)) {
                setInput(event.target.value);
            }
        } else {
            setInput(event.target.value);
        }
    }

    const validateInput = (value, maxLen, fn) => {
        if (value.length <= maxLen /*&& event.keyCode !== 8 && event.keyCode !== 46*/) {
            fn(value);
        }
    };

    return (
        <>
            <div className="gray-background"/>
            <div className="modal-window modal-ref">
                <div className="background-color background-color__base" />
                <div className="modal-window-content modal-window-content__question">
                    <div className="modal-title">
                        {modalDetailsObject.title}
                    </div>
                    <div className="modal-description modal-description-edit">
                        {modalDetailsObject.message}
                    </div>
                    <div className="modal-input">
                        <input
                            type="text"
                            id="default-payment-reference"
                            placeholder={modalDetailsObject.placeholder}
                            name="defaultRef"
                            value={input}
                            onChange={handleChange}
                        />
                    </div>
                    {modalDetailsObject.prompt && <span className="modal-prompt">{modalDetailsObject.prompt}</span>}
                    <div className="modal-buttons-group">
                        <div className="modal-info-button modal-info-button__fluid" onClick={()=>{ modalDetailsObject.maxLength===undefined? modalDetailsObject.onSave(input): validateInput(input, modalDetailsObject.maxLength, modalDetailsObject.onSave) }}>
                            Save
                        </div>
                        <div className="modal-cancel-button" onClick={modalDetailsObject.onCancel}>
                            CANCEL
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddBankAccountDetailsModal
