import { 
    takeLatest, 
    put 
} from 'redux-saga/effects';
import * as actionType from '../actions/actionTypes';
import { sendMessage } from '../actions';
import { sendError } from './../resolver/errorResolver';
import { 
    ErrorFlow, 
    ErrorObject 
} from '../entities/errorObject';

function* sendPin(action) {
    yield put(sendMessage({
        type : 'storage',
        set: 'pin',
        pin: action.payload
    }));
}

function* getPin() {
    yield put(sendMessage({ type: 'pinProtection', get: 'values' }));
}

function* setBankAccount(action) {
    try {
        yield put(sendMessage({
            type : 'pinProtection',
            set: 'bankAccount',
            value: action.payload.checkBoxValue,
            pin: action.payload.pin
        }));
    } catch (error) {
        yield put(sendError({error:new ErrorObject('Error set pin', error, ErrorFlow.mobileStorage)}));
    }
}

function* setActivity(action) {
    try {
        yield put(sendMessage({
            type : 'pinProtection',
            set: 'activity',
            value: action.payload.checkBoxValue,
            pin: action.payload.pin
        }));
    } catch (error) {
        yield put(sendError({error:new ErrorObject('Error sending message for set activity', error, ErrorFlow.mobileStorage)}));
    }
}

function* setBoppLaunch(action) {
    try {
        yield put(sendMessage({
            type : 'pinProtection',
            set: 'launch',
            value: action.payload.checkBoxValue,
            pin: action.payload.pin
        }));
    } catch (error) {
        yield put(sendError({error:new ErrorObject('Error sending message for set BOPP launch', error, ErrorFlow.mobileStorage)}));
    }
}

export function* pinWatcher() {
    yield takeLatest(actionType.SET_PIN_CODE, sendPin);
    yield takeLatest(actionType.GET_PIN_CODE, getPin);
    yield takeLatest(actionType.SET_BANK_ACCOUNT, setBankAccount);
    yield takeLatest(actionType.SET_ACTIVITY, setActivity);
    yield takeLatest(actionType.SET_BOPP_LAUNCH, setBoppLaunch);
}