import React from 'react';
import CloseIcon from '../../../assets/images/close-icon.svg';

const LimitReachedModal = ({ closeModal }) => {
  return (
    <>
      <div className="gray-background" />
      <div className="modal-window">
        <div className="background-color background-color__error" />
        <div className="modal-window-content">
          <div onClick={closeModal} className="modal-close-button">
            <img src={CloseIcon} alt="Close the message" />
          </div>
          <div className="modal-title__border">
            Payee limit reached
          </div>
          <div className="modal-description">
            The amount you are trying to pay exceeds a limit for this payee. Please contact them for details.
          </div>
        </div>
      </div>
    </>
  );
};

export default LimitReachedModal;