import {
  changeWrongScreen,
  readError,
  setError,
  payerActivityReportCancelled,
  setIsPaymentSocketClosed
} from '../actions';

import { PAYLINK_PAYMENT_INSTRUCTION, PAYLINK_PAYMENT_REQUEST } from '../entities/entityContexts'

import {resolveMessage} from './../resolver/messageResolver'
import {MessageType} from './../static/CommonDefinitions'

import config from './../config';

const ActivityReportCancelled = "https://dvschema.io/activation#ActivityReportCancelled";
function responseHandler(dispatch, data) {
  console.log("responseHandler", JSON.parse(data.toString()))
  const message = JSON.parse(data.toString());

  if (message.error) {
    console.log("SERVER ERROR", message.error);
    try {
      dispatch(setError({code: message.error.code, message: message.error.message}));
    } catch(err) {
      console.log("setError", err)
    }
    dispatch(changeWrongScreen(true));
    return
  }

  const response = message.params[1];
  if (response.state) {
    dispatch(readError(response));

    const responseType = response['@type']

    const context = response.state['@type'].substring(0, response.state['@type'].lastIndexOf('#'));

    console.log("context:", context)
    switch (context) {
      case PAYLINK_PAYMENT_INSTRUCTION:
        switch (responseType) {
          case ActivityReportCancelled:
            dispatch(payerActivityReportCancelled(response))
            return "stop"
            break;
          default:
            console.warn("responseType", context)
            break;
        }
        break;
      case PAYLINK_PAYMENT_REQUEST:
        switch (responseType) {
          case ActivityReportCancelled:
            //dispatch(payeeActivityReportCancelled(response))
            return "stop"
            break;
          default:
            console.warn("responseType", context)
            break;
        }
        break;
      default:
        console.warn("context", context)
        break;
    }

  }
  return
}

const setupSocket = (dispatch, url) => {
  let websocketUrl = url;

  console.log('Connection setup', websocketUrl);

  const initSocket = (dispatch, data) => {
    const socket = new WebSocket(websocketUrl);

    socket.onopen = () => {
      console.log('Connection opened', websocketUrl);
      if(socket.url === config.paylinkServerURL) {
        dispatch(setIsPaymentSocketClosed(false))
        console.log('Socket opened', websocketUrl);
      }
      if (data) {
        console.log(data)
        socket.send(data)
      }
    };

    socket.onmessage = ({ data }) => {
      console.log(JSON.parse(data.toString()));
      const message = JSON.parse(data.toString());
      resolveMessage({socketUrl: socket.url, message: {message}}, MessageType.Server, dispatch)
    };

    socket.onclose = () => {
      console.log(`log chanel close`, socket.url);
      socket.isClosed = true;
      if(socket.url === config.paylinkServerURL) {
        dispatch(setIsPaymentSocketClosed(true))
        console.log('Socket closed', websocketUrl);
      }
    };

    return socket;
  };

  let socket = initSocket(dispatch);
  let asyncSend = async (data) => {
    if (!socket || (socket && socket.isClosed) || !socket.readyState) {
      socket = initSocket(dispatch, data);
      return;
    } else {
      console.log(data)
      return socket.send(data);
    }

  };

  let reconnect = (url) => {
    if (url != websocketUrl) {
      websocketUrl = url
      socket = initSocket(dispatch);
    }
  };

  let closeSocet = () => {
      socket.close();
  };

  let WrappedSocket = {
    send: asyncSend,
    reconnect: reconnect,
    close: closeSocet
  };
  return WrappedSocket;
};

export default setupSocket;