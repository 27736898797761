import React, {useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import CloseIcon from '../../assets/images/close-icon.svg';
import Success from "../../assets/images/success.svg";
import Logo from '../../assets/images/bopp-logo.svg';
import WarningIcon from "../../assets/images/warning.svg";
import './style.scss'
import {
    setCheckEmailStatus,
    updateBank,
    setStatusBarColor,
    saveInApp,
    setPayeeSubscriberIds,
    getPayeeSubscriberIdByPayeeIdAction,
    getApiKeyByPayeeIdAction
} from '../../actions';
import colors from '../../assets/style.theme.scss';
import {KYCVerificationStatus} from '../../static/CommonDefinitions'
import HeaderUniversal from '../../components/HeaderUniversal';
import Button from '../../components/Button';

function  VerifyEmail({gotItCallback}) {

    const history = useHistory();
    const dispatch = useDispatch();
    const {bank, emailVerified, emailAndRefernceNumberPairExists, email} = useSelector(state => {
        return {
            bank: state.payment.bank,
            emailVerified: state.kyc.emailVerified,
            emailAndRefernceNumberPairExists: state.kyc.emailAndRefernceNumberPairExists,
            email: state.kyc.email
        }
    })
    const [details, setDetails] = useState(null)

    useEffect(() => {
        dispatch(setStatusBarColor(colors.defaultBar));

        if(emailAndRefernceNumberPairExists){
            setDetails("identityVerified");
            dispatch(updateBank({ bankAccountStatus: KYCVerificationStatus.Verified }));
        }
        return () => {
            dispatch(setCheckEmailStatus(null))
        }
    }, [])

    useEffect(() => {
        if (!emailAndRefernceNumberPairExists && bank.bankAccountStatus !== KYCVerificationStatus.Verified) {
            if(bank?.id && bank.bankAccountStatus !== KYCVerificationStatus.Sent){
                setDetails(KYCVerificationStatus.Sent)
                dispatch(updateBank({ bankAccountStatus: KYCVerificationStatus.Sent }));
            }
        }
    }, [bank])

    useEffect(() => {
        if(typeof history.location.state === 'object' && 'bankId' in history.location.state){
                if(emailVerified){
                    dispatch(getPayeeSubscriberIdByPayeeIdAction());
                    dispatch(getApiKeyByPayeeIdAction())
                    dispatch(updateBank({ bankAccountStatus: KYCVerificationStatus.Verified }));
                    setDetails(KYCVerificationStatus.Verified)
                } else {
                    if (emailVerified !== null){
                        setDetails('warning')
                    }
                }
        }
    }, [emailVerified])

    const pageDetails = {
        [KYCVerificationStatus.Sent]: {
            icon: Success,
            title: 'Verify your email',
            message: 'Thank you, your data was successfully submitted. Please verify your email by clicking the link we have just sent you. If our email is not in your inbox please check your spam folder just in case.',
        },
        warning: {
            icon: WarningIcon,
            title: 'Email is Not Verified',
            message:
                <div>
                    <p>Please verify your email using the link that we’ve sent to your email address.</p>
                    <p>Please note it can take a few minutes before the application can detect this.</p>
                </div>
        },
        [KYCVerificationStatus.Verified]: {
            icon: Success,
            title: 'Email Verified Successfully',
            message: 'Congratulations! Now you can start sending payment requests.',
        },
        identityVerified: {
            icon: Success,
            title: 'Identity Verified',
            message: 'Congratulations! Now you can start sending payment requests on this device.',
        }
    }

    console.log(details)
    return (
        <div className={`verify-email-page ${details === 'warning' ? 'warning' : ''}`}>
            <HeaderUniversal
                logo={details === KYCVerificationStatus.Sent ? undefined : Logo}
                rightButtonIcon={CloseIcon}
                rightButtonEvent={gotItCallback}
                customStylesLogo={{top: '30px'}}
                customStylesRightBtn={{ width: '32px', height: '32px', top: '33px' }}
            />

            <div className="flex-centering">
                {details && <div className="message-block">
                    <img width={64} height={64} src={pageDetails[details].icon}/>
                    <h3>{pageDetails[details].title}</h3>
                    <div className="message">
                        {pageDetails[details].message}
                    </div>
                </div>}
                <div className="bottom-button">
                    <Button title="Got It" handleClick={gotItCallback} color="black"/>
                </div>
            </div>
        </div>
    )
}

export default VerifyEmail;
