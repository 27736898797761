import { 
  put, 
  select, 
  takeLatest 
} from '@redux-saga/core/effects';
import * as actionType from '../actions/actionTypes';
import * as entityContexts from '../entities/entityContexts';
import packageJson from '../../package.json';
import {
  changeDomesticPayment,
  changeAccountDetails,
  sendMessage,
  handlePaymentStatus,
  addBank,
  selectBankAccepted,
  changePendingAccept,
  saveBankList,
  saveInApp,
  addUsedBank,
  paylinkRequestAccepted,
  startOtpCalculator,
  setUsedBank,
  changePaymentStatusScreen,
  setError,
  clearPaylinkUrl,
  showInvalidPaymentError,
  setAccountLimit,
  setPayeeSubscriberIds,
  updateBank,
  setPaylinkSourceAction,
  setRefreshSubscriptionRequestStatus,
  getPayeeSubscriberIdByPayeeIdAction,
  getApiKeyByPayeeIdAction,
  checkUserSubscription
} from '../actions';
import {
  PaymentReportState, 
  AccountType, 
  TermType, 
  PaymentDirection, 
  ReferenceSettingType
} from "../static/CommonDefinitions";
import axios from 'axios';
import config from '../config';
import {
  addHistoryRequest, 
  updateHistoryRequest, 
  addHistoryPayment, 
  setHistoryPendingPayment, 
  updateHistoryPayment, 
  addHistoryPendingPaymentToHistory, 
  setHistoryRequestCancelledFromHistory, 
  addLasteReceipt, 
  setUpdateRequestSent, 
  updateLasteReceipt
} from './../reducers/historyReducer'
import { 
  saveRequestToNative, 
  savePaymentToNative, 
  updatePaymentInNative, 
  updateRequestInNative 
} from './../sagas/historyWatcher';
import {
  PaymentRequestStatus, 
  PaymentTrigger, 
  PaymentRequestStatusReason
} from './../static/CommonDefinitions'
import { 
  addParamToURL,
  checkIsBoppButtonPayment, 
  findFirstValidKeyInObject, 
  findMetainfoObject, 
  sendStatusToBoppButton, 
  stringToId 
} from '../helpers';
import { ResponseType } from '../entities/responseObject';
import { sendError } from './../resolver/errorResolver';
import { 
  addLastPayment, 
  setBoppButton, 
  setPaymentRequest 
} from '../reducers/paymentPersistReducer';
import { getCompletedInstructionsCountForPayments } from '../resolver/historyProcessor';
import { 
  ErrorFlow,
  ErrorObject 
} from '../entities/errorObject';
import { store } from '../store';
import { push } from 'connected-react-router';

const REACT_APP_BANK_LIST = process.env.REACT_APP_BANK_LIST ? process.env.REACT_APP_BANK_LIST : 'https://storage.googleapis.com/bopp-dev-public/banks/status.json';

function checkIsPaymentWithIdExistsInHistory(identifier, lastReceipts, response) {
  try {
    let index = lastReceipts.findIndex(payment => payment.id === identifier);
    if (index === -1) {
      return false
    } else {
      return true
    }
  }
  catch(error) {
    store.dispatch(sendError({error:new ErrorObject('Error checking is payment exists in history, id ' + identifier, error, ErrorFlow.payments)}));
  }
}

function getPaymentReportFromResponse(response) {
  try {
    if (response.reportState) {
      if (response.reportState.length > 0) {
        return response.reportState[0];
      }
    }
  
    return null
  }
  catch(error) {
    store.dispatch(sendError({error:new ErrorObject('Error getting payment report from response', error, ErrorFlow.payments)}));
  }
}

function getReferenceFromReport(report, response) {
  try {
    if (!report.metainfo) {
      return;
    }
    
    const metainfo = JSON.parse(report.metainfo);
    return metainfo.reference.value || 'BOPP payment';
  }
  catch(error) {
    store.dispatch(sendError({error:new ErrorObject('Error getting reference from report', error, ErrorFlow.other)}));
  }
}

// Handle response for history request update
function* historyUpdate({ responseObject }) {
  const response = responseObject.getResponseMessage();
  
  try {

    const lastPaymentHistoryRequestId = yield select(state => (state.history.lastPaymentHistoryRequestId));

    if(response.requestId === lastPaymentHistoryRequestId) {
      if(response?.properties?.paymentTerms?.paymentMethods[0]?.properties?.apikey){
        return // Exclude bopp button payments
      }

      if(response?.lastReport) { // if last message set status that loading finished on most recent page
        yield put(setUpdateRequestSent(false))
      }

      const lastReceipts = yield select(state => (state.history.lastReceipts));
      const report = getPaymentReportFromResponse(response);

      if(report) {
        if(!checkIsPaymentWithIdExistsInHistory(report.id, lastReceipts, response)) {
          let payment = {
            id:report.id,
            amount: report.amount.value,
            reference: getReferenceFromReport(report, response),
            reportState: report.reportState,
            activityTime: report.activityTime
          }
          yield put(addLasteReceipt(payment))
        } else {
          const lastReceipt = lastReceipts.find(element => element.id === response.reportState[0].id);
          let payment = {
            id:lastReceipt.id,
            amount: lastReceipt.amount,
            reference: lastReceipt.reference,
            reportState: report.reportState,
            activityTime: report.activityTime
          }

          yield put(updateLasteReceipt(payment))
        }
      }

      return
    }
    
    if(response?.lastReport && !response?.properties) { // if last message and no data just skip
      return
    }
    
    if(response?.properties['@type'] === 'https://miapago.io/paymentinstruction#Properties') {
      const { payments } = yield select(state => ({payments: state.history.payments}));
      const payment = payments.find(element => element.id === response.state.id);

      if(response?.reportState) {
        for(let i = 0; i < response.reportState.length; i++) {
          const metainfo = findMetainfoObject(response)
          let pay = {
            id:payment.id,
            reportId:response.reportState[i].id,
            reportState:response.reportState[i].reportState,
            payeeServiceId:{
              id: payment.id,
              accountName: payment.payeeServiceId.accountName,
              reference: getReferenceFromReport(response.reportState[i], response),
            },
            ...metainfo,
          }

          yield put(updateHistoryPayment(pay))
          yield put(updatePaymentInNative(pay));
        }
      }
    } else { // Update for request
      const { requests } = yield select(state => ({requests: state.history.requests}));
      const request = requests.find(element => element.ids.paymentRequestId === response.state.id);

      let paymentsIds = []
      if(response?.reportState) {
        for(let i = 0; i < response.reportState.length; i++) {
          const metainfo = findMetainfoObject(response.reportState[i])
          let pay = {
            id:response.reportState[i].id,
            reportId:response.reportState[i].id,
            reportState:response.reportState[i].reportState,
            timestamp:response.reportState[i].lastUpdateTime,
            executorId:response.reportState[i].executorId,
            requestId:response.activatingEntityId,
            executingEntityId:response.reportState[i].executingEntityId,
            amount:response.reportState[i].amount,
            dirrection:PaymentDirection.InCome,
            paymentMethod:"",
            payeeServiceId:{
              id:request.bankServiceId,
              accountName: request.account.accountName,
              reference: getReferenceFromReport(response.reportState[i], response),
            },
            ...metainfo,
          }

          yield put(addHistoryPayment(pay))
          yield put(savePaymentToNative(pay));
          paymentsIds.push(response.reportState[i].id)
        }
      }

      let req = {
        ids:{
          paymentRequestId: response.state.id,
          originatorId: request.ids.originatorId,
          activatingEntityId: request.ids.activatingEntityId,
          requestId:request.ids.requestId
        },
        totalPaid: response.state.received,
        payments: paymentsIds,
      }

      const { payments } = yield select(state => ({payments: state.history.payments}));
      const requestPayments = payments.filter(payment => payment.requestId === req.ids.paymentRequestId);
      let completedPaymentsCount = getCompletedInstructionsCountForPayments(requestPayments)

        //Set status as closed automatically if payment trigger "OnInstruction" and there is payment for this request
      if(response.properties.paymentTerms.paymentTrigger === PaymentTrigger.OnInstruction && completedPaymentsCount > 0) {
        req.status = PaymentRequestStatus.Closed
        req.statusReason = PaymentRequestStatusReason.ClosedAutomatically
      }

      const xInstructionValue = +request.paymentSettings.xInstructionsValue;

      if (response.properties.paymentTerms.paymentTrigger === PaymentTrigger.EachInstruction && xInstructionValue) {
          if (completedPaymentsCount >= xInstructionValue) {
              req.status = PaymentRequestStatus.Closed;
              req.statusReason = PaymentRequestStatusReason.ClosedAutomatically;
          }
      }

      if(response?.state?.activationState?.includes('Cancelled')) {
        req.status = PaymentRequestStatus.Closed
        req.statusReason = PaymentRequestStatusReason.Cancelled
      }

      yield put(updateHistoryRequest(req))
      yield put(updateRequestInNative(req));
    }
  }
  catch(error) {
    yield put(sendError({error:new ErrorObject('Error updating history', error, ErrorFlow.historyQuery, response)}));
  }
}

function* handleBank({ payload }) {
  try {
    if(payload.state.startsWith('aisp-')) {
      yield put(changeAccountDetails(payload));
    } else {
      yield put(changeDomesticPayment(payload));
    }
  }
  catch(error) {
    yield put(sendError({error:new ErrorObject('Error handling bank and parsing payload', error, ErrorFlow.payments)}));
  }
}

function* getPlatform() {
  yield put(sendMessage({ type: 'platform', get: 'platform' }));
}

function* getHome() {
  yield put(sendMessage({ type: 'storage', get: 'home' }));
}

function* getTransactionCounter() {
  yield put(sendMessage({ type: 'storage', get: 'transactionsCounter' }));
}

function* passBankUrlToNative({ payload }) {
  yield put(sendMessage({type: 'bankUrls', payload: [payload]}));
}

function* getPayeeSubscriberIdByPayeeUniqueId() {
  const bank = yield select(state => state.payment.bank);

  try {
    if(bank.id) {
      const { data } = yield axios.get(config.dashboardServerUrl + '/persona/parties/' + bank.id +'/sync');
      yield fillSubscriberIdsFromResponse(data)
    }
  }
  catch (error) {
    yield put(sendError({error:new ErrorObject('Error getting payee subscriber Id by payee unique id ' + bank.id, error, ErrorFlow.payments)}));
    yield put(setRefreshSubscriptionRequestStatus('complete'))
  }
}

function* getApiKeyByPayeeUniqueId() {
  const bank = yield select(state => state.payment.bank);
  const paymentPayeeSubscriberIds = yield select(state => state.payment.paymentPayeeSubscriberIds);
  
  try {
    if(bank.id) {
      if(bank?.subscriptionId){
        const { data } = yield axios.post(config.dashboardServerUrl + '/capability',
          {
            subscriptionId: bank.subscriptionId,
            accountDID: bank.accountDID,
          }
        );

        console.log(data)
        if (data.success) {
          yield put(setPayeeSubscriberIds({apiKey:data.data.apiKey}))
        }
      } else {
        yield put(checkUserSubscription(bank.id));
      }
    }
  }
  catch (error) {
    yield put(sendError({error:new ErrorObject('Error getting payee subscriber Id by payee unique id ' + bank.id, error, ErrorFlow.payments)}));
    yield put(setRefreshSubscriptionRequestStatus('complete'))
  }
}

function* runMigration() {
  yield put(getPayeeSubscriberIdByPayeeIdAction())
  yield put(getApiKeyByPayeeIdAction())
  yield put(saveInApp({key: 'checkMigration', value: {version: packageJson.version}}));
}

function* getBankListSaga() {
  try {
       const { data } = yield axios.get(REACT_APP_BANK_LIST);
       if(config.appEnvironment === "DEV" || config.appEnvironment === "SANDBOX") {
         data.push({accountName: "Demo", api: "demo-aspsp", status: "healthy", update_time: "2020-10-30T15:24:26.786037524Z", friendly_name: "Demo", logo_uri: "", short_description: "",    aisp_disabled: false, pisp_disabled: false})
       }
       data.push(
         {accountName: "Co-operative Bank", api: "did:mia:ob:ss:general#coop", status: "healthy", update_time: "2021-04-12T15:24:26.786037524Z", friendly_name: "Co-operative Bank", logo_uri: "https://boppapp-dev.netlify.app/banklogos/coop.png", short_description: "", aisp_disabled: false, pisp_disabled: true},
         {accountName: "Metro Bank", api: "did:mia:ob:ss:general#metro", status: "healthy", update_time: "2021-04-12T15:24:26.786037524Z", friendly_name: "Metro Bank", logo_uri: "https://boppapp-dev.netlify.app/banklogos/metro.png", short_description: "", aisp_disabled: false, pisp_disabled: true}
       )
       yield put(saveBankList(data));
     }
     catch (error) {
      yield put(sendError({error:new ErrorObject('Error getting bank list', error, ErrorFlow.accounts)}));
     }
}

function* getAccountlimitSaga() {
  const { id } = yield select(state => ({id: state.payment.bank.id}));
  try {
    const { data } = yield axios.get(config.positionKeeperUrl + '/limits/' + id + '?x-api-key=' + config.positionKeeperApiKey);
    yield put(setAccountLimit(data.data));
  }
  catch (error) {
    yield put(sendError({error:new ErrorObject('Error getting account limit for bank id ' + id, error, ErrorFlow.payments)}));
  }
}

function* replyRejected({ responseObject }) {
  const resp = responseObject.getResponseMessage();

  try {
    if(resp['@type'] === 'https://dvschema.io/activation#CreateRejected' && resp.state["@type"] === 'https://miapago.io/paymentinstruction#State') {
      const { payments } = yield select(state => ({payments: state.history.payments}));
      const payment = payments.find(element => element.id === resp.activatingEntityId);
      const metainfo = findMetainfoObject(resp)

      if(payment) {
          let pay = {...metainfo, ...payment, reportState:PaymentReportState.CancelledOnBank, reportId: payment.id}
          yield put(updateHistoryPayment(pay))
          yield put(updatePaymentInNative(pay));
      }

      let statusScreenDetails = {
        amount: resp.properties.amount.value,
        accountName: resp.properties.paymentMethod.properties.linkedMethod.properties.name,
        orderNumber: resp.properties.paymentMethod.properties.linkedMethod.properties.paymentReference,
        giftAid: metainfo.giftAid,
        thankYouNote: metainfo.thankYouNote,
        internalReference: metainfo?.internalReference,
      }

      statusScreenDetails.instructionId = resp.state.id

      if (metainfo.failRedirectUrl) {
        let res = addParamToURL(metainfo.failRedirectUrl, 'instructionId', resp.state.id)
        res = addParamToURL(res, 'paymentReference', statusScreenDetails.orderNumber)
        res = addParamToURL(res, 'paymentAmount', statusScreenDetails.amount)
        statusScreenDetails.redirectUrl = res
      }

      yield put(addLastPayment(statusScreenDetails))
    }

    yield put(changePaymentStatusScreen({ paymentInProcess: false , waitingForCancel:false, cancelled: false, transferringToBank:false, waitingForConsent:false}));
    yield put(handlePaymentStatus({ status: 'failed' }));
    yield put(setPaymentRequest(null))
  }
  catch(error) {
    yield put(sendError({error:new ErrorObject('Error haldling to rejected payment response', error, ErrorFlow.payments, resp)}));
  }
}

function* replyCancelled({ responseObject }) {
  const response = responseObject.getResponseMessage();

  try {
    const { isCancelledFromHistory } = yield select(state => ({ isCancelledFromHistory: state.history.isCancelledFromHistory }));
    if(isCancelledFromHistory) {
      yield put(setHistoryRequestCancelledFromHistory(false))
      return;
    }

    const metainfo = findMetainfoObject(response)
    
    if(response){
      if(response["@type"] === 'https://dvschema.io/activation#ActivityStatusReport' || response['@type'] === 'https://dvschema.io/activation#StatusReport') {
        if(response.state["@type"] === 'https://miapago.io/paymentinstruction#State') {
          let statusScreenDetails = {
            amount: response.properties.amount.value,
            accountName:response.properties.paymentMethod.properties.name,
            orderNumber:response.properties.paymentMethod.properties.paymentReference,
            giftAid: metainfo.giftAid,
            thankYouNote: metainfo.thankYouNote,
            internalReference: metainfo?.internalReference,
          }

          statusScreenDetails.instructionId = response.state.id
          if (metainfo.cancelRedirectUrl) {
            let res = addParamToURL(metainfo.cancelRedirectUrl, 'instructionId', response.state.id)
            res = addParamToURL(res, 'paymentReference', statusScreenDetails.orderNumber)
            res = addParamToURL(res, 'paymentAmount', statusScreenDetails.amount)
            statusScreenDetails.redirectUrl = res
          }

          yield put(addLastPayment(statusScreenDetails))
        } else {
          let statusScreenDetails = {
            amount: response.properties.amount.value,
            accountName: response.properties.paymentTerms.paymentMethods[0].properties.name,
            orderNumber: response.properties.paymentTerms.paymentMethods[0].properties.paymentReference,
            giftAid: metainfo.giftAid,
            thankYouNote: metainfo.thankYouNote,
            internalReference: metainfo?.internalReference,
          }

          statusScreenDetails.instructionId = response.state.id
          if (metainfo.cancelRedirectUrl) {
            let res = addParamToURL(metainfo.cancelRedirectUrl, 'instructionId', response.state.id)
            res = addParamToURL(res, 'paymentReference', statusScreenDetails.orderNumber)
            res = addParamToURL(res, 'paymentAmount', statusScreenDetails.amount)
            statusScreenDetails.redirectUrl = res
          }

          yield put(addLastPayment(statusScreenDetails))

          yield put(clearPaylinkUrl())
          yield put(showInvalidPaymentError(true))
          yield put(setPaymentRequest(null))

          return
        }
      }
    } else if(response.properties?.paymentMethod?.properties) {
      let statusScreenDetails = {
        amount: response.properties.amount.value,
        accountName: response.properties.paymentMethod?.properties?.linkedMethod?.properties?.name,
        orderNumber: response.properties.paymentMethod?.properties?.linkedMethod?.properties?.paymentReference,
        giftAid: metainfo.giftAid,
        thankYouNote: metainfo.thankYouNote,
        internalReference: metainfo?.internalReference,
      }

      statusScreenDetails.instructionId = response.state.id

      if (metainfo.cancelRedirectUrl) {
        let res = addParamToURL(metainfo.cancelRedirectUrl, 'instructionId', response.state.id)
        res = addParamToURL(res, 'paymentReference', statusScreenDetails.orderNumber)
        res = addParamToURL(res, 'paymentAmount', statusScreenDetails.amount)
        statusScreenDetails.redirectUrl = res
      }
      yield put(addLastPayment(statusScreenDetails))
    } else {
      let statusScreenDetails = {
        amount: response.properties.amount.value,
        accountName: response.properties.paymentTerms.paymentMethods[0].properties.name,
        orderNumber: response.properties.paymentTerms.paymentMethods[0].properties.paymentReference,
        giftAid: metainfo.giftAid,
        thankYouNote: metainfo.thankYouNote,
        internalReference: metainfo?.internalReference,
      }

      statusScreenDetails.instructionId = response.state.id
      if (metainfo.cancelRedirectUrl) {
        let res = addParamToURL(metainfo.cancelRedirectUrl, 'instructionId', response.state.id)
        res = addParamToURL(res, 'paymentReference', statusScreenDetails.orderNumber)
        res = addParamToURL(res, 'paymentAmount', statusScreenDetails.amount)
        statusScreenDetails.redirectUrl = res
      }

      yield put(addLastPayment(statusScreenDetails))
    }

    if(response) {
      const { payments } = yield select(state => ({payments: state.history.payments}));
      const payment = payments.find(element => element.id === response.state.id);

      if(payment) {
        let pay = {...metainfo, ...payment, reportState:PaymentReportState.CancelledOnBank, reportId: payment.id}
        yield put(updateHistoryPayment(pay))
        yield put(updatePaymentInNative(pay));
      }
    }

    yield put(changePaymentStatusScreen({ paymentInProcess: false , waitingForCancel:false, cancelled: true, transferringToBank:false, waitingForConsent:false}));
    yield put(handlePaymentStatus({ status: 'cancelled' }));
    yield put(setPaymentRequest(null))
  }
  catch(error) {
    yield put(sendError({error:new ErrorObject('Error handling to canceled payment response', error, ErrorFlow.payments, response)}));
  }
}

function* replyPendingChange({ responseObject }) {
  const resp = responseObject.getResponseMessage();

  try {
    if (resp['@type'] != 'https://dvschema.io/activation#ActivityStatusReport' && resp.properties["@type"] === 'https://miapago.io/paymentinstruction#Properties') {

      const { payments } = yield select(state => ({payments: state.history.payments}));
      const payment = payments.find(element => element.id === resp.state.id);

      if(!payment)
      {
        const metainfo = findMetainfoObject(resp)

        const payment = {
          id:resp.state.id,
          reportId:resp.reportState.id,
          reportState:"Pending",
          timestamp:resp.timestamp,
          executorId:stringToId(resp.properties.paymentMethod.properties.linkedMethod.properties.serviceId),
          requestId:resp.originatingEntityId,
          amount:resp.properties.amount,
          dirrection:PaymentDirection.OutCome,
          paymentMethod:"",
          payeeServiceId:{
            id:"",
            accountName: resp.properties.paymentMethod.properties.linkedMethod.properties.name,
            reference:resp.properties.paymentMethod.properties.linkedMethod.properties.paymentReference
          },
          ...metainfo,
        }

        yield put(setHistoryPendingPayment(payment))
        yield put(addHistoryPendingPaymentToHistory())
        yield put(savePaymentToNative(payment));
        yield put(setHistoryPendingPayment(undefined))
      }
    }

    const metainfo = findMetainfoObject(resp)
  
    let statusScreenDetails = {
      amount: "0",
      accountName:  "",
      orderNumber: "",
      redirectUrl: "",
      internalReference: metainfo?.internalReference,
    }

    yield put(setPaylinkSourceAction(resp))

    statusScreenDetails.giftAid = metainfo.giftAid;
    statusScreenDetails.thankYouNote = metainfo.thankYouNote;
    statusScreenDetails.transactionId = resp.reportState?.executingEntityId;

    if(resp.properties["@type"] === 'https://miapago.io/paymentinstruction#Properties') {
      statusScreenDetails.direction = PaymentDirection.OutCome;
      statusScreenDetails.amount = resp.properties.amount.value
      statusScreenDetails.accountName = resp.properties.paymentMethod.properties.linkedMethod.properties.name
      statusScreenDetails.orderNumber = resp.properties.paymentMethod.properties.linkedMethod.properties.paymentReference
    } else if(resp.properties["@type"] === 'https://miapago.io/paylink/request#Properties') {
      statusScreenDetails.direction = PaymentDirection.InCome;
      statusScreenDetails.amount = resp.state.instructions[resp.activatingEntityId]?.amount?.value
      statusScreenDetails.accountName = resp.properties.paymentTerms.paymentMethods[0].properties.name
      statusScreenDetails.orderNumber = metainfo.reference.value
    }

    statusScreenDetails.instructionId = resp.state.id

    if (metainfo.successRedirectUrl) {
      let res = addParamToURL(metainfo.successRedirectUrl, 'instructionId', resp.state.id)
      res = addParamToURL(res, 'paymentReference', statusScreenDetails.orderNumber)
      res = addParamToURL(res, 'paymentAmount', statusScreenDetails.amount)
      statusScreenDetails.redirectUrl = res
    }

    const payment = {
      id: resp.state.id,
      executingEntityId: resp.reportState?.executingEntityId,
    }

    yield put(addLastPayment(statusScreenDetails));
    yield put(updateHistoryPayment(payment));
    yield put(changePaymentStatusScreen({ paymentInProcess: false }));
    yield put(handlePaymentStatus({ status: 'success' }));
    const { paymentRequest } = yield select(state => ({paymentRequest: state.paymentPersist.paymentRequest}));
    if(paymentRequest) {
      yield put(addUsedBank(paymentRequest.payerBank));
    }
  }
  catch(error) {
    yield put(sendError({error:new ErrorObject('Error handling to pending change payment response', error, ErrorFlow.payments, resp)}));
  }
}

export function getPaymentMetainfo(metainfoJson) {
  try {
    const metainfo = JSON.parse(metainfoJson);
    const enabledAndSkippedByPayer = !!metainfo.giftAid?.skippedByPayer || false;
    
    return {
      notes: metainfo.notes,
      thankYouNote: metainfo.thankYouNote,
      requestEmail: metainfo.requestEmail,
      giftAid: {
        enabled: enabledAndSkippedByPayer || metainfo.giftAid?.payloadBase64,
        skippedByPayer: enabledAndSkippedByPayer,
      },
      reference: metainfo.reference,
      successRedirectUrl: metainfo.successRedirectUrl,
      failRedirectUrl: metainfo.failRedirectUrl,
      cancelRedirectUrl: metainfo.cancelRedirectUrl,
      internalReference: metainfo?.internalReference,
    }
  }
  catch(error) {
    store.dispatch(sendError({error:new ErrorObject('Error getting metainfo', error, ErrorFlow.payments)}));
  }
}

function* payerActivityReportCancelled({ payload }) {
  try {
    const { payments } = yield select(state => ({payments: state.history.payments}));
    const payment = payments.find(element => element.id === payload.state.id);
    const metainfo = findMetainfoObject(payload)

    if(payment) {
        let pay = {...metainfo, ...payment, reportState:PaymentReportState.CancelledOnBank, reportId: payment.id}
        yield put(updateHistoryPayment(pay))
        yield put(updatePaymentInNative(pay));
    }

    let statusScreenDetails = {
      amount: payload.properties.amount.value,
      accountName: payload.properties.paymentMethod.properties.linkedMethod.properties.name,
      orderNumber: payload.properties.paymentMethod.properties.linkedMethod.properties.paymentReference,
      giftAid: metainfo.giftAid,
      thankYouNote: metainfo.thankYouNote,
      internalReference: metainfo?.internalReference,
    }

    statusScreenDetails.instructionId = payload.state.id

    if (metainfo.cancelRedirectUrl) {
      let res = addParamToURL(metainfo.cancelRedirectUrl, 'instructionId', payload.state.id)
      res = addParamToURL(res, 'paymentReference', statusScreenDetails.orderNumber)
      res = addParamToURL(res, 'paymentAmount', statusScreenDetails.amount)
      statusScreenDetails.redirectUrl = res
    }

    yield put(addLastPayment(statusScreenDetails))

    yield put(changePaymentStatusScreen({ paymentInProcess: false , waitingForCancel:false, cancelled: true, transferringToBank:false, waitingForConsent:false}));
    yield put(handlePaymentStatus({ status: 'cancelled' }));
    yield put(setPaymentRequest(null))
  }
  catch(error) {
    yield put(sendError({error:new ErrorObject('Error handling canceled payer activity report', error, ErrorFlow.payments, payload)}));
  }
}

function* payeeActivityReportCancelled({ payload }) {
  try {
    let resp = payload
    if(resp?.state?.instructions) {
      let instruction = resp.state.instructions[resp.activatingEntityId]

      if(instruction) {
        if(instruction.entityState === "Cancelled") {
          const metainfo = findMetainfoObject(resp)

          let statusScreenDetails = {
            amount: resp.properties.amount.value,
            accountName: resp.properties.paymentTerms.paymentMethods[0].properties.name,
            orderNumber: resp.properties.paymentTerms.paymentMethods[0].properties.paymentReference,
            giftAid: metainfo.giftAid,
            thankYouNote: metainfo.thankYouNote,
            internalReference: metainfo?.internalReference,
          }

          statusScreenDetails.instructionId = resp.state.id

          if (metainfo.failRedirectUrl) {
            let res = addParamToURL(metainfo.failRedirectUrl, 'instructionId', resp.state.id)
            res = addParamToURL(res, 'paymentReference', statusScreenDetails.orderNumber)
            res = addParamToURL(res, 'paymentAmount', statusScreenDetails.amount)
            statusScreenDetails.redirectUrl = res
          }

          yield put(addLastPayment(statusScreenDetails))
          yield put(changePaymentStatusScreen({ paymentInProcess: false }));
          yield put(handlePaymentStatus({ status: 'failed' }));
          yield put(setPaymentRequest(null))
        }
      }
    }

    return null;
  }
  catch(error) {
    yield put(sendError({error:new ErrorObject('Error handling canceled payee activity report', error, ErrorFlow.payments, payload)}));
  }
}

function* replyPendingAccept({responseObject}) {
  const response = responseObject.getResponseMessage();

  try {
    const { requestMethod } = yield select(state => ({requestMethod: state.payment.requestMethod}));

    if (response['@type'] === 'https://dvschema.io/activation#Processing') {
      return null
    }

    yield put(changePendingAccept(true));

    if (response['@type'] != 'https://dvschema.io/activation#ActivityStatusReport') {

      //Add created payment to panding as we can add it only when user go to bank
      const payment = {
        id:response.state.id,
        reportId:"",
        reportState:"Pending",
        timestamp:response.timestamp,
        executorId:stringToId(response.properties.paymentMethod.properties.linkedMethod.properties.serviceId),
        requestId:response.originatingEntityId,
        amount:response.properties.amount,
        dirrection:PaymentDirection.OutCome,
        paymentMethod:requestMethod.option,
        payeeServiceId:{
          id:"",
          accountName: response.properties.paymentMethod.properties.linkedMethod.properties.name,
          reference:response.properties.paymentMethod.properties.linkedMethod.properties.paymentReference
        }
      }

      yield put(setHistoryPendingPayment(payment))
    }

    const paymentRequest = yield select(state => state.paymentPersist.paymentRequest);
    yield put(setPaymentRequest({...paymentRequest, authUrl:response.state.paymentState.authorizationURL, isAuthUrlRequestSent:false}))
    yield put(selectBankAccepted(response.state));
  }
  catch(error) {
    yield put(sendError({error:new ErrorObject('Error handling pending accept payment', error, ErrorFlow.payments, response)}));
  }
}

function* replyAccepted( {responseObject} ) {
  const response = responseObject.getResponseMessage();

  try {
    //skip status update
    if (responseObject.getEntityContext() !== entityContexts.PAYLINK_PAYMENT_REQUEST || response['@type'] === 'https://dvschema.io/activation#Processing') {
      if(response?.state?.instructions) {
        let instruction = response.state.instructions[response.activatingEntityId]

        if(instruction) {
          if(instruction.entityState === "Cancelled") {
            const metainfo = findMetainfoObject(response)

            let statusScreenDetails = {
              amount: response.properties.amount.value,
              accountName: response.properties.paymentTerms.paymentMethods[0].properties.name,
              orderNumber: response.properties.paymentTerms.paymentMethods[0].properties.paymentReference,
              giftAid: metainfo.giftAid,
              thankYouNote: metainfo.thankYouNote,
              internalReference: metainfo?.internalReference,
            }

            statusScreenDetails.instructionId = response.state.id

            if (metainfo.failRedirectUrl) {
              let res = addParamToURL(metainfo.failRedirectUrl, 'instructionId', response.state.id)
              res = addParamToURL(res, 'paymentReference', statusScreenDetails.orderNumber)
              res = addParamToURL(res, 'paymentAmount', statusScreenDetails.amount)
              statusScreenDetails.redirectUrl = res
            }

            yield put(addLastPayment(statusScreenDetails))
            yield put(changePaymentStatusScreen({ paymentInProcess: false }));
            yield put(handlePaymentStatus({ status: 'failed' }));
            yield put(setPaymentRequest(null))
          }
        }
      }

      return null;
    }

    const state = response.state;
    const properties = response.properties;
    const amountTerm = yield getPaymentAmountTerm(properties.paymentTerms.amountTerm, response)

    if (state.otpStatus) {
        const bank = yield select(state => state.payment.bank);
        const paymentSettings = yield select(state => state.paymentRequestSettings.oneTimePaymentSettings);

        //Save request to history
        let hrequest = {
          ids:{
            originatorId: response.originatorId,
            activatingEntityId: response.activatingEntityId,
            paymentRequestId: state.id,
            requestId:response.requestId
          },
          bankServiceId:bank.serviceId,
          account:{
            accountName: bank.accountName,
            bankName: bank.bankName,
            bankLogo: bank.logo,
          },
          paymentSettings: {
            amountTerm,
            paymentTrigger:response.properties.paymentTerms.paymentTrigger,
            paymentExpiryDate: paymentSettings.expire.onCertainDate ? paymentSettings.expire.certainDate : null,
            xInstructionsValue: paymentSettings.expire.xInstructionsValue,
            reference:response.properties.paymentTerms.paymentMethods[0].properties.paymentReference,
            referenceType: paymentSettings.reference.type,
            referenceAutonumber: paymentSettings.reference.autonumberValue || "INV",
            requestEmail: paymentSettings.requestEmail.enabled,
            notes: paymentSettings.notes,
            giftAid: paymentSettings.giftAid.enabled,
          },
          status: PaymentRequestStatus.Open,
          paylink: state.paylink,
          timestamp: response.timestamp,
          requestedAmount: response.state.requested,
          totalPaid: response.state.received,
          payments: []
        }

        yield put(addHistoryRequest(hrequest))
        yield put(saveRequestToNative(hrequest));

        yield put(handlePaymentStatus({status: ''}));

        yield put(paylinkRequestAccepted({
          paylink: state.paylink,
          otp: {
            status: state.otpStatus
          }
        }));
        return yield put(startOtpCalculator({
          type: properties.otpOptions.type,
          digits: properties.otpOptions.digits,
          seed: state.paylink,
          issuer: state.otpStatus.issuer,
          reference: state.otpStatus.reference,
          epoch: state.otpStatus.epoch ? new Date(state.otpStatus.epoch) : new Date()
        }))
      }
  }
  catch(error) {
    yield put(sendError({error:new ErrorObject('Error replying accepted payment', error, ErrorFlow.payments, response)}));
  }
}

function getCompletedInstructionsCountForpaylink(instructions, response) {
  try {
    if(instructions) {
      let completedInstructionsCount = 0
      let inst = Object.entries(instructions)
      inst.forEach(instruction => {
        if(isInstructionCopleted(instruction, response)) {
          completedInstructionsCount ++
        }
      });
      
      return completedInstructionsCount
    } else {
      return 0
    }
  }
  catch(error) {
    store.dispatch(sendError({error:new ErrorObject('Error getting completed instructions count for paylink', error, ErrorFlow.payments, response)}));
  }
}

function isInstructionCopleted(instruction, response) {
  try {
    let obj = findFirstValidKeyInObject(instruction, 'entityState')
    if(obj != null) {
      if(obj === 'Complete') {
        return true
      }
    } 
    return false
  }
  catch(error) {
    store.dispatch(sendError({error:new ErrorObject('Error checking is instruction completed', error, ErrorFlow.payments, response)}));
  }
}

function getMaxInstructionForPaylink(response) {
  const paymentTerms = response?.properties?.paymentTerms;
  
  try {
    if(paymentTerms?.paymentTrigger === PaymentTrigger.OnInstruction) {
      return 1
    } else if(paymentTerms?.amountTerm?.canBeExecutedXTimes) {
      return paymentTerms.amountTerm.canBeExecutedXTimes
    } else {
      return -1
    }
  }
  catch(error) {
    store.dispatch(sendError({error:new ErrorObject('Error getting max instruction for paylink', error, ErrorFlow.payments, response)}));
  }
}

function isPaylinkValid(response) {
  try {
    if(getIsPaylinkExpired(response)) {
      return false
    }

    let maxInstructions = getMaxInstructionForPaylink(response)
    if(maxInstructions === -1) {
      return true
    } else {
      let instructions = getCompletedInstructionsCountForpaylink(response?.state?.instructions, response)
      if(instructions >= maxInstructions) {
        return false
      } else {
        return true
      }
    }
  }
  catch(error) {
    store.dispatch(sendError({error:new ErrorObject('Error checking is paylink valid', error, ErrorFlow.payments, response)}));
  }
}

function getIsPaylinkExpired(response) {
  try {
    let date = new Date(response.properties.activationLifetime.expiryDate)
    let now = new Date()
    date.setHours(0,0,0,0);
    now.setHours(0,0,0,0);
    if(now > date) {
      return true
    } else {
      return false
    }
  }
  catch(error) {
    store.dispatch(sendError({error:new ErrorObject('Error checking is paylink expired', error, ErrorFlow.payments, response)}));
  }
}

function* getPaymentAmountValuesFromResponse(response) {
  try {
    const properties = response.properties;
    const amountTerm = yield getPaymentAmountTerm(properties.paymentTerms.amountTerm, response);
    
    const paymentCustomUrlSettings = yield select(state => state.payment.paymentCustomUrlSettings);
    
    if(paymentCustomUrlSettings.amount !== null) {
      return yield getPaymentAmountValuesFromResponseForCustomUrlSetting(response)
    } else {
      let amount = properties?.amount?.value || ''
      let suggestedAmount = ''
      let minAmount = ''

      if(amountTerm === TermType.SuggestedAmount) {
        suggestedAmount = properties.paymentTerms.amountTerm.properties.range[0].value
      } else if(amountTerm === TermType.MinAmount) {
        minAmount = properties.paymentTerms.amountTerm.properties.range[0].value
      }

      return {amount, suggestedAmount, minAmount}
    }
  }
  catch(error) {
    yield put(sendError({error:new ErrorObject('Error getting payment amount value from properties', error, ErrorFlow.payments, response)}));
  }
}

function* getPaymentAmountValuesFromResponseForCustomUrlSetting(response) {
  try {
    const properties = response.properties;
    const amountTerm = yield getPaymentAmountTerm(properties.paymentTerms.amountTerm, response)
    const paymentCustomUrlSettings = yield select(state => state.payment.paymentCustomUrlSettings);
    
    let amount = properties.amount.value
    let suggestedAmount = ''
    let minAmount
    if(amountTerm === TermType.AnyAmount){
      amount = paymentCustomUrlSettings.amount
    } else if(amountTerm === TermType.SuggestedAmount) {
      suggestedAmount = properties.paymentTerms.amountTerm.properties.range[0].value
      amount = paymentCustomUrlSettings.amount
    } else if(amountTerm === TermType.MinAmount) {
      minAmount = properties.paymentTerms.amountTerm.properties.range[0].value
      if(properties.paymentTerms.amountTerm.properties.range[0].value > paymentCustomUrlSettings.amount) {
        amount = properties.paymentTerms.amountTerm.properties.range[0].value
      } else {
        amount = paymentCustomUrlSettings.amount
      }
    }

    return {amount, suggestedAmount, minAmount}
  }
  catch(error) {
    yield put(sendError({error:new ErrorObject('Error getting payment amount value from properties for custom URL settings', error, ErrorFlow.payments, response)}));
  }
}

function* getPaymentReferenceFromProperties(setting, reference, response) {
  try {
    const paymentCustomUrlSettings = yield select(state => state.payment.paymentCustomUrlSettings);
    let pReference = reference;
    
    if(paymentCustomUrlSettings.reference !== null) {
      if(setting.reference.type === ReferenceSettingType.SetByMe) {
        return paymentCustomUrlSettings.reference
      }
    }
    
    return pReference
  }
  catch(error) {
    yield put(sendError({error:new ErrorObject('Error getting payment reference from properties', error, ErrorFlow.payments, response)}));
  }
}

function* getTaglineText(response) {
  try {
    return response?.state?.attachments[0]?.content?.json?.taglineText
  }
  catch(error) {
    return ''
    yield put(sendError({error:new ErrorObject('Error getting payment reference from properties', error, ErrorFlow.payments, response)}));
  }
}

function* getPaymentAmountTerm(amountTerm, response) {
  try {
    if(amountTerm.termType === TermType.FixedAmount) {
      return TermType.FixedAmount
    } else if(amountTerm.termType === TermType.AnyAmount) {
      return TermType.AnyAmount
    } else {
      if(amountTerm.properties.min.value === amountTerm.properties.range[0].value) {
        return TermType.MinAmount
      } else {
        return TermType.SuggestedAmount
      }
    }
  }
  catch(error) {
    yield put(sendError({error:new ErrorObject('Error getting payment amount term', error, ErrorFlow.payments, response)}));
  }
}

function* getUsedBankList() {
  yield put(sendMessage({ type: 'storage', get: 'lastUsedBank' }));
}

function* addBankToUsedList({ payload }) {
  if (!payload) {
    return;
  }

  const { usedBankList } = yield select(state => ({ usedBankList: state.payment.usedBankList }));
  try {
    const bankList = usedBankList.filter(el => el.accountName !== payload.accountName)
    yield put(sendMessage({ type: 'storage', set: 'lastUsedBank', state: JSON.stringify([...bankList, payload]) }));
    yield put(setUsedBank([...bankList, payload]))
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error adding bank to used list', error, ErrorFlow.payments)}));
  }
}

function* removeUsedBankFromList() {
  const { usedBankList } = yield select(state => ({ usedBankList: state.payment.usedBankList }));
  try {
    yield put(sendMessage({ type: 'storage', set: 'lastUsedBank', state: JSON.stringify([...usedBankList]) }));
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error removing used bank from list', error, ErrorFlow.payments)}));
  }
}

function* readError(message) {
  try {
    try {
      yield put(setError(message.state.statusReason));
    } catch (error) {
      yield put(setError({code:'', message:''}));
    }
  }
  catch(error) {
    yield put(sendError({error:new ErrorObject('Error setting error in readError function', error, ErrorFlow.payments)}));
  }
}

function *fillSubscriberIdsFromResponse(data) {
  try {
    yield put(setRefreshSubscriptionRequestStatus('complete'))

    let giftAidPkey = ''
    let payee = ''
    let subscriber = ''

    if(data.data.giftAidPublicKey) {
      giftAidPkey = data.data.giftAidPublicKey
      yield put(updateBank({ subscriptionType: AccountType.Charity }));
    }

    if(data?.data?.partiesRoles) {
      payee = data.data.partiesRoles["payment:Payee"]
      subscriber = data.data.partiesRoles["payment:Subscriber"]
    } else {
      payee = data.data["payment:Payee"]
      subscriber = data.data["payment:Subscriber"]
    }

    yield put(saveInApp({key: 'subscriberInfo', value:{payee: payee, subscriber: subscriber, giftAidPublicKey:giftAidPkey}}));
    yield put(setPayeeSubscriberIds({payee: payee, subscriber: subscriber, giftAidPublicKey:giftAidPkey}))
  }
  catch(error) {
    yield put(sendError({error:new ErrorObject('Error filling subscriber IDs from response', error, ErrorFlow.payments)}));
  }
}

function* requestCanceled({ payload }) {
  const response = payload?.params[1];

  try {

    // Hack to remove later
    const { isCancelledFromHistory } = yield select(state => ({ isCancelledFromHistory: state.history.isCancelledFromHistory }));
    if(isCancelledFromHistory) {
      yield put(setHistoryRequestCancelledFromHistory(false))
    }

    // Payer - payment request canceled
    if(response['@type'] === 'https://dvschema.io/activation#ActivityStatusReport' || response['@type'] === 'https://dvschema.io/activation#StatusReport') {
      if(response.state.activationState === 'Cancelled:Accepted:NonePending') {
        yield put(clearPaylinkUrl())
        yield put(showInvalidPaymentError(true))
        yield put(setPaymentRequest(null))

        return
      }

      const metainfo = findMetainfoObject(response)

      let statusScreenDetails = {
        amount: response.properties.amount.value,
        accountName: response.properties.paymentTerms.paymentMethods[0].properties.name,
        orderNumber: response.properties.paymentTerms.paymentMethods[0].properties.paymentReference,
        giftAid: metainfo.giftAid,
        thankYouNote: metainfo.thankYouNote,
        internalReference: metainfo?.internalReference,
      }

      statusScreenDetails.instructionId = response.state.id
      if (metainfo.failRedirectUrl) {
        let res = addParamToURL(metainfo.failRedirectUrl, 'instructionId', response.state.id)
        res = addParamToURL(res, 'paymentReference', statusScreenDetails.orderNumber)
        res = addParamToURL(res, 'paymentAmount', statusScreenDetails.amount)
        statusScreenDetails.redirectUrl = res
      }

      yield put(addLastPayment(statusScreenDetails))
      yield put(changePaymentStatusScreen({ paymentInProcess: false }));
      yield put(handlePaymentStatus({ status: 'failed' }));
      yield put(setPaymentRequest(null))
    }
  }
  catch(error) {
    yield put(sendError({error:new ErrorObject('Error replying to canceled request', error, ErrorFlow.payments, response)}));
  }
}

function* updateBankAction({ payload }) {
  try {
    const bank = yield select(state => state.payment.bank);
    const updatedBank = { ...bank, ...payload };
    
    yield put(addBank(updatedBank));
    yield put(saveInApp({key: 'bankAccountDetails', value: updatedBank}));
  }
  catch(error) {
    yield put(sendError({error:new ErrorObject('Error updating bank', error, ErrorFlow.accounts)}));
  }
}

function* setPaylinkSource(payload) {
  try {
    let obj = findFirstValidKeyInObject(payload, 'endToEndIdentification')
    if(obj != null) {
      if(checkIsBoppButtonPayment(obj)) {
        yield put(setBoppButton(true));
      } else {
        yield put(setBoppButton(false));
      }
    } else {
      yield put(setBoppButton(false));
    }
  }
  catch(error) {
    yield put(sendError({error:new ErrorObject('Error setting paylink source', error, ErrorFlow.payments, payload)}));
  }
}

export function* paymentWatcher() {
  yield takeLatest(actionType.HANDLE_BANK_MESSAGE, handleBank);
  yield takeLatest(actionType.PASS_BANKS_URL, passBankUrlToNative);
  yield takeLatest(actionType.GET_PLATFORM, getPlatform);
  yield takeLatest(actionType.GET_HOME, getHome);
  yield takeLatest(actionType.GET_TRANSACTION_COUNTER, getTransactionCounter);
  yield takeLatest(actionType.REPLY_REJECTED, replyRejected);
  yield takeLatest(actionType.REPLY_PENDING_CHANGE, replyPendingChange);
  yield takeLatest(actionType.PAYEE_ACTIVITY_REPORT_CANCELLED, payeeActivityReportCancelled);
  yield takeLatest(actionType.PAYER_ACTIVITY_REPORT_CANCELLED, payerActivityReportCancelled);
  yield takeLatest(actionType.REPLY_PENDING_ACCEPT, replyPendingAccept);
  yield takeLatest(actionType.REPLY_ACCEPTED, replyAccepted);
  yield takeLatest(actionType.GET_BANK_LIST, getBankListSaga);
  yield takeLatest(actionType.GET_USED_BANK_LIST, getUsedBankList);
  yield takeLatest(actionType.ADD_USED_BANK_TO_LIST, addBankToUsedList);
  yield takeLatest(actionType.REMOVE_USED_BANK_FROM_LIST, removeUsedBankFromList)
  yield takeLatest(actionType.REPLY_CANCELLED, replyCancelled)
  yield takeLatest(actionType.READ_ERROR, readError)
  yield takeLatest(actionType.UPDATE_BANK, updateBankAction);
  yield takeLatest(actionType.REPLY_HISTORY_CHANGE, historyUpdate);
  yield takeLatest(actionType.REPLY_REQUEST_CANCELLED, requestCanceled);
  yield takeLatest(actionType.GET_ACCOUNT_LIMIT, getAccountlimitSaga);
  yield takeLatest(actionType.GET_PAYEE_SUSBCRIBER_ID_BY_PAYEE_ID, getPayeeSubscriberIdByPayeeUniqueId);
  yield takeLatest(actionType.GET_APIKEY_BY_PAYEE_ID, getApiKeyByPayeeUniqueId);
  yield takeLatest(actionType.RUN_MIGRATION, runMigration);
  yield takeLatest(actionType.SET_PAYLINK_SOURCE, setPaylinkSource);
}
