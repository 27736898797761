import moment from 'moment';
import { takeLatest, select, put, delay } from 'redux-saga/effects';
import { setExpireDateIsSetToPast,
  setOneTimePaymentSettings,
  setOneTimeSettingChanges,
} from '../reducers/paymentRequestSettingsReducer';
import { getSettingChanges } from "../helpers/getSettingChanges";

export const UPDATE_ONE_TIME_SETTINGS = 'paymentRequestSettings/update/updateOneTimeSettings';
export const START_EXPIRY_DATE_TIMER = 'pymentRequestSettings/start/expiryDateTimer';

export const updateOneTimeSettings = (payload) => ({
  type: UPDATE_ONE_TIME_SETTINGS,
  payload,
});

export const startExpiryDateTimer = (payload) => ({
  type: START_EXPIRY_DATE_TIMER,
  payload,
});

function* dispatchUpdateOneTimeSettings({payload}) {
  yield put(setOneTimePaymentSettings(payload));

  const oneTimePaymentSettings = yield select(state => state.paymentRequestSettings.oneTimePaymentSettings);
  const defaultPaymentSettings = yield select(state => state.paymentRequestSettings.defaultPaymentSettings);

  const changes = getSettingChanges(oneTimePaymentSettings, defaultPaymentSettings);
  yield put(setOneTimeSettingChanges(changes));
}

function getActualExpiryDate(expiryDate) {
  return moment(expiryDate).add(1, 'days').set({hour:0,minute:0,second:0,millisecond:0});
}

function* dispatchStartExpiryDateTimer({payload}) {
  const { expiryDate } = payload;
  const actualExpiryDateSeconds = getActualExpiryDate(expiryDate).format('x');

  yield delay(actualExpiryDateSeconds - moment().format('x'));

  const oneTimePaymentSettings = yield select(state => state.paymentRequestSettings.oneTimePaymentSettings);

  if(!oneTimePaymentSettings.expire.onCertainDate) {
    return;
  }

  if (expiryDate !== oneTimePaymentSettings.expire.certainDate) {
    return;
  }

  const currentDateSeconds = moment().format('x');

  if (actualExpiryDateSeconds <= currentDateSeconds) {
    yield put(setExpireDateIsSetToPast(true));
  }
}

export function* paymentRequestSettingsSaga() {
  yield takeLatest(UPDATE_ONE_TIME_SETTINGS, dispatchUpdateOneTimeSettings);
  yield takeLatest(START_EXPIRY_DATE_TIMER, dispatchStartExpiryDateTimer);
}
