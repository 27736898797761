export const SET_ACCOUNT_LIMIT = 'SET_ACCOUNT_LIMIT';
export const SET_PAYEE_SUBSCRIBER_IDS = 'SET_PAYEE_SUBSCRIBER_IDS';
export const SET_CUSTOM_URL_PAYMENT_SETTINGS = 'SET_CUSTOM_URL_PAYMENT_SETTINGS';

export const GET_ACCOUNT_LIMIT = 'GET_ACCOUNT_LIMIT';
export const GET_PAYEE_SUSBCRIBER_ID_BY_PAYEE_ID = 'GET_PAYEE_SUSBCRIBER_ID_BY_PAYEE_ID';
export const GET_APIKEY_BY_PAYEE_ID = 'GET_APIKEY_BY_PAYEE_ID';
export const RUN_MIGRATION = 'RUN_MIGRATION';
export const SET_PAYLINK_SOURCE = 'SET_PAYLINK_SOURCE';

export const SET_CALLBACK_URL = 'SET_CALLBACK_URL';
export const SET_AMOUNT = 'SET_AMOUNT';
export const SET_ORDER_NUMBER = 'SET_ORDER_NUMBER';
export const SEND_POST_MESSAGE = 'SEND_POST_MESSAGE';
export const HANDLE_POST_MESSAGE = 'HANDLE_POST_MESSAGE';

export const SELECT_PAYMENT_REQUEST = 'SELECT_PAYMENT_REQUEST';
export const HANDLE_PAYMENT_STATUS = 'HANDLE_PAYMENT_STATUS';

export const OPEN_BLUETOOTH = 'OPEN_BLUETOOTH';
export const OPEN_LOCATION = 'OPEN_LOCATION';

export const SET_PLATFORM = 'SET_PLATFORM';
export const GET_PLATFORM = 'GET_PLATFORM';

export const SET_DEFAULT_SCREEN_HEIGHT = 'SET_DEFAULT_SCREEN_HEIGHT';

export const GET_APP_VERSION = 'GET_APP_VERSION';
export const SET_APP_BUILD = 'SET_APP_BUILD';
export const SET_ACCOUNT_TYPE = 'SET_ACCOUNT_TYPE';

export const OPEN_NEW_PAGE = 'OPEN_NEW_PAGE';

export const GET_BLUETOOTH_PERMISSION_STATUS = 'GET_BLUETOOTH_PERMISSION_STATUS';

export const OPEN_CALLBACK_PAGE = 'OPEN_CALLBACK_PAGE';
export const CALLBACK_PAGE_OPENED = 'CALLBACK_PAGE_OPENED';

export const SET_PIN_CODE = 'SET_PIN_CODE';
export const GET_PIN_CODE = 'GET_PIN_CODE';
export const SET_PIN_VALUE = 'SET_PIN_VALUE';
export const CLEAR_PIN_CODE = 'CLEAR_PIN_CODE';
export const SET_PIN_ON_LAUNCH = 'SET_PIN_ON_LAUNCH';

export const SET_BANK_ACCOUNT = 'SET_BANK_ACCOUNT';
export const SET_ACTIVITY = 'SET_ACTIVITY';
export const SET_BOPP_LAUNCH = 'SET_BOPP_LAUNCH';

export const GET_TRANSACTION_COUNTER = 'GET_TRANSACTION_COUNTER';
export const PUT_TRANSACTION_COUNTER = 'PUT_TRANSACTION_COUNTER';

export const SET_HOME_PAGE = 'SET_HOME_PAGE';
export const SET_HOME = 'SET_HOME';
export const GET_HOME = 'GET_HOME';
export const SET_IS_APP_LAUNCHED_FIRST_TIME = 'SET_IS_APP_LAUNCHED_FIRST_TIME';
export const SAVE_IS_APP_LAUNCHED_FIRST_TIME = 'SAVE_IS_APP_LAUNCHED_FIRST_TIME';
export const GET_IS_APP_LAUNCHED_FIRST_TIME = 'GET_IS_APP_LAUNCHED_FIRST_TIME';

export const CHANGE_WRONG_SCREEN = 'CHANGE_WRONG_SCREEN';

export const SET_PAYMENT_REQUEST_METHOD = 'SET_PAYMENT_REQUEST_METHOD';
export const SET_PAYMENT_INSTRUCTION_METHOD = 'SET_PAYMENT_INSTRUCTION_METHOD';

export const CHANGE_DOMESTIC_PAYMENT = 'CHANGE_DOMESTIC_PAYMENT';
export const CANCEL_DOMESTIC_PAYMENT = 'CANCEL_DOMESTIC_PAYMENT';

export const CREATE_PAYLINK_REQUEST = 'CREATE_PAYLINK_REQUEST';
export const PAYLINK_REQUEST_ACCEPTED = 'PAYLINK_REQUEST_ACCEPTED';
export const CLEAR_PAYLINK = 'CLEAR_PAYLINK';
export const CLEAR_PAYLINK_URL = 'CLEAR_PAYLINK_URL';

export const CREATE_PAYMENT_INSTRUCTION = 'CREATE_PAYMENT_INSTRUCTION';

export const SELECT_BANK = 'SELECT_BANK';
export const ADD_BANK = 'ADD_BANK';
export const UPDATE_BANK = 'UPDATE_BANK';
export const REMOVE_BANK = 'REMOVE_BANK';
export const REMOVE_AUTH_URL_PAYER_BANK = 'REMOVE_AUTH_URL_PAYER_BANK';

export const PASS_BANKS_URL = 'PASS_BANKS_URL';
export const GET_BANK_LIST = 'GET_BANK_LIST';
export const SET_BANK_LIST = 'SET_BANK_LIST';

export const SELECT_BANK_ACCEPTED = 'SELECT_BANK_ACCEPTED';
export const HANDLE_BANK_MESSAGE = 'HANDLE_BANK_MESSAGE';

export const REPLY_REJECTED = 'REPLY_REJECTED';
export const REPLY_PENDING_CHANGE = 'REPLY_PENDING_CHANGE';
export const REPLY_PENDING_ACCEPT = 'REPLY_PENDING_ACCEPT';
export const REPLY_ACCEPTED = 'REPLY_ACCEPTED';
export const REPLY_CANCELLED = 'REPLY_CANCELLED';
export const REPLY_REQUEST_CANCELLED = 'REPLY_REQUEST_CANCELLED';
export const PAYEE_ACTIVITY_REPORT_CANCELLED = 'PAYEE_ACTIVITY_REPORT_CANCELLED';
export const PAYER_ACTIVITY_REPORT_CANCELLED = 'PAYER_ACTIVITY_REPORT_CANCELLED';

export const REPLY_HISTORY_CHANGE = 'REPLY_HISTORY_CHANGE';
export const CANCEL_PAYMENT_REQUEST = 'CANCEL_PAYMENT_REQUEST';

export const CHANGE_PIN_SET = 'CHANGE_PIN_SET';

export const GET_HISTORY_OBJECT_STATUS = 'get/history/object/status';

// payment events
export const CHANGE_ISNULL_AMOUNT = 'CHANGE_ISNULL_AMOUNT';
export const SET_PAYMENT_ACTION = 'SET_PAYMENT_ACTION';

export const BROWSER_BANKING_ACCOUNT_DETAILS = 'BROWSER_BANKING_ACCOUNT_DETAILS';
export const CHANGE_PAYMENT_STATUS_SCREEN = 'CHANGE_PAYMENT_STATUS_SCREEN';

export const BROWSER_BANKING = 'BROWSER_BANKING';
export const CLEAR_USED_LIST_BANK = 'CLEAR_USED_LIST_BANK';
export const SAVE_PAYLINK_URL = 'SAVE_PAYLINK_URL';


// account details
export const CREATE_ACCOUNT_DETAILS = 'CREATE_ACCOUNT_DETAILS';
export const CHANGE_ACCOUNT_DETAILS = 'CHANGE_ACCOUNT_DETAILS';
export const CANCEL_ACCOUNT_DETAILS = 'CANCEL_ACCOUNT_DETAILS';
export const CHANGE_IS_ADD_BANK_ACCOUNT = 'CHANGE_IS_ADD_BANK_ACCOUNT';
export const REMOVE_ACCOUNT_DETAILS = 'REMOVE_ACCOUNT_DETAILS';
export const CHANGE_PENDING_ACCEPT = 'CHANGE_PENDING_ACCEPT';
export const CHANGE_ACCOUNT_DETAILS_SCREEN = 'CHANGE_ACCOUNT_DETAILS_SCREEN';

export const UPDATE_ACCOUNT_DETAILS = 'UPDATE_ACCOUNT_DETAILS';
export const REMOVE_ACCOUNT_DETAILS_AUTH_URL = 'REMOVE_ACCOUNT_DETAILS_AUTH_URL';
export const UPDATE_ACCOUNT_DETAILS_BANK = 'UPDATE_ACCOUNT_DETAILS_BANK';
export const HANDLE_ACCOUNT_DETAILS_STATUS = 'HANDLE_ACCOUNT_DETAILS_STATUS';
export const CLEAR_ACCOUNT_DETAILS_STATUS = 'CLEAR_ACCOUNT_DETAILS_STATUS';

export const SELECT_BANK_ACCOUNT_DETAILS = 'SELECT_BANK_ACCOUNT_DETAILS';
export const SAVE_IN_APP = 'SAVE_IN_APP';
export const GET_FROM_APP = 'GET_FROM_APP';

export const GET_USED_BANK_LIST = 'GET_USED_BANK_LIST';
export const ADD_USED_BANK_TO_LIST = 'ADD_USED_BANK_TO_LIST';
export const SET_USED_BANK_TO_LIST = 'SET_USED_BANK_TO_LIST';
export const REMOVE_USED_BANK_FROM_LIST = 'REMOVE_USED_BANK_FROM_LIST';

export const START_OTP_CALCULATOR = 'START_OTP_CALCULATOR';
export const OTP_CALCULATED = 'OTP_CALCULATED';

export const SHOW_CAMERA = 'SHOW_CAMERA';
export const HIDE_CAMERA = 'HIDE_CAMERA';
export const CALL_SHARE = 'CALL_SHARE';
export const GET_SHARE = 'GET_SHARE';
export const SHARE_QR = 'SHARE_QR';
export const CLEAR_PAYER_BANK = 'CLEAR_PAYER_BANK';
export const CLICK_BACK_BUTTON = 'CLICK_BACK_BUTTON';
export const INVALID_PAYMENT_ERROR = 'INVALID_PAYMENT_ERROR'

export const COPY_TO_CLIPBOARD = 'COPY_TO_CLIPBOARD';

export const SET_ERROR = 'SET_ERROR';
export const READ_ERROR = 'READ_ERROR';

export const SET_ADD_ACCOUNT_ERROR = "SET_ADD_ACCOUNT_ERROR";
export const SET_REFRESH_SUBSCRIPTION_REQUEST_STATUS = "SET_REFRESH_SUBSCRIPTION_REQUEST_STATUS";
export const SET_FAIL_REASON_TEXT = "SET_FAIL_REASON_TEXT";
export const SET_IS_PAYMENT_SOCKET_CLOSED = "SET_IS_PAYMENT_SOCKET_CLOSED";
export const SET_LATEST_CHANGE_REQUEST = "SET_LATEST_CHANGE_REQUEST";
export const SET_IFRAME_REDIRECT_URL = "SET_IFRAME_REDIRECT_URL";

export const CHANGE_BANK_ACCOUNT_STATUS = "CHANGE_BANK_ACCOUNT_STATUS";

export const CHECK_EMAIL = "CHECK_EMAIL";
export const SET_CHECK_EMAIL_STATUS = "SET_CHECK_EMAIL_STATUS";
export const CHECK_EMAIL_REFERENCE_NUMBER_PAIR = "CHECK_EMAIL_REFERENCE_NUMBER_PAIR";
export const SET_EMAIL_REFERENCE_NUMBER_PAIR_CHECK = "SET_EMAIL_REFERENCE_NUMBER_PAIR_CHECK";
export const SET_EMAIL = "SET_EMAIL";
export const SET_FIRTSNAME = "SET_FIRSTNAME";
export const SET_LASTNAME = "SET_LASTNAME";
export const SET_CHECK_AUTH_CODE_STATUS = 'SET_CHECK_AUTH_CODE_STATUS';
export const SET_TIMER_DEADLINE = 'SET_TIMER_DEADLINE';
export const UPDATE_TIMER_DEADLINE = 'UPDATE_TIMER_DEADLINE';

export const CHECK_USER_SUBSCRIPTION = 'CHECK_USER_SUBSCRIPTION';
export const SHOW_MESSAGE_ACCOUNT_DEACTIVATED = 'SHOW_MESSAGE_ACCOUNT_DEACTIVATED';
export const SHOW_MESSAGE_LIMIT_REACHED = 'SHOW_MESSAGE_LIMIT_REACHED';
export const CHANGE_FIXED_AMOUNT = 'CHANGE_FIXED_AMOUNT';
export const TOGGLE_MENU = 'TOGGLE_MENU';

export const SET_STATUS_BAR_COLOR = 'SET_STATUS_BAR_COLOR';
export const STOP_PING_PONG = 'STOP_PING_PONG';
export const CLOSE_PING_PONG_SOCKET = 'CLOSE_PING_PONG_SOCKET';

export const SET_PAYER_STEP = 'SET_PAYER_STEP';
export const SET_FIXED_AMOUNT = 'SET_FIXED_AMOUNT';

export const SET_ADDITIONAL_USER_AGREEMENT = 'SET_ADDITIONAL_USER_AGREEMENT';

export const GET_PAYMENTS_HISTORY = 'GET_PAYMENTS_HISTORY';

export const ADD_ERROR = 'ADD_ERROR';