import React, { useState, useEffect } from 'react';

import RedCamera from '../../assets/images/camera.svg';
import RedLocation from '../../assets/images/location-red.svg';
import { PermissionType } from "../../static/CommonDefinitions";
import RedBluetooth from '../../assets/images/bluetooth-red.svg';

import './styles.scss';

const PermissionPopup = ({ requiredPermission }) => {

    const [img, setImg] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        if(requiredPermission === PermissionType.Camera) {
            setImg(RedCamera);
            setMessage('BOPP needs access to your camera to read QR codes.');
        }
        if(requiredPermission === PermissionType.Bluetooth) {
            setImg(RedBluetooth);
            setMessage('BOPP needs permission to use Bluetooth to send and receive payment requests.');
        }
        if(requiredPermission=== PermissionType.Location) {
            setImg(RedLocation);
            setMessage('BOPP needs location permission to receive payment requests over Bluetooth.');
        }
    }, [requiredPermission]);

    return (
        <div className="permission-popup">
            <img src={img} alt={''} className="permission-popup-icon" />
            <p className="permission-popup-text">{message}</p>
        </div>
    )
}

export default PermissionPopup;