import React from 'react';
import CloseIcon from '../../../assets/images/close-icon.svg';
import '../style.scss';

const PinProtectionModal = ({ closeModal }) => (
    <>
        <div className="gray-background" />
        <div className="modal-window pin-protect">
            <div className="background-color background-color__base" />
            <div className="modal-window-content modal-window-content__question">
                <img onClick={() => closeModal()} className="close-modal" src={CloseIcon} alt="CloseIcon" />
                <div className="modal-title modal-title__question modal-title__pin-title">
                    PIN Protection
                </div>
                <div className="modal-description modal-description__pin-protect">
                    Secure your bank account - PIN will be required to view and manage your bank account.
                </div>
                <button
                    onClick={() => closeModal(true)}
                    className="modal-buttons modal-buttons__cancel pin-protect-btn"
                >
                    CREATE PIN
                </button>
            </div>
        </div>
    </>
);

export default PinProtectionModal;