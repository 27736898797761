import { sendError } from '../errorResolver';
import { 
    takeLatest, 
    put,
    select
} from 'redux-saga/effects';

import { 
    ErrorFlow, 
    ErrorObject 
} from '../../entities/errorObject';
import { getPropertyFrom } from '../../helpers/objectHelper';
import { checkIsBoppButtonPayment, findFirstValidKeyInObject, wrap } from '../../helpers';
import { IncomingMessagePayload } from '../messageResolver';
import { changePaymentStatusScreen, changeWrongScreen, setPaylinkSourceAction, showInvalidPaymentError } from '../../actions';
import { StatusRequest, StatusRequestPayload } from '../../entities/statusRequestObject';
import { addPaylinkStatusData, paylinkStatusData, RedirectUrls, setActivePaylink, updatePaylinkStatusData } from '../../reducers/statusSlice';
import { PaymentRequestType, PaymentTrigger, ReferenceSettingType, TermType } from '../../static/CommonDefinitions';
import moment, { max } from "moment";
import { addLastPayment, setBoppButton, setPaymentRequest } from '../../reducers/paymentPersistReducer';
import { push } from 'connected-react-router';
import { notifyOpenedPaylinkAction } from './boppButtonNotifier';
import { StaticSocket } from '../../sockets/StaticSocket';
import config from '../../config';
import { useDispatch } from 'react-redux';

export const RESOLVE_STATUS_REQUEST_MESSAGE = 'resolve/status/request/message';
export interface ResolveStatusRequestMessage { type: typeof RESOLVE_STATUS_REQUEST_MESSAGE; payload: IncomingMessagePayload }
export const resolveStatusResponseMessageAction = (payload: IncomingMessagePayload): ResolveStatusRequestMessage => ({
    type: RESOLVE_STATUS_REQUEST_MESSAGE,
    payload,
})

export const SEND_STATUS_REQUEST_MESSAGE = 'send/status/request/message';
export interface SendStatusRequestMessage { type: typeof SEND_STATUS_REQUEST_MESSAGE; payload: StatusRequestPayload }
export const sendStatusRequestMessageAction = (payload: StatusRequestPayload): SendStatusRequestMessage => ({
    type: SEND_STATUS_REQUEST_MESSAGE,
    payload,
})

interface PaylinkAmountValues { 
    amount:string; 
    suggestedAmount:string;
    minAmount:string;
    maxAmount:string;
}

interface CustomUrlSettings { 
    amount:string; 
    reference:string;
}

function* resolveStatusRequestMessage({ payload }: ResolveStatusRequestMessage): any {
    try {
        let propertiesType = getPropertyFrom(payload, 'properties', '@type')

        if(propertiesType === "https://miapago.io/paylink/request/v0.1.0#LinkStatus"){
            return
        }
        
        yield put(setPaylinkSourceAction(payload))
        let paylinkType = getPaymentRequestType(payload)
        if(isPaylinkValid(payload, paylinkType)) {
            if(paylinkType === PaymentRequestType.Recurring) {
                yield processRecurryingPaymentStatusResponse(payload)
            } else {
                yield processStandardPaymentStatusResponse(payload)
            }   
        } else {
            yield put(showInvalidPaymentError(true))
            yield put(setPaymentRequest(null))
        }
    } catch (error) {
        yield put(sendError({error:new ErrorObject('Error resolving PISP Incoming message', error, ErrorFlow.accounts, payload)}));
        yield put(changeWrongScreen(true));
    }
}

function* sendStatusRequestMessage(socket: WebSocket,  payload: SendStatusRequestMessage): any {
    console.log(payload)
    const paylink = payload.payload.paylink;
    const otp = payload.payload?.otp;
    const isPayment = payload.payload?.isPayment;
    try {    
        let statusRequest = new StatusRequest({paylink, otp});
        const request = statusRequest.createRequest();

        yield put(addPaylinkStatusData({
            isPayment: isPayment,
            paylink: paylink,
            otp: otp,
            statusRequestId: request.id
        }));

        yield sendWSMessage(wrap('paylink-initiator', request))
    } catch (error) {
        yield put(sendError({error:new ErrorObject('Error sending create payment instruction for ' + paylink, error, ErrorFlow.payments, null)}));
    }
}

function getPaymentRequestType(payload:any) : PaymentRequestType {
    let requestType = getPropertyFrom(payload, 'properties.paymentTerms', '@type')
    if(requestType === "https://miapago.io/paymentterms/v0.1.0#RecurringPayment") {
        return PaymentRequestType.Recurring;
    } else {
        return PaymentRequestType.Standard;
    }
}

function *processRecurryingPaymentStatusResponse(response:any) {
    const boppButton = isBOPPButtonPaylink(response)
    yield put(setBoppButton(boppButton))
    let recurringPaymentAmount = getPropertyFrom(response, 'properties.paymentTerms', 'recurringPaymentAmount');
    let firstPaymentDue = getPropertyFrom(response, 'properties.paymentTerms', 'firstPaymentDue', true);
    let frequency = getPropertyFrom(response, 'properties.paymentTerms', 'frequency', true);
    let paymentReference = getPropertyFrom(response, 'properties.paymentTerms.paymentMethods[0]', 'paymentReference', true);
    let payeeName = getPropertyFrom(response, 'state', 'requesterName', true);
    let firstPaymentDueDate = getFirstPaymentDueDate(firstPaymentDue);
    firstPaymentDue = moment(firstPaymentDueDate).set({hour: 23, minute: 59, second: 59,}).format("DD / MM / YY");
    let finalPaymentDue = getPropertyFrom(response, 'properties.paymentTerms', 'finalPaymentDue');
    let firstPaymentAmount = getPropertyFrom(response, 'properties.paymentTerms', 'firstPaymentAmount', true)
    let finalPaymentAmount = getPropertyFrom(response, 'properties.paymentTerms', 'finalPaymentAmount');
    let numberOfPayments = getPropertyFrom(response, 'properties.paymentTerms', 'numberOfPayments');
    let firstRecurringPaymentDue = getPropertyFrom(response, 'properties.paymentTerms', 'firstRecurringPaymentDue');
    let paylink = getPropertyFrom(response, 'state', 'paylink');
    let taglineText = getPropertyFrom(response, 'state.attachments[0].json', 'taglineText');
            
    const savedPaylinks:paylinkStatusData[] = yield select(state => state.paylink.paylinks);
    const selectedPaylink = savedPaylinks.find(element => element.statusRequestId === response.previousRequestId)

    if(selectedPaylink == undefined) {
        // throw error
    }

    if(boppButton && selectedPaylink?.isPayment) {
        yield put(notifyOpenedPaylinkAction({paylink: paylink}));
    }
    
    let activePaylink = {
        isPayment:selectedPaylink?.isPayment,
        paylink,
        boppButton,
        details:{
            payeeName,
            paymentReference,
            finalPaymentDue,
            firstPaymentAmount,
            recurringPaymentAmount,
            finalPaymentAmount,
            frequency,
            numberOfPayments,
            firstPaymentDue,
            firstRecurringPaymentDue,
            redirectUrls: getRedirectUrls(response),
            taglineText: taglineText || ''
        }
    }

    yield put(setActivePaylink(activePaylink))

    if(selectedPaylink?.isPayment) {
        yield put(updatePaylinkStatusData(activePaylink))
        yield put(push('/so-request-details'));
    } else {
        let previousRequestId = getPropertyFrom(response, '', 'previousRequestId');
        let statusScreenDetails = {
            amount: firstPaymentAmount.value,
            accountName:  payeeName,
            orderNumber: paymentReference,
            transactionId: previousRequestId,
            redirectUrls: getRedirectUrls(response)
        }
    
        yield put(addLastPayment(statusScreenDetails));
        yield put(changePaymentStatusScreen({ paymentInProcess: false }));
        yield put(push('/so-status'))
    }
}

function getRedirectUrls(response:any): RedirectUrls {

    let redirectUrls = {
        success: undefined,
        fail: undefined,
        cancel: undefined,
    }

    const attachment = getRedirectAttachments(response)
    if(attachment != undefined) {
        redirectUrls.success = getPropertyFrom(attachment, 'json', 'success');
        redirectUrls.fail = getPropertyFrom(attachment, 'json', 'fail');
        redirectUrls.cancel = getPropertyFrom(attachment, 'json', 'cancel');
    }

    return redirectUrls;
}

function getRedirectAttachments(response:any) :any {
    const attachments = getPropertyFrom(response, 'properties', 'attachments');
    if(attachments != undefined) {
        for(let i = 0; i < attachments.length; i++) {
            if(attachments[i]['@type'] === 'https://dvschema.io/attachments/v0.1.0#Attachment') {
                return attachments[i];
            }
        }
    }

    return undefined
}

function *processStandardPaymentStatusResponse(response:any) {
    const boppButton = isBOPPButtonPaylink(response)
    yield put(setBoppButton(boppButton))

    const paylinkUrl = getPropertyFrom(response, 'state', 'paylink');
    const name = getPayeeNameForDomesticPayment(response);
    const endToEndIdentification = getPropertyFrom(response, 'properties.paymentTerms.paymentMethods[0].properties', 'endToEndIdentification');


    const savedPaylinks:paylinkStatusData[] = yield select(state => state.paylink.paylinks);
    const selectedPaylink = savedPaylinks.find(element => element.statusRequestId === response.previousRequestId)

    if(selectedPaylink == undefined) {
        // throw error
    }

    if(boppButton) {
        yield put(notifyOpenedPaylinkAction({paylink: paylinkUrl}));
    }

    const paymentCustomUrlSettings:CustomUrlSettings = yield select(state => state.payment.paymentCustomUrlSettings);
    
    let amountValues = getPaymentAmountValuesFromResponse(response, paymentCustomUrlSettings)


    let settings = getPropertyFrom(response, 'properties.paymentTerms.paymentMethods[0].properties', 'metainfo');
    if(settings == undefined) {
        settings = {"encryption":{"serviceProvider":{"pk":"bopp-public-key"},"payer":{"pk":"payer-public-key"},"payee":{"pk":"payee-public-key"}},"reference":{"type":"SetByMe","value":"BOPP payment"},"notes":{"enabled":false,"type":"SetByMe","notesValue":"","notesCaption":"","makeNotesMandatory":false},"requestEmail":{"enabled":false,"mandatory":false,"displayMarketingOptIn":false,"organizationName":""},"thankYouNote":{"enabled":false,"message":""},"giftAid":{"enabled":false,"payee":{}}}
    } else {
        settings = JSON.parse(settings)
    }

    const ref = getPropertyFrom(response, 'state', 'reference');
    let pReference = getPaymentReferenceFromProperties(settings, ref, paymentCustomUrlSettings)

    let termType = getPropertyFrom(response, 'properties.paymentTerms.amountTerm', 'termType', true);
    let rangeValue = getPropertyFrom(response, 'properties.paymentTerms.amountTerm.properties.range[0]', 'value');
    let minValue = getPropertyFrom(response, 'properties.paymentTerms.amountTerm.properties.min', 'value');
    let maxValue = getPropertyFrom(response, 'properties.paymentTerms.amountTerm.properties.max', 'value');
    let taglineText = getPropertyFrom(response, 'state.attachments[0].json', 'taglineText');
    const amountTerm = getPaymentAmountTerm(termType, rangeValue, minValue, maxValue);

    let maxInstructions = getMaxInstructionForPaylink(response);
    if(maxInstructions != -1) {
        maxValue = maxValue/ maxInstructions;
    }

    if(selectedPaylink?.isPayment) {
        yield put(setPaymentRequest({
            paylinkUrl: paylinkUrl,
            detailsLoaded: true,
            amount: amountValues.amount,
            minAmount :amountValues.minAmount,
            maxAmount :maxValue,
            suggestedAmount: amountValues.suggestedAmount,
            name: name,
            reference: pReference,
            amountTerm,
            endToEndIdentification: endToEndIdentification,
            settings:settings,
            taglineText: taglineText || ''
        }))

        yield put(push('/request-details/' + paylinkUrl.split('/').pop()))
    } else {
        let previousRequestId = getPropertyFrom(response, '', 'previousRequestId');
        let statusScreenDetails = {
            amount: amountValues.amount,
            accountName:  name,
            orderNumber: pReference,
            transactionId: previousRequestId,
            internalReference: settings.internalReference
        }
    
        yield put(addLastPayment(statusScreenDetails));
        yield put(changePaymentStatusScreen({ paymentInProcess: false }));
        yield put(push('/status'))
    }
}

function getPayeeNameForDomesticPayment(response:any): string {
    let name = getPropertyFrom(response, 'properties.paymentTerms.paymentMethods[0].properties', 'name');
    if(name != undefined) {
        return name
    } else {
        return getPropertyFrom(response, 'state', 'requesterName', true);
    }
}

function getPaymentAmountValuesFromResponse(response:any, paymentCustomUrlSettings:CustomUrlSettings) : PaylinkAmountValues  {
    let termType = getPropertyFrom(response, 'properties.paymentTerms.amountTerm', 'termType', true);
    let rangeValue = getPropertyFrom(response, 'properties.paymentTerms.amountTerm.properties.range[0]', 'value');
    let minValue = getPropertyFrom(response, 'properties.paymentTerms.amountTerm.properties.min', 'value');
    let maxValue = getPropertyFrom(response, 'properties.paymentTerms.amountTerm.properties.max', 'value');
    const amountTerm = getPaymentAmountTerm(termType, rangeValue, minValue, maxValue);

    if(paymentCustomUrlSettings.amount !== null) {
        return getPaymentAmountValuesFromResponseForCustomUrlSetting(response, paymentCustomUrlSettings, amountTerm)
    } else {
        let amount = response?.properties?.amount?.value || ''
        let suggestedAmount = ''
        let minAmount = ''
        let maxAmount = ''
  
        if(amountTerm === TermType.SuggestedAmount) {
            suggestedAmount = rangeValue
        } else if(amountTerm === TermType.MinAmount) {
            minAmount = rangeValue
        } else if(amountTerm === TermType.MaxAmount) {
            maxAmount = maxValue
        }
  
        return {amount, suggestedAmount, minAmount, maxAmount}
    }
}
  
function getPaymentAmountValuesFromResponseForCustomUrlSetting(response:any, paymentCustomUrlSettings:CustomUrlSettings, amountTerm: TermType): PaylinkAmountValues {
    let amount = response.properties.amount.value
    let suggestedAmount = ''
    let maxAmount = ''
    let minAmount
    if(amountTerm === TermType.AnyAmount){
        amount = paymentCustomUrlSettings.amount
    } else if(amountTerm === TermType.SuggestedAmount) {
        suggestedAmount = response.properties.paymentTerms.amountTerm.properties.range[0].value
        amount = paymentCustomUrlSettings.amount
    } else if(amountTerm === TermType.MinAmount) {
        minAmount = response.properties.paymentTerms.amountTerm.properties.range[0].value
        if(response.properties.paymentTerms.amountTerm.properties.range[0].value > paymentCustomUrlSettings.amount) {
            amount = response.properties.paymentTerms.amountTerm.properties.range[0].value
        } else {
            amount = paymentCustomUrlSettings.amount
        }
    }

    return {amount, suggestedAmount, minAmount, maxAmount}
}
  
function getPaymentReferenceFromProperties(setting:any, reference:string, paymentCustomUrlSettings:CustomUrlSettings): string {
    let pReference = reference;
      
    if(paymentCustomUrlSettings.reference) { 
        if(setting.reference.type === ReferenceSettingType.SetByMe) {
            return paymentCustomUrlSettings.reference
        }
    }
      
    return pReference
}
  
function getPaymentAmountTerm(termType:TermType, rangeValue:string, minValue:string, maxValue:string): TermType {
    if(termType === TermType.FixedAmount) {
        return TermType.FixedAmount
    } else if(termType === TermType.AnyAmount) {
        if(maxValue === '99999.99') {
            return TermType.AnyAmount
        } else {
            if(maxValue) {
                return TermType.MaxAmount
            } else {
                return TermType.AnyAmount
            }
        }
    } else {
        if(minValue === rangeValue) {
            return TermType.MinAmount
        } else {
            return TermType.SuggestedAmount
        }
    }
}
  

function getFirstPaymentDueDate(firstPaymentDue: string): Date | never {
    let firstDate = parseDate(firstPaymentDue)
    if(!firstDate) {
        throw new ReferenceError('Can not resolve recurrying payment date ' + firstPaymentDue + ', invalid date');
    } 

    return firstDate;
}

function parseDate(datestring: string): Date | never {
    let miliseconds = Date.parse(datestring)
    if (isNaN(miliseconds) == false) {
        return new Date(miliseconds)   
    } else {
        throw new ReferenceError('Can not resolve recurrying payment date ' + datestring + ', invalid date');
    }
}

function isPaylinkValid(payload: any, paylinkType: PaymentRequestType) {
    if(getIsPaylinkExpired(payload, paylinkType)) {
        return false;
    } else {
        if(paylinkType === PaymentRequestType.Recurring) {
            return true;
        } else {
            return isPaylinkCanBePayed(payload);
        }
    }
}
  
function getIsPaylinkExpired(response: any, paylinkType: PaymentRequestType): boolean {
    let expDate = getPaylinkExpireDate(response, paylinkType);
    if(isDateInPast(expDate)) {
        return true;
    } else {
        return false;
    }
}

function getPaylinkExpireDate(response: any, paylinkType: PaymentRequestType) : Date {
    if(paylinkType === PaymentRequestType.Recurring) {
        let firstPaymentDue = getPropertyFrom(response, 'properties.paymentTerms', 'firstPaymentDue', true);
        let firstPaymentDueDate = getFirstPaymentDueDate(firstPaymentDue);
        firstPaymentDue = moment(firstPaymentDueDate).set({hour: 23, minute: 59, second: 59,}).format("DD / MM / YY");
        return firstPaymentDue;
    } else {
        let expDate = getPropertyFrom(response, 'properties.activationLifetime', 'expiryDate');
        if(expDate == undefined) { // Expire date isn't set
            return new Date();
        } else {
            expDate = moment(expDate).set({hour: 23, minute: 59, second: 59,});
            return new Date(expDate);
        }
    }
}

function isDateInPast(date: Date) {
    return date < new Date();
}

function isPaylinkCanBePayed(response:any) {
    let maxInstructions = getMaxInstructionForPaylink(response);
    if(maxInstructions === -1) {
        return true;
    } else {
        const instructions = getPropertyFrom(response, 'state', 'instructions');
        if(instructions == undefined) {
            return true;
        }

        const completedInstructions = getCompletedInstructionsCountForpaylink(instructions)
        if(completedInstructions >= maxInstructions) {
            return false;
        } else {
            return true;
        }
    }
}

function getCompletedInstructionsCountForpaylink(instructions:any): number {
    if(instructions) {
    let completedInstructionsCount = 0
        let inst = Object.entries(instructions)
        inst.forEach(instruction => {
            if(isInstructionCopleted(instruction)) {
            completedInstructionsCount ++
            }
        });
        
        return completedInstructionsCount
    } else {
        return 0
    }
}
  
function isInstructionCopleted(instruction:any): boolean {
    let obj = findFirstValidKeyInObject(instruction, 'entityState')
    if(obj != null) {
        if(obj === 'Complete') {
            return true
        }
    } 

    return false
}

function getMaxInstructionForPaylink(response:any) {
    const paymentTrigger = getPropertyFrom(response, 'properties.paymentTerms', 'paymentTrigger', true);
    const canBeExecutedXTimes = getPropertyFrom(response, 'properties.paymentTerms.amountTerm', 'canBeExecutedXTimes');
    
    try {
        if(paymentTrigger === PaymentTrigger.OnInstruction) {
            return 1
        } else if(canBeExecutedXTimes != undefined) {
            return canBeExecutedXTimes;
        } else {
            return -1;
        }
    }
    catch(error) {
        throw new ReferenceError('Error getting max instruction for paylink ' + response);
    }
}

function isBOPPButtonPaylink(payload:any): boolean {
    let obj = findFirstValidKeyInObject(payload, 'endToEndIdentification')
    if(obj != null) {
        if(checkIsBoppButtonPayment(obj)) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export function* sendWSMessage(data:any) {
    const apiKey:string = yield select(state => state.payment.paymentPayeeSubscriberIds.apiKey);
    let ssoc = StaticSocket.getInstance();
    let key  = config.wsApiKey
    if(apiKey !== '') {
        yield ssoc.setAPIKey(apiKey)
        key = apiKey
    }
    if(ssoc.isSocketReady()) {
        yield ssoc.send(data)
    } else {
        yield ssoc.setupSocket(config.paylinkServerURL, key as string)
        yield ssoc.send(data)
    }
}

export function* statusProcessor({paylinkSocket}: any) {
    yield takeLatest(RESOLVE_STATUS_REQUEST_MESSAGE, resolveStatusRequestMessage);
    yield takeLatest(SEND_STATUS_REQUEST_MESSAGE, sendStatusRequestMessage, paylinkSocket);
}