import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import BankIcon from '../../assets/images/bank-icon-black.svg';
import Preloader from "../../assets/images/spinner-black.svg";

import { checkEmail, removeAccountDetails, removeBank, setEmail } from '../../actions';
import { AccountType } from '../../static/CommonDefinitions';
import { KYCVerificationStatus } from "../../static/CommonDefinitions";
import FormSteps from '../KYCForm/FormSteps';
import './style.scss'

import BankAccountLabel from '../../components/BankAccountLabel';
import Footer from '../../components/Footer';
import InfoModal from '../Modal/InfoModal';

function BankAccountNotVerified() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { bank } = useSelector(state => ({
    bank: state.payment.bank,
  }));
  const { emailVerified, email } = useSelector(state => ({
    emailVerified: state.kyc.emailVerified,
    email: state.kyc.email,
  }));

  const [restartVerification, setRestartVerification] = useState(false);
  const [showLoaderVerified, setShowLoaderVerified] = useState(false);

  const footerDetails = getFooterDetails();

  useEffect(() => {
    if(showLoaderVerified && emailVerified !== null) {
        setShowLoaderVerified(false);
        history.push('/verify-email', {bankId: bank.id});
    }
  }, [emailVerified, bank]);

  const verificationModal = {
    title: "Restart Verification",
    message: "If you can’t find your email please check your spam folder or restart verification - you will need to re-link your bank account (your current bank account will be removed from BOPP) and submit basic details once again.",
    buttons: [
      {
        buttonText: "Restart",
        buttonType: "tip",
        buttonOnClick: () => {
          dispatch(removeAccountDetails());
          dispatch(removeBank());
          setRestartVerification(false);
          dispatch(setEmail({ email: '' }));
        }
      },
      {
        buttonText: "Cancel",
        buttonType: "cancel",
        buttonOnClick: () => { setRestartVerification(false) }
      }
    ]
  }

  function getFooterDetails() {
    const onVerifiedButton = () => {
      setShowLoaderVerified(true);
      dispatch(checkEmail({ referenceId: bank.id, email: email }));
    }

    switch (bank.bankAccountStatus) {
      case KYCVerificationStatus.None:
        return {
          message: "Before you request a payment please quickly verify your identity in case we need to contact you (and to meet our legal requirements).",
          button: {
            buttonText: "Next",
            onButtonClick: () => { history.push('/kyc-form', bank.subscriptionType === AccountType.Business ? FormSteps.activateBusiness : FormSteps.choosePlan) }
          },
          title: "Verify Your Identity"
        }
      case KYCVerificationStatus.Sent:
        return {
          message: "Please verify your email in order to start sending payment requests.",
          button: {
            buttonText: showLoaderVerified ? <img className="spinner" src={Preloader} width={50} height={50} alt="" /> : "I Verified my Email",
            onButtonClick: onVerifiedButton
          },
          title: "Verify Your Email",
          onLinkClick: () => { setRestartVerification(true) }
        }
    }
  }

  return (
    <>
      {restartVerification && <InfoModal {...verificationModal} />}

      <div className="bank-added-screen">
        <div className="bank-account-container">
          <h2>Bank Account Details</h2>

          <BankAccountLabel
            logo={bank?.logo ? bank.logo : BankIcon}
            accountNumber={bank.accountNumber}
            bankAccountName={bank.accountName}
            accountNickName={bank.accountNickName}
            sortCode={bank?.sortCode}
          />

          <Footer {...footerDetails} />
        </div>
      </div>
    </>
  )
}

export default BankAccountNotVerified
