import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { AmountLabelType, PlatformType, TermType } from '../../static/CommonDefinitions';
import { cssVhToPx } from '../../helpers';
import { numberWithCommas } from "../../helpers";

import './style.scss';


const Amount = forwardRef((props, ref) => {
  let { fixedAmount = false, payee, choosePayee, assignedAmount, labelType, hideLabel } = props;
  const platform = useSelector(state => state.settings.platform);
  const oneTimePaymentSettings = useSelector(state => state.paymentRequestSettings.oneTimePaymentSettings);
  const amount = useSelector((state) => state.payment.amount);
  const request = useSelector(state => state.payment.paymentRequests?.length ?
      state.payment.paymentRequests[0] : {});
  const amountRef = useRef();

  useImperativeHandle(ref, () => ({
    error: () => {
      amountRef.current.classList.add('error')
      const clear = () => {
        amountRef.current.classList.remove('error')
        amountRef.current.removeEventListener('animationend', clear)
      }
      amountRef.current.addEventListener('animationend', clear)
    }
  }));

  const renderAmount = () => {
    if(!fixedAmount) {
      return <p className="amount-wrapper__anyamount">Payment amount set by payer</p>;
    }
    if(request?.amount === '0' && choosePayee) {
      return <p>£_</p>;
    }

    return `£${numberWithCommas(assignedAmount !== undefined ? assignedAmount : amount)}`;
  };

  const getAmountTypeText = () => {
    switch(oneTimePaymentSettings.amount.type) {
      case TermType.MinAmount:
        return 'Min Amount';
      case TermType.SuggestedAmount:
        return 'Suggested amount';
      default:
        return '';
    }
  }

  const renderLabel = () => {
    switch(labelType) {
      case AmountLabelType.Simple:
        return (
          <span className='amount-wrapper__amount-type-label-simple'>{getAmountTypeText()}</span>
        )
      case AmountLabelType.Solid:
        const labelText = getAmountTypeText();
        return labelText ? (
          <div className='amount-wrapper__text-container'>
            <span>{getAmountTypeText()}</span>
          </div>
        ) : <></>
      default:
        return <></>
    }
  }

  const amountClasses = classNames({
    'amount-wrapper': true,
    'choose-payee-amount': payee
  });

  const amountStyles = (payee && platform !== PlatformType.Desktop) ? styles['choose-payee-amount'] : {};

  return (
    <div ref={amountRef} className={amountClasses} style={amountStyles}>
      {
        oneTimePaymentSettings.amount.type === TermType.AnyAmount ?
        <div className='amount-wrapper__text-container amount-wrapper__amount-container'>
          <span>Payment amount <br/> set by payer</span>
        </div> :
        renderAmount()
      }
      {!hideLabel && renderLabel() }

    </div>
  );
})

const styles = {
  ['choose-payee-amount']: {
    fontSize: cssVhToPx(7.2)
  },
}

export default Amount;
