import React from "react";
import "./style.scss";

const Popup = ({title, description, bottomContent}) => {
  return (
    <div className='popup'>
      <div className='popup__inner'>
        <div className='popup__top'>
          <span className='popup__title'>{title}</span>
        </div>
        <div className='popup__center'>
          <span className='popup__description'>{description}</span>
        </div>
        <div className='popup__bottom'>
          {bottomContent}
        </div>
      </div>
    </div>
  )
}

export default Popup;
