import React, {useState} from 'react';
import "./styles.scss"
import Bank_of_Scotland from "../../assets/images/banks-logo/Bank_of_Scotland.svg"
import Barclays from "../../assets/images/banks-logo/Barclays.svg"
import Danske_Bank from "../../assets/images/banks-logo/Danske_Bank.svg"
import FD_Bank from "../../assets/images/banks-logo/FD_Bank.svg"
import Halifax from "../../assets/images/banks-logo/Halifax.svg"
import Lloyds_Bank from "../../assets/images/banks-logo/Lloyds_Bank.svg"
import Monzo_Bank from "../../assets/images/banks-logo/Monzo_Bank.svg"
import Natwest from "../../assets/images/banks-logo/Natwest.svg"
import Revolut from "../../assets/images/banks-logo/Revolut.svg"
import Santander from "../../assets/images/banks-logo/Santander.svg"
import TSB from "../../assets/images/banks-logo/TSB.svg"
import Vector from "../../assets/images/banks-logo/Vector.svg"
import InfoIcon from "../../assets/images/info-icon-purple.svg"
import Button from '../../components/Button';
import PinSetModal from '../Modal/PinSetModal';

function AddBankAccount({openAccountDetails}) {
    const bankLogos = [Barclays, Lloyds_Bank, Halifax, Santander, TSB, Monzo_Bank, FD_Bank, Revolut, Natwest, Bank_of_Scotland, Vector, Danske_Bank];

    const [showModal, setShowModal] = useState(false)

    const gotItCallback = () =>{
        setShowModal(false)
    }

    return (
        <>
            {showModal &&
                <PinSetModal
                    closeButton={false}
                    bottomButton={true}
                    closeModal={gotItCallback}
                    title="Why Do You Need This?"
                    content="To ensure that each payment request sent can be trusted as it contains account details provided directly by the bank."
                />
            }
            <div className="add-bank-container">
                <div className="add-bank-content">
                    <div className="add-bank-header">Start BOPP’ing Today</div>
                    <div className="add-bank-secondary-text">To request a payment you need to add a bank account and supply additional details</div>

                    <div>
                        <div className="banks-grid">
                            {bankLogos.map((item, index) =>
                                <div className="grid-cell" key={index}>
                                    <img src={item}/>
                                </div>
                            )}
                        </div>
                        <div className="add-bank-secondary-text right">... and others</div>
                    </div>
                    <div className="button-block">
                        <Button title="Start Now" handleClick={openAccountDetails} color="black"/>
                    </div>
                </div>
                <div className="add-bank-footer">
                    <div className="tip-block" onClick={() => {setShowModal(true)}}>
                        <img src={InfoIcon}/> <span>Why do we need this?</span>
                    </div>
                    <div className="tip-description">
                        BOPP is a registered trademark for payment services of Mia Pago Ltd, a company registered in England and Wales (No. 11263859). Mia Pago Ltd is regulated by the Financial Conduct Authority. Our Financial Services Register number is 826380.
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddBankAccount
