import {NotesSettingType, PaymentTrigger, ReferenceSettingType} from "../static/CommonDefinitions";

const referenceChangesCheck = (setting, target, settingChanges) => {
    if (setting?.reference?.type !== target?.reference?.type) {
        settingChanges.reference = true;
    }

    switch(setting?.reference?.type) {
        case ReferenceSettingType.SetByMe:
            if (setting?.reference?.setByMeValue !== target?.reference?.setByMeValue) {
                settingChanges.reference = true;
            }
            break;
        case ReferenceSettingType.Autonumber:
            if (setting?.reference?.autonumberValue !== target?.reference?.autonumberValue) {
                settingChanges.reference = true;
            }
            break;
    }
};
const expireChangesCheck = (setting, target, settingChanges) => {
    if (setting?.expire?.type !== target?.expire?.type) {
        settingChanges.expire = true;
    }

    switch(setting?.expire?.type) {
        case PaymentTrigger.XInstructions:
            if (setting?.expire?.xInstructionsValue !== target?.expire?.xInstructionsValue) {
                settingChanges.expire = true;
            }
            break;
    }

    if (setting?.expire?.onCertainDate !== target?.expire?.onCertainDate) {
        settingChanges.expire = true;
    }

    if (setting?.expire?.onCertainDate) {
        if (setting?.expire?.certainDate !== target?.expire?.certainDate) {
            settingChanges.expire = true;
        }
    }
};
const amountChangesCheck = (setting, target, settingChanges) => {
    if (setting?.amount?.type !== target?.amount?.type) {
        settingChanges.amount = true;
    }
};
const emailChangesCheck = (setting, target, settingChanges) => {
    if (setting?.requestEmail?.enabled !== target?.requestEmail?.enabled) {
        settingChanges.requestEmail = true;
    }

    if (setting?.requestEmail?.enabled) {
        if (
            setting?.requestEmail?.mandatory !== target?.requestEmail?.mandatory ||
            setting?.requestEmail?.displayMarketingOptIn !== target?.requestEmail?.displayMarketingOptIn
        ) {
            settingChanges.requestEmail = true;
        }

        if (setting?.requestEmail?.displayMarketingOptIn) {
            if (setting?.requestEmail?.organizationName !== target?.requestEmail?.organizationName) {
                settingChanges.requestEmail = true;
            }
        }
    }
};
const notesChangesCheck = (setting, target, settingChanges) => {
    if (setting?.notes?.enabled !== target?.notes?.enabled) {
        settingChanges.notes = true;
    }

    if (setting?.notes?.enabled) {
        if (setting?.notes?.type !== target?.notes?.type) {
            settingChanges.notes = true;
        }

        if (setting?.notes?.type === NotesSettingType.SetByMe) {
            if (setting?.notes?.notesValue !== target?.notes?.notesValue) {
                settingChanges.notes = true;
            }
        }
        else {
            if (setting?.notes?.notesCaption !== target?.notes?.notesCaption) {
                settingChanges.notes = true;
            }

            if (setting?.notes?.makeNotesMandatory !== target?.notes?.makeNotesMandatory) {
                settingChanges.notes = true;
            }
        }
    }
};
const thankYouNoteChangesCheck = (setting, target, settingChanges) => {
    if (setting?.thankYouNote?.enabled !== target?.thankYouNote?.enabled) {
        settingChanges.thankYouNote = true;
    }

    if (setting?.thankYouNote?.enabled && setting?.thankYouNote?.message !== target?.thankYouNote?.message) {
        settingChanges.thankYouNote = true;
    }
};
const giftAidChangesCheck = (setting, target, settingChanges) => {
    if (setting?.giftAid?.enabled !== target?.giftAid?.enabled) {
        settingChanges.giftAid = true;
    }
};

export const getSettingChanges = (setting, target) => {
    const settingChanges = {
        reference: false,
        expire: false,
        amount: false,
        requestEmail: false,
    }

    referenceChangesCheck(setting, target, settingChanges);
    expireChangesCheck(setting, target, settingChanges);
    amountChangesCheck(setting, target, settingChanges);
    emailChangesCheck(setting, target, settingChanges);
    notesChangesCheck(setting, target, settingChanges);
    thankYouNoteChangesCheck(setting, target, settingChanges);
    giftAidChangesCheck(setting, target, settingChanges);

    return settingChanges;
}
