import { useEffect, useRef } from 'react'

function useUpdateEffect(callback, deps) {
  const loaded = useRef(false);
  useEffect(() => {
    if (loaded.current) {
      callback()
    } else {
      loaded.current = true;
    }
  }, deps);
}

export default useUpdateEffect