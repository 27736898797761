import React, {useState, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useHistory } from 'react-router-dom';

import BankAccountDetails from "../BankAccountDetails";
import BankAccountRemoved from "../Modal/PinSetModal";

import './style.scss'

const BankAccountWrapper = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [bankAccountRemoved, setBankAccountRemoved] = useState(false);
  const { bank } = useSelector(state => ({
    bank: state.payment.bank,
  }));

  const timerToClearSomewhere = useRef(null)

  useEffect(() => {
    if(bankAccountRemoved){
      timerToClearSomewhere.current = setTimeout(() => {
        setBankAccountRemoved(false);
        closeBankAccountDetails(false);
      }, 10000)
    }
  }, [bankAccountRemoved]);

  const closeBankAccountDetails = () => {
    !bank?.accountNumber || history.location?.state?.includes('/payee-request') ? history.push('/home-screen') : history.push('/next');
  };

  const openBankAccountRemoved = () => {
    setBankAccountRemoved(true);
  };

  const closeBankAccountRemoved = () => {
    clearTimeout(timerToClearSomewhere.current)
    setBankAccountRemoved(false);
    closeBankAccountDetails();
  };

  return (
    <>
      {bankAccountRemoved && <BankAccountRemoved closeModal={closeBankAccountRemoved} title={"Bank Account Removed"} content={"Your bank account has now been removed from BOPP. To request a payment, go to 'Get paid' and add a bank account."}/>}
      {bank?.accountNumber &&
        <BankAccountDetails closeBankAccountDetails={closeBankAccountDetails} openBankAccountRemoved={openBankAccountRemoved}/>
      }
    </>
  );
}

export default BankAccountWrapper;
