import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import config from "../../config";

import BankItem from '../../components/BankItem';
import SlideItem from '../../components/SlideItem';
import colors from '../../assets/style.theme.scss';
import Search from '../../assets/images/search.svg';
import { filterIntegratedBanks } from '../../helpers';
import {PlatformType, StatusBank} from "../../static/CommonDefinitions";
import DraggableList from '../../components/DraggableList';
import CloseIcon from "../../assets/images/close-icon.svg";
import DefaultIcon from '../../assets/images/bank-icon.svg';
import Logo from '../../assets/images/bopp-logo.svg';
import DeleteButton from '../../assets/images/delete-rounded.svg';
import {
  getUsedBankList,
  changeIsAddBankAccount,
  getBankList,
  removeUsedBank,
  setUsedBank,
  removeUrlPayerBank,
  setStatusBarColor,
} from '../../actions';
import { useScrollPosition } from '../../hooks/useScrollPosition.js';
import './style.scss';

const ChooseBank = ({ closeChooseBankModal, payer = false, boppButton = false, aispDisabled = true, pispDisabled = true}) => {
  const [input, setInput] = useState('');
  const [list, setList] = useState([]);
  const [usedList, setUsedList] = useState([]);
  const usedBankList = useSelector((state) => state.payment.usedBankList);
  const bankList = useSelector((state) => state.payment.bankList);
  const payerBank = useSelector((state) => state.payment.payerBank);
  const platform = useSelector(state => state.settings.platform);
  const bankListRef = useRef();
  const bankListScroll = useScrollPosition(bankListRef.current);

  const dispatch = useDispatch();

  const otherBank = {
    accountName: 'Other',
    api: 'did:mia:ob:ss:general#other',
    status: 'healthy',
    update_time: '2021-04-12T15:24:26.786037524Z',
    friendly_name: 'Other',
    logo_uri: 'https://boppapp-dev.netlify.app/banklogos/defoult-icon-black.png',
    short_description: '',
  }

  let showBanksWithMissingFields = config.showBanksWithMissingFields;

  useEffect(
    () => {
      if(Object.keys(payerBank).length !== 0) {
        if(payerBank?.state?.paymentState) {
          dispatch(removeUrlPayerBank());
        }
      }
      dispatch(getBankList())
    }, [dispatch],
  );

  useEffect(() => {
    dispatch(getBankList());
    dispatch(getUsedBankList());
  }, [dispatch]);

  useEffect(
    () => {
      setList(payer ? filterIntegratedBanks(bankList) : bankList);
    },
    [bankList],
  );

  useEffect(() => {
    setUsedList(filterIntegratedBanks(usedBankList));
  }, [usedBankList]);

  useEffect(() => {
    if(payer) {
      dispatch(setStatusBarColor(colors.mainYellow));
      return () => dispatch(setStatusBarColor(colors.defaultBar));
    } else {
      dispatch(setStatusBarColor(colors.lightGrayBg));
    }
  }, []);

  const filterList = (e, clear) => {
    if (clear) {
      setInput('');
      setList([...list]);
    } else {
      setInput(e.target.value);
      let updatedList = [...bankList];
      if (updatedList.length) {
        updatedList = updatedList.filter(({ accountName }) => accountName.toLowerCase().includes(e.target.value.toLowerCase()));
      }
      setList([...updatedList]);
    }
  };

  const chooseBank = (bank) => {
    if(bank.status === StatusBank.Healthy){
      if(payer){
        dispatch(changeIsAddBankAccount(false));
        return closeChooseBankModal(bank);
      } else {
        dispatch(changeIsAddBankAccount(true));
        return closeChooseBankModal(bank);
      }
    }

    return false;
  };

  const deleteBank = (index) => {
    dispatch(removeUsedBank(index));
  };

  const getListOfBanks = () => {
    let predicate;

    if (!aispDisabled && !pispDisabled) {
      predicate = () => true;
    } else if (aispDisabled && pispDisabled) {
      predicate = () => false;
    } else if (!aispDisabled) {
      predicate = bank => {
        if (showBanksWithMissingFields) {
          return !bank?.aisp_disabled;
        } else {
          return bank?.aisp_disabled === false;
        }
      }
    } else if (!pispDisabled) {
      predicate = bank => {
        if (showBanksWithMissingFields) {
          return !bank?.pisp_disabled;
        } else {
          return bank?.pisp_disabled === false;
        }
      }
    }

    return list
        .filter(predicate)
        .sort((bank1, bank2) => bank1.friendly_name.localeCompare(bank2.friendly_name));
  };

  const getListOfUsedBanks = () => {
    let predicate;

    if (aispDisabled && !pispDisabled) {
      predicate = bank => {
        if (!bank) {
          return false;
        }

        if (showBanksWithMissingFields) {
          return !bank.pisp_disabled;
        } else {
          return bank.pisp_disabled === false;
        }
      }
    } else {
      predicate = () => false;
    }

    return usedList.filter(predicate)
  }

  return (
    <div id={platform === PlatformType.Desktop ? 'choose-bank-desktop' : ''} className={`choose-bank-modal ${payer ? 'pay' : 'request'} ${platform === PlatformType.Desktop ? 'boppbutton-choose-bank' : ''}`}>
      {platform === PlatformType.Desktop ? <></>: <div className={`choose-bank-modal-header ${bankListScroll.y > 0 ? 'choose-bank-modal-header--with-shadow' : ''} `}>
        <div className="modal-bank-title">Choose Bank</div>
        <img onClick={() => closeChooseBankModal(null)} className="close-button" src={CloseIcon} alt=""/>

        {payer ?
            (<div className="modal-bank-search">
              <img src={Search} alt=""/>
              <input
                  type="text "
                  value={input}
                  onChange={filterList}
                  placeholder="Search.."
              />
            </div>) :
            (<div className="modal-bank-search-wrapper">
              <div className="search-request">
                <img src={Search} alt=""/>
                <input
                    type="text "
                    value={input}
                    onChange={filterList}
                    placeholder="Search.."
                />
              </div>
            </div>)
        }
      </div>
      }

      <div ref={bankListRef} className={`modal-bank-list ${payer ? 'pay' : 'request'} ${platform === PlatformType.Desktop ? 'boppbutton-choose-bank' : ''}`}>
        {platform === PlatformType.Desktop &&  <img onClick={() => closeChooseBankModal(null)} className="close-button" src={CloseIcon} alt=""/>}
        {platform === PlatformType.Desktop && <div className="modal-bank-search-wrapper">
          <div className="search-request boppbutton-search">
            <img src={Search} alt=""/>
            <input
                type="text "
                value={input}
                onChange={filterList}
                placeholder="Search a bank.."
            />
          </div>
        </div>
        }
        { getListOfUsedBanks().length > 0 &&
          <div className="modal-bank-frequently-used">
            <div className="modal-bank-frequently-used-title">Frequently used</div>
            <DraggableList
              className="bank-items"
              list={getListOfUsedBanks()}
              onOrderChanged={(prevIndex, currentIndex) => {
                const orderedUsedBankList = [...usedList]
                const [bank] = orderedUsedBankList.splice(prevIndex, 1)
                orderedUsedBankList.splice(currentIndex, 0, bank)
                dispatch(setUsedBank(orderedUsedBankList))
              }}
              itemKey={bank => bank.accountName}
              render={({item: bank, index, drag}) => (
                <SlideItem
                  right={() => (
                  <div onClick={() => deleteBank(index)} className="bank-item-delete">
                    <img src={DeleteButton} alt="delete" />
                  </div>
                  )}
                >
                  <BankItem
                    bankStatus={bank.status}
                    bankLogo={bank.logo_uri}
                    defaultLogo={DefaultIcon}
                    bankName={bank.friendly_name}
                    chooseBank={() => chooseBank(bank)}
                    frequentingList={true}
                    onHold={drag}
                  />
                </SlideItem>
              )}
            />
          </div>
        }
        <div className="bank-items">
          {
            Boolean(list.length)
            && getListOfBanks().map((bank, index) => (
              <BankItem
                key={index}
                bankStatus={bank.status}
                bankLogo={bank.logo_uri}
                defaultLogo={DefaultIcon}
                bankName={bank.friendly_name}
                chooseBank={() => chooseBank(bank)}
              />
            ))
          }
          { pispDisabled && 
            <BankItem
              bankStatus={otherBank.status}
              bankLogo={otherBank.logo_uri}
              defaultLogo={DefaultIcon}
              bankName={otherBank.friendly_name}
              chooseBank={() => chooseBank(otherBank)}
            />
          } 
        </div>
      </div>
    </div>
  );
};

export default ChooseBank;
