// outsource dependencies
import html2canvas from "html2canvas";
import { useHistory, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from 'classnames';

// local dependencies
import Amount from "../../components/Amount";
import Button from "../../components/Button";
import Menu from "../../assets/images/menu.png";
import colors from '../../assets/style.theme.scss';
import Close from '../../assets/images/close-icon.svg';
import QRCodeBlock from "../../components/QRCodeBlock";
import { AmountLabelType, ReferenceSettingType, TermType } from "../../static/CommonDefinitions";
import HeaderUniversal from "../../components/HeaderUniversal";
import {
    callShare,
    clearPaylink,
    shareQrCode,
    toggleMenu,
    setStatusBarColor,
    stopPingPong
 } from "../../actions";

import './style.scss';
import { updateOneTimeSettings } from "../../sagas/paymentRequestSettingsSaga";

const RequestPage = () => {
    const plinkurl = process.env.REACT_APP_SETTING_PAYLINK_URL;
    const history = useHistory();
    const dispatch = useDispatch();
    const { id: requestId } = useParams();

    const bank = useSelector(state => state.payment.bank);
    const amount = useSelector(state => state.payment.amount);
    const requests = useSelector(state => state.history.requests);
    const otp = useSelector(state => state.payment.otp ? state.payment.otp.password : null);
    const oneTimePaymentSettings = useSelector(state => state.paymentRequestSettings.oneTimePaymentSettings);
    const [paylink, setPaylink] = useState('');
    const [fixedAmount, setFixedAmount] = useState(true);
    const [isInHistory, setIsInHistory] = useState(requestId.startsWith('cri:') ? true : false);
    const defaultPaymentSettings = useSelector(state => state.paymentRequestSettings.defaultPaymentSettings);

    const url = paylink ? plinkurl + '/link' + paylink.split('bopp.link')[1] : '';
    const targetRequest = isInHistory && requests.find(request => request.ids.paymentRequestId === requestId);
    let showAmountInLinkMessage = false;

    if (targetRequest) {
        showAmountInLinkMessage = targetRequest.paymentSettings?.amountTerm === TermType.FixedAmount || targetRequest.paymentSettings?.amountTerm === TermType.MinAmount;
    }
    else {
        showAmountInLinkMessage = oneTimePaymentSettings.amount.type === TermType.FixedAmount || oneTimePaymentSettings.amount.type === TermType.MinAmount;
    }

    useEffect(() => {
       let paylink = isInHistory
        ? `paylink://bopp.link/${targetRequest?.paylink.split('/').pop()}`
        : `paylink://bopp.link/${window.location.pathname.split('/').pop()}`;
       setPaylink(paylink);
    }, [window.location]);

    useEffect(() => {
        if(isInHistory) {
            if(targetRequest?.paymentSettings?.amountTerm === TermType.AnyAmount){
                setFixedAmount(false);
            }
        } else if(oneTimePaymentSettings.termType === TermType.AnyAmount) {
            setFixedAmount(false);
        }
    }, [oneTimePaymentSettings]);

    useEffect(() => {
        dispatch(setStatusBarColor(colors.mainYellow));
        return () => {
            dispatch(stopPingPong())
        };
    }, []);

    const closePage = () => {
        dispatch(updateOneTimeSettings(defaultPaymentSettings));
        dispatch(clearPaylink());
        history.goBack();
    };

    const sharePaylink = () => {
        dispatch(callShare({
            title: "BOPP",
            message: `Hi, please click on the link below to pay me${showAmountInLinkMessage ? ` £${targetRequest?.requestedAmount?.value || amount}` : ''} securely from your bank using BOPP.  Thanks, ${targetRequest?.account?.accountName || bank.accountName}`,
            url,
            subject: "New BOPP Payment Request"
        }));
    };

    const handleSaveQRCode = () => {
        let qr = document.querySelector('.qrcode-wrapper').children[0];
        html2canvas(qr).then((canvas) => {
            dispatch(shareQrCode(canvas.toDataURL()));
        });
    };

    const openMenu = () => {
        dispatch(toggleMenu());
    };

    const bankName = targetRequest?.account?.accountName
        || bank.accountNickName ? bank.accountNickName : bank.accountName;

    const bankNameStyles = classNames({
        'request-bank-name': true,
        'fs-14': bank?.accountNickName?.length > 30
            || bank?.accountName?.length > 30
            || targetRequest?.account?.accountName?.length > 30,
    });

    const refClasses = classNames({
        'request-ref': true,
        'request-ref--with-fixed-amount': oneTimePaymentSettings.amount.type === TermType.FixedAmount,
        'request-ref--with-any-amount': oneTimePaymentSettings.amount.type === TermType.AnyAmount,
    })

    return(
        <>
        <div className="request-wrapper">
            <div className="request-wrapper__content">
                <HeaderUniversal
                    rightButtonIcon={Menu}
                    leftButtonIcon={Close}
                    leftButtonEvent={closePage}
                    rightButtonEvent={openMenu}
                />
                <div className="request-wrapper-block">
                    <div className={bankNameStyles}>
                        {bankName}
                    </div>
                    <Amount
                        fixedAmount={fixedAmount}
                        assignedAmount={targetRequest?.requestedAmount?.value !== undefined ? Number(targetRequest?.requestedAmount?.value).toFixed(2) : undefined}
                        labelType={AmountLabelType.Solid}
                    />
                    {
                        oneTimePaymentSettings.reference.type !== ReferenceSettingType.SetByMe ?
                            <></> :
                            <div className={refClasses}>
                                { isInHistory ? targetRequest?.paymentSettings?.reference : oneTimePaymentSettings.reference.setByMeValue }
                            </div>
                    }
                </div>
                <QRCodeBlock paylink={paylink} otp={otp} />
            </div>
            <div className="request-wrapper__buttons">
                <Button color="yellow" title="Share Paylink" handleClick={sharePaylink} />
                <div className="request-save-qr" onClick={handleSaveQRCode}>
                    Save QR Code
                </div>
            </div>
        </div>
        </>
    )
};

export default RequestPage;
