import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

import {
  openNewPage,
  changeAmount,
  clearPaylink,
  setStatusBarColor,
  showLimitReached,
  getAccountLimit,
  setAccountLimit
} from '../../actions';
import colors from '../../assets/style.theme.scss';
import './style.scss';

import CloseIcon from '../../assets/images/close-icon.svg';
import Logo from '../../assets/images/bopp-logo.svg';
import WarningIcon from "../../assets/images/warning.svg";


import HeaderUniversal from '../../components/HeaderUniversal';

const LimitReached = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(setStatusBarColor(colors.defaultBar));

    return () => {
      dispatch(changeAmount('0.00'));
      dispatch(showLimitReached(null));
      dispatch(clearPaylink());
    }
  }, [])

  const closeScreenIcon = () => {
    history.push('next');
  }

  return (
    <div className="account-deactivated">
      <HeaderUniversal
        logo={Logo}
        rightButtonIcon={CloseIcon}
        rightButtonEvent={closeScreenIcon}
        customStylesRightBtn={{ width: '32px', height: '32px', top: '41px' }}
      />
        <div className="messages">
          <img className="image" src={WarningIcon} alt="Failure icon" />

          <p className="title">
            Pay request limit reached
          </p>

          <p className="text">
            You are requesting an amount that exceeds the limit set for your account. To learn more about pay request limits visit our FAQ page.
          </p>

          <p onClick={() => { dispatch(openNewPage("https://help.bopp.io/portal/en/kb/articles/do-you-have-limits-for-payment-requests")) }} className="link">
            Learn More
          </p>
        </div>
    </div>
  )
}

export default LimitReached;
