import moment from "moment";
import { common } from ".";
import { paymentRequestSettings } from './paymentSettings';
import config from "../config";
import { createId, createAmount } from "../helpers";
import { AccountType, PaymentTrigger, ReferenceSettingType, TermType } from "../static/CommonDefinitions";

interface createRequest extends common.Activation  {
    entitySelector:common.EntitySelector
    properties: paymentrequest.Properties
}

interface PaymentMethod {
    methodType: string
    properties: PaymentMethodProperties
}

interface PaymentMethodProperties {
    "@type": string
    endToEndIdentification: string
    name: string
    accountNumber?: string /** @deprecated */
    paymentReference: string
    sortCode?:string /** @deprecated */
    payId: string
    subscriptionType: AccountType
    metainfo: string
    accountDID: string
}

interface OtpOptions {
    type: string
    calculationStatus: string
    digits: number
    mappingExpiry: Date
    interval: number
}

interface ActivationLifetime {
    activationDate: string
    expiryDate?: string
}

interface PaymentTerms {
    termsType: string
    paymentTrigger: PaymentTrigger
    amountTerm: AmountTerm
    paymentMethods: PaymentMethod[]
}

interface AmountTerm {
    termType: TermType
    properties: AmountTermProperties
    canBeExecutedXTimes?: number
}

interface AmountTermProperties {
    "@type": string
    min?: common.Amount
    max?: common.Amount
    range?: common.Amount[]
    allowOther?: boolean
}

declare namespace paymentrequest {
    interface Properties {
        "@type": string
        activationLifetime: ActivationLifetime
        parties: common.Parties
        requestType: string
        amount: common.Amount
        paymentTerms :PaymentTerms
        otpOptions :OtpOptions
    }

    interface Request {
        "@type": string
        activationLifetime: ActivationLifetime
        parties: common.Parties
        requestType: string
        amount: common.Amount
        paymentTerms :PaymentTerms
        otpOptions :OtpOptions
    }
}

const MIN_PAYMENT_AMOUNT = "0.01"
const MAX_PAYMENT_AMOUNT = "99999.99"

export class CreatePaymentRequest {
    params:any
    settings:any
    paymentPayeeSubscriberIds:any
    amount:string
    bank:any
    
    constructor(paymentParams :any) {
        this.params = paymentParams
        this.settings = this.params.settings
        this.paymentPayeeSubscriberIds = this.params.paymentPayeeSubscriberIds
        this.amount = this.params.payload.amount
        this.bank = this.params.bank
    }

    getPaymentRequest() {
        return this.getPaymentRequestParams()
    }

    getPaymentRequestParams() : createRequest {
        return  {
            "@type": "https://dvschema.io/activation#CreateRequest",
            id: createId(),
            entitySelector: this.getEntitySelector(),
            properties: this.getPaymentRequestProperties(),
            timestamp: new Date(),
            possibleResend: false
        }
    }

    getEntitySelector() : common.EntitySelector {
        return {
            entityType: "https://miapago.io/paylink/request/PaylinkRequest"
        }
    }

    getPaymentRequestProperties() : paymentrequest.Properties {
        return {
            "@type": "https://miapago.io/paylink/request#Properties",
            activationLifetime: this.getActivationLifetime(),
            parties: this.getParties(),
            requestType: "Original",
            amount: this.getAmount(),
            paymentTerms: this.getPaymentTerms(),
            otpOptions:this.getOtpOptions()
          }
    }

    getActivationLifetime() : ActivationLifetime {
        let activationLifetime: ActivationLifetime = {
            activationDate: moment().format("YYYY-MM-DD"),
        }
        
        if(this.settings.expire.onCertainDate) {
            activationLifetime.expiryDate = moment(this.settings.expire.certainDate).set({hour: 23, minute: 59, second: 59,}).format("YYYY-MM-DD");
        }

        return activationLifetime
    }

    getParties() : common.Parties {
        return  {
            "payment:Payee": this.paymentPayeeSubscriberIds.payee || 'cri:NTgRQ2DIfWWuQIlromAFnR' ,
            "payment:Subscriber": this.paymentPayeeSubscriberIds.subscriber || 'cri:NTgRQ2DIfWWuQIlromAFnR'
        }
    }

    getAmount() : common.Amount {
        return  createAmount(this.amount)
    }

    getPaymentTerms() : PaymentTerms {
        return  {
            termsType: "Instant",
            paymentTrigger: this.getPaymentTrigger(),
            amountTerm: this.getAmountTerm(),
            paymentMethods: [this.getPaymentMethod()]
        }
    }

    getPaymentTrigger() : PaymentTrigger {
        let expireType = this.settings.expire.type
        if(expireType === PaymentTrigger.XInstructions){
            expireType = PaymentTrigger.EachInstruction
        }

        return expireType
    }

    getAmountTerm() : AmountTerm {
        let terms:AmountTerm = {
            termType: this.getAmountTermType(),
            properties: this.getAmountTermProperties(),
        }

        if(this.settings.expire.xInstructionsValue !== '') {
            terms.canBeExecutedXTimes =  Number(this.settings.expire.xInstructionsValue)
        }

        return terms
    }

    getAmountTermType() : TermType {
        let amountTerm = this.settings.amount.type
        if(this.settings.amount.type == TermType.MinAmount) {
            amountTerm = TermType.SuggestedAmount
        } 

        return amountTerm
    }

    getAmountTermProperties() : AmountTermProperties {
        switch (this.settings.amount.type) {
            case TermType.AnyAmount:
                return {
                    "@type": "https://miapago.io/paymentterms/terms/v0.1.0#AnyAmount",
                    "min": createAmount(MIN_PAYMENT_AMOUNT),
                    "max": createAmount(MAX_PAYMENT_AMOUNT)
                }
            case TermType.MinAmount:
                return {
                    "@type": "https://miapago.io/paymentterms/terms/v0.1.0#SuggestedAmount",
                    "min": createAmount(this.amount),
                    "max": createAmount(MAX_PAYMENT_AMOUNT),
                    "range": [createAmount(this.amount)],
                    "allowOther": true
                }
            case TermType.SuggestedAmount:
                return {
                    "@type": "https://miapago.io/paymentterms/terms/v0.1.0#SuggestedAmount",
                    "min": createAmount(MIN_PAYMENT_AMOUNT),
                    "max": createAmount(MAX_PAYMENT_AMOUNT),
                    "range": [createAmount(this.amount)],
                    "allowOther": true
                }
            default:
                return {
                    "@type": "https://miapago.io/paymentterms/FixedAmountProperties"
                }
        }
    }
    
    getPaymentMethod() : PaymentMethod {
        return {
            methodType: "OpenBankingDomesticPayment",
            properties : this.getPaymentMethodProperties()
        }
    }

    getPaymentMethodProperties() : PaymentMethodProperties {
        const metainfo = this.getPaymentSettingsObject();

        return {
            "@type": "https://miapago.io/ob/domesticpayment#Properties",
            endToEndIdentification: "BOPP App",
            name: this.bank?.accountName || "No name",
            paymentReference: metainfo.reference.value,
            payId: "",
            subscriptionType: this.getSubscriptionType(),
            metainfo: JSON.stringify(metainfo),
            accountDID: this.getAccountDID()
        }
    }

    getPaymentReferenceFromReferenceSettings() : string {
        switch (this.settings.reference.type) {
          case ReferenceSettingType.SetByMe:
            return this.settings.reference.setByMeValue
          case ReferenceSettingType.SetByPayer:
            return ""
          default :
            return `autonumber:${this.settings.reference.autonumberValue}`; 
        }
    }

    getSubscriptionType() : AccountType {
        if(this.bank.subscriptionType) {
            return this.bank.subscriptionType
        } else {
            return AccountType.Personal
        }
    }

    getPaymentSettingsObject() : paymentRequestSettings.paymentSettingsMetainfo {
        let gad = this.settings.giftAid
        if(this.paymentPayeeSubscriberIds.giftAidPublicKey !== "") {
          let giftAidData = {
            payee:{publicKey: this.paymentPayeeSubscriberIds.giftAidPublicKey}
          }
          gad = {...this.settings.giftAid, ...giftAidData}
        }
      
        let res = {
            encryption:{
                serviceProvider: {
                    pk:"bopp-public-key"
                },
                payer:{
                    pk:"payer-public-key"
                },
                payee: {
                    pk:"payee-public-key"
                }
            },
            reference: {
                type: this.settings.reference.type,
                value: this.getPaymentReferenceFromReferenceSettings(),
            },
            notes: this.settings.notes,
            amount: this.settings.amount,
            requestEmail: this.settings.requestEmail,
            thankYouNote: this.settings.thankYouNote,
            giftAid: gad
            }
      
        return res;
    }

    getAccountDID() : string {
        if(this.bank.accountDID) {
            let did = this.bank.accountDID.replace(/did:mia:ob:account:/g,'') 
            return 'did:mia:ob:account:' + did
        } else {
            let did = this.bank.id.replace(/did:mia:ob:account:/g,'') 
            return "did:mia:ob:account:" + did
        }
    }

    getOtpOptions() : OtpOptions {
        return {
            type: "TOTP",
            calculationStatus: "Map",
            digits: 6,
            mappingExpiry: moment().add(5, "m").toDate(),
            interval: config.otpIntervalInSec * 1000000000  // interval in micro seconds golang time.Duration
        }
    }

    getStatusScreenDetails() {
        return {
            amount: this.amount,
            accountName: this.params.payload.accountName,
            orderNumber: this.getPaymentReferenceFromReferenceSettings(),
            giftAid: this.settings.giftAid,
            thankYouNote: this.settings.thankYouNote,
        }
    }
}