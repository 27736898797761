import { toBool } from '../utils';

const usedEnvVariables = [
  process.env.REACT_APP_INITIAL_HOME,
  process.env.REACT_APP_AIS_SERVER_URL,
  process.env.REACT_APP_PAYLINK_SERVER_URL,
  process.env.REACT_APP_OTP_INTERVAL_SEC,
  process.env.REACT_APP_DISSABLE_BANK_MODAL_HIDE,
  process.env.REACT_APP_ENABLE_CLOSE_BUTTON_FOR_STATUS_SCREEN_IN_BROWSER,
  process.env.REACT_APP_STATUS_WATCHER_FIRST_QUERY,
  process.env.REACT_APP_STATUS_WATCHER_INTERVAL,
  process.env.REACT_APP_STATUS_WATCHER_STOP_AFTER_N_ATTEMPTS,
  process.env.REACT_APP_ENV,
  process.env.REACT_APP_KYC_FORM_ENABLED,
  process.env.REACT_APP_ZOHO_FORM_URL,
  process.env.REACT_APP_ZOHO_ACCOUNTS_API_KEY,
  process.env.REACT_APP_ZOHO_ACCOUNTS_SUBSCRIPTIONS_API_KEY,
  process.env.REACT_APP_ZOHO_ACCOUNTS_PAYEE_DETAILS_API_KEY,
  process.env.REACT_APP_SUBSCRIPTION_SERVICE_URL,
  process.env.REACT_APP_ZOHO_SERVICE_URL,
  process.env.REACT_APP_BOPP_BUTTON_PROXY_SERVER,
  process.env.REACT_APP_POSITION_KEEPER_URL,
  process.env.REACT_APP_POSITION_KEEPER_API_KEY,
  process.env.REACT_APP_UCB_REDIRECT,
  process.env.REACT_APP_MERCHANT_DASHBOARD_SERVER_URL,
  process.env.REACT_APP_SHOW_BANKS_WITH_MISSING_FIELDS,
  process.env.REACT_APP_SETTING_PAYLINK_URL,
]

export const isEnvSetupProperly = () => !usedEnvVariables.includes(undefined);

const config = {
  initialHome: process.env.REACT_APP_INITIAL_HOME,
  accountsServerURL: `${process.env.REACT_APP_AIS_SERVER_URL}/ad`,
  //paylinkServerURL: `${process.env.REACT_APP_PAYLINK_SERVER_URL}/api/paylink?x-bopp-api-key=${process.env.REACT_APP_API_KEY}`,
  paylinkServerURL: `${process.env.REACT_APP_PAYLINK_SERVER_URL}/api/paylink`,
  otpIntervalInSec: +process.env.REACT_APP_OTP_INTERVAL_SEC ,
  dissableBankModaleHide: toBool(process.env.REACT_APP_DISSABLE_BANK_MODAL_HIDE),
  enableCloseButtonForStatusScreenInBrowser: toBool(process.env.REACT_APP_ENABLE_CLOSE_BUTTON_FOR_STATUS_SCREEN_IN_BROWSER),
  statusWatcherFirstQuery: +process.env.REACT_APP_STATUS_WATCHER_FIRST_QUERY,
  statusWatcherInterval: +process.env.REACT_APP_STATUS_WATCHER_INTERVAL,
  statusWatcherStopAfterNAttempts: +process.env.REACT_APP_STATUS_WATCHER_STOP_AFTER_N_ATTEMPTS,
  appEnvironment: process.env.REACT_APP_ENV,
  kycFormEnabled: toBool(process.env.REACT_APP_KYC_FORM_ENABLED),
  zohoFormURL: process.env.REACT_APP_ZOHO_FORM_URL,
  zohoAccountsApiKey: process.env.REACT_APP_ZOHO_ACCOUNTS_API_KEY,
  zohoAccountsSubscriptionApiKey: process.env.REACT_APP_ZOHO_ACCOUNTS_SUBSCRIPTIONS_API_KEY,
  zohoAccountsPatyeeDetailsApiKey: process.env.REACT_APP_ZOHO_ACCOUNTS_PAYEE_DETAILS_API_KEY,
  subscriptionServiceUrl: process.env.REACT_APP_SUBSCRIPTION_SERVICE_URL,
  zohoServiceUrl: process.env.REACT_APP_ZOHO_SERVICE_URL,
  boppButtonProxy: process.env.REACT_APP_BOPP_BUTTON_PROXY_SERVER,
  positionKeeperUrl:  process.env.REACT_APP_POSITION_KEEPER_URL,
  positionKeeperApiKey: process.env.REACT_APP_POSITION_KEEPER_API_KEY,
  ucbRedirectUrl:  process.env.REACT_APP_UCB_REDIRECT,
  dashboardServerUrl: `${process.env.REACT_APP_MERCHANT_DASHBOARD_SERVER_URL}`,
  showBanksWithMissingFields: toBool(process.env.REACT_APP_SHOW_BANKS_WITH_MISSING_FIELDS),
  boppAppUrl:  process.env.REACT_APP_SETTING_PAYLINK_URL,
  wsApiKey: process.env.REACT_APP_API_KEY
};

export default config;
