import { ScreenType, PermissionType, PlatformType } from '../static/CommonDefinitions'

/**
 * @param {screen name to check which permission needed } screen 
 * @param {platform to determine correct permission needed} platform 
 */
export const getPermissionTypeForScreenAndPlatform = (screen: ScreenType, platform: PlatformType) => {
    if(screen === ScreenType.QrScanner) {
        return PermissionType.Camera
    } else if(screen === ScreenType.BluetoothPayOthers) {
        if(platform === PlatformType.Android) {
            return PermissionType.Location
        } else if(platform === PlatformType.Ios) {
            return PermissionType.Bluetooth
        } else {
            return PermissionType.None
        }
    } else if(screen === ScreenType.BluetoothGetPaid) {
        if(platform === PlatformType.Ios) {
            return PermissionType.Bluetooth
        } else {
            return PermissionType.None
        }
    } else {
        return PermissionType.None
    }
};