import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPermissionStatus } from "../sagas/permissonSaga";
import { getPermissionTypeForScreenAndPlatform } from "../helpers/permissionHelper";

const usePagePermission = (screen) => {
    const dispatch = useDispatch();
    const platform = useSelector(state => state.settings.platform);

    const requiredPermission = getPermissionTypeForScreenAndPlatform(screen, platform);

    const permissionStatus = useSelector((state) => state.permissions[requiredPermission]);

    useEffect(() => {
        dispatch(getPermissionStatus(requiredPermission));
    }, [dispatch, requiredPermission]);

    return { requiredPermission, permissionStatus };
}

export default usePagePermission;