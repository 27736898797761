import React, {useEffect} from 'react'
import {useDispatch} from "react-redux";
import { useHistory } from 'react-router-dom';

import SuccessIcon from '../../assets/images/success.svg';
import CloseIcon from '../../assets/images/close-icon.svg';
import Logo from '../../assets/images/bopp-logo.svg';
import './style.scss'

import {
    setCheckEmailStatus,
    updateBank,
    setStatusBarColor,
    setCheckUserAuthCodeStatus,
} from '../../actions';
import Button from '../../components/Button';
import colors from '../../assets/style.theme.scss';
import { cleanTimer } from '../../sagas/kycWatcher';
import { AuthCodeStatuses } from '../../reducers/kycReducer';
import HeaderUniversal from '../../components/HeaderUniversal';
import {KYCVerificationStatus} from '../../static/CommonDefinitions';

function BusinessAccountVerified() {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(setStatusBarColor(colors.defaultBar));
        dispatch(updateBank({ bankAccountStatus: KYCVerificationStatus.Verified }));
        dispatch(cleanTimer());

        return () => {
            dispatch(setCheckEmailStatus(null))
            dispatch(setCheckUserAuthCodeStatus(AuthCodeStatuses.notChecked));
        }
    }, [])

    const closeScreen = () => {
        history.push('/home-screen');
    }

    const pageDetails = {
        icon: SuccessIcon,
        title: 'Success',
        message: 'You have successfully activated your BOPP Account on this device and you can start sending payment requests.',
    }

    return (
        <>
            <div className="business-verifi-page">
                <HeaderUniversal
                    logo={Logo}
                    rightButtonIcon={CloseIcon}
                    rightButtonEvent={closeScreen}
                    customStylesLogo={{top: '30px'}}
                    customStylesRightBtn={{ width: '32px', height: '32px', top: '33px' }}
                />

                <div className="verify-content">
                    <div className="message-block">
                        <img width={64} height={64} src={pageDetails.icon}/>
                        <h3>{pageDetails.title}</h3>
                        <div className="message">
                            {pageDetails.message}
                        </div>
                    </div>
                    <div className={"bottom-button"}>
                        <Button title="Got It" handleClick={closeScreen} color="black"/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BusinessAccountVerified
