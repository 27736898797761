export const SET_BOPP_BUTTON = "SET_BOPP_BUTTON";
export const ADD_LAST_PAYMENT = 'ADD_LAST_PAYMENT';
export const SET_PAYMENT_REQUEST = 'SET_PAYMENT_REQUEST';
export const SET_LAST_PAYMENT_INSTRUCTION_ID = "SET_LAST_PAYMENT_INSTRUCTION_ID";

export const setBoppButton = (payload) => ({
  type: SET_BOPP_BUTTON,
  payload
});

export const addLastPayment = (payload) => ({
  type: ADD_LAST_PAYMENT,
  payload,
});

export const setPaymentRequest = (payload) => ({
  type: SET_PAYMENT_REQUEST,
  payload
});

export const setLastPaymentInstructionId = (payload) => ({
  type: SET_LAST_PAYMENT_INSTRUCTION_ID,
  payload
});

export const initialState = {
  boppButton: false,
  lastPayment: null,
  paymentRequest: null,
  lastPaymentInstructionId:''
};

export const paymentPersistReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LAST_PAYMENT_INSTRUCTION_ID:
      return {
        ...state,
        lastPaymentInstructionId: action.payload
      };
    case SET_PAYMENT_REQUEST:
      return {
        ...state,
        paymentRequest: action.payload
      };
    case SET_BOPP_BUTTON:
      return {
        ...state,
        boppButton: action.payload
      };
    case ADD_LAST_PAYMENT:
      return {
        ...state,
        lastPayment: {
          ...action.payload
        }
      };
    default:
      return state;
  };
}

export default paymentPersistReducer;
