import React, {
    useState, 
    useEffect, 
    useRef
} from "react";
import {
    useDispatch, 
    useSelector
} from "react-redux";
import {useHistory} from 'react-router-dom';
import NumPanel from "../../components/NumPanel";
import {
    selectPaymentRequest,
    showInvalidPaymentError,
    setPaymentRequestMethod,
    toggleMenu,
    setPaymentInstructionMethod,
    showCamera,
    setStatusBarColor
} from "../../actions";
import spinner from '../../assets/images/spinner.svg';
import Menu from '../../assets/images/menu-bars.svg';
import CloseIcon from '../../assets/images/close-icon.svg';
import colors from '../../assets/style.theme.scss';
import './index.scss';
import ErrorModal from "../Modal/ErrorModal";
import useCountdown from "../../hooks/useCountdown";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import HeaderUniversal from "../../components/HeaderUniversal";
import { sendStatusRequestMessageAction } from "../../resolver/payment/statusRequestProcessor";

export default function NumericCode() {
    const dispatch = useDispatch();
    const history = useHistory();

    const inputs = useRef([])

    const { numericModalShown } = useSelector(state => ({numericModalShown: state.payment.numericModalShown}))
    const { paymentRequest } = useSelector(state => ({paymentRequest: state.paymentPersist.paymentRequest}));
    const [code, setCode] = useState('');
    const [isDisabled, setIsDisabled] = useState(Boolean(localStorage.getItem('disabledUntil')))
    const [countdown, startCountdown] = useCountdown()

    const [titleError, setTitle] = useState('Invalid Numeric Code');
    const [contentError, setContent] = useState('Sorry, we’re unable to find payment request associated with this numeric code.');
    const [spinnerOn, setSpinnerOn] = useState(false);
    const spinnerTimeOut = useRef();

    useEffect(()=> {
        dispatch(setPaymentRequestMethod('paylink', 'otp'))
        dispatch(setStatusBarColor(colors.mainYellow));
    }, []);

    useEffect(() => {
        for (const [index, input] of inputs.current.entries()) {
            input.value = code[index] || ''
        }
        selectFreeInput();
    }, [code])

    useUpdateEffect(() => {
        if (countdown <= 0) {
            setIsDisabled(false)
        }
    }, [countdown])

    useEffect(() => {
        if (isDisabled) {
            const disableUntil = localStorage.getItem('disabledUntil')
            if (disableUntil) {
                const interval = Math.round((Number(disableUntil) - Date.now()) / 1000)
                if (interval > 0) startCountdown(interval)
                else setIsDisabled(false)
            } else {
                startCountdown(30)
                localStorage.setItem('disabledUntil', Date.now() + 30000)
            }

        } else {
            localStorage.removeItem('disabledUntil')
        }
    }, [isDisabled])

    const closeModalError = () => {
        dispatch(showInvalidPaymentError(false))
        setCode('')
        for (const [index, input] of inputs.current.entries()) {
            input.value = ''
        }
    }

    const handlePayRequest = () => {
        spinnerTimeOut.current = setTimeout(() => setSpinnerOn(true), 1000)
        const otp = code
        dispatch(sendStatusRequestMessageAction({ otp, isPayment:true })); 
        dispatch(selectPaymentRequest({
            paymentMethod: {
                methodType: 'BoppPaylink',
                properties: { otp },
            },
        }))
    }

    useEffect(() => {
        if(paymentRequest?.paylinkUrl) {
            history.push('/request-details/' + paymentRequest.paylinkUrl.split('/').pop())
        }
    }, [paymentRequest?.paylinkUrl])

    useEffect(() => {
        if(numericModalShown) {
            setSpinnerOn(false)
            clearTimeout(spinnerTimeOut.current)
            const currentCounter = Number(localStorage.getItem('modalCounter')) || 0;
            if (currentCounter + 1 === 5) {
                localStorage.setItem('modalCounter', 0)
                setIsDisabled(true)
            } else {
                localStorage.setItem('modalCounter', currentCounter + 1)
            }
        }
    }, [numericModalShown])

    useEffect(() => {
        if(code.length === 6) {
            handlePayRequest();
        }
    }, [code]);

    const selectFreeInput = () => {
        if (inputs.current.length > 0) {
            if (code.length < inputs.current.length) {
                inputs.current[code.length].focus()
            } else {
                inputs.current[inputs.current.length - 1].blur()
            }
        }
    }

    const handleDigitInput = (digit) => {
        setCode(code + digit)
    }

    const handleBackSpace = () => {
        setCode(code.slice(0, -1))
    }

    const handleClean = () => {
        setCode('')
    }

    const onCloseButton = () => {
        dispatch(showCamera());
        dispatch(setPaymentInstructionMethod('paylink', 'qr'));
        history.goBack();
    };

    return (
        <div className="numeric-code-page">
            <HeaderUniversal
                title="Enter numeric code to pay"
                rightButtonIcon={Menu}
                rightButtonEvent={() => {dispatch(toggleMenu())}}
                leftButtonIcon={CloseIcon}
                leftButtonEvent={onCloseButton}
            />

            <div className="numeric-code-content">
                <div onClick={selectFreeInput} className="numeric-code-page__container">
                    {Array.from({length: 6}, (_, index) => (
                        <input disabled={isDisabled} key={index} ref={ref => inputs.current[index] = ref} name={index} maxLength="1" readOnly/>
                    ))}
                </div>
                {isDisabled && <div className="numeric-code-page-countdown">Try again in {countdown} seconds</div>}
                <div className="numeric-code-page__keyboard">
                    <NumPanel
                        disabled={isDisabled}
                        numericCode
                        onInput={handleDigitInput}
                        onBackSpace={handleBackSpace}
                        onClean={handleClean}
                    />
                </div>
                { spinnerOn && <img className="numeric-code-page-spinner" alt="spinner" src={spinner} />}
            </div>
            {
                numericModalShown && <ErrorModal closeModal={closeModalError} title={titleError} content={contentError} />
            }
        </div>
    );
}