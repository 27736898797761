import React from "react";
import BoppLogo from "../../../assets/images/bopp-logo.svg";
import CloseIcon from "../../../assets/images/close-icon.svg";
import CircleFailedICon from "../../../assets/images/circle-failed-icon.svg";
import {useHistory} from "react-router-dom";
import "./styles.scss";

function ActivationFailed() {
    const history = useHistory()

    const backToSetBankScreen = () => {
        history.push('/home-screen', 'show-retrive');
    }

    return(
        <div className="activation-screen">
            <div className="activation-screen__content">
                <div className="activation-screen__content-header">
                    <img className="activation-screen__content-header-logo" src={BoppLogo} alt="BoppLogo"/>
                    <img src={CloseIcon} alt="Close" onClick={backToSetBankScreen}/>
                </div>
                <div className="activation-screen__content-main">
                    <div className="activation-screen__content-main-icon">
                        <img src={CircleFailedICon} alt="FailedIcon"/>
                    </div>
                    <div className="activation-screen__content-main-title">Activation failed</div>
                    <div className="activation-screen__content-main-subtitle">Unfortunately, we cannot activate your account at this time as the information supplied does not correspond to the information received from your bank.  Please try again with a different bank account.</div>
                </div>
            </div>
            <div className="activation-screen__footer">
                <p onClick={backToSetBankScreen}>Try Again</p>
            </div>
        </div>
    )
}

export default ActivationFailed;
