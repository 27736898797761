import {MessageType} from '../static/CommonDefinitions'
import config from '../config';
import {resolveAISPIncomingMessageAction} from './aispProcessor'
import {getResponseType, resolvePISPIncomingMessageAction, ResponseMessageType} from './payment/pispProcessor'

export interface IncomingMessagePayload {
    message: any,
}

export function resolveMessage(response: any, type: any, dispatch: any) {
    if(type === MessageType.Server) {
        if(response.socketUrl.includes(config.paylinkServerURL)){
            dispatch(resolvePISPIncomingMessageAction(response.message))
        } else {
            if(response.message.nonintegrated) {
                dispatch(resolveAISPIncomingMessageAction(response))
            } else {
                dispatch(resolveAISPIncomingMessageAction(response.message))
            }
        }
    } else if(type === MessageType.App) {
        console.log("RECEIVED MESSAGE from app")
    } else if(type === MessageType.Ulink) {
        console.log("RECEIVED MESSAGE from ulink")
    }
}