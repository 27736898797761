import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { numberWithCommas } from "../../helpers";
import Calendar from "../../assets/images/calendar-v2.svg";
import "./style.scss";

const PaymentSettingInput = (props) => {
  const canShowError =  useSelector(state => state.paymentRequestSettings.paymentSettingError);
  const {type = "text", splitWithCommas, value, onChange, onBlur, description, placeholder, validator, mandatory, inputClassName} = props;

  const handleChange = (e) => {
    const finalValue = splitWithCommas ? e.target.value.split(',').join('') : e.target.value;
    if (!validator || validator(finalValue)) {
      onChange(finalValue);
    }
  }

  const error = mandatory && canShowError && !value?.length;
  const innerInputClassName = `payment-setting-input__input ${error ? 'payment-setting-input__input--error' : ''} ${inputClassName}`;

  const renderInput = () => {
    switch (type) {
      case "text":
        return <input
          type={type}
          value={value}
          onBlur={onBlur}
          onChange={handleChange}
          placeholder={placeholder}
          className={innerInputClassName}
        />
      case "textarea":
        return <textarea
          value={value}
          onBlur={onBlur}
          onChange={handleChange}
          placeholder={placeholder}
          className={innerInputClassName + " payment-setting-input__input--textarea"}
        />
      case "number":
        return <input
          type={splitWithCommas ? 'text' : type}
          value={splitWithCommas ? numberWithCommas(value || "") : value}
          onBlur={onBlur}
          onChange={handleChange}
          placeholder={placeholder}
          className={innerInputClassName}
        />
      case "date":
        const date = value || new Date();
        return <div className="payment-setting-input__input-wrap">
          <img src={Calendar} className="payment-setting-input__input-icon" />
          <input
            type={type}
            min={props.minDate ? moment(props.minDate).format("yyyy-MM-DD") : undefined}
            value={moment(date).format("yyyy-MM-DD")}
            onBlur={onBlur}
            onChange={handleChange}
            className={innerInputClassName}
          />
        </div>
      default:
        return <></>
    }
  }

  return (
    <div className="payment-setting-input">
      <div className="payment-setting-input__inner">
        {renderInput()}
        <span className="payment-setting-input__description">{description}</span>
      </div>
    </div>
  )
}

export default PaymentSettingInput;
