export function toBool(value) {
  if (!value) {
    return false;
  }

  value = value.toString();
  value = value.trim();
  value = value.toLowerCase();

  const TRUTHY_VALUES = 'y yes true'.split(/\s/);

  if (!value.length) {
    return false;
  } if (!isNaN(Number(value))) {
    return value > 0;
  }
  return TRUTHY_VALUES.indexOf(value) >= 0;

};

export function lastNameMatch(bankAccountName, lastName) {
  return bankAccountName.toLowerCase().replace(/-/g, ' ').split(' ').find( item => item === lastName.toLowerCase()) !== undefined;
}

export function copyToClipboardInBrowser(text) {
  if (!navigator.clipboard) {
    copyTextUsingExecCommand(text);
  }
  else {
    navigator.clipboard.writeText(text).catch(() => copyTextUsingExecCommand(text));
  }
}

function copyTextUsingExecCommand(text) {
  const input = document.createElement("input");

  input.value = text;
  input.style.position="fixed";
  document.body.appendChild(input);
  input.select();
  document.execCommand('copy');
  document.body.removeChild(input)  
}
