import { sendError } from '../errorResolver';
import { 
    takeLatest, 
    put,
    select
} from 'redux-saga/effects';

import { 
    ErrorFlow, 
    ErrorObject 
} from '../../entities/errorObject';
import { getPropertyFrom } from '../../helpers/objectHelper';
import { updateCreateInstructionState } from '../../reducers/pispSlice';
import { createId, wrap } from '../../helpers';
import { IncomingMessagePayload } from '../messageResolver';
import { changePaymentStatusScreen, changeWrongScreen, clearPaylinkUrl, showInvalidPaymentError } from '../../actions';
import { addLastPayment, setLastPaymentInstructionId } from '../../reducers/paymentPersistReducer';
import { ActivationState } from '../../static/CommonDefinitions';
import { paylinkStatusData } from '../../reducers/statusSlice';
import { push } from 'connected-react-router';

export const RESOLVE_INCOMING_MESSAGE = 'resolve/cancel/incomming/message';
export interface ResolveCancelIncomingMessage { type: typeof RESOLVE_INCOMING_MESSAGE; payload: IncomingMessagePayload }
export const resolveCancelIncomingMessageAction = (payload: IncomingMessagePayload): ResolveCancelIncomingMessage => ({
    type: RESOLVE_INCOMING_MESSAGE,
    payload,
})


function* resolveCancelIncomingMessage({ payload }: ResolveCancelIncomingMessage): any {
    try {
        let state = getPropertyFrom(payload, 'state', 'activationState', true)
        if(getState(state) === ActivationState.Cancelled) {
            const transactionId = getPropertyFrom(payload, 'state', 'id', true);
            const paylink = getPropertyFrom(payload, 'properties.paymentMethod', 'paylink', true);
            
            const savedPaylinks:paylinkStatusData[] = yield select(state => state.paylink.paylinks);
            const selectedPaylink = savedPaylinks.find(element => element.paylink === paylink)

            if(selectedPaylink !== undefined) {
                let payeeName = selectedPaylink?.details?.payeeName
                let firstPaymentAmount = selectedPaylink?.details?.firstPaymentAmount?.value
                let paymentReference = selectedPaylink?.details?.paymentReference

                let statusScreenDetails = {
                    amount: firstPaymentAmount,
                    accountName:  payeeName,
                    orderNumber: paymentReference,
                    transactionId: transactionId,
                    redirectUrls: selectedPaylink?.details?.redirectUrls,
                    isSuccess:false
                }
            
                yield put(addLastPayment(statusScreenDetails));
                yield put(changePaymentStatusScreen({ paymentInProcess: false , waitingForCancel:false, cancelled: false, transferringToBank:false, waitingForConsent:false}));
                yield put(push('/so-status'))
            }
        }
    } catch (error) {
        yield put(sendError({error:new ErrorObject('Error cancel', error, ErrorFlow.payments, payload)}));
        yield put(changeWrongScreen(true));
    }
}

function getState(activationState: string): ActivationState {
    const status = activationState.substring(0, activationState.indexOf(':'));
    if(status === 'Cancelled') {
        return ActivationState.Cancelled
    } else {
        return ActivationState.Activated
    }
}

export function* cancelProcessor({paylinkSocket}: any) {
  yield takeLatest(RESOLVE_INCOMING_MESSAGE, resolveCancelIncomingMessage);
}