// outsource dependencies
import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

// local dependencies
import Wallet from '../../assets/images/transparent-wallet.svg';
import Calendar from "../../assets/images/calendar-v2.svg";
import CalendarDate from "../../assets/images/calendar-date.svg";
import Reference from '../../assets/images/reference-v2.svg';
import CloseIcon from '../../assets/images/close-icon.svg';
import EmailIcon from "../../assets/images/transparent-email.svg";
import NotesIcon from "../../assets/images/notes.svg";
import GiftIcon from "../../assets/images/gift-aid.svg";
import InfoIcon from '../../assets/images/menu/info-circle.svg';
import SuccessIcon from "../../assets/images/success.svg";
import Button from "../../components/Button";
import PaymentSettingsItem from "../../components/PaymentSettingsItem";
import {
    settingChangesDefaultValue,
    setExpireDateIsSetToPast,
    setPaymentSettingError,
    setDefaultPaymentSettings,
} from "../../reducers/paymentRequestSettingsReducer";
import { setStatusBarColor, saveInApp } from "../../actions";
import colors from '../../assets/style.theme.scss';
import { AccountType, NotesSettingType, PaymentTrigger, ReferenceSettingType, TermType } from './../../static/CommonDefinitions'
import PaymentSetting from "../../components/PaymentSetting";
import PaymentSettingInput from "../../components/PaymentSettingInput";
import { isPaymentReferenceValid, numberWithCommas } from "../../helpers";
import { getSettingChanges } from "../../helpers/getSettingChanges";
import { updateOneTimeSettings } from "../../sagas/paymentRequestSettingsSaga";
import TickboxBlock from "../../components/TickboxBlock";
import Popup from "../../components/Popup";
import moment from "moment";
import './style.scss';

const defaultSettingsState = {
    reference: {
        type: ReferenceSettingType.SetByMe,
        setByMeValue: 'BOPP payment',
        autonumberValue: '',
    },
    expire: {
        type: PaymentTrigger.OnInstruction,
        xInstructionsValue: '',
        onCertainDate: false,
        certainDate: null,
    },
    amount: {
        type: TermType.FixedAmount,
    },
    requestEmail: {
        enabled: false,
        mandatory: false,
        displayMarketingOptIn: false,
        organizationName: '',
    },
    notes: {
        enabled: false,
        type: NotesSettingType.SetByMe,
        notesValue: '',
        notesCaption: '',
        makeNotesMandatory: false,
    },
    thankYouNote: {
        enabled: false,
        message: '',
    },
    giftAid: {
        enabled: false,
    }
};

const defaultChangesState = {
    reference: false,
    expire: false,
    amount: false,
    requestEmail: false,
    notes: false,
    thankYouNote: false,
    giftAid: false,
}

const PaymentRequestSettings = ({ defaultSettings = false }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [unsavedPaymentSettings, setUnsavedPaymentSettings] = useState(defaultSettingsState);
    const [unsavedSettingChanges, setUnsavedSettingChange] = useState(defaultChangesState);


    const oneTimePaymentSettings = useSelector(state => state.paymentRequestSettings.oneTimePaymentSettings);
    const oneTimeSettingChanges = useSelector(state => state.paymentRequestSettings.oneTimeSettingChanges);

    const defaultPaymentSettings = useSelector(state => state.paymentRequestSettings.defaultPaymentSettings);
    
    const bank = useSelector(state => state.payment.bank);

    const [showPopup, setShowPopup] = useState(false);

    const isBusinessOrCharity = bank.subscriptionType === AccountType.Business || bank.subscriptionType === AccountType.Charity;

    const hasChanged = Object.values(unsavedSettingChanges).includes(true);

    useEffect(() => {
        setUnsavedPaymentSettings(oneTimePaymentSettings);
    }, []);

    useEffect(() => {
        dispatch(setStatusBarColor(colors.lightGrayBg));
    }, []);

    const clearUnsavedSettings = () => {
        setUnsavedPaymentSettings(defaultPaymentSettings);
        setUnsavedSettingChange(settingChangesDefaultValue);
    }

    const validateSettings = () => {
        if (unsavedPaymentSettings.reference.type === ReferenceSettingType.SetByMe && !unsavedPaymentSettings.reference.setByMeValue) {
            return false;
        }

        if (unsavedPaymentSettings.expire.type === PaymentTrigger.XInstructions && !unsavedPaymentSettings.expire.xInstructionsValue) {
            return false;
        }

        if (unsavedPaymentSettings.notes.enabled) {
            if (unsavedPaymentSettings.notes.type === NotesSettingType.SetByMe && !unsavedPaymentSettings.notes.notesValue) {
                return false;
            }
        }

        if (unsavedPaymentSettings.thankYouNote.enabled) {
            if (!unsavedPaymentSettings.thankYouNote.message) {
                return false;
            }
        }

        return true;
    }

    const saveChangeSettings = () => {
        if (!validateSettings()) {
            dispatch(setPaymentSettingError(true));
            return;
        }

        dispatch(setPaymentSettingError(false));

        if(defaultSettings) {
            handleSaveDefaultSettings();
        } else {
            dispatch(updateOneTimeSettings(unsavedPaymentSettings));
            clearUnsavedSettings();
        }

        updateExpireDateIsSetToPast();
        handleClosePage();
    };

    const handleClosePage = (showPopup = false) => {
        if (hasChanged && showPopup) {
            setShowPopup(true);
        }
        else {
            close(true);
        }
    };

    const close = () => {
        history.goBack();
        clearUnsavedSettings();
    }

    const handleSaveDefaultSettings = () => {
        if(hasChanged) {
            if (!validateSettings()) {
                dispatch(setPaymentSettingError(true));
                return;
            }

            dispatch(setPaymentSettingError(false));
            dispatch(setDefaultPaymentSettings(unsavedPaymentSettings));
            dispatch(updateOneTimeSettings(unsavedPaymentSettings));
            clearUnsavedSettings();

            updateExpireDateIsSetToPast();

            handleClosePage();
        }
    };

    const updateExpireDateIsSetToPast = () => {
        if (unsavedPaymentSettings.expire.onCertainDate) {
            if (unsavedPaymentSettings.expire.certainDate !== oneTimePaymentSettings.expire.certainDate) {
                dispatch(setExpireDateIsSetToPast(false));
            }
        }
        else {
            dispatch(setExpireDateIsSetToPast(false));
        }
    }

    const handleSettingsChange = (changed) => {
        const unsavedChanges = { ...unsavedPaymentSettings, ...changed };
        setUnsavedPaymentSettings(unsavedChanges);
        const changes = getSettingChanges(unsavedChanges, oneTimePaymentSettings);
        setUnsavedSettingChange(changes);
    }

    return(
        <>
            <div className="payment-settings">
                <div>
                    <img className="close-icon" src={CloseIcon} onClick={handleClosePage} alt='close'/>
                    <h4>Customise the way you ask to get paid</h4>
                    <h5>Update the settings to meet your needs:</h5>
                    <ReferenceSetting
                        changed={oneTimeSettingChanges.reference}
                        settings={unsavedPaymentSettings}
                        onSettingsChange={(changed) => {
                            handleSettingsChange(changed);
                        }}
                    />
                    <ExpireSetting
                        changed={oneTimeSettingChanges.expire}
                        settings={unsavedPaymentSettings}
                        onSettingsChange={(changed) => {
                            handleSettingsChange(changed);
                        }}
                    />
                    <PaymentAmountSetting
                        changed={oneTimeSettingChanges.amount}
                        settings={unsavedPaymentSettings}
                        onSettingsChange={(changed) => {
                            handleSettingsChange(changed);
                        }}
                    />
                    {isBusinessOrCharity &&
                        <>
                            <RequestEmailSetting
                                changed={oneTimeSettingChanges.requestEmail}
                                settings={unsavedPaymentSettings}
                                onSettingsChange={(changed) => {
                                    handleSettingsChange(changed);
                                }}
                            />
                            <NotesSetting
                                changed={oneTimeSettingChanges.notes}
                                settings={unsavedPaymentSettings}
                                onSettingsChange={(changed) => {
                                    handleSettingsChange(changed);
                                }}
                            />
                            <ThankYouNoteSetting
                                changed={oneTimeSettingChanges.thankYouNote}
                                settings={unsavedPaymentSettings}
                                onSettingsChange={(changed) => {
                                    handleSettingsChange(changed);
                                }}
                            />
                        </>
                    }
                    {bank.subscriptionType === AccountType.Charity &&
                        <GiftAidSetting
                            changed={oneTimeSettingChanges.giftAid}
                            settings={unsavedPaymentSettings}
                            onSettingsChange={(changed) => {
                                handleSettingsChange(changed);
                            }}
                        />
                    }
                    <div className="menu-button">
                        <img src={InfoIcon} alt="InfoIcon" />
                        <div className="menu-button__text">
                            <span className='limit-text'>BOPP accounts are subject to account limits.</span>
                            <a
                                target="_blank"
                                href='https://help.bopp.io/portal/en/kb/articles/do-you-have-limits-for-payment-requests'
                            className='limit-link'>
                                Learn more
                            </a>
                        </div>
                    </div>
                </div>
                <div className="payment-settings__button">
                    <Button
                        title={ defaultSettings ? 'Save default settings' : 'Apply to this request' }
                        handleClick={saveChangeSettings}
                        disabled={!hasChanged}
                        className="payment-settings-save-button"
                        color="yellow"
                    />
                        {!defaultSettings ? <div className="payment-settings-default" onClick={handleSaveDefaultSettings} >
                        Save as default settings
                    </div> : null}
                </div>
            </div>
            {
                showPopup &&
                <Popup
                    title='Unsaved changes'
                    description='Are you sure you want to leave this page? The changes you made will not be saved.'
                    bottomContent={
                        <div className='payment-settings__popup-content'>
                            <Button title='Back to page' color='black' handleClick={() => setShowPopup(false)} />
                            <span className='payment-settings__popup-link' onClick={close}>Leave anyway</span>
                        </div>
                    }
                />
            }
        </>
    )
}

const ReferenceSetting = ({settings, onSettingsChange, changed}) => {
    const getDescription = () => {
        switch(settings.reference.type) {
            case ReferenceSettingType.SetByMe:
                return 'Set by me: ' + settings.reference.setByMeValue;
            case ReferenceSettingType.SetByPayer:
                return "Set by payer";
            case ReferenceSettingType.Autonumber:
                return settings.reference.autonumberValue ?
                    <span>Autonumber<br/>Prefix: {settings.reference.autonumberValue}</span> :
                    "Autonumber";
        }
    }

    const validateAutonumber = (value) => {
        if (!value.length) {
            return true;
        }

        const lettersNumbers = new RegExp(/^[\w]+$/);
        return value.length <= 8 && lettersNumbers.test(value);
    }

    return (
        <PaymentSettingsItem
            differenceSettings={changed}
            img={Reference}
            title='Payment Reference'
            description={getDescription()}
            openTriggerDescription='Payment reference (visible on bank statement)'
        >
            <PaymentSetting
                handleRadioChange={(e) => {
                    onSettingsChange({reference: {...settings.reference, type: e.target.value }})
                }}
                value={settings.reference.type}
                type="radio"
                name='reference'
                radioOptions={[
                    {
                        value: ReferenceSettingType.SetByMe,
                        label: ["Set by me"],
                        checkedContent:
                            <PaymentSettingInput
                                mandatory
                                value={settings.reference.setByMeValue}
                                placeholder="Set payment reference…"
                                description="Max 18 chars - letters, numbers and spaces only"
                                validator={isPaymentReferenceValid}
                                onChange={(value) => onSettingsChange({reference: {...settings.reference, setByMeValue: value }})}
                                onBlur={(e) => {
                                    if (e.target.value[e.target.value.length - 1] === ' ') {
                                        const withoutLastSpace = e.target.value.substring(0, e.target.value.length - 1);
                                        onSettingsChange({reference: {...settings.reference, setByMeValue: withoutLastSpace }});
                                    }
                                }}
                            />
                    },
                    {
                        value: ReferenceSettingType.SetByPayer,
                        label: ["Set by payer"],
                    },
                    {
                        value: ReferenceSettingType.Autonumber,
                        label: ["Autonumber", "Each request will be assigned a unique no. You can add a prefix below."],
                        checkedContent:
                            <PaymentSettingInput
                                value={settings.reference.autonumberValue}
                                placeholder="Add request prefix…"
                                description="Max 8 chars - letters and numbers only"
                                validator={validateAutonumber}
                                onChange={(value) => onSettingsChange({reference: {...settings.reference, autonumberValue: value }})}
                            />
                    },
                ]}
            />
        </PaymentSettingsItem>
    )
}

const ExpireSetting = ({settings, onSettingsChange, changed}) => {
    const getDescription = () => {
        let firstLine = '';

        switch(settings.expire.type) {
            case PaymentTrigger.OnInstruction:
                firstLine = "Single use";
                break;
            case PaymentTrigger.EachInstruction:
                firstLine = "Multi use";
                break
            case PaymentTrigger.XInstructions:
                const xPayments = settings.expire.xInstructionsValue;
                firstLine =  `After ${xPayments ? numberWithCommas(xPayments) : 'X'} payment${xPayments === 1 ? '' : 's'}`;
                break;
        }

        if(settings.expire.onCertainDate) {
            return <span>{firstLine}<br/>Or on {moment(settings.expire.certainDate).format('MMM DD, YYYY')}</span>
        }

        return firstLine;
    }

    const handleRadioChange = (e) => {
        onSettingsChange({expire: {...settings.expire, type: e.target.value }});
    }

    const handleSwitchChange = (newValue) => {
        onSettingsChange({expire: {...settings.expire, onCertainDate: newValue, certainDate: moment(new Date()).format("yyyy-MM-DD") }});
    }

    const validate = (value) => !value.toString().length || (value > 0 && value <= 100000);

    return (
        <PaymentSettingsItem
            differenceSettings={changed}
            img={Calendar}
            title='Expiry Settings'
            description={getDescription()}
            openTriggerDescription='Decide when your payment request should expire.'
        >
            <PaymentSetting
                handleRadioChange={handleRadioChange}
                value={settings.expire.type}
                type="radio"
                name='expire'
                radioOptions={[
                    {
                        value: PaymentTrigger.OnInstruction,
                        label: ["Single use", "Your request is active until someone pays it. Once paid the Paylink (or QR code) cannot be used again."],
                    },
                    {
                        value: PaymentTrigger.EachInstruction,
                        label: ["Multi use", "Your pay request stays open unless cancelled or reaches its maximum pay limit. Multiple people can pay using the same Paylink or QR code."],
                    },
                    {
                        value: PaymentTrigger.XInstructions,
                        label: ["After X payments", "Payment request stays open until you receive X payments against this request or max pay limit is reached."],
                        checkedContent:
                            <PaymentSettingInput
                                mandatory
                                splitWithCommas
                                type="number"
                                validator={validate}
                                value={settings.expire.xInstructionsValue}
                                placeholder="Set number of payments…"
                                description="Max 100 000 only numbers allowed."
                                onChange={(value) => onSettingsChange({expire: {...settings.expire, xInstructionsValue: value }})}
                            />
                    },
                ]}
            />
            <PaymentSetting
                value={settings.expire.onCertainDate}
                type="switch"
                name="onCertainDate"
                handleSwitchChange={handleSwitchChange}
                switchOptions={{
                    icon: CalendarDate,
                    title: "On a certain date",
                    id: "onACertainDate",
                    description: "Request will expire on a certain date even if it hasn’t been paid yet.",
                    content:
                        <PaymentSettingInput
                            type="date"
                            minDate={new Date()}
                            value={settings.expire.certainDate}
                            onChange={(value) => onSettingsChange({expire: {...settings.expire, certainDate: value }})}
                        />
                }}
            />
        </PaymentSettingsItem>
    )
}

const PaymentAmountSetting = ({settings, onSettingsChange, changed}) => {
    const getDescription = () => {
        switch(settings.amount.type) {
            case TermType.FixedAmount:
                return "Fixed";
            case TermType.AnyAmount:
                return "Set by payer";
            case TermType.SuggestedAmount:
                return "Suggested";
            case TermType.MinAmount:
                return "Min Amount";
        }
    }

    return (
        <PaymentSettingsItem
            differenceSettings={changed}
            img={Wallet}
            title='Payment Amount'
            description={getDescription()}
            openTriggerDescription='Choose whether you determine the payment amount or the payer.'
        >
            <PaymentSetting
                handleRadioChange={(e) => {
                    onSettingsChange({amount: {...settings.amount, type: e.target.value }})
                }}
                value={settings.amount.type}
                type="radio"
                name='amount'
                radioOptions={[
                    {
                        value: TermType.FixedAmount,
                        label: ["Fixed", "You set the amount and the payer cannot change."],
                    },
                    {
                        value: TermType.AnyAmount,
                        label: ["Set by payer", "Payer chooses the amount."],
                    },
                    {
                        value: TermType.SuggestedAmount,
                        label: ["Suggested", "You set the desired amount but the payer can change (increase or decrease) it."],
                    },
                    {
                        value: TermType.MinAmount,
                        label: ["Min Amount", "You set the minimum required amount for the payment but the payer can increase it."],
                    },
                ]}
            />
        </PaymentSettingsItem>
    )
}

const RequestEmailSetting = ({settings, onSettingsChange, changed}) => {
    const validateOrganizationName = (name) => {
        const regExp = new RegExp(/^[\w\s&'-]+$/g);
        return !name.length || (name.length <= 30 && regExp.test(name));
    }

    return (
        <PaymentSettingsItem
            differenceSettings={changed}
            img={EmailIcon}
            title='Request Email'
            description={settings.requestEmail.enabled ? "Enabled" : "Disabled"}
            openTriggerDescription='Payer will be asked to supply their email.'
        >
            <PaymentSetting
                handleSwitchChange={(value) => {
                    onSettingsChange({requestEmail: {...settings.requestEmail, enabled: value }})
                }}
                value={settings.requestEmail.enabled}
                type="switch"
                name='requestEmail'
                switchOptions={{
                    title: "Request email from payer",
                    id: "emailRequest",
                }}
            />
            {
                settings.requestEmail.enabled &&
                <>
                    <PaymentSetting
                        value={settings.requestEmail.enabled}
                        type="tickbox"
                        name='requestEmailTickbox'
                        tickboxOptions={[
                            {
                                checked: settings.requestEmail.mandatory,
                                onChange: (value) => onSettingsChange({requestEmail: {...settings.requestEmail, mandatory: value}}),
                                title: "Mandatory",
                                id: "Mandatory",
                                description: "User won’t be able to pay if they don’t supply email."
                            },
                            {
                                checked: settings.requestEmail.displayMarketingOptIn,
                                onChange: (value) => onSettingsChange({requestEmail: {...settings.requestEmail, displayMarketingOptIn: value}}),
                                title: "Display marketing opt-in",
                                id: "DisplayMarketing",
                                description: "Payers will be asked to opt-in to your marketing communications. You can add your organisation name to our default consent message, otherwise we will use you bank account name.",
                                checkedContent:
                                    <PaymentSettingInput
                                        type="text"
                                        placeholder="Your organisation name.."
                                        description="Max 70 chars"
                                        validator={validateOrganizationName}
                                        value={settings.requestEmail.organizationName}
                                        onChange={(value) => onSettingsChange({requestEmail: {...settings.requestEmail, organizationName: value }})}
                                    />
                            }
                        ]}
                    />
                </>
            }
        </PaymentSettingsItem>
    )
}

const NotesSetting = ({settings, onSettingsChange, changed}) => {
    const getDescription = () => {
        return settings.notes.enabled ? 'Enabled' : 'Disabled';
    }

    const validateNotesValue = (value) => {
        const regexp = new RegExp(/^[\w\s.,:;()\"\'\&\#\!\?\%]+$/);
        return !value.length || (value.length <= 200 && regexp.test(value));
    }

    const validateNotesCaption = (value) => {
        const regexp =  new RegExp(/^[\w\s]+$/);
        return !value.length || (value.length <= 100 && regexp.test(value))
    }

    return (
        <PaymentSettingsItem
            differenceSettings={changed}
            img={NotesIcon}
            title='Notes'
            description={getDescription()}
            openTriggerDescription='Notes can be added for additional communication about the payment.'
        >
            <PaymentSetting
                handleSwitchChange={(value) => {
                    onSettingsChange({notes: {...settings.notes, enabled: value }})
                }}
                value={settings.notes.enabled}
                type="switch"
                name='notesSwitcher'
                switchOptions={{
                    title: "Enable notes",
                    id: "enableNotes"
                }}
            />
            {settings.notes.enabled && <PaymentSetting
                    handleRadioChange={(e) => {
                        onSettingsChange({notes: {...settings.notes, type: e.target.value }})
                    }}
                    value={settings.notes.type}
                    type="radio"
                    name='notesRadioss'
                    radioOptions={[
                        {
                            value: NotesSettingType.SetByMe,
                            label: ["Set by me", "Attach additional message for payer"],
                            showSubtitleOnlyIfChecked: true,
                            checkedContent:
                                <PaymentSettingInput
                                    mandatory
                                    type="textarea"
                                    value={settings.notes.notesValue}
                                    placeholder="Add notes..."
                                    description="Max 200 characters allowed."
                                    validator={validateNotesValue}
                                    onChange={(value) => onSettingsChange({notes: {...settings.notes, notesValue: value }})}
                                    inputClassName="payment-settings__notes-input"
                                />
                        },
                        {
                            value: NotesSettingType.SetByPayer,
                            label: ["Set by payer", "Ask the payer to send additional information to you, for example ‘indicate shoes size’ or ‘type in name of child’. This information will not appear in the bank transaction, just in BOPP."],
                            showSubtitleOnlyIfChecked: true,
                            checkedContent:
                                <>
                                    <PaymentSettingInput
                                        type="textarea"
                                        value={settings.notes.notesCaption}
                                        placeholder="Caption for payer..."
                                        description="Max 100 characters allowed."
                                        validator={validateNotesCaption}
                                        onChange={(value) => onSettingsChange({notes: {...settings.notes, notesCaption: value }})}
                                        inputClassName="payment-settings__notes-caption-input"

                                    />
                                    <TickboxBlock
                                        checked={settings.notes.makeNotesMandatory}
                                        onChange={(value) => onSettingsChange({notes: {...settings.notes, makeNotesMandatory: value}})}
                                        title="Make notes mandatory - user won’t be able to pay if they don’t supply notes."
                                        className="payment-settings__notes-tickbox-block"
                                        id="MakeNoteMandatory"
                                    />
                                </>
                        },
                    ]}
                />}
        </PaymentSettingsItem>
    )
}

const ThankYouNoteSetting = ({settings, onSettingsChange, changed}) => {
    const validateMessage = (value) => {
        const regexp = new RegExp(/^[\w\s.,:;()\"\'\&\#\!\?\%]+$/);
        return !value.length || (value.length <= 80 && regexp.test(value));
    }

    return (
        <PaymentSettingsItem
            differenceSettings={changed}
            img={SuccessIcon}
            title='Thank You Note'
            description={settings.thankYouNote.enabled ? "Enabled" : "Disabled"}
            openTriggerDescription='You can add a message that will be displayed to Payer after they complete payment.'
        >
            <PaymentSetting
                handleSwitchChange={(value) => {
                    onSettingsChange({thankYouNote: {...settings.thankYouNote, enabled: value }})
                }}
                value={settings.thankYouNote.enabled}
                type="switch"
                name='thankYouNoteSwitch'
                switchOptions={{
                    headerClassName: settings.thankYouNote.enabled ? "payment-settings__thank-you-note-header" : '',
                    title: "Show Thank you note",
                    id: "showThankYouNote",
                    content:
                        <div className="payment-settings__thank-you-note-content">
                            <PaymentSettingInput
                                mandatory
                                type="textarea"
                                value={settings.thankYouNote.message}
                                placeholder="Add thank you message..."
                                description="Max 80 characters"
                                validator={validateMessage}
                                onChange={(value) => onSettingsChange({thankYouNote: {...settings.thankYouNote, message: value }})}
                                inputClassName="payment-settings__thank-you-note-input"
                            />
                        </div>
                }}
            />
        </PaymentSettingsItem>
    )
}

const GiftAidSetting = ({settings, onSettingsChange, changed}) => {
    return (
        <PaymentSettingsItem
            differenceSettings={changed}
            img={GiftIcon}
            title='Gift Aid'
            description={settings.giftAid.enabled ? "Enabled" : "Disabled"}
            openTriggerDescription='Turn on this setting to request payers’ details required for participation in Gift Aid.'
        >
            <PaymentSetting
                handleSwitchChange={(value) => {
                    onSettingsChange({giftAid: {...settings.giftAid, enabled: value }})
                }}
                value={settings.giftAid.enabled}
                type="switch"
                name='giftAid'
                switchOptions={{
                    title: "Request details for Gift Aid",
                    id: "requestGiftAid",
                    headerClassName: "payment-settings__gift-aid-header",
                }}
            />
        </PaymentSettingsItem>
    )
}

export default PaymentRequestSettings;
