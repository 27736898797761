import { AdapterState, AdapterType } from '../static/CommonDefinitions'

const ADAPTER_SET_STATE = 'adapter/set/state';

export const setAdapterState = (adapter, status) => ({
    type: ADAPTER_SET_STATE,
    payload: {
        adapter,
        status
    }
});

export const initialState = {
    bluetooth : AdapterState.PoweredOff,
    location : AdapterState.PoweredOff
};

export const hardwareReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADAPTER_SET_STATE:
            if(action.payload.adapter === AdapterType.Bluetooth) {
                // check is adapted is 'PoweredOn' in other cases we set it to 'PoweredOff' as we don't handle other adapter states, so we need to know only is adapter is ready to use
                if(action.payload.status === AdapterState.PoweredOn) {
                    return {
                        ...state,
                        bluetooth: AdapterState.PoweredOn,
                    };
                } else {
                    return {
                        ...state,
                        bluetooth: AdapterState.PoweredOff,
                    };
                }
            } else if(action.payload.adapter === AdapterType.Location) {
                if(action.payload.status === AdapterState.PoweredOn) {
                    return {
                        ...state,
                        location: AdapterState.PoweredOn,
                    };
                } else {
                    return {
                        ...state,
                        location: AdapterState.PoweredOff,
                    };
                }
            } else {
                return {
                    ...state
                };
            }
        default:
        return {
            ...state,
        };
    }
};

export default hardwareReducer;