import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import colors from '../../assets/style.theme.scss';
import Menu from '../../assets/images/menu-bars.svg';
import CloseIcon from '../../assets/images/close-icon.svg';
import HeaderUniversal from "../../components/HeaderUniversal";
import { setStatusBarColor, getPaymentsHistory, toggleMenu } from "../../actions";
import { PaymentReportState } from '../../static/CommonDefinitions';
import { formatAmount } from '../../helpers';
import CrossIco from '../../assets/images/cancel-transaction.svg';
import ArrowIncomeIco from '../../assets/images/incoming-transaction.svg';
import MostRecentIcon from '../../assets/images/most-recent-payment-icon.svg';
import PendingIncomeIco from '../../assets/images/pending-transaction.svg';
import History from "../../assets/images/payment-history-empty.svg";
import './style.scss';
import ReactPullToRefresh from 'react-pull-to-refresh'
import Genericon from './Genericons.svg'

let stopLoading = null

const LastReceipt = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const lastReceipts = useSelector(state =>state.history.lastReceipts);
    const isUpdateRequestSent = useSelector(state =>state.history.isUpdateRequestSent);
    const bank = useSelector(state => state.payment.bank);
    const [lastRefreshTime, setlastRefreshTime] = useState(new Date(+0));
    const [isRefreshQueued, setIsRefreshQueued] = useState(false);
    let sortedReciepts = lastReceipts.sort(function(a,b){
        return new Date(b.activityTime) - new Date(a.activityTime);
    });

    let first = sortedReciepts[0]
    let second = sortedReciepts[1]
    let third = sortedReciepts[2]
    
    useEffect(() => {
        sendHistoryRequest()
        dispatch(setStatusBarColor(colors.defaultBar));
    }, []);

    useEffect(() => {
        sortedReciepts = lastReceipts.sort(function(a,b){
            return new Date(a.activityTime) - new Date(b.activityTime)
        });

        first = sortedReciepts[0]
        second = sortedReciepts[1]
        third = sortedReciepts[2]
    }, [lastReceipts]);

    useEffect(() => {
        if(stopLoading && !isUpdateRequestSent) {
            stopLoading()
        }
    }, [isUpdateRequestSent]);

    const handleRefresh = (resolve, reject) => {
        stopLoading = resolve;
        sendHistoryRequest(resolve)
    }

    const sendHistoryRequest = (resolve) => {
        if(bank?.accountNumber) {
            let timePassedFrtomLastRequest = moment().unix() - moment(lastRefreshTime).unix()
            if (timePassedFrtomLastRequest > 2) {
                dispatch(getPaymentsHistory())
                setlastRefreshTime(new Date());
            } else {
                if(!isRefreshQueued) {
                    setIsRefreshQueued(true)
                    setTimeout(() => {
                        console.log("sending request")
                        dispatch(getPaymentsHistory())
                        setIsRefreshQueued(false)
                    }, timePassedFrtomLastRequest * 1000);
                }
            }
        } else {
            if(resolve) {
                resolve()
            }
        }
    }

    const onCloseButton = () => {
        history.push('/next');
    };

    const getTransactionIcon = (payment) => {
        if(payment.reportState === PaymentReportState.Activated ||
        payment.reportState === PaymentReportState.Confirmed) {
            return ArrowIncomeIco;
        } else if(payment.reportState === PaymentReportState.PendingAccept) {
            return PendingIncomeIco;
        } else {
            return CrossIco;
        }
    }

    const renderLatestPayment = (payment) => {
            if(payment) {
                return (
                    <div className="most-recent-payment">
                        <div className='most-recent-payment-vpart' style={{height:'35%'}}>
                            <img
                                src={getTransactionIcon(payment)}
                                alt=""
                                className='licon'
                            />
                            <div className='amount'>
                                <span>{formatAmount(payment.amount)}</span>
                            </div>
                        </div>
                        <div className='most-recent-payment-vpart' style={{height:'65%'}}>
                            <div style={{flexDirrection:'column', width:'60%', height:'100%'}}>
                                <div style={{display:'flex', flexDirection:'column', width:'100%', height:'33%', alignItems:'flex-start', justifyContent:'center'}}>
                                    <span className='latest-payment-top'>Date & time</span>
                                    <span className='latest-payment-bottom'>{moment(payment.activityTime).format('D MMM YYYY, HH:mm')}</span>
                                </div>
                                <div style={{display:'flex', flexDirection:'column', width:'100%', height:'33%', alignItems:'flex-start', justifyContent:'center'}}>
                                    <span className='latest-payment-top'>Bank Reference</span>
                                    <span className='latest-payment-bottom'>{payment.reference}</span>
                                </div>
                                <div style={{display:'flex', flexDirection:'column', width:'100%', height:'33%', alignItems:'flex-start', justifyContent:'center'}}>
                                    <span className='latest-payment-top'>Paid To</span>
                                    <span className='latest-payment-bottom'>{bank.accountNickName || bank.accountName}</span>
                                </div>
                            </div>
                            <div style={{display:'flex', flexDirection:'column', width:'40%', height:'100%',justifyContent:'flex-end', alignItems:'center'}}>
                                <img
                                    src={MostRecentIcon}
                                    alt=""
                                    className='licon-big'
                                />
                            </div>
                        </div>
                    </div>
                )
            } else {
                return null
            }
            
    };

    const renderPayments = () => {
        return (
            <>
                {renderLatestPayment(first)}
                {renderOtherPayment(second)}
                {renderOtherPayment(third)}
            </>
        )
    };

    const renderOtherPayment = (payment) => {
        if(payment) {
            return (
                <div style={{display:'flex', flexDirection:'column', width:'100%', height:'10vh', backgroundColor:'white', alignItems:'center'}}>
                    <div style={{display:'flex', flexDirection:'row', width:'100%', height:'10vh' }}>
                        <div style={{display:'flex', flexDirection:'row', width:'25%', height:'100%', justifyContent:'center', alignItems:'center'}}>
                            <img
                                src={getTransactionIcon(payment)}
                                alt=""
                                className='licon-small'
                            />
                        </div>
                        <div style={{display:'flex', flexDirection:'row', width:'50%', height:'100%', justifyContent:'center', alignItems:'center'}}>
                            <div style={{display:'flex', flexDirection:'column', width:'100%', height:'33%', alignItems:'flex-start', justifyContent:'center'}}>
                                <span className='payment-top'>{moment(payment.activityTime).format('DD.MM.YYYY HH:mm')}</span>
                                <span className='payment-bottom'>{payment.reference}</span>
                            </div>
                        </div>
                        <div style={{display:'flex', flexDirection:'row', width:'35%', height:'100%', alignItems:'flex-start', justifyContent:'center'}}>
                            <div className='amount-small'>
                                <span>{formatAmount(payment.amount)}</span>
                            </div>
                        </div>
                    </div>
                    <div style={{width:'90%', height:'1px', backgroundColor:'#EBEBEB'}}/>
                </div>
            )
        } else {
            return null
        }
    };

    return (
        <div className="last-receipt">
            <HeaderUniversal
                title="Most Recent Receipts"
                leftButtonIcon={CloseIcon}
                leftButtonEvent={onCloseButton}
                rightButtonIcon={Menu}
                rightButtonEvent={() => {dispatch(toggleMenu())}}
                customStylesTitle={{top: '40px', fontSize: '2.6vh', fontWeight: '400'}}
            />
            <div className="header-underline"/>
            <ReactPullToRefresh
                onRefresh={handleRefresh}
                style={{textAlign: 'center'}}
                icon={<img className='genericon genericon-next' src={Genericon}/>}
            >
                <p className='payment-bottom'>Pull down to refresh</p>
                {lastReceipts.length > 0 && bank?.accountNumber ? renderPayments() : 
                    <div style={{width:'100%', height:'100vh'}}>
                        <div className="payment-history__empty">
                            <img src={History} alt="history"/>
                            <p>There are no records in payments history yet</p>
                        </div>
                    </div>
                }
            </ReactPullToRefresh>
        </div>
    )
};

export default LastReceipt;
