// outsource dependencies
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// local dependencies
import {
    toggleMenu,
    getBankList,
    setStatusBarColor,
    getHistoryObjectStatusAction
} from "../../actions";
import Menu from "../../assets/images/menu.png";
import PinBlock from "../../components/PinBlock";
import colors from '../../assets/style.theme.scss';
import CloseIcon from "../../assets/images/close-icon.svg";
import CalendarIcon from "../../assets/images/calendar.svg";
import HeaderUniversal from "../../components/HeaderUniversal";
import History from "../../assets/images/payment-history-empty.svg";
import HistoryPaymentItem from "../../components/HistoryPaymentItem";
import { decodeExecutingEntityId } from "../../utils/paymentIdDecoder";
import { groupingByDate, returnDate, sortByTime } from "../../helpers";

import {PaymentDirection, PaymentRequestStatus, PaymentReportState} from './../../static/CommonDefinitions'

import './style.scss';
import { getHistoryAction } from "../../resolver/historyProcessor";

const PaymentHistory = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const payments = useSelector(state =>state.history.payments);
    const {requests} = useSelector(state => ({requests: state.history.requests}));
    const [pinBlock, setPinBlock] = useState(JSON.parse(localStorage.getItem('activity'))||false);
    const [checkPinCode, setCheckPinCode] = useState(false);
    const [groupPayment, setGroupPayment] = useState([]);

    useEffect(() => {
        if (payments.length) {
            setGroupPayment(groupingByDate(payments));
        }
    }, [payments]);

    useEffect(() => {
        dispatch(setStatusBarColor(colors.mainYellow));
        dispatch(getBankList());

        if(payments?.length) {
            payments.forEach((payment) => {
                if(payment.dirrection === PaymentDirection.OutCome && payment.reportState !== PaymentReportState.Cancelled && payment.reportState !== PaymentReportState.CancelledOnBank) {
                    dispatch(getHistoryObjectStatusAction({ id: payment.id, isRequest:false }));
                }
            });
        }

        if(requests?.length) {
            requests.forEach((request) => {
                if(request.status !==  PaymentRequestStatus.Closed) {
                    dispatch(getHistoryAction({ id: request.ids.activatingEntityId, isRequest:true }))
                    dispatch(getHistoryObjectStatusAction({ id: request.ids.activatingEntityId, isRequest:false }));
                }
            });
          }
    }, []);

    useEffect(() => {
        if (localStorage.getItem('pinCode') && !checkPinCode) {
            setCheckPinCode(true);
            localStorage.setItem('checkedPin', false);
        }
    }, [pinBlock]);

    const handleClosePage = () => {
        history.push('/next');
    };

    const openSummary = (payment) => {
        history.push(`/payment-summary/${payment.id}`)
    };

    const openMenu = () => {
        dispatch(toggleMenu());
    };

    return(
        <div className="payment-history">
            <HeaderUniversal
                rightButtonIcon={Menu}
                leftButtonIcon={CloseIcon}
                leftButtonEvent={handleClosePage}
                rightButtonEvent={openMenu}
                title="Sent & Received"
                customStylesTitle={{top: '40px', fontSize: '22px', fontWeight: '600'}}
            />
            {!payments.length > 0 && <div className="payment-history__empty">
                <img src={History} alt="history"/>
                <p>There are no records in payments history yet</p>
            </div> }
            {payments.length > 0 && <div className="payment-history__content">
                {Object.keys(groupPayment).sort().reverse().map(key => groupPayment[key]).map((list) => (
                    <div className="payment-history__content--block" key={list[0].timestamp}>
                        <div className="payment-history__content--title">
                            <img src={CalendarIcon} alt="CalendarIcon"/>
                            <span>{returnDate(list[0].timestamp)}</span>
                        </div>
                        {sortByTime(list).map((payment) => (
                            <HistoryPaymentItem payment={payment} key={payment.id} handleClick={() => openSummary(payment)}/>
                        ))}
                    </div>
                ))}
            </div> }

            { pinBlock && <PinBlock closePinBlock={() => setPinBlock(false)} onCloseButton={() => history.push('/next')}/> }
        </div>
    )
};

export default PaymentHistory;
