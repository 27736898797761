import React from "react";

import GoogleStore from '../../assets/images/placeholders/download-google-store.svg';
import AppStore from '../../assets/images/placeholders/download-app-store.svg';
import Phones from '../../assets/images/placeholders/phones.png';
import ScreenLogo from '../../assets/images/bopp-logo.svg';
import HeaderUniversal from "../HeaderUniversal";
import './style.scss';

export default function CheckBrowser() {

  return (
    <div className='check-browser'>
      <HeaderUniversal logo={ScreenLogo} classForCustomStyles="check-browser__header" />

      <div className="check-browser__wrapper">
        <div className="check-browser__content">
          <div className="check-browser__text">
            Download the BOPP app so you can easily and securely send and receive payments
          </div>

          <a className="check-browser__button" href="https://apps.apple.com/app/id1513552626">
            <img className="check-browser__image" src={AppStore} alt="AppStore" />
          </a>
          <a className="check-browser__button" href="https://play.google.com/store/apps/details?id=com.agitate.bopp">
            <img className="check-browser__image" src={GoogleStore} alt="GoogleStore" />
          </a>

          <a href="https://bopp.io/" className="check-browser__more-link">
            Learn more about BOPP
          </a>
        </div>

        <div className="check-browser__phones">
          <img src={Phones} alt="A different screens of the BOPP app" className="check-browser__phones-image"/>
        </div>
      </div>
    </div>
  );
}
