import React, { useEffect, useRef, forwardRef, useImperativeHandle, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux';

import Amount from '../Amount'
import NumPanel from '../NumPanel'
import {changeAmount} from '../../actions';
import { AmountLabelType, PlatformType, TermType } from '../../static/CommonDefinitions';
import "./style.scss";
import {cssVhToPx, numberWithCommas} from '../../helpers';

const AmountTypeLabel = () => {
  const platform = useSelector(state => state.settings.platform,);
  const paymentRequest = useSelector((state) => state.paymentPersist.paymentRequest);

  const style = platform === PlatformType.Desktop ? {} : {
    fontSize: cssVhToPx(2.1),
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2
  });

  switch(paymentRequest?.amountTerm) {
      case TermType.MinAmount:
        return <p className="desktop_amount_input_field__settings" style={style}>Min amount {formatter.format(paymentRequest.minAmount)}</p>
      case TermType.SuggestedAmount:
        return <p className="desktop_amount_input_field__settings" style={style}>Suggested amount {formatter.format(paymentRequest.suggestedAmount)}</p>;
      case TermType.MaxAmount:
        if(paymentRequest.maxAmount === '99999.99'){
          return <></>;  
        } else {
          return <p className="desktop_amount_input_field__settings" style={style}>Max amount {formatter.format(paymentRequest.maxAmount)}</p>;
        }
      default:
        return <></>;
    }
}

const AmountInput = forwardRef((props, ref) => {
  const { disabled, fixedAmount, showAmountSettings = false, payee = false, handleClickPanel = () => {}, numpad = true, error = false } = props;
  const { controlledAmount } = props;
  const dispatch = useDispatch();
  const paymentAmount = useSelector((state) => state.payment.amount);
  const oneTimePaymentSettings = useSelector(state => state.paymentRequestSettings.oneTimePaymentSettings);
  const numPanelRef = useRef();
  const amountRef = useRef();
  const [choosePayee, setChoosePayee] = useState(payee);

  useImperativeHandle(ref, () => ({
    error: () => amountRef.current.error()
  }));

  useEffect(() => {
    const finishInput = ({target}) => {
      if (numpad) {
        if (!numPanelRef.current || !numPanelRef.current.contains(target)) {
          const delimiterIndex = getAmount().indexOf('.');
          if (delimiterIndex < 0) setAmount(`${getAmount()}.00`)
          else {
            const missedZeros = 2 - (getAmount().length - delimiterIndex - 1)
            if (missedZeros > 0) {
              setAmount(`${getAmount()}${Array(missedZeros).fill(0).join('')}`);
            }
          }
        }
      }
    }

    document.addEventListener('click', finishInput);
    return () => requestAnimationFrame(() => document.removeEventListener('click', finishInput))
  }, [paymentAmount, controlledAmount?.value, numpad])

  const getAmount = () => {
    if (controlledAmount) {
      return controlledAmount.value;
    }

    return paymentAmount;
  }

  const setAmount = (amount) => {
    if (controlledAmount) {
      controlledAmount.onChange(amount);
    } else {
      dispatch(changeAmount(amount));
    }
  };

  const formatAmount = (amount, char, remove) => {
    if (amount.length >= 9) return amount;
    if (remove) {
      if(amount === '0.') return '0.00';

      if (amount !== '0.00') {
        amount = amount.length > 1 ? amount.slice(0, -1) : '0.00';
      }

      return amount;
    }

    const delimiterIndex = amount.indexOf('.')

    if (char === '.') {
      if (delimiterIndex !== -1) return amount
      else if (amount === '0.00') return '0.';
      else return `${amount}${char}`
    }

    if ((amount === '0' || amount === '0.00') && char === 0) return '0.';

    if (amount === '0.00') return `${char}`;
    else {
      if (delimiterIndex >= 0) {
        if (amount.length - delimiterIndex - 1 >= 2) return amount
      } else {
        if (amount.length >= 5) return amount
      }
      return `${amount}${char}`
    }
  };

  const handleClickNumPanel = () => {
    handleClickPanel();
    setChoosePayee(false);
  };

  if (numpad) {
    if(oneTimePaymentSettings.amount.type === TermType.AnyAmount) {
      return (
        <div className='amount-message-wrap'>
          <span>Payment amount <br/> set by payer</span>
        </div>
      )
    }

    return (
      <>
        <Amount assignedAmount={controlledAmount?.value} ref={amountRef} fixedAmount={fixedAmount} payee={payee} choosePayee={choosePayee} labelType={AmountLabelType.Simple}/>
        {showAmountSettings && <AmountTypeLabel /> }
        <div ref={numPanelRef}>
          <NumPanel
            onInput={(val) => setAmount(formatAmount(getAmount(), val))}
            onBackSpace={() => setAmount(formatAmount(getAmount(), 0, true))}
            onClean={() => setAmount('0.00')}
            disabled={disabled}
            handleClickPanel={handleClickNumPanel}
            className='desktop_amount_input_field__num-panel'
          />
        </div>
      </>
    )
  }

  return (
    <>
      <DesktopAmountInputField value={getAmount()} onChange={setAmount} error={error} fixedAmount={fixedAmount} />
    </>
  )
})

function DesktopAmountInputField(props) {
  const {value, onChange, error, showAmountSettings = true} = props;
  const [backspacePressed, setBackspacePressed] = useState(false);
  const [ignoreChanges, setIgnoreChanges] = useState(false);
  const [timeoutId, setTimeoutId] = useState("");
  const [inputWidth, setInputWidth] = useState(105);
  const inputRef = useRef(null);

  useEffect(() => {
    setInputWidth(value.length * 25 + (numberWithCommas(value).match(',')?.length || 0) * 5);
  }, [value.length])

  const handleChange = (e) => {
    const newValue = e.target.value;
    const formattedValue = newValue.replace(/,| /g, '');
    const allowDigitsAfterDot =
        formattedValue.includes('.') &&
        formattedValue.lastIndexOf('.') === formattedValue.length - 4;

    if (isNaN(+formattedValue) || allowDigitsAfterDot) return;

    if (+formattedValue > 99999.99 || ignoreChanges) {
      return;
    }

    if (backspacePressed) {
      setIgnoreChanges(true);
    }

    onChange(formattedValue);
  }

  const handleBlur = () => {
    if (value.length) {
      onChange(Number(value).toFixed(2).toString());
    }
  }

  const timeoutCallback = () => {
    onChange("0.00");
  }

  const className = `desktop_amount_input_field${error ? " desktop_amount_input_field--error" : ""}`;

  return (
      <>
        <div className={className} onClick={() => inputRef.current?.focus()}>
          <div className='desktop_amount_input_field--wrapper'>
              <span className="desktop_amount_input_field__currency">£</span>
              <input
                  ref={inputRef}
                  style={{maxWidth: `${inputWidth || 100}px`}}
                  onKeyUp={(e) => {
                    if (e.key === "Backspace") {
                      setBackspacePressed(false);
                      setIgnoreChanges(false);
                      clearTimeout(timeoutId);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key !== "Backspace") {
                      return;
                    }

                    if (!backspacePressed) {
                      setBackspacePressed(true);
                      clearTimeout(timeoutId);
                      setTimeoutId(setTimeout(timeoutCallback, 1000));
                    }
                  }}
                  value={numberWithCommas(value)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={"0.00"}
              />
          </div>
        </div>
        {showAmountSettings && <AmountTypeLabel /> }
      </>
  )
}

export default AmountInput;
