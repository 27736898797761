import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import backspace from '../../assets/images/backspace.svg';
import { cssVhToPx } from '../../helpers';
import { PlatformType } from '../../static/CommonDefinitions';
import './style.scss';

const NumPanel = ({
  disabled,
  numericCode,
  onInput,
  onBackSpace,
  onClean,
  handleClickPanel,
  className = '',
  gridStyles = {},
}) => {
  const platform = useSelector(state => state.settings.platform);
  const buttons = [1, 2, 3, 4, 5, 6, 7, 8, 9, '.', 0, 'backspace'];
  const [cleanTimer, setCleanTimer] = useState(null);

  const startTimer = () => {
    if (onClean) {
      setCleanTimer(setTimeout(onClean, 1000))
    }
  };

  const stopTimer = () => {
    clearTimeout(cleanTimer)
  };

  const numpanelStyles = platform === PlatformType.Desktop ? {} : {...styles['numpanel-grid'], ...gridStyles};
  const buttonStyles = platform === PlatformType.Desktop ? {} : styles['numpanel-button'];

  return (
    <div className={"numpanel-wrapper " + className} onClick={handleClickPanel}>
      <div className="numpanel-grid" style={numpanelStyles}>
        {buttons.map((value) => (
          <button
            style={buttonStyles}
            disabled={(numericCode && value === '.') || disabled}
            className="numpanel-button"
            key={value}
            onClick={() => value === 'backspace' ? onBackSpace() : onInput(value)}
            onTouchStart={() => value === 'backspace' && startTimer()}
            onTouchEnd={() => value === 'backspace' && stopTimer()}
            onContextMenu={(event) => event.preventDefault()}
          >
            {/* {value !== 'backspace' ? value : <img src={backspace} alt="" />} */}
            {value !== 'backspace' ? value : <div>  &#x2190; </div>}
          </button>
        ))}
      </div>
    </div>
  );
};

const styles = {
  ['numpanel-grid']: {
    gridTemplateRows: `repeat(4, ${cssVhToPx(7.5)})`,
    gridTemplateColumns: `repeat(3, ${cssVhToPx(7.5)})`,
    gridGap: `${cssVhToPx(1.5)} 9.3vw`,
  },
  ['numpanel-button']: {
    fontSize: cssVhToPx(3.1),
  },
}

export default NumPanel;
