import React from "react";

import './style.scss';
import { returnShortTime } from "../../helpers";
import { PaymentDirection, PaymentReportState } from '../../static/CommonDefinitions';

const HistoryPaymentItem = ({ payment, handleClick }) => {
    const { amount, reportState, dirrection, payeeServiceId } = payment;
    const isInCome = dirrection === PaymentDirection.InCome;

    const renderStatus = () => {

        if(isInCome) {
            if(reportState === PaymentReportState.Cancelled) {
                return "Rejected"
            } else if(reportState === PaymentReportState.PendingAccept) {
                return "Pending"
            } else {
                return "Received"
            }
        } else {
            if(reportState === PaymentReportState.Cancelled) {
                return "Rejected"
            } else if(reportState === PaymentReportState.PendingAccept) {
                return "Pending"
            } else if(reportState === PaymentReportState.CancelledOnBank) {
                return "Failed"
            } else {
                return "Paid"
            }
        }
    }

    return(
        <div className="history-item" onClick={handleClick}>
            <div className="history-item--bank">
                <div>
                    <p className="history-item--name">{payeeServiceId.accountName}</p>
                    <p className="history-item--time"> {returnShortTime(payment.timestamp)}</p>
                </div>
            </div>
            <div className="history-item--info">
                <p className="history-item--amount">{`${isInCome ? '+' : '-'}£${(+amount.value).toFixed(2)}`}</p>
                <p className="history-item--status">{renderStatus()}</p>
            </div>
        </div>
    )
};

export default HistoryPaymentItem;
