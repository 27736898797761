import {
    Hook,
    Decode
} from 'console-feed';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import React, {
    useCallback,
    useEffect,
    useState,
    useRef,
    useMemo
} from 'react';
import {
    Route,
    Switch,
    Redirect,
    useHistory,
    useLocation
} from 'react-router-dom';
import { isEnvSetupProperly } from './config';
import { store } from "./store";
import { toBool } from './utils';
import Menu from './containers/Menu';
import CbPage from './containers/CbPage';
import UcbPage from './containers/UcbPage';
import KYCForm from './containers/KYCForm';
import {
    isDeadlineActual,
    isIosInstagramBrowser
} from './helpers';
import PinBlock from './components/PinBlock';
import QrScanner from "./containers/QrScanner";
import PayeePage from "./containers/PayeePage";
import HomeScreen from "./containers/HomeScreen";
import NumericCode from "./containers/NumericCode";
import RequestPage from "./containers/RequestPage";
import VerifyEmail from './containers/VerifyEmail';
import CheckBrowser from "./components/CheckBrowser";
import StatusScreen from './containers/StatusScreen';
import KYCSubmitted from './containers/KYCSubmitted';
import IntroScreens from './containers/IntroScreens';
import PinProtection from './containers/PinProtection';
import RequestSummary from './containers/RequestSummary';
import PaymentHistory from "./containers/PaymentHistory";
import RequestHistory from './containers/RequestHistory';
import { AuthCodeStatuses } from './reducers/kycReducer';
import ErrorPaymentLink from "./containers/ErrorPaymentLink";
import PaymentInProcess from './containers/PaymentInProcess';
import LinkPaymentNotApp from "./containers/LinkPaymentNotApp";
import WrongScreen from './containers/StatusScreen/WrongScreen';
import LinkAccountDetails from './containers/LinkAccountDetails';
import BankAccountWrapper from "./containers/BankAccountWrapper";
import BackButtonModal from './containers/Modal/BackButtonModal';
import PaymentDetailsScreen from "./containers/PaymentDetailsScreen";
import {
    Home,
    PlatformType
} from "./static/CommonDefinitions";
import HistoryPaymentSummary from './containers/HistoryPaymentSummary';
import PaymentRequestSettings from "./containers/PaymentRequestSettings";
import BusinessAccountVerified from './containers/BusinessAccountVerified';
import {
    getRequestHistory,
    getPaymentHistory
} from "./sagas/historyWatcher";
import TransactionCounterModal from "./containers/Modal/TransactionCounterModal";
import LoadingPaymentDetails from "./containers/LoadingPaymentDetails";
import InvalidEnvironmentFileError from './containers/InvalidEnvironmentFileError';
import LastReceipt from './containers/LastReceiptScreen';
import PaymentRequestDetailsScreen from './containers/PaymentRequestDetails';

import {
    handleMessage,
    getTransactionCounter,
    getPin,
    getPlatform,
    getHome,
    changePaymentStatusScreen,
    changeAccountDetailsScreen,
    cancelDomesticPayment,
    savePaylinkUrl,
    setHome,
    changeWrongScreen,
    cancelAccountDetails,
    getFromApp,
    getIsAppLaunchedFirstTime,
    saveInApp,
    handleAccountDetailsStatus,
    changeIsAddBankAccount,
    clickBackButton,
    removeBank,
    setPlatform,
    setCheckUserAuthCodeStatus,
    setEmail,
    setPinOnLaunch,
    setPaymentRequestMethod,
    removeUrlPayerBank,
    setCustomUrlPaymentSettings,
    setDefaultScreenHeight,
    setFailReasonText,
    setIframeRedirectUrl,
} from './actions';
import { UcbCallBack } from './helpers/ucbCallback';
import { PaylinkUrlParameters } from './helpers/PaylinkUrlParameters';
import { updateOneTimeSettings } from './sagas/paymentRequestSettingsSaga';
import { sendError } from './resolver/errorResolver';
import {
    ErrorFlow,
    ErrorObject
} from './entities/errorObject';
import SOStatusScreen from './containers/SOStatusScreen';

const EnableLogging = toBool(process.env.REACT_APP_ENABLE_LOGGING);
const paidCount = process.env.PAID_PAYMENT_REQUEST;
const checkIsBrowser = toBool(process.env.REACT_APP_CHECK_IS_BROWSER);
let logs = [];

function App() {
    const dispatch = useDispatch();
    const history = useHistory();
    const currentLocation = useLocation();

    const [isBrowser, setIfBrowser] = useState(false);
    const [showLoadingScreen, changeLoadingScreen] = useState(true);
    const [transactionCounter, setModalTransactionCounter] = useState(false);
    const [backButtonModalopened, setBackButtonModalopened] = useState(false);
    const [checkPinCode, pinCodeHas] = useState(false);
    const [envErrorScreenClosed, setEnvErrorScreenClosed] = useState(false);

    const {
        paymentInProcess,
        savingAccountDetails,
        countTrans,
        status,
        failReasonText,
        isPaymentSocketClosed,
        latestChangeRequest,
        paymentTransferringToBank,
        paymentWaitingForConsent,
        accountDetailsWaitingForConsent,
        accountDetailsTransferringToBank,
        wrongScreen,
        loading,
        bank,
        isClickedBackButton,
        paymentDetailsWaitingForCancel,
        accountDetailsWaitingForCancel,
        paylinkErrorModalShowed,
        accountDetailsScreen,
        targetBankForAccountDetails,
        targetBankForPaymentStatus
    } = useSelector(state => ({
        paymentInProcess: state.payment.paymentStatusScreen.paymentInProcess,
        loading: state.payment.loading,
        savingAccountDetails: state.payment.accountDetailsScreen.savingAccountDetails,
        paymentTransferringToBank: state.payment.paymentStatusScreen.transferringToBank,
        accountDetailsTransferringToBank: state.payment.accountDetailsScreen.transferringToBank,
        countTrans: state.payment.paymentAction,
        status: state.payment.status,
        isPaymentSocketClosed: state.payment.isPaymentSocketClosed,
        failReasonText: state.payment.failReasonText,
        latestChangeRequest: state.payment.latestChangeRequest,
        isClickedBackButton: state.payment.isClickedBackButton,
        bank: state.payment.bank,
        wrongScreen: state.payment.wrongScreen,
        paymentWaitingForConsent: state.payment.paymentStatusScreen.waitingForConsent,
        accountDetailsWaitingForConsent: state.payment.accountDetailsScreen.waitingForConsent,
        paymentDetailsWaitingForCancel: state.payment.paymentStatusScreen.waitingForCancel,
        accountDetailsWaitingForCancel: state.payment.accountDetailsScreen.waitingForCancel,
        paylinkErrorModalShowed: state.payment.paylinkErrorModalShowed,
        accountDetailsScreen: state.payment.accountDetailsScreen,
        targetBankForAccountDetails: state.payment.accountDetailsScreen.targetBank,
        targetBankForPaymentStatus: state.payment.paymentStatusScreen.targetBank
    }));
    const {
        home,
        platform,
        isAppLaunchedFirstTime,
        boppLaunch,
        pinOnAppLaunch
    } = useSelector(state => ({
        home: state.settings.home,
        platform: state.settings.platform,
        isAppLaunchedFirstTime: state.settings.isAppLaunchedFirstTime,
        boppLaunch: state.settings.protection,
        pinOnAppLaunch: state.settings.pinOnAppLaunch,
    }));
    const paymentPayeeSubscriberIds = useSelector(state => state.payment.paymentPayeeSubscriberIds);
    const timerDeadline = useSelector(state => state.kyc.timerDeadline);
    const defaultPaymentSettings = useSelector(state => state.paymentRequestSettings.defaultPaymentSettings);
    const browserDetectCallback = useCallback(() => {
        if (platform === PlatformType.Web || platform === PlatformType.Desktop) {
            setIfBrowser(checkIsBrowser);
        } else {
            setIfBrowser(false);
        }
    }, [navigator, platform]);

    const handlePostMessage = (event) => {
        const tabmessage = '__TAB__';
        const tabCustom = '__TAB__CUSTOM_EVENT__';
        let messageData = {};

        if (typeof event.data === 'object') {
            if (event.data && event.data.source && event.data.source.startsWith("react-devtools")) {
                return
            }

            messageData = event.data;
        }

        if(event.data === "form_submitted") {
            history.push('/verify-email')
            return
        }

        if (typeof event.data === 'string') {
            if (event.data.includes(tabmessage)) {
                if (event.data.includes(tabCustom)) {
                    messageData = JSON.parse(event.data.replace(tabCustom, ''));
                } else {
                    return;
                }
            } else if (event.data.length === 0) {
                return;
            } else {
                if(event.data) {
                    messageData = JSON.parse(event.data);
                }
            }
        }

        dispatch(handleMessage(messageData, event.source, history));
    };

    useEffect(() => {
        dispatch(updateOneTimeSettings(defaultPaymentSettings));
    }, []);

    useEffect(() => {
        window.addEventListener('message', handlePostMessage, true);
        return () => window.removeEventListener('message', handlePostMessage);
    }, []);

    useEffect(() => {
        if (localStorage.getItem('pinCode') && !checkPinCode) {
            checked();
            localStorage.setItem('checkedPin', false);
        }
    }, [boppLaunch]);

    const isFirstRun = useRef(true);
    const isFirstRun2 = useRef(true);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        if (paylinkErrorModalShowed) {
            history.push('/payment-link-error')
        } else {
            if (home === Home.Pay) {
                history.push('/qr-scanner')
            } else {
                history.push('/home-screen')
            }
        }
    }, [paylinkErrorModalShowed]);

    useEffect(() => {
        browserDetectCallback();
        dispatch(getPin());
    }, [currentLocation, isBrowser, browserDetectCallback, dispatch]);

    useEffect(() => {
        if(isAppLaunchedFirstTime) {
            return history.push('/intro-screens');
        }

        if(history.location.pathname.includes('/kyc-submitted-action')) {
            return history.push(`/kyc-submitted-action`);
        }

        if(history.location.pathname.includes('/aisp-not-app')) {
            return history.push(`/aisp-not-app`);
        }

        if(history.location.pathname.includes('/verify-email')) {
            return history.push(`/verify-email`);
        }

        if(!home && !history.location.pathname.includes('/link/')) {
            return history.push('/');
        }

        if(history.location.pathname === '/cb') {
            return;
        }

        if(history.location.pathname === '/so-request-details') {
            return;
        }

        if(history.location.pathname === '/so-status') {
            return;
        }

        if(history.location.pathname.includes('/ucb')) {
            let ucbCallback = new UcbCallBack(window.location.href)
            if(ucbCallback.redirectUrl) {
                window.location.replace(ucbCallback.redirectUrl);
                return
            }
        }

        if(!history.location.pathname.includes('/link')) {
            if (home === Home.Pay) {
                return history.push('/qr-scanner');
            }
            return history.push('/home-screen');
        }

        if(history.location.pathname.includes('/link/')) {
            let pb = store.getState().payment.payerBank
            if(Object.keys(pb).length !== 0) {
                if(pb.state !== undefined) {
                    dispatch(removeUrlPayerBank());
                }
            }

            let paylinkUrlParameters = new PaylinkUrlParameters('https://bopp.app' + history.location.pathname + history.location.search)
            dispatch(setCustomUrlPaymentSettings({amount: paylinkUrlParameters.amount, reference: paylinkUrlParameters.paymentReference}))

            const paylinkUrl = 'paylink://bopp.link/' + history.location.pathname.split('/').pop();
            dispatch(setHome(Home.Pay));

            dispatch(setPaymentRequestMethod('paylink', 'link'))
            dispatch(savePaylinkUrl(paylinkUrl));

            let params = new URLSearchParams(history.location.search);
            if(params.get('rUrl')) {
                dispatch(setIframeRedirectUrl(params.get('rUrl')))
            }
        }
    }, [history, home, isAppLaunchedFirstTime, dispatch]);

    useEffect(() => {
        dispatch(getPlatform());
        dispatch(getHome());
        dispatch(getIsAppLaunchedFirstTime());
        dispatch(getFromApp('timerDeadline'));
        dispatch(getRequestHistory());
        dispatch(getPaymentHistory());
        changeLoadingScreen(false);
        dispatch(getFromApp('subscriberInfo'));
        dispatch(getFromApp('checkMigration'));
    }, [dispatch]);

    useEffect(() => {
        if (EnableLogging) {
            Hook(window.console, (log) => {
                logs = [...logs, Decode(log)];
            });
        }
    });

    useEffect(() => {
        if(document.body.clientWidth > 1024) {
            dispatch(setPlatform('desktop'));
        }
    }, []);

    useEffect(() => {
        dispatch(getTransactionCounter());
        if (countTrans && +countTrans >= paidCount && !localStorage.getItem('closePopUpTransaction')) {
            openTransactionModal();
        }
    }, [dispatch, countTrans]);

    useEffect(() => {
        if(status !== '') {
            history.push('/status')
        }
    }, [status]);

    useEffect(() => {
        if (isFirstRun2.current) {
            isFirstRun2.current = false;
            return;
        }

        if(isPaymentSocketClosed && paymentInProcess && failReasonText != null) {
            dispatch(changePaymentStatusScreen({ paymentInProcess: false , waitingForCancel:false, cancelled: false, transferringToBank:false, waitingForConsent:false}));
            dispatch(setFailReasonText('Bank Payment Confirmation Failed. Please try again'))
            dispatch(sendError({error:new ErrorObject('Did not receive any response from server for change request', latestChangeRequest, ErrorFlow.payments)}));
            history.push('/status')
        }
    }, [isPaymentSocketClosed]);

    useEffect(() => {
        if(home === Home.Request) {
            dispatch(getFromApp('bankAccountDetails'));
        }
    }, [dispatch, home]);

    useEffect(() => {
        if(isClickedBackButton && savingAccountDetails) {
            setBackButtonModalopened(true)
        }
    }, [isClickedBackButton, savingAccountDetails, setBackButtonModalopened])

    useEffect(() => {
        if (!isDeadlineActual(timerDeadline?.endTimeTimer)) {
            dispatch(setCheckUserAuthCodeStatus(AuthCodeStatuses.notChecked));
        }
    }, [dispatch])

    useEffect(() => {
        let viewport = document.querySelector("meta[name=viewport]");
        const targetHeight = isIosInstagramBrowser() ? document.documentElement.clientHeight : window.innerHeight;

        viewport.setAttribute(
            "content",
            `width=device-width, height=${targetHeight}px, initial-scale=1.0, maximum-scale=1.0, user-scalable=0`
        );
        dispatch(setDefaultScreenHeight(targetHeight));
    }, []);

    useEffect(() => {
        const callback = () => {
        const targetHeight = isIosInstagramBrowser() ?  document.documentElement.clientHeight: window.innerHeight;
            document.getElementById('root').style.setProperty('height', targetHeight+ 'px');
        }
        window.addEventListener('resize', callback);
        return () => window.removeEventListener('resize', callback);
    }, []);

    const openTransactionModal = () => {
        setModalTransactionCounter(true);
    };

    const closeTransactionModal = () => {
        setModalTransactionCounter(false);
        localStorage.setItem('closePopUpTransaction', true);
    };

    const saveAccountDetails = () => {
        dispatch(saveInApp({key: 'bankAccountDetails', value: bank}));
        dispatch(handleAccountDetailsStatus({ statusAccountDetails: 'success' }));
        dispatch(changeIsAddBankAccount(false));
        setBackButtonModalopened(false);
        dispatch(clickBackButton(false));
    };
    const deleteAccountDetails = () => {
        dispatch(removeBank());
        setBackButtonModalopened(false);
        dispatch(changeAccountDetailsScreen({savingAccountDetails: false}));
        dispatch(clickBackButton(false));
        dispatch(setEmail({ email: '' }));
        history.push('/next');
    }

    const handleCancelPayment = () => {
        if(home === Home.Pay) {
            dispatch(changePaymentStatusScreen({waitingForConsent: false, clickCancelPayment: true, cancelled: true, waitingForCancel:true}));
            dispatch(cancelDomesticPayment());
        } else {
            dispatch(changeAccountDetailsScreen({waitingForConsent: false, clickCancelConsent: true, cancelled: true, waitingForCancel:true}))
            dispatch(cancelAccountDetails());
        }
    };

    const closeWrongScreen = () => {
        dispatch(changePaymentStatusScreen({ paymentInProcess: false , waitingForCancel:false, cancelled: false, transferringToBank:false, waitingForConsent:false}));
        dispatch(changeWrongScreen(false));
        history.push('/next');
    };

    const checked = () => {
        pinCodeHas(true);
    };

    const closePinBlock = () => {
        dispatch(setPinOnLaunch(false));
    };

    const getTransferringTitle = useMemo(() => {
        const targetBank = paymentTransferringToBank ? targetBankForPaymentStatus : targetBankForAccountDetails;
        return `Securely transferring to ${targetBank}`;
    }, [targetBankForPaymentStatus, targetBankForAccountDetails, paymentTransferringToBank]);

    return (
        <>
            <Switch>
                <Route path='/link/:id' component={LoadingPaymentDetails}/>
                <Route path='/request-details/:id' component={PaymentDetailsScreen}/>
                <Route path='/so-request-details' component={PaymentRequestDetailsScreen}/>
                <Route path='/intro-screens' component={isBrowser ? CheckBrowser : IntroScreens}/>
                <Route path='/bank-account' render={() => (isBrowser ? <CheckBrowser/> : <BankAccountWrapper/>)}/>
                <Route path='/pin' component={isBrowser ? CheckBrowser : PinProtection}/>
                <Route path='/payment-history' component={isBrowser ? CheckBrowser : PaymentHistory}/>
                <Route path='/payment-summary/:id' component={isBrowser ? CheckBrowser : HistoryPaymentSummary}/>
                <Route path='/request-history' component={isBrowser ? CheckBrowser : RequestHistory}/>
                <Route path='/request-summary/:id' component={isBrowser ? CheckBrowser : RequestSummary}/>
                <Route path='/qr-scanner' component={isBrowser ? CheckBrowser : QrScanner}/>
                <Route path='/cb' component={CbPage}/>
                <Route path='/ucb' component={UcbPage}/>
                <Route path='/numeric-code' component={isBrowser ? CheckBrowser : NumericCode}/>
                <Route path='/home-screen' component={isBrowser ? CheckBrowser : HomeScreen}/>
                <Route path='/get-paid' component={isBrowser ? CheckBrowser : PayeePage}/>
                <Route path='/payee-request/:id' component={isBrowser ? CheckBrowser : RequestPage}/>
                <Route path='/payment-link-error' component={ErrorPaymentLink}/>
                <Route path='/next' render={() => {
                    if (home === Home.Pay) {
                        return (<Redirect to={'/qr-scanner'}/>);
                    }
                    return (<Redirect to={'/home-screen'}/>);
                }}/>
                <Route path='/kyc-form' component={KYCForm}/>
                <Route path='/verify-email' render={()=><VerifyEmail gotItCallback={()=>{history.push('/home-screen')}}/>}/>
                <Route path='/business-verified' component={BusinessAccountVerified}/>
                <Route path='/kyc-submitted-action' component={KYCSubmitted}/>
                <Route path='/connect-account' component={LinkAccountDetails}/>
                <Route path='/aisp-not-app' component={LinkPaymentNotApp}/>
                <Route path='/payment-settings' component={isBrowser ? CheckBrowser : PaymentRequestSettings} />
                <Route path='/default-payment-settings' render={()=>(isBrowser ? <CheckBrowser/> : <PaymentRequestSettings defaultSettings={true}/>)}/>
                <Route path='/status' render={()=><StatusScreen closeStatusScreen={closeWrongScreen}/>}/>
                <Route path='/so-status' render={()=><SOStatusScreen/>}/>
                <Route path='/last-receipt' component={LastReceipt}/>
            </Switch>

            { (paymentInProcess && accountDetailsScreen.addBankError===null) && <PaymentInProcess message={'Payment In Process...'}/> }
            { (paymentInProcess && failReasonText!=null && !isPaymentSocketClosed) && <PaymentInProcess message={failReasonText}/> }
            { (loading || showLoadingScreen && accountDetailsScreen.addBankError===null) && <PaymentInProcess/> }
            { transactionCounter && <TransactionCounterModal closeTransactionModal={closeTransactionModal}/> }
            { (savingAccountDetails && accountDetailsScreen.addBankError===null) && <PaymentInProcess message={'Saving Account details'}/> }
            { backButtonModalopened && <BackButtonModal saveAccountDetails={saveAccountDetails} deleteAccountDetails={deleteAccountDetails} /> }
            { (paymentTransferringToBank || accountDetailsTransferringToBank && accountDetailsScreen.addBankError===null) && <PaymentInProcess message={getTransferringTitle}/> }
            { (paymentWaitingForConsent && accountDetailsScreen.addBankError===null) && <PaymentInProcess message={'Waiting for you to confirm the payment in your bank'} button={true} buttonName={'Cancel Payment'} closeScreen={handleCancelPayment}/> }
            { (accountDetailsWaitingForConsent && accountDetailsScreen.addBankError===null) && <PaymentInProcess message={'Waiting for you to approve in your bank app'} button={true} buttonName={'Cancel'} closeScreen={handleCancelPayment}/> }
            { (paymentDetailsWaitingForCancel || accountDetailsWaitingForCancel && accountDetailsScreen.addBankError===null) && <PaymentInProcess message={'Waiting for cancel'}/> }
            { wrongScreen && <WrongScreen closeWrongScreen={closeWrongScreen}/> }
            { !isEnvSetupProperly() && !envErrorScreenClosed && <InvalidEnvironmentFileError closeScreen={() => setEnvErrorScreenClosed(true)} /> }
            <Menu />
            { pinOnAppLaunch && <PinBlock closePinBlock={closePinBlock} /> }
        </>
    );
}

export default App;
