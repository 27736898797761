import { common } from ".";

interface CreateAccountDetailsRequest extends common.Activation {
    properties: {
        "@type": string,
        serviceId: string
    },
}

export class CreateAccountDetails {
    api: string
    createRequestId: string

    constructor(api: string, createRequestId: string) {
        this.api = api
        this.createRequestId = createRequestId
    }

    createRequest() {
        return this.getRequestParams()
    }

    getRequestParams() : CreateAccountDetailsRequest {
        return  {
            "@type": "https://dvschema.io/activation#CreateRequest",
            id: this.createRequestId,
            properties: {
                "@type": "https://miapago.io/ob/accountdetails#Properties",
                serviceId: this.api,
            },
            timestamp: new Date(),
            possibleResend: false
        }
    }
}