import React, {useState, useEffect} from "react";
//import {useHistory} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ScreenLogo from '../../assets/images/bopp-logo.svg';

import Close from '../../assets/images/close-icon.svg';
import Logo from '../../assets/images/bopp-logo.svg';
import WarningIcon from "../../assets/images/warning.svg";
import colors from '../../assets/style.theme.scss';
import './index.scss';

import {
    showInvalidPaymentError,
    setStatusBarColor,
} from "../../actions";
import HeaderUniversal from "../../components/HeaderUniversal";
import { PlatformType } from "../../static/CommonDefinitions";

export default function ErrorPaymentLink() {
    //const history = useHistory();

    const dispatch = useDispatch();
    const platform = useSelector(state => state.settings.platform);

    const [title, changeTitle] = useState('Invalid payment link');
    const [desc, changeDesc] = useState('Sorry, we’re unable to recognize this payment link.');

    useEffect(() => {
        dispatch(setStatusBarColor(colors.defaultBar));
        return () => {
            dispatch(showInvalidPaymentError(false))
          }
    }, []);

    const closePage = () => {
        dispatch(showInvalidPaymentError(false))
    }

    return (
        <div className={`error-payment-page ${platform === PlatformType.Desktop ? 'desktop' : ''}`}>
            {platform === PlatformType.Desktop ?
                <img className={`screen-logo ${platform === PlatformType.Desktop ? 'screen-logo--desktop' : ''}`} src={ScreenLogo} alt="" />
                :
            <HeaderUniversal
                logo={Logo}
                rightButtonIcon={Close}
                rightButtonEvent={closePage}
                customStylesRightBtn={{ width: '32px', height: '32px', top: '33px' }}
                customStylesLogo={{top: '30px'}}
            />}
            <div className="error-payment-page__body">
                <img src={WarningIcon} alt="CloseIcon"/>

                <span className="main-container-title">{title}</span>
                <span className="main-container-desc">{desc}</span>
            </div>
        </div>
    );
}
