import { takeLatest } from 'redux-saga/effects';
import { wrap } from '../helpers';
import { ErrorObject } from '../entities/errorObject';
import { sendWSMessage } from './payment/statusRequestProcessor';

export const SEND_ERROR = 'error/send';

export interface SendErrorPayload {
    error: ErrorObject;
}

export interface SendError {
    type: typeof SEND_ERROR,
    payload: SendErrorPayload,
}

export const sendError = (payload: SendErrorPayload): SendError => ({
    type: SEND_ERROR,
    payload
});

function* dispatchSendError(socket: WebSocket, payload: SendError) {
    const { error } = payload.payload;
    yield sendWSMessage(wrap('paylink-initiator', error.makeError()))
}

export function* errorResolver({paylinkSocket}: any) {
  yield takeLatest(SEND_ERROR, dispatchSendError, paylinkSocket);
}