// outsource dependencies
import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// local dependencies
import Menu from '../../assets/images/menu-bars.svg';
import Cross from '../../assets/images/close-icon.svg';
import NoRecords from '../../assets/images/request-history-empty.svg';
import CalendarIcon from '../../assets/images/calendar.svg';

import {
  toggleMenu,
  setStatusBarColor,
  getHistoryObjectStatusAction,
  getPaymentsHistory,
} from '../../actions';
import { returnDate, groupingByDate, sortByTime } from '../../helpers';
import colors from '../../assets/style.theme.scss';
import './style.scss';

import HeaderUniversal from '../../components/HeaderUniversal';
import RequestsHistoryItem from '../../components/RequestsHistoryItem';
import {TermType, PaymentRequestStatus} from './../../static/CommonDefinitions'
import PinBlock from '../../components/PinBlock';
import { getHistoryAction } from '../../resolver/historyProcessor';

const RequestHistory = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {requests} = useSelector(state => ({requests: state.history.requests}));

  const [checkPinCode, setCheckPinCode] = useState(false);
  const [groupRequest, setGroupRequest] = useState([]);
  const [pinBlock, setPinBlock] = useState(JSON.parse(localStorage.getItem('activity'))||false);

  useEffect(() => {
    dispatch(setStatusBarColor(colors.mainYellow));

    if(requests?.length) {
      requests.forEach((request) => {
        if(request.status !==  PaymentRequestStatus.Closed) {
          dispatch(getHistoryAction({ id: request.ids.activatingEntityId, isRequest:true }))
          dispatch(getHistoryObjectStatusAction({ id: request.ids.activatingEntityId, isRequest:false }));
        }
      });
    }
  }, []);

  useEffect(() => {
    if (requests?.length) {
      setGroupRequest(groupingByDate(requests));
    }
  }, [requests]);

  useEffect(() => {
    if (localStorage.getItem('pinCode') && !checkPinCode) {
        setCheckPinCode(true);
        localStorage.setItem('checkedPin', false);
    }
}, [pinBlock]);

  const closeRequestHistory = () => {
    history.push('/next');
  }

  const openItemSummary = (id) => {
      history.push(`/request-summary/${id}`)
  };

  return (
    <div className="request-history">
      <HeaderUniversal
        title={'Request Activity'}
        rightButtonIcon={Menu }
        rightButtonEvent={() => dispatch(toggleMenu())}
        leftButtonIcon={Cross}
        leftButtonEvent={closeRequestHistory}
      />

      {requests.length ? (
        <div className="request-history__content">
          { Object.keys(groupRequest).sort().reverse().map(key => groupRequest[key]).map((list) => (
            <div className="request-history__day-records"  key={list[0]?.timestamp} >
              <WrapperByDate date={list[0]?.timestamp}>
                {sortByTime(list).map((request) => (
                  <RequestsHistoryItem
                    status={request.status}
                    date={request.timestamp}
                    amount={request.totalPaid.value}
                    key={request.ids.paymentRequestId}
                    accountName={request.account.accountName}
                    subAmount={request.paymentSettings.amountTerm === TermType.AnyAmount ? undefined : request.requestedAmount.value}
                    handleClick={() => openItemSummary(request.ids.paymentRequestId)}
                  />
                ))}
              </WrapperByDate>
            </div>
          ))}
        </div>
      ) : (
        <div className="request-history__empty">
          <img src={NoRecords} alt="History empty" className="request-history__image"/>
          <div className="request-history__text">
            There are no records in request activity yet
          </div>
        </div>
      )}

      { pinBlock && <PinBlock closePinBlock={() => setPinBlock(false)} onCloseButton={() => history.push('/next')}/> }
    </div>
  );
};

export default RequestHistory;

const WrapperByDate = ({ date, children }) => (
  <div className="date-wrapper">
    <div className="date-wrapper__date">
      <img src={CalendarIcon} alt="calendar-icon" className="date-wrapper__calendar-icon"/>
      {returnDate(date)}
    </div>
    {children}
  </div>
);
