import React from "react";
import "./style.scss";

const Switcher = ({ enabled, onChange = () => console.log('onchange not passed') , className, id }) => {
  const style = `switcher ${enabled ? 'switcher--enabled' : ''} ${className}`;

  return <div className={style} onClick={() => onChange(!enabled)} id={id}>
    <div className="switcher__background" />
    <div className="switcher__slider" />
  </div>
}

export default Switcher
