import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import WarningIcon from "../../assets/images/warning.svg";
import SuccessIconRounded from '../../assets/images/success.svg';
import FailIconRounded from '../../assets/images/circle-failed-icon.svg';
import CloseIcon from '../../assets/images/close-icon.svg';
import GiftIcon from '../../assets/images/gift-aid.svg';
import CopyIcon from '../../assets/images/copy-v2.svg';
import config from './../../config'

import { PaymentDirection, PlatformType, Status } from '../../static/CommonDefinitions';
import {
  setStatusBarColor,
  handlePaymentStatus,
  setFixedAmount,
  openNewPage,
  copyToClipboard
} from '../../actions';

import colors from '../../assets/style.theme.scss';
import { checkLength, cssAdaptivePixelsVh, cssAdaptivePixelsVw, formatAmount, isEmptyObject, isPaymentOutcome } from '../../helpers';

import styles from './styles.module.scss';
import mobileStyles from './stylesMobile.module.scss';

import classNames from 'classnames';
import moment from 'moment';
import { copyToClipboardInBrowser } from '../../utils';
import Toast from '../../components/Toast';
import { decodeExecutingEntityId } from '../../utils/paymentIdDecoder';
import { setPaymentRequest } from '../../reducers/paymentPersistReducer';

const StatusScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showToast, setShowToast] = useState(false);
  const status = useSelector((state) => state.payment.status);
  const failReasonText = useSelector((state) => state.payment.failReasonText);
  const defaultRequest = {
    amount: '',
    accountName: '',
    orderNumber: '',
    giftAid: { enabled: false, confirmMoney: false, confirmDonation: false },
    thankYouNote: { enabled: false, message: ''},
    transactionId: '',
  };
  const request = useSelector((state) => state.paymentPersist.lastPayment || defaultRequest);
  const boppButton = useSelector((state) => state.paymentPersist.boppButton);
  const iframeRedirectUrl = useSelector(state => state.payment.iframeRedirectUrl)
  const [timestamp, setTimestamp] = useState('');

  const [parent, setParent] = useState(window.ReactNativeWebView);

  const platform = useSelector(state => state.settings.platform);

  const isPaymentSuccess = status === Status.Success;
  const giftAidStatus = request?.giftAid?.enabled && !request?.giftAid?.skippedByPayer;
  const showGiftAidBlock = giftAidStatus && isPaymentSuccess;
  const thankYouNote = request.thankYouNote;
  const isDesktop = platform === PlatformType.Desktop;
  const isOutcome = request.direction === PaymentDirection.OutCome;
  const targetStyles = isDesktop ? styles : mobileStyles;

  useEffect(() => {
    setTimestamp(moment().format('DD/MM/YYYY, HH:mm'));
  }, []);

  if (config.enableCloseButtonForStatusScreenInBrowser) {
    if (!parent) {
      setParent(true)
    }
  }

  var hidden, visibilityChange;

  function handleVisibilityChange() {
    if (document[hidden]) {
      if((platform === PlatformType.Desktop || platform === PlatformType.Web) && boppButton) {
        return
      }


      history.push('/home-screen')
      dispatch(setPaymentRequest(null))
      close()
      document.removeEventListener(visibilityChange, handleVisibilityChange, false);
    }
  }

  useEffect(() => {
    dispatch(setFixedAmount(false));
    if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
      hidden = "hidden";
      visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
      visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    }

    if (typeof document.addEventListener === "undefined" || hidden === undefined) {
      console.log("This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.");
    } else {
      document.addEventListener(visibilityChange, handleVisibilityChange, false);
    }
  }, [])

  useEffect(() => {
    if(iframeRedirectUrl) {
      window.parent.postMessage({
          source:'BOPPAPP',
          action:'paymentStatus',
          data:{status: isPaymentSuccess ? 'success' : 'fail',
          reference: request.orderNumber,
          amount: request.amount,
          instructionId: request.instructionId,
          internalReference: request?.internalReference,
          transactionId:request?.transactionId
        },
      }, "*")
    }

    if (request.redirectUrl) {
        if (window.ReactNativeWebView) {
          dispatch(openNewPage(request.redirectUrl))
        } else {
          window.location.replace(request.redirectUrl);
        }
    }
  }, [request, isPaymentSuccess])

  useEffect(() => {
    dispatch(setStatusBarColor(colors.defaultBar));
  }, []);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2
  })


  const getGiftAidAmountToDisplay = () => formatter.format(request.amount * 0.25);
  const getThanksNoteToDisplay = () => request.direction === PaymentDirection.OutCome && thankYouNote.message;

  const close = () => {
    dispatch(handlePaymentStatus({status:''}))
    history.push('/next');
  };

  const handleCopyClick = () => {
    const data = {
      'Payment status': isPaymentSuccess ? 'Paid' : 'Failed',
      'Paid to': request.accountName,
      'Payment reference': request.orderNumber,
      'Payment amount': formatter.format(request.amount),
      'Transaction ID': decodeExecutingEntityId(request.transactionId),
      'Date and time': timestamp,
      'Gift Aid': showGiftAidBlock ? getGiftAidAmountToDisplay() : undefined,
    };

    let text = '';

    for(const [key, value] of Object.entries(data)) {
      if (value) {
        text += `${key}: ${value}\n`;
      }
    }

    if (window.ReactNativeWebView) {
      dispatch(copyToClipboard(text));
    } else {
      copyToClipboardInBrowser(text);
    }

    setShowToast(true);

    setTimeout(() => {setShowToast(false)}, 3000);
  }

  const renderThankYouNoteBlock = () => {
    return thankYouNote?.enabled && isPaymentSuccess && isPaymentOutcome(request) && (
      <div className={targetStyles.status_screen__thanks_block} style={isDesktop ? {} : inline['status_screen__thanks_block']}>
        {isDesktop && showGiftAidBlock && <div className={targetStyles.status_screen__thanks_block_border} />}
        <span className={targetStyles.status_screen__thanks_block_text} style={isDesktop ? {} : inline['status_screen__thanks_block_text']}>
          {getThanksNoteToDisplay()}
        </span>
      </div>
    )
  }

  const renderGiftAidBlock = () => {
    return showGiftAidBlock && (
      <div className={targetStyles.status_screen__gift_aid_block}>
        <img 
          src={GiftIcon}
          alt="GiftIcon"
          className={targetStyles.status_screen__gift_aid_block_icon}
          style={isDesktop ? {} : inline['status_screen__gift_aid_block_icon']}
        />
        <span
          className={targetStyles.status_screen__gift_aid_block_value}
          style={isDesktop ? {} : inline['status_screen__gift_aid_block_value']}
        >
          {getGiftAidAmountToDisplay()}
        </span>
        <div
          className={targetStyles.status_screen__gift_aid_block_text}
          style={isDesktop ? {} : inline['status_screen__gift_aid_block_text']}
        >
            {isOutcome ? "Extra added by you with Gift Aid" : "Extra added with Gift Aid"}
        </div>
      </div>
    )
  }

  const renderFailReason = () => {
    return failReasonText && (
      <div className={targetStyles.status_screen__gift_aid_block}>
        <span
          className={targetStyles.status_screen__gift_aid_block_value}
          style={{}}
        >
          {failReasonText}
        </span>
      </div>
    )
  }

  const getInfoListItemsToDisplay = () => {
    return (
      <>
        { request.accountName && <InfoListItem name={isOutcome ? 'Paid to' : 'Requested to'} value={request.accountName} /> }
       { request.orderNumber && <InfoListItem name='Payment reference' value={request.orderNumber} /> }
        <InfoListItem name='Payment amount' value={formatter.format(request.amount)} />
        { request.transactionId && <InfoListItem name='Transaction ID' value={checkLength(20, decodeExecutingEntityId(request.transactionId))} /> }
        <InfoListItem name='Date and Time' value={timestamp} />
      </>
    )
  }

  const renderToast = () => {
    return showToast &&
      <Toast
        className={targetStyles.toast}
        title='Copied to clipboard'
        onClose={() => setShowToast(false)}
      />
  }

  const finalClassName = classNames([
    targetStyles.status_screen,
    !isPaymentSuccess && targetStyles['status_screen--fail'],
  ]);

  if (isDesktop) {
    return (
      <div className={finalClassName}>
        <div className={styles.status_screen__content}>
          <div className={styles.status_screen__copy_block} onClick={handleCopyClick}>
            <img className={styles.status_screen__copy_block_icon} src={CopyIcon} />
            <span className={styles.status_screen__copy_block_text}>Copy</span>
          </div>
          <img className={styles.status_screen__top_icon} src={isPaymentSuccess ? SuccessIconRounded : FailIconRounded} />
          <span className={styles.status_screen__title}>{isPaymentSuccess ? 'Payment paid' : 'Payment failed'}</span>
          <div className={styles.status_screen__info_list}>{ getInfoListItemsToDisplay() }</div>
           { renderGiftAidBlock() } 
          { renderThankYouNoteBlock() }
          { renderToast() }
          { renderFailReason() }
        </div>
      </div>
    )
  }

  

  return (
    <div className={finalClassName}>
      <div className={mobileStyles.status_screen__content}>
        <div className={mobileStyles.status_screen__top} style={inline['status_screen__top']}>
          <img className={mobileStyles.status_screen__copy_icon} style={inline['status_screen__copy_icon']} src={CopyIcon} onClick={handleCopyClick} />
          <img className={mobileStyles.status_screen__close_icon} style={inline['status_screen__close_icon']} src={CloseIcon} onClick={() => close()} />
        </div>
        <div className={mobileStyles.status_screen__status_block} style={inline['status_screen__status_block']}>
          <img className={mobileStyles.status_screen__status_icon} style={inline['status_screen__status_icon']} src={isPaymentSuccess ? SuccessIconRounded : WarningIcon} />
          <span className={mobileStyles.status_screen__title} style={inline['status_screen__title']}>{ isPaymentSuccess ? 'Payment paid' : 'Payment failed' }</span>
        </div>
        <div className={mobileStyles.status_screen__info_list} style={inline['status_screen__info_list']}>
          { getInfoListItemsToDisplay() }
        </div>
        { renderGiftAidBlock() }
        { renderThankYouNoteBlock() }
        { renderToast() }
        { renderFailReason() }
      </div>
    </div>
  )
};

const InfoListItem = ({name, value}) => {
  const platform = useSelector(state => state.settings.platform);
  const isDesktop = platform === PlatformType.Desktop;

  const targetStyles = isDesktop ? styles : mobileStyles;

  return (
    <div className={targetStyles.info_list_item} style={isDesktop ? {} : inline['info_list_item']}>
      <span className={targetStyles.info_list_item__name} style={isDesktop ? {} : inline['info_list_item__name']}>{name}</span>
      <span className={targetStyles.info_list_item__value} style={isDesktop ? {} : inline['info_list_item__value']}>{value}</span>
    </div>
  )
}

const inline = {
  ['status_screen__top']: {
    padding: `${cssAdaptivePixelsVh(35)} ${cssAdaptivePixelsVw(20)} ${cssAdaptivePixelsVh(55)} ${cssAdaptivePixelsVw(29)}`,
  },
  ['status_screen__copy_icon']: {
    width: cssAdaptivePixelsVh(32),
    height: cssAdaptivePixelsVh(32),
  },
  ['status_screen__close_icon']: {
    width: cssAdaptivePixelsVh(32),
    height: cssAdaptivePixelsVh(32),
  },
  ['status_screen__status_block']: {
    marginBottom: cssAdaptivePixelsVh(30),
  },
  ['status_screen__status_icon']: {
    width: cssAdaptivePixelsVh(32),
    height: cssAdaptivePixelsVh(32),
    marginRight: cssAdaptivePixelsVw(10),
  },
  ['status_screen__title']: {
    fontSize: cssAdaptivePixelsVh(22),
  },
  ['status_screen__info_list']: {
    padding: `0 ${cssAdaptivePixelsVw(20)}`,
    marginBottom: cssAdaptivePixelsVh(20),
  },
  ['info_list_item']: {
    padding: `${cssAdaptivePixelsVh(16)} 0`,
  },
  ['info_list_item__name']: {
    fontSize: cssAdaptivePixelsVh(14),
    marginRight: cssAdaptivePixelsVw(30),
  },
  ['info_list_item__value']: {
    fontSize: cssAdaptivePixelsVh(14),
  },
  ['status_screen__gift_aid_block_icon']: {
    width: cssAdaptivePixelsVh(26),
    height: cssAdaptivePixelsVh(26),
  },
  ['status_screen__gift_aid_block_value']: {
    margin: `${cssAdaptivePixelsVh(6)} 0`,
    fontSize: cssAdaptivePixelsVh(14),
  },
  ['status_screen__gift_aid_block_text']: {
    fontSize: cssAdaptivePixelsVh(14),
  },
  ['status_screen__thanks_block']: {
    padding: `0 ${cssAdaptivePixelsVw(20)}`,
  },
  ['status_screen__thanks_block_text']: {
    fontSize: cssAdaptivePixelsVh(14),
  }
}

export default StatusScreen;
