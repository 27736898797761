import { useState, useEffect, useRef } from 'react';

const useCountdown = () => {
  const [seconds, setSeconds] = useState(0);
  
  const timer = useRef();

  useEffect(() => {
    return () => clearTimeout(timer.current)
  }, [])

  useEffect(() => {
    if (seconds > 0) {
      timer.current = setTimeout(() => {
        setSeconds((seconds) => seconds - 1)
      }, 1000)
    }
  }, [seconds])

  const start = (seconds) => {
    clearTimeout(timer.current)
    setSeconds(seconds)
  }

  return [seconds, start]
}

export default useCountdown;