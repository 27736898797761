const SET_HISTORY_REQUESTS = 'history/requests/set';
const SET_HISTORY_PAYMENTS = 'history/payments/set';
const ADD_HISTORY_REQUEST = 'history/request/add';
const UPDATE_HISTORY_REQUEST = 'history/request/update';
const ADD_HISTORY_PAYMENT = 'history/payment/add';
const ADD_HISTORY_PENDING_PAYMENT = 'history/payment/pending/add';
const SET_HISTORY_PENDING_PAYMENT = 'history/payment/pending/set';
const UPDATE_HISTORY_PAYMENT = 'history/payment/update';
const SET_HISTORY_REQUESTS_CANCELLED_FROM_HISTORY = 'history/request/cancelled/from/history/set';
const SET_LAST_HISTORY_REQUEST_ID = 'history/last/payment/request/id';
const ADD_HISTORY_LAST_RECEIPT = 'history/last/receipt/add';
const SET_UPDATE_REQUEST_SENT = 'history/update/request/sent/set';
const UPDATE_HISTORY_LAST_RECEIPT = 'history/last/receipt/update';
const ADD_PAYMENT_REQUEST_STATUS_ID = 'history/add/request/status/id';
const REMOVE_PAYMENT_REQUEST_STATUS_ID = 'history/remove/request/status/id';

export const addRequestStatusId = (payload) => ({
    type: ADD_PAYMENT_REQUEST_STATUS_ID,
    payload,
});

export const removeRequestStatusId = (payload) => ({
    type: REMOVE_PAYMENT_REQUEST_STATUS_ID,
    payload,
});

export const setHistoryRequests = (payload) => ({
    type: SET_HISTORY_REQUESTS,
    payload,
});

export const addHistoryRequest = (payload) => ({
    type: ADD_HISTORY_REQUEST,
    payload,
});

export const updateHistoryRequest = (payload) => ({
    type: UPDATE_HISTORY_REQUEST,
    payload,
});

export const setHistoryPayments = (payload) => ({
    type: SET_HISTORY_PAYMENTS,
    payload,
});

export const addHistoryPayment = (payload) => ({
    type: ADD_HISTORY_PAYMENT,
    payload,
});

export const addHistoryPendingPaymentToHistory = () => ({
    type: ADD_HISTORY_PENDING_PAYMENT,
});

export const setHistoryPendingPayment = (payload) => ({
    type: SET_HISTORY_PENDING_PAYMENT,
    payload,
});

export const updateHistoryPayment = (payload) => ({
    type: UPDATE_HISTORY_PAYMENT,
    payload,
});

export const setHistoryRequestCancelledFromHistory = (payload) => ({
    type: SET_HISTORY_REQUESTS_CANCELLED_FROM_HISTORY,
    payload,
});

export const setLasteHistoryPaymentRequestId = (payload) => ({
    type: SET_LAST_HISTORY_REQUEST_ID,
    payload,
});

export const addLasteReceipt = (payload) => ({
    type: ADD_HISTORY_LAST_RECEIPT,
    payload,
});

export const updateLasteReceipt = (payload) => ({
    type: UPDATE_HISTORY_LAST_RECEIPT,
    payload,
});

export const setUpdateRequestSent = (payload) => ({
    type: SET_UPDATE_REQUEST_SENT,
    payload,
});

export const initialState = {
    requests: [],
    payments: [],
    pendingPayment: undefined, // Put payment in order to add it as soon as user press "pay via bank" button
    isCancelledFromHistory: false, // Hack to avoid failed screen when cancelling from history
    lastPaymentHistoryRequestId:'', // Id of last payment request id in order to update new laste receip screen
    lastReceipts: [],// laste receip screen
    isUpdateRequestSent: false,
    paymentRequestStatueIds:[]
};

export const historyReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_PAYMENT_REQUEST_STATUS_ID:
            return {
                ...state,
                paymentRequestStatueIds: [...state.paymentRequestStatueIds, action.payload]
            };
        case REMOVE_PAYMENT_REQUEST_STATUS_ID:
            const index = state.paymentRequestStatueIds.map(item => item).indexOf(action.payload);
            const stateTemp = [
              ...state.paymentRequestStatueIds.slice(0, index),
              ...state.paymentRequestStatueIds.slice(index + 1)
            ];

            return {
                ...state,
                paymentRequestStatueIds: stateTemp
            };
        case SET_UPDATE_REQUEST_SENT:
            return {
                ...state,
                isUpdateRequestSent: action.payload
            };
        case UPDATE_HISTORY_LAST_RECEIPT:
            return {
                ...state,
                lastReceipts: (state.lastReceipts.length && state.lastReceipts || []).map((lastReceipt) => {
                  return lastReceipt.id === action.payload.id ? {...lastReceipt, ...action.payload} : lastReceipt
                })
            };
        case ADD_HISTORY_LAST_RECEIPT:
            return {
                ...state,
                lastReceipts: [...state.lastReceipts,{...action.payload}]
            };
        case SET_LAST_HISTORY_REQUEST_ID:
            return {
                ...state,
                lastPaymentHistoryRequestId: action.payload
            };
        case SET_HISTORY_REQUESTS:
            return {
                ...state,
                requests: [
                    ...action.payload
                ]
            };
        case SET_UPDATE_REQUEST_SENT:
            return {
                ...state,
                isUpdateRequestSent: action.payload
            };
        case ADD_HISTORY_LAST_RECEIPT:
            return {
                ...state,
                lastReceipts: [...state.lastReceipts,{...action.payload}]
            };
        case SET_LAST_HISTORY_REQUEST_ID:
            return {
                ...state,
                lastPaymentHistoryRequestId: action.payload
            };
        case SET_HISTORY_PAYMENTS:
            return {
                ...state,
                payments: [
                    ...action.payload
                ]
            };
        case ADD_HISTORY_REQUEST:
            return {
                ...state,
                requests: [...state.requests,{...action.payload}]
            };
        case UPDATE_HISTORY_REQUEST:
            return {
                ...state,
                requests: (state.requests.length && state.requests || []).map((request) => {
                  return request.ids.paymentRequestId === action.payload.ids.paymentRequestId ? {...request, ...action.payload} : request
                })
            };
        case ADD_HISTORY_PAYMENT:
            //Check is peyment already exist, if yes skiip adding it
            if(state.payments.some(element => (element.id === action.payload.id) && element.dirrection === action.payload.dirrection)) {
                return {
                    ...state
                  };
            }
            return {
                ...state,
                payments: [...state.payments,{...action.payload}]
            };
        case ADD_HISTORY_PENDING_PAYMENT:
            if (state.pendingPayment) {
                return {
                    ...state,
                    payments: [...state.payments,{...state.pendingPayment}]
                };
              }
              return {
                ...state
              };
        case SET_HISTORY_PENDING_PAYMENT:
            return {
                ...state,
                pendingPayment: action.payload
            };
        case UPDATE_HISTORY_PAYMENT:
            return {
                ...state,
                payments: (state.payments.length && state.payments || []).map((payment) => {
                    return payment.id === action.payload.id ? {...payment, ...action.payload} : payment
                })
            };
        case SET_HISTORY_REQUESTS_CANCELLED_FROM_HISTORY: // hack to remove later
            return {
                ...state,
                isCancelledFromHistory: action.payload
            };
        default:
            return {
                ...state,
            };
    }
};

export default historyReducer;
