import { 
    takeLatest, 
    put, 
    delay, 
    cancel, 
    fork 
} from 'redux-saga/effects';
import * as actionType from '../actions/actionTypes';
import { otpCalculated } from '../actions';
import { totp } from 'otplib';
import config from '../config';
import { sendError } from './../resolver/errorResolver';
import { 
    ErrorFlow, 
    ErrorObject 
} from '../entities/errorObject';

function* calculator(options) {
    const interval = options.interval || config.otpIntervalInSec
    let epoch = options.epoch ? options.epoch.getTime() : Date.now()

    while (true) {
        console.log("inside of cycle")
        try {
            switch (options.type) {
                case 'TOTP':
                    totp.options = {
                        epoch: epoch,
                        digits: options.digits,
                        step: interval
                    }
      
                    epoch += interval * 1000 //* 1000 * 1000
    
                    yield put(otpCalculated({
                        issuer: options.issuer,
                        reference: options.reference,
                        password: totp.generate(options.seed),
                        expiry: new Date(epoch),
                        createdTime: new Date()
                    }))

                    break;
                default:
                    break;
            }
        } catch (err) {
            yield put(sendError({error:new ErrorObject('Error in otp calculator', err, ErrorFlow.other)}));
        }

        yield delay(1000 * interval);
    }
}

let calculators = {}

function* otpStart(action) {
    try {
        const options = action.payload;
        const current = calculators[options.reference];

        if (current) {
            yield cancel(current);
        }

        calculators[options.reference] = yield fork(calculator, options);
    } catch (err) {
        yield put(sendError({error:new ErrorObject('Error starting OTP calculation', err, ErrorFlow.other)}));
    }
}

export function* otpCalculator() {
    yield takeLatest(actionType.START_OTP_CALCULATOR, otpStart);
}
