import React, { useEffect } from 'react'
import Tip from '../../../assets/images/Tip.svg'
import '../style.scss';

const  TipModal = ({gotItCallback, tipMessage}) => {
    const tipMessages = {
        bankAppTip: {
            icon: Tip,
            title: "Tip from BOPP",
            message: "Using your bank app to authorise is always quicker than using your bank's website",
            useEffectFunction: () => {
                localStorage.setItem('bankingTipShown', true)
            }
        },
    }

    useEffect(tipMessages[tipMessage].useEffectFunction, [])

    

    return (
        <>
            <div className="gray-background" />
            <div className="modal-window tip-modal">
                <div className="background-color background-color__base background-color__tip" />
                <div className={`modal-window-content modal-window-content__tip`}>
                    <img className="modal-title__tip-icon" src={tipMessages[tipMessage].icon} alt="tip icon"></img>
                    <div className="modal-title modal-title__tip-header">
                        {tipMessages[tipMessage].title}
                    </div>
                    <div className="modal-title__question modal-title__lite modal-title__tip">
                        {tipMessages[tipMessage].message}
                    </div>

                    <div className="modal-description-buttons modal-description-buttons__tip">
                        <button onClick={gotItCallback} className="modal-buttons modal-buttons__tip">GOT IT</button> 
                    </div>
                </div>
            </div>
        </>
    )
}

export default TipModal
