import React from 'react';
import './style.scss';

import {PlatformType, StatusBank} from "../../static/CommonDefinitions";
import ThreeLinesGray from '../../assets/images/three-lines-gray.svg';
import { useSelector } from 'react-redux';

const BankItem = ({bankStatus, bankLogo, defaultLogo, bankName, chooseBank, frequentingList, onHold}) => {
    const platform = useSelector(state => state.settings.platform);

    return (
        <div
            className={`bank-item ${bankStatus === StatusBank.Unhealthy ? 'grey' : ''} ${platform === PlatformType.Desktop  ? 'bank-item-desktop' : ''}`}
            onClick={chooseBank}
        >
            <div className={`icon-background ${bankLogo ? 'icon-background-white' : ''}`}>
                {bankLogo ? <img className="bank-account-logo-img" src={bankLogo} alt="bank-logo" /> : <img src={defaultLogo} alt="bank-logo" />}
            </div>
            <div className="account-block">
                <div className="account-name">
                    {bankName}
                    {bankStatus === StatusBank.Unhealthy ?
                        <span className="red-text">Currently unavailable</span> : null
                    }
                </div>
                { frequentingList ?
                    <img className="bank-item__holder" src={ThreeLinesGray} alt="three-lines" onTouchStart={onHold}/> 
                    : null}
            </div>
        </div>
    );
}
export default BankItem;