import { 
  takeLatest, 
  put, 
  select 
} from 'redux-saga/effects';
import { setPermissionStatus } from '../reducers/permissionsReducer';
import { 
  PermissionType, 
  PermissionState, 
  AdapterType, 
  Home, 
  StatusAccountDetails, 
  PostMessageType, 
  IdDomain,
  PaymentRequestType
} from '../static/CommonDefinitions';
import { 
  setHistoryPayments, 
  setHistoryRequests 
} from '../reducers/historyReducer';
import { getAdapterState } from './hardwareSaga';
import { setAdapterState } from '../reducers/hardwareReducer';
import * as actionType from '../actions/actionTypes';
import {
  setPlatform,
  handleBankMessage,
  setHome,
  handlePaymentStatus,
  putTransactionsCounter,
  setPinValue,
  setBuildNumber,
  addBank,
  handleMessage,
  changePaymentStatusScreen,
  changeAccountDetailsScreen,
  handleAccountDetailsStatus,
  changeWrongScreen,
  setUsedBank,
  hideCamera,
  cancelAccountDetails,
  cancelDomesticPayment,
  setIsAppLaunchedFirstTime,
  clickBackButton,
  setPaymentRequestMethod,
  setTimerDeadline,
  showInvalidPaymentError,
  changeAmount,
  openNewPage,
  setPayeeSubscriberIds,
  getPayeeSubscriberIdByPayeeIdAction,
  runMigrationAction,
  getFromApp,
  setCustomUrlPaymentSettings,
  setFailReasonText,
  getApiKeyByPayeeIdAction
} from '../actions';

import { getIdDomain, parseCallbackURL } from './../helpers'
import { UcbCallBack } from './../helpers/ucbCallback'
import { PaylinkUrlParameters } from '../helpers/PaylinkUrlParameters';
import { sendError } from './../resolver/errorResolver';
import { setPaymentRequest } from '../reducers/paymentPersistReducer';
import { 
  ErrorFlow, 
  ErrorObject 
} from '../entities/errorObject';
import { resolveChangeOutcomingMessageAction } from '../resolver/payment/changePaymentInstructionProcessor';

const sendMessage = (data) => {
  const msgObj = {
    ...data,
  };
  msgObj.msgId = Math.floor((1 + Math.random()) * 0x10000).toString(16);

  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify(msgObj));
  }
};

function* sendPostMessage(action) {
  try {
    if (window.ReactNativeWebView) {
      yield sendMessage(action.payload);
    } else {
      if (action.payload.type === 'openNewTab') {
        yield handlePostMessage(handleMessage(action.payload), window)
      }

      if (action.payload.type === 'bankMessage') {
        yield handlePostMessage(handleMessage(action.payload), window)
      }

      if (action.payload.type === 'storage') {
        if (action.payload.set) {
          switch (action.payload.set) {
            case 'string':
              window.localStorage.setItem(action.payload.key, action.payload.value);
              break;
            case 'home':
            case 'transactionsCounter':
              window.localStorage.setItem(action.payload.set, action.payload.state);
              break;
            default:
              console.log("UNHANDLED SET", action.payload.set, action)
          }
        } else if (action.payload.get) {
            switch (action.payload.get) {
              case 'string':
                const value = window.localStorage.getItem(action.payload.key);
                yield handlePostMessage(handleMessage({
                  type: 'storage',
                  key: action.payload.key,
                  value
                }), window);
                break;
              case 'home':
              case 'transactionsCounter':
                 yield handlePostMessage(handleMessage({
                   type: 'storage',
                   [action.payload.get]: window.localStorage.getItem(action.payload.get)
                 }), window);
                 break;
              default:
                console.log("UNHANDLED GET", action.payload.get, action)
            }
          } else {
            console.log("UNHANDLED STORAGE EVENT", action)
        }
      }
    }
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error sending post message', error, ErrorFlow.mobileStorage)}));
  }
}
let windows = {};
const paylinkUrl = process.env.REACT_APP_SETTING_PAYLINK_URL;

function* handlePostMessage(action) {

  const { data:payload, source, history } = action.payload;

  switch (payload.type) {
    case PostMessageType.OpenNewTab:
      yield handleOpenNewTabMessage(payload, source)
      break;
    case PostMessageType.Permission:
      yield handlePermissionMessage(payload)
      break;
    case PostMessageType.Bluetooth:
      yield handleBluetoothMessage(payload)
      break;
    case PostMessageType.LocationAdapter:
      yield handleLocationMessage(payload)
      break;
    case PostMessageType.Platform:
      yield handlePlatformMessage(payload)
      break;
    case PostMessageType.IsAppLaunchedFirstTime:
      yield handleIsAppLaunchedFirstTimeMessage(payload)
      break;
    case PostMessageType.Storage:
      yield handleStorageMessage(payload)
      break;
    case PostMessageType.AppState:
      yield handleAppStateMessage(payload)
      break;
    case PostMessageType.OpenedFromULink:
      yield handleBankPostMessage(payload, history)
      break;
    case PostMessageType.BankMessage:
      yield handleBankPostMessage(payload, history)
      break;
    case PostMessageType.ScanQR:
      yield handleScanQRMessage(payload, history)
      break;
    case PostMessageType.AndroidBackButton:
      yield handleAndroidBackButtonMessage(payload)
      break;
    case PostMessageType.AppVersion:
      yield handleAppVersionMessage(payload)
      break;
    case PostMessageType.PinProtection:
      yield handlePinProtectionMessage(payload)
      break;
    default:
      if(payload?.type && !payload?.source) {
        yield put(sendError({error:new ErrorObject('Error handling post message, such action is not handled', action, ErrorFlow.mobileStorage)}));
      }
      break;
  }
}

function* handleOpenNewTabMessage(payload, source) {
  try {
    switch (payload.action) {
      case 'open':
        windows[payload.url] = window.open(payload.url, "_blank");
        const home = yield select(state => state.settings.home);
  
        const clickPayViaBank = yield select(state => state.payment.paymentStatusScreen.clickPayViaBank);
        const clickConfirm = yield select(state => state.payment.accountDetailsScreen.clickConfirm);
  
        if(home === Home.Pay && clickPayViaBank) {
          yield put(changePaymentStatusScreen({waitingForConsent: true, transferringToBank: false}));
        } else if(home === Home.Request && clickConfirm) {
          yield put(changeAccountDetailsScreen({waitingForConsent: true, transferringToBank: false}));
        }
        break;
      case 'close':
        for (const url in windows) {
          if (windows[url]===source) {
            source.close();
            delete windows[url];
          }
        }
        break;
      default:
        console.log("such action is not handled", payload.action)
        break;
    }
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error post message open new tab', error, ErrorFlow.other)}));
  }
}

function* handlePermissionMessage(payload) {
  try {
    /**
    * Description: payload.status may contain next values:
    * "status" : "unavailable" // This feature is not available (on this device / in this context)
    * "status" : "denied" // The permission has not been requested / is denied but requestable
    * "status" : "granted" // The permission is granted
    * "status" : "blocked" // The permission is denied and not requestable anymore can be guaranteed only from phone settings
    */
    yield put(setPermissionStatus(payload.name, payload.status))

    //Set bluetooth state listener or get Location adapter state only when user grant permission
    //For iOS platform we need to check only bluetooth adapter, for android we need to check both bluetooth and location.
    if(payload.status === PermissionState.Granted) {
      if(payload.name === PermissionType.Bluetooth) {
        yield put(getAdapterState(AdapterType.Bluetooth));
      } else if(payload.name === PermissionType.Location) {
        yield put(getAdapterState(AdapterType.Location));
        yield put(getAdapterState(AdapterType.Bluetooth));
      }
    }
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error post message handle permission ' + PermissionType.Bluetooth + ' ', error, ErrorFlow.other)}));
  }
}

function* handleBluetoothMessage(payload) {
  try {
    // "value" == "PoweredOn" - "action"
    // cancel_all we lose bluetooth
    // none in all other cases
    yield put(setAdapterState(AdapterType.Bluetooth, payload.status));
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error post message bluetooth state', error, ErrorFlow.other)}));
  }
}

function* handleLocationMessage(payload) {
  try {
    yield put(setAdapterState(AdapterType.Location, payload.status));
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error post message location state', error, ErrorFlow.other)}));
  }
}

function* handlePlatformMessage(payload) {
  try {
    if(payload.os) {
      yield put(setPlatform(payload.os));
    } else {
      yield put(sendError({error:new ErrorObject('Error post message undefined platform', payload.os, ErrorFlow.other)}));
    }
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error post message platform', error, ErrorFlow.other)}));
  }
}

function* handleIsAppLaunchedFirstTimeMessage(payload) {
  try {
    yield put(setIsAppLaunchedFirstTime(payload.value));
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error post message isAppLaunchedFirstTime', error, ErrorFlow.other)}));
  }
}

function* handleStorageMessage(payload) {
  try {
    if(payload.key === 'bankAccountDetails') {
      if (payload.value !== 'unknown') {
        yield put(addBank(JSON.parse(payload.value)));
        yield put(getFromApp('subscriberInfo'));
        yield put(getFromApp('checkMigration'));
      }
    } else if(payload.key === 'checkMigration') {
      if (payload.value === 'unknown' || payload.value === null) {
        yield put(runMigrationAction())
      }
    } else if(payload.key === 'subscriberInfo') {
      if (payload.value !== 'unknown' && payload.value) {
        let ids = JSON.parse(payload.value)
        if(ids.payee && ids.subscriber && ids.apiKey) {
          if(ids.payee.length === 0  || ids.subscriber.length === 0 || ids.apiKey.length === 0) {
            yield put(getPayeeSubscriberIdByPayeeIdAction())
            yield put(getApiKeyByPayeeIdAction())
          } else {
            yield put(setPayeeSubscriberIds(ids))
          }
        } else {
          yield put(getPayeeSubscriberIdByPayeeIdAction())
          yield put(getApiKeyByPayeeIdAction())
        }
      } else {
        yield put(getPayeeSubscriberIdByPayeeIdAction())
        yield put(getApiKeyByPayeeIdAction())
      }
    } else if(payload.key === 'timerDeadline') {
      if (payload.value !== 'unknown') {
        yield(put(setTimerDeadline(JSON.parse(payload.value))));
      }
    } else if(payload.kind == 'request') {
      const requests = payload.history.slice().map(el => JSON.parse(el));
      yield put(setHistoryRequests(requests));
    } else if(payload.kind == 'payment') {  
      const payments = payload.history.slice().map(el => JSON.parse(el));
      yield put(setHistoryPayments(payments));
    } else {
      if (payload.home) {
        if(payload.home === 'undefined') {
          yield put(setHome(Home.Pay));
        } else {
          yield put(setHome(payload.home));
        }
      }
  
      if (payload.lastUsedBank) {
        if(payload.lastUsedBank !== 'unknown') {
          yield put(setUsedBank(JSON.parse(payload.lastUsedBank)));
        }
      }
      if (payload.transactionsCounter) {
        yield put(putTransactionsCounter(payload.transactionsCounter));
      }
    }
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error post message storage', error, ErrorFlow.other)}));
  }
}

function* handleAppStateMessage(payload) {
  try {
    const home = yield select(state => state.settings.home);
    if(payload.value === 'active'){
      if(home === Home.Pay){
        yield put(changePaymentStatusScreen({clickPayViaBank: false, consentFromBank: false}));
      } else {
        yield put(changeAccountDetailsScreen({clickConfirm: false, consentFromBank: false}));
      }
    }
    if(payload.value === 'inactive'){
      const clickPayViaBank = yield select(state => state.payment.paymentStatusScreen.clickPayViaBank);
      const clickConfirm = yield select(state => state.payment.accountDetailsScreen.clickConfirm);

      yield put(showInvalidPaymentError(false))
      if(home === Home.Pay && clickPayViaBank) {
        yield put(changePaymentStatusScreen({waitingForConsent: true, transferringToBank: false}));
      }
      if(home === Home.Request && clickConfirm){
        yield put(changeAccountDetailsScreen({waitingForConsent: true, transferringToBank: false}));
      }
    }
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error post message app state', error, ErrorFlow.other)}));
  }
}

function* handleBankPostMessage(payload, history) {
  try {
    yield put(handlePaymentStatus({ status: '' }));
      yield put(hideCamera());

      if (payload.url && payload.url.includes(paylinkUrl + '/link/')) { // Handle payment request
        yield processPaylink(payload.url, history)
        return;
      }

      let params = parseCallbackURL(payload)
      let state = params.get('state')
      let error = params.get('error')
      let code = params.get('code')

      if(state == null) {
        yield put(sendError({error:new ErrorObject('Filed to retrieve state value from bank response', payload?.url || payload?.value, ErrorFlow.payments)}));
        yield put(changePaymentStatusScreen({ paymentInProcess: false , waitingForCancel:false, cancelled: false, transferringToBank:false, waitingForConsent:false}));
        yield put(handlePaymentStatus({ status: 'failed' }));
        yield put(setFailReasonText('Invalid Bank Consent. Please try again'));
        yield put(setPaymentRequest(null))
        history.push('/status')
        return;
      }
      // handle error in response from bank
      if (error != null || code === 'reject') {
        if (state?.startsWith('aisp-')) { // account error
          yield put(changeAccountDetailsScreen({waitingForConsent: false, clickCancelConsent: false, cancelled: true, waitingForCancel:false, paymentInProcess: false, transferringToBank:false }))
          yield put(handleAccountDetailsStatus({statusAccountDetails: StatusAccountDetails.Failed}));
          return yield put(cancelAccountDetails());
        } else { // payment error
          let previousId = "cri:" + state.split('-')[0];
          yield put(changeAmount('0.00'));
          yield put(setPaymentRequest(null))
          if(error) {
            yield put(sendError({error:new ErrorObject('Bank responded with access denied', payload?.url || payload?.value, ErrorFlow.payments)}));
          } 
          yield put(changePaymentStatusScreen( {waitingForCancel: true, cancelled: true}));
          return yield put(cancelDomesticPayment({
            paymentMethod: 'BoppPaylink',
            previousId,
          }));
        }
      }

      let previousId = "cri:" + state.split('-')[0];
    
      if(params.get('code') == null || previousId == null) {
        yield put(sendError({error:new ErrorObject('Code or previous ID values missing from bank response', payload?.url || payload?.value, ErrorFlow.payments)}));
        yield put(changePaymentStatusScreen({ paymentInProcess: false , waitingForCancel:false, cancelled: false, transferringToBank:false, waitingForConsent:false}));
        yield put(handlePaymentStatus({ status: 'failed' }));
        yield put(setFailReasonText('Invalid Bank Consent. Please try again'));
        yield put(setPaymentRequest(null))
        history.push('/status')
        return
      }

      // handle success flow
      let detailsScreenData = {
        waitingForConsent: false,
        transferringToBank: false,
        consentFromBank: true,
        clickConfirm: false
      }

      if(state.startsWith('aisp-')){ // account flow
        if (payload.url && payload.url.includes('/ucb')) {
          let ucbCallback = new UcbCallBack(payload.url)
          if(ucbCallback.redirectUrl) {
              yield put(openNewPage(ucbCallback.redirectUrl));
          }

          yield put(changeAccountDetailsScreen({waitingForConsent: false, clickCancelConsent: false, cancelled: false, waitingForCancel:false, paymentInProcess: false, transferringToBank:false, savingAccountDetails: false }))

          return

        } else {
          const accountDetails = yield select(state => state.payment.accountDetails);

          if(accountDetails?.bank?.accountName) {
            detailsScreenData.savingAccountDetails = true
            yield put(changeAccountDetailsScreen(detailsScreenData));
          } else {
            return yield put(changeWrongScreen(true));
          }
        }
      } else { // payment flow
          detailsScreenData.paymentInProcess = true
          yield put(changePaymentStatusScreen(detailsScreenData));
          yield put(setHome(Home.Pay));
      }

      previousId = "cri:" + state.split('-')[0];
      
      if(state.startsWith('aisp-')){ 
        yield put(handleBankMessage({
          code: params.get('code'),
          idToken: params.get('id_token'),
          state: params.get('state'),
          paymentMethod: 'BoppPaylink',
          previousId,
        }));
      } else {
        if(getIdDomain(previousId) == PaymentRequestType.Recurring) {
          yield put(resolveChangeOutcomingMessageAction({
            idToken: params.get('id_token'),
            previousId,
            consent: params.get('code'),
          }))
        } else {
          yield put(handleBankMessage({
            code: params.get('code'),
            idToken: params.get('id_token'),
            state: params.get('state'),
            paymentMethod: 'BoppPaylink',
            previousId,
          }));
        }
      }
      
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error post message bank message', error, ErrorFlow.payments)}));
  }
}

function* handleScanQRMessage(payload, history) {
  try {
    if (payload.value && payload.value.includes(paylinkUrl + '/link/')) {
      yield processPaylink(payload.value, history)
    } else {
      yield put(sendError({error:new ErrorObject('Error post message scanQR: not a playlink QR', payload.value, ErrorFlow.other)}));
    }
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error post message scanQR', error, ErrorFlow.other)}));
  }
}

function* handleAndroidBackButtonMessage(payload) {
  try {
    const savingAccountDetails = yield select(state => state.payment.accountDetailsScreen.savingAccountDetails);
    if (savingAccountDetails) {
      yield put(clickBackButton(true));
    }
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error post message android back button', error, ErrorFlow.other)}));
  }
}

function* handleAppVersionMessage(payload) {
  try {
    if (payload.payload.build) {
      yield put(setBuildNumber(payload.payload.build));
    } else {
      yield put(sendError({error:new ErrorObject('Error post message application version not passed', payload, ErrorFlow.other)}));
    }
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error post message application version', error, ErrorFlow.other)}));
  }
}

function* handlePinProtectionMessage(payload) {
  try {
    if (payload.event === 'getValues') {
      yield put(setPinValue({
        bankAccount: payload.bankAccount,
        activity: payload.activity,
        boppLaunch: payload.launch,
      }))
    }
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error post message pin protection', error, ErrorFlow.other)}));
  }
}


function * processPaylink(url, history) {
  yield put(setPaymentRequest(null))
  yield put(changeAmount('0.00'));
  yield put(setPaymentRequestMethod('paylink', 'paylink'))
  history.push('/link/' + url.split('/').pop());

  let paylinkUrlParameters = new PaylinkUrlParameters(url)
  yield put(setCustomUrlPaymentSettings({amount: paylinkUrlParameters.amount, reference: paylinkUrlParameters.paymentReference}))
}

export function* postMessageWatcher() {
  yield takeLatest(actionType.SEND_POST_MESSAGE, sendPostMessage);
  yield takeLatest(actionType.HANDLE_POST_MESSAGE, handlePostMessage);
}
