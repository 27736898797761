import React from 'react'
import './style.scss'

/**
 * 
 * @param {*} param0 
 * 
 * button types ["cancel", "tip"]
 */
function InfoModal({message, buttons, title}) {
    return (
        <>
            <div className="gray-background" />
            <div className="modal-window tip-modal">
                <div className="background-color background-color__base background-color__tip" />
                <div className={`modal-window-content modal-window-content__tip`}>
                    <div className="modal-title__question modal-title__tip">
                       {title && <h2 className="tip-title" style={{margin: "10px 13px 0"}}>
                            {title}
                        </h2>}
                        <div className="tip-text" style={{margin: "10px 13px 0"}}>
                            {message}
                        </div>
                    </div>
                    {
                        buttons.map(item => {
                            return <div className={"modal-button-block"}>
                                <div onClick={item.buttonOnClick} className={`modal-button modal-button__${item.buttonType?item.buttonType.toLowerCase(): "cancel"}`}>
                                    {item.buttonText}
                                </div>
                        </div>
                        })
                    }
                    
                </div>
            </div>
        </>
    )
}

export default InfoModal
