import { common } from ".";

interface GetPaymentHistoryRequest extends common.Activation {
    entitySelector:common.EntitySelector
    reportSelector: {
        stateSelector: common.StateSelector
    },
    visit: boolean
}

export class GetPaymentHistory {
    id: string
    sd: Date
    payee: string

    constructor(id: string, sd: Date, payee: string) {
        this.id = id
        this.sd = sd
        this.payee = payee
    }

    createRequest() {
        return this.getRequestParams()
    }

    getRequestParams(): GetPaymentHistoryRequest {
        return  {
            '@type': 'https://dvschema.io/activation#StatusRequest',
            id: this.id,
            entitySelector: {
                entityType: 'https://miapago.io/paylink/request/PaylinkRequest',
                id: this.payee,
                propertiesSelector: {
                    '@type': 'https://miapago.io/paylink/request#PropertiesSelector',
                    paymentMethods: [{
                        methodType: 'OpenBankingDomesticPayment',
                    }],
                },
            },
            reportSelector: {
                stateSelector: {
                    "@type": "https://miapago.io/paymentreport#StateSelector",
                    "parties": {
                        "payment:Payee": this.payee
                    },
                    startDate: this.sd,
                }
            },
            visit: true,
            timestamp: new Date(),
            possibleResend: false
        }
    }
}