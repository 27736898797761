// outsource dependencies
import React, { useRef, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// local dependencies
import Button from "../../components/Button";
import Menu from '../../assets/images/menu.png';
import BankLogo from "../../components/BankLogo";
import RedWarningIcon from "../../assets/images/red-warning.svg";
import colors from '../../assets/style.theme.scss';
import AmountInput from "../../components/AmountInput";
import ChangeScreen from '../../assets/images/scan.svg';
import Settings from '../../assets/images/settings.svg';
import { AccountType, TermType } from "../../static/CommonDefinitions";
import Preloader from "../../assets/images/spinner-black.svg";
import HeaderUniversal from "../../components/HeaderUniversal";
import AccountDeactivated from '../AccountDeactivated';
import LimitReached from '../LimitReached';
import {
    changeWrongScreen,
    createPaylinkRequest,
    setHomePage,
    toggleMenu,
    setStatusBarColor,
    changeAmount,
    getPayeeSubscriberIdByPayeeIdAction,
    getApiKeyByPayeeIdAction,
} from "../../actions";

import './style.scss';
import { startExpiryDateTimer } from '../../sagas/paymentRequestSettingsSaga';

let showWrongScreen = null;
const empty = () => false;

const PayeePage = () => {
    const amountInputRef = useRef();
    const dispatch = useDispatch();
    const history = useHistory();

    const bank = useSelector(state => state.payment.bank);
    const amount = useSelector(state => state.payment.amount);
    const oneTimePaymentSettings = useSelector(state => state.paymentRequestSettings.oneTimePaymentSettings);
    const oneTimePaymentSettingChanges = useSelector(state => state.paymentRequestSettings.oneTimeSettingChanges);
    const paylink = useSelector(state => state.payment.paylink);
    const isAccountDeactivated = useSelector(state => state.payment.isAccountDeactivated);
    const isLimitReached = useSelector(state => state.payment.isLimitReached);
    const expireDateIsSetToPast = useSelector(state => state.paymentRequestSettings.expireDateIsSetToPast);
    const [isDisabled, setIsDisabled] = useState(false);
    const [fixedAmount, setFixedAmount] = useState(true);
    const [error, setError] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const paymentPayeeSubscriberIds = useSelector(state => state.payment.paymentPayeeSubscriberIds);

   // useEffect(() => {
   //     if (oneTimePaymentSettings.expire.onCertainDate) {
   //         dispatch(startExpiryDateTimer({expiryDate: oneTimePaymentSettings.expire.certainDate }));
   //     }
   // }, [oneTimePaymentSettings.expire.onCertainDate, oneTimePaymentSettingChanges.expire.certainDate]);

    useEffect(() => {
        if(oneTimePaymentSettings.amount.type === TermType.AnyAmount) {
            setFixedAmount(false);
            setIsDisabled(true);
        }
    }, [oneTimePaymentSettings]);

    useEffect(() => {
        if(!isSubscriptionIdsExist()) {
            dispatch(getPayeeSubscriberIdByPayeeIdAction());
            dispatch(getApiKeyByPayeeIdAction())
        } 

        document.querySelector('.animation').addEventListener('animationend', clearAnimation);
        return () => {
            clearTimeout(showWrongScreen);
            document.querySelector('.animation').removeEventListener('animationend', clearAnimation);
        }
    }, []);

    useEffect(() => {
        if(isAccountDeactivated) {
            clearTimeout(showWrongScreen);
        }
    }, [isAccountDeactivated]);

    useEffect(() => {
        if(isLimitReached) {
            clearTimeout(showWrongScreen);
        }
    }, [isLimitReached]);

    useEffect(() => {
        if(paylink) {
            const id = paylink.split('/').pop();
            history.push(`/payee-request/${id}`);
        }
    }, [paylink]);

    useEffect(() => {
        dispatch(setStatusBarColor(colors.mainYellow));
    }, []);

    useEffect(() => {
        if (isNaN(amount)) {
            dispatch(changeAmount('0.00'));
        }
    }, []);

    const clearAnimation = () => {
        setError(false);
    };

    const isSubscriptionIdsExist = () => {
        if(bank.subscriptionType != AccountType.Personal) {
            if(paymentPayeeSubscriberIds.apiKey != undefined) {
                if(paymentPayeeSubscriberIds.apiKey.length === 0) {
                    return false
                }
            }
        }
        
        if(bank.subscriptionType) {
            if(bank.subscriptionType != AccountType.Personal) {
                if(paymentPayeeSubscriberIds.payee != undefined) {
                    if(paymentPayeeSubscriberIds.payee.length === 0) {
                        return false
                    }
                }
            }
        }

        return true
    }

    const handleSendRequest = () => {
        if (fixedAmount && Number(amount) <= 0) {
            return amountInputRef.current.error();
        }

        if(!isSubscriptionIdsExist()) {
            dispatch(getPayeeSubscriberIdByPayeeIdAction());
            dispatch(getApiKeyByPayeeIdAction())
            dispatch(changeWrongScreen(true));
            return
        } 

        const requestAmount = fixedAmount ? amount : null;
        setShowLoader(true);
        setIsDisabled(true);
        dispatch(createPaylinkRequest({
            amount: requestAmount,
            orderNumber: bank.orderNumber,
            accountNumber: bank.accountNumber,
            accountName: bank.accountName,
            sortCode: bank.sortCode,
        }))

        showWrongScreen = setTimeout(() => {
            dispatch(changeWrongScreen(true));
        }, 30000);
    };

    const openPaymentSettings = () => {
        history.push('/payment-settings');
    };

    const changePage = () => {
        dispatch(setHomePage('pay'));
    };

    const handleClickPanel = () => {
        if(isDisabled) {
            setError(true);
        }
    };

    const handleOpenMenu = () => {
        dispatch(toggleMenu());
    };

    const differentSettings = Object.values(oneTimePaymentSettingChanges).includes(true);
    const requestButtonDisabled = expireDateIsSetToPast || (oneTimePaymentSettings.amount.type !== TermType.AnyAmount && +amount === 0);

    return (
        <>
            {isAccountDeactivated && <AccountDeactivated />}
            {isLimitReached && <LimitReached />}

            <div className="payee-page">
                <div>
                    <HeaderUniversal
                        rightButtonIcon={Menu}
                        leftButtonIcon={ChangeScreen}
                        leftButtonEvent={showLoader ? empty : changePage}
                        rightButtonEvent={showLoader ? empty : handleOpenMenu}
                    />
                    <div className="payee-page__amount">
                        <AmountInput
                            ref={amountInputRef}
                            fixedAmount={fixedAmount}
                            disabled={isDisabled}
                            handleClickPanel={handleClickPanel}
                        />
                        {
                            expireDateIsSetToPast &&
                            <div className="payee-page__invalid-expire-date-error">
                                <div className="payee-page__invalid-expire-date-error-inner">
                                    <img src={RedWarningIcon} />
                                    <span>Please change expiry date in request settings</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="payee-page__buttons">
                    <div className={`payee-page__buttons--settings ${error ? 'error' : ''}`}>
                        <img className="animation" src={Settings} alt="settings" onClick={showLoader ? empty : openPaymentSettings}/>
                        { differentSettings && <span className="payee-page__buttons--difference"/> }
                    </div>
                    <Button
                        disabled={requestButtonDisabled}
                        color="yellow"
                        handleClick={handleSendRequest}
                        title={showLoader ? <img src={Preloader} alt="spinner"/> : 'Request'}
                    />
                    <BankLogo logo={bank?.logo} bankName={bank.bankName} disabled={showLoader}/>
                </div>
            </div>
        </>
    )
};

export default PayeePage;
