import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import kyc from './kycReducer';
import history from './historyReducer';
import payment from './paymentReducer';
import paymentPersist from './paymentPersistReducer';
import settings from './settingsReducer';
import hardware from './hardwareReducer';
import permissions from './permissionsReducer';
import paymentRequestSettings from './paymentRequestSettingsReducer';
import pispSlice from './pispSlice';
import paylinkStatusSlice from './statusSlice';

const rootReducer = (historyObj) => combineReducers({
  router: connectRouter(historyObj),
  payment,
  paymentPersist,
  history,
  paymentRequestSettings,
  settings,
  permissions,
  hardware,
  kyc,
  pisp:pispSlice,
  paylink:paylinkStatusSlice
});

export default rootReducer;
