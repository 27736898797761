import {
  createStore,
  applyMiddleware,
  compose
} from 'redux';
import config from '../config';
import createSagaMiddleware from 'redux-saga';
import {
  persistStore,
  persistReducer
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';
import rootReducer from '../reducers';
import rootSaga from '../sagas';
import setupSocket from '../sockets';
import { createBrowserHistory } from 'history'
import { routerMiddleware } from "connected-react-router";
import { toBool } from '../utils';

export const history = createBrowserHistory()

const reduxLogger = toBool(process.env.REACT_APP_REDUX_LOGGER);
const persistConfig = {
  key: 'boopapp',
  storage,
};

const enchantedCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const middlewares = reduxLogger ?
  applyMiddleware(routerMiddleware(history), sagaMiddleware, logger) :
  applyMiddleware(routerMiddleware(history), sagaMiddleware);


export const store = createStore(
  persistReducer(persistConfig, rootReducer(history)),
  enchantedCompose(middlewares));
export const persistor = persistStore(store);

const accountsSocket = setupSocket(store.dispatch, config.accountsServerURL);
sagaMiddleware.run(rootSaga, { sockets: { accountsSocket }, dispatch:store.dispatch});

export const RootState = store.getState