import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Toast from '../../components/Toast';
import { setStatusBarColor, copyToClipboard } from '../../actions';
import HeaderUniversal from '../../components/HeaderUniversal';
import CopyIcon from '../../assets/images/copy-black.svg';
import colors from '../../assets/style.theme.scss';
import Logo from '../../assets/images/bopp-logo.svg';
import './index.scss';
import { copyToClipboardInBrowser } from '../../utils';

let toastTimeout = null;

const CheckInsideApp = () => {
  const dispatch = useDispatch();
  const [showInfoPopup, setShowInfoPopup] = useState(false);

  const paylinkUrl = window.location.hostname + '/link/' + window.location.href.split('/').pop();
  const paiLinkPublicStyle = paylinkUrl.replace(/http:\/\/|https:\/\//g, '');

  useEffect(() => {
    dispatch(setStatusBarColor(colors.mainYellow));
  }, []);

  const copyLink = (e) => {
    e.stopPropagation();
    setShowInfoPopup(true);

    if (toastTimeout) {
      clearTimeout(toastTimeout)
    }

    toastTimeout = setTimeout(() => {
      setShowInfoPopup(false);
    }, 5000);

    copyToClipboardInBrowser('https://' + paiLinkPublicStyle);
  };

  return (
    <div className="check-in-app">
      <HeaderUniversal logo={Logo} />

      <div className="check-in-app__content">
        <h2 className="check-in-app__title">Please continue in browser</h2>

        <p className="check-in-app__message">
          To ensure that the BOPP paylink opens securely, simply copy and paste the link below into Chrome (or alternate browser):
        </p>

        <div className="check-in-app__paylink-container" onClick={copyLink}>
          <p className="check-in-app__paylink">
            {paiLinkPublicStyle}
          </p>
          <img src={CopyIcon} alt="Press to copy" className="check-in-app__copy-ico"/>
        </div>
      </div>
      {showInfoPopup &&
        <Toast
          title='Copied to clipboard'
          onClose={() => setShowInfoPopup(false)}
        />
      }
    </div>
  );
}

export default CheckInsideApp;
