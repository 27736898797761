import React from 'react';

import './style.scss';
import CalendarIcon from '../../assets/images/calendar.svg';

import { PaymentRequestStatus } from '../../static/CommonDefinitions';
import { returnDate, formatAmount } from '../../helpers';

const RequestsHistoryItem = ({ status, amount, date, accountName, subAmount, handleClick }) => {
  const isRequestOpen = status === PaymentRequestStatus.Open;

  return (
    <div className="r-history-item" onClick={() => handleClick('uniqueID')} >
      <div className="r-history-item__row">
        <div className={`r-history-item__status ${!isRequestOpen ? 'r-history-item__status--closed' : ''}`}>
          {isRequestOpen ? 'Open' : 'Closed'}
        </div>
        <p className="r-history-item__text">
          Total paid
        </p>
      </div>

      <div className="r-history-item__row">
        <p className="r-history-item__bank-name">
          {accountName}
        </p>
        <p className="r-history-item__amount">
          {formatAmount(amount)}
        </p>
      </div>

      <div className="r-history-item__row">
        <p className="r-history-item__text">
          <img className="r-history-item__calendar-icon" src={CalendarIcon} alt="CalendarIcon"/>
          {returnDate(date, '/')}
        </p>
        <p className="r-history-item__text">
          {subAmount ? formatAmount(subAmount) : 'Open amount'}
        </p>
      </div>
    </div>
  )
};

export default RequestsHistoryItem;
