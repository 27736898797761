import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import classNames from 'classnames';
import moment from 'moment';

import { copyToClipboard } from '../../actions';
import './style.scss';

import { returnDate, formatAmount, returnShortTime, checkLength } from '../../helpers';
import { PaymentReportState } from '../../static/CommonDefinitions';
import CopyIcon from '../../assets/images/copy-gray.svg';
import GiftAidIcon from "../../assets/images/gift-aid.svg";
import ReferenceIcon from "../../assets/images/reference-gray.svg";
import NotesIcon from "../../assets/images/notes-gray.svg";

const RequestPaymentItem = ({ reportState, amount, date, bankName, transactionId, withGiftAid, reference, notes, handleClick, type}) => {
  const dispatch = useDispatch();
  const [hover, setHover] = useState(false);
  const isRejected = reportState === PaymentReportState.Cancelled;

  const copyCode = (e) => {
    e.stopPropagation();
    //works only with https protocol
    dispatch(copyToClipboard(transactionId))
    //navigator.clipboard.writeText(transactionId);
  };

  const renderStatus = () => {  
    if(reportState === PaymentReportState.Cancelled) {
        return "Rejected"
    } else if(reportState === PaymentReportState.PendingAccept) {
        return "Pending"
    } else {
        return "Received"
    }
  }

  const statusStyles = classNames(
    'r-payment-item__status',
    {'r-payment-item__status--rejected': isRejected}
  );

  return (
    <div className="r-payment-item" onClick={handleClick}>
      <div className="r-payment-item__top-part">
        <div className="r-payment-item__column">
          <div className="r-payment-item__bank-name">
            {bankName}
          </div>
          <div className="r-payment-item__date">
            {
              moment().format("DD MMM YYYY") === moment(date).format("DD MMM YYYY") ?
              'Today' :
              returnDate(date, '/', true)
            }
            <span className="r-payment-item__time">
              {returnShortTime(date)}
            </span>
          </div>
        </div>

        <div className="r-payment-item__column">
          <div className="r-payment-item__amount">
            {`+${formatAmount(amount)}`}
          </div>
          <div className={statusStyles}>
            {renderStatus()}
          </div>
        </div>
      </div>
      <div className='r-payment-item__code_and_gift_aid'>
        <div
          className={classNames("r-payment-item__code", {"r-payment-item__code--hover": hover})}
          onClick={copyCode}
          onTouchStart={() => setHover(true)}
          onTouchEnd={() => setHover(false)}
        >
          <img src={CopyIcon} alt="CopyIcon" className="r-payment-item__copy-icon"/>
          {checkLength(20, transactionId)}
        </div>
        {
          withGiftAid &&
            <div className='r-payment-item__gift_aid'>
              <span>With Gift Aid</span>
              <img src={GiftAidIcon} />
            </div>
        }
      </div>
      <div className='r-payment-item__important_info_container'>
        {
          reference &&
            <div className='r-payment-item__important_info'>
              <img src={ReferenceIcon} />
              <span>{ reference }</span>
          </div>
        }
        {
          notes &&
            <div className='r-payment-item__important_info'>
              <img src={NotesIcon} />
              <span>{ notes }</span>
            </div>
        }
      </div>
    </div>
  );
};

export default RequestPaymentItem;
