// outsource dependencies
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ChooseBank from "../ChooseBank";
import TipModal from "../Modal/TipModal";
import AddBankError from "../AddBankError";
import { isDeadlineActual } from '../../helpers';
import { cleanTimer } from "../../sagas/kycWatcher";
import BankAccountDetails from "../BankAccountDetails";
import { AuthCodeStatuses } from '../../reducers/kycReducer';
import FormSteps from '../../containers/KYCForm/FormSteps.js';
import RetrieveAccountDetails from "../RetrieveAccountDetails";
import BankUnavailableModal from "../Modal/BankUnavailableModal";
import {Status, StatusAccountDetails} from "../../static/CommonDefinitions";

import {
    changeAccountDetailsScreen,
    clearAccountDetailsStatus,
    createAccountDetails,
    removeAccountDetails,
    setHomePage,
    updateAccountDetailsBank,
    setCheckUserAuthCodeStatus,
} from "../../actions";

function NoRequisites ({accountDetails, closeAccountDetails, openBankAccountRemoved}) {

    const dispatch = useDispatch();
    const history = useHistory();

    const [bankAccountAdded, setBankAccountAdded] = useState(false);
    const [popUpQuestion, setPopUp] = useState(false);
    const [manageBank, setManageBank] = useState(false);
    const [bankAccountRemoved, setBankAccountRemoved] = useState(false);
    const [permissionDenied, setPermissionDenied] = useState(false);
    const [chooseBank, setChooseBank] = useState(false);
    const [unavailableBank, setUnavailableBank] = useState(false);

    const timerDeadline = useSelector(state => state.kyc.timerDeadline);
    const {bank, statusAccountDetails, accountDetailsScreen} = useSelector(state => ({
        bank: state.payment.bank,
        statusAccountDetails: state.payment.statusAccountDetails,
        accountDetailsScreen: state.payment.accountDetailsScreen,
    }));
    const home = useSelector((state) => state.settings.home);

    useEffect(() => {
        if (bankAccountRemoved) {
            setTimeout(() => {
                setBankAccountRemoved(false);
            }, 10000)
            closeAccountDetails();
        }
    }, [bankAccountRemoved]);

    useEffect(() => {
        if(statusAccountDetails === StatusAccountDetails.Success){
            if (Object.keys(bank).length === 0) return;
            setBankAccountAdded(true);
            closeAccountDetails();
            history.push('/kyc-form', FormSteps.bankAccountAdded)
        }

        if(statusAccountDetails === StatusAccountDetails.Failed){
            setPermissionDenied(true);
        }

        if(statusAccountDetails === StatusAccountDetails.Unavailable){
            setUnavailableBank(true);
        }

        dispatch(clearAccountDetailsStatus());
    }, [statusAccountDetails]);

    const closePopUp = () => {
        setPopUp(false);
    };

    const openChooseBank = () => {
        dispatch(removeAccountDetails());
        setPopUp(false);
        setChooseBank(true);
    };

    const closeChooseBank = (bank) => {
        const isTimerNoAispFlow = bank?.api?.includes('general');
        if (bank) {
            dispatch(updateAccountDetailsBank(bank));
            if(isTimerNoAispFlow) {
                if(isDeadlineActual(timerDeadline?.endTimeTimer)) {
                    dispatch(setCheckUserAuthCodeStatus(AuthCodeStatuses.tooManyAttempts));
                }
                history.push('/connect-account');
            }
            dispatch(createAccountDetails(bank));

            if(isDeadlineActual(timerDeadline?.firstAttemptTimeDeadline) && !isTimerNoAispFlow) {
                dispatch(cleanTimer());
            }
        }

        setChooseBank(false);
    };

    const changeHomeScreen = (homePage) => {
        if (homePage !== home) {
            dispatch(setHomePage(homePage));
        }
    };
    const closeBankAccountAdded = (goPrevious) => {
        setBankAccountAdded(false);
        closeAccountDetails();
        if (goPrevious) {
            history.push('/bank-account');
        } else {
            history.push('/home-screen')
            changeHomeScreen('request')
        }
    };

    const closeBankAccountDetails = () => {
        setBankAccountAdded(false);
        setManageBank(false);
    };

    const closePermissionDenied = (goPrevious) => {
        if(goPrevious) {
            setChooseBank(true);
        } else {
            closeAccountDetails();
        }
        dispatch(changeAccountDetailsScreen({clickCancelConsent: false, cancelled: false, waitingForCancel:false}))
        setPermissionDenied(false);
    };

    const closeBankAccountRemoved = () => {
        setBankAccountRemoved(false);
        closeBankAccountDetails();
    };

    const openAnotherChooseBank = () => {
        setUnavailableBank(false);
        setChooseBank(true);
    };

    const closeUnavailableBank = () => {
        setUnavailableBank(false);
        closeAccountDetails();
    };

    const openPopUp = () => {
        if (!localStorage.getItem('bankingTipShown')) {
            setPopUp(true);
        } else {
            openChooseBank()
        }
    };

    return (
        <>
            {popUpQuestion && <TipModal gotItCallback={openChooseBank} tipMessage={"bankAppTip"}/>}
            {chooseBank && <ChooseBank closeChooseBankModal={closeChooseBank} aispDisabled={false}/>}
            {accountDetails && <RetrieveAccountDetails openModal={openPopUp} closeAccountDetails={closeAccountDetails} openChooseBankModal={openChooseBank}/>}
            {manageBank && <BankAccountDetails closeBankAccountDetails={closeBankAccountDetails} openBankAccountRemoved={openBankAccountRemoved}/>}
            {permissionDenied && <AddBankError closeScreen={closePermissionDenied} onTryAgain={closePermissionDenied} error={{ title: 'Permission Denied', message: 'We were unable to retrieve your bank account details, please try again.' }} />}
            {unavailableBank && <BankUnavailableModal closeModal={closeUnavailableBank} handleAnotherChooseBank={openAnotherChooseBank} />}
            {/* {bankAccountRemoved && <BankAccountRemoved closeModal={closeBankAccountRemoved} title={"Bank Account Removed"} content={"Your bank account has now been removed from BOPP. To request a payment, go to 'Get paid' and add a bank account."}/>} */}
            {accountDetailsScreen.addBankError!==null && <AddBankError error={accountDetailsScreen.addBankError} closeScreen={closeAccountDetails}/>}
        </>
    );
}

export default NoRequisites;
