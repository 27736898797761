// outsource dependencies
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

// local dependencies
import Copy from "../../assets/images/copy.svg";
import Edit from '../../assets/images/edit.png';
import PinBlock from "../../components/PinBlock";
import CheckBox from '../../components/CheckBox';
import Share from "../../assets/images/share.svg";
import colors from '../../assets/style.theme.scss';
import { cleanTimer } from '../../sagas/kycWatcher';
import Wallet from '../../assets/images/wallet.svg';
import SelectionModal from "../Modal/SelectionModal";
import Trash from '../../assets/images/trash-icon.svg';
import RemoveItemModal from "../Modal/RemoveItemModal";
import Reference from '../../assets/images/reference.svg';
import BackArrow from "../../assets/images/back-arrow.svg";
import CloseIcon from "../../assets/images/close-icon.svg";
import DefaultIcon from '../../assets/images/bank-icon-black.svg';
import TimeSquare from '../../assets/images/time-square.svg';
import PinProtectionModal from "../Modal/PinProtectionModal";
import { PaymentTrigger, TermType } from "../../static/CommonDefinitions";
import AddBankAccountDetailsModal from '../Modal/AddBankAccountDetailsModal';
import BankAccountName from '../../components/BankAccountName';
import Preloader from "../../assets/images/spinner-2.svg";
import {
    addBank,
    removeAccountDetails,
    removeBank,
    sendBankAccount,
    getBankList,
    saveInApp,
    setEmail,
    setStatusBarColor, setFirstName, setLastName, getPayeeSubscriberIdByPayeeIdAction, setRefreshSubscriptionRequestStatus, getApiKeyByPayeeIdAction,
} from "../../actions";

import './style.scss';
import Button from '../../components/Button';
import PinSetModal from '../Modal/PinSetModal';

const BankAccountDetails = ({ closeBankAccountDetails, openBankAccountRemoved}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const bank = useSelector(state => state.payment.bank);
    const bankList = useSelector((state) => state.payment.bankList);
    const refreshSubscriptionRequestStatus = useSelector((state) => state.payment.refreshSubscriptionRequestStatus);

    const [pinProtection, setPinProtection] = useState(JSON.parse(localStorage.getItem('bank-account')) || false);
    const [pinCode, setPinCode] = useState(JSON.parse(localStorage.getItem('pinCode')) || false);
    const [bankAccount, setBankAccount] = useState(JSON.parse(localStorage.getItem('bank-account')) || false);

    const [paidIdSection, showPaidIdSection] = useState(false);
    const [saveChanges, setSaveChanges] = useState(false);

    const [title, changeTitle] = useState('Bank Account Details');

    const [accountNickName, setAccountNickName] = useState(bank.accountNickName || "");
    const [defaultRef, setDefaultRef] = useState(bank.orderNumber);
    const [pinProtectionModal, showPinProtectionModal] = useState(false);
    const [disabled, setActiveToggle] = useState(true);
    const [activeButton, setActiveButton] = useState(false);
    const [removeModal, showRemoveModal] = useState(false);
    const [accountDetailsModal, setAccountDetailsModal] = useState(false);
    const [modalValue, setModalValue] = useState("")
    const [pinBlock, showPinBlock] = useState(false);
    const [checkPinCode, pinCodeHas] = useState(false);
    const [showSetPin, setShowSetPin] = useState(false);
    const [refreshPopup, setRefreshPopup] = useState(false);

    useEffect(() => {
        if (bankAccount && localStorage.getItem('pinCode') && !JSON.parse(localStorage.getItem('checkedPin'))) {
            showPinBlock(true);
        }

        if (localStorage.getItem('pinCode') && !checkPinCode) {
            checked();
            localStorage.setItem('checkedPin', false);
        }
    }, [checkPinCode, pinBlock]);

    useEffect(()=>{
        if(modalValue.length>0){
            setAccountDetailsModal(true)
        } else {
            saveBankChanges()
        }
    }, [modalValue])

    useEffect(() => {
        setAccountNickName(bank.accountNickName || '');
        setDefaultRef(bank.orderNumber || '');
    }, [bank]);

    useEffect(() => {
        const pin = JSON.parse(localStorage.getItem('pinCode')) || false;
        if (pin) {
            setPinCode(pin);
            setActiveToggle(false);
        }
      }, [showSetPin]);

    useEffect(() => {
        dispatch(getBankList());
    }, []);

    useEffect(() => {
        if (!showSetPin && !pinBlock) {
            dispatch(setStatusBarColor(colors.defaultBar));
        }
    }, [showSetPin, pinBlock])

    useEffect(() => {
        const pinCode = JSON.parse(localStorage.getItem('pinCode')) || false;
        setPinCode(pinCode);
    }, []);

    useEffect(() => {
       if(refreshSubscriptionRequestStatus === 'complete') {
            dispatch(setRefreshSubscriptionRequestStatus('none'))
            setRefreshPopup(true)
       }
    }, [refreshSubscriptionRequestStatus]);


    const handlerSaveChange = () => {
        if(accountNickName !== bank.accountNickName || defaultRef !== bank.orderNumber || pinProtection !== bankAccount){
            setSaveChanges(true);
        } else {
            closeBankAccountDetails();
        }
    };

    const saveBankChanges = () => {
        const updatedBank = {
            ...bank,
            //accountName: bank.accountName,
            //accountNumber: bank.accountNumber,
            //sortCode: bank.sortCode,
            accountNickName,
            orderNumber: defaultRef,
            //accountSubType: bank.accountSubType,
            //bankName: bank.bankName
        };

        const pc = JSON.parse(localStorage.getItem('pinCode')) || false;
            dispatch(sendBankAccount({
                checkBoxValue: bankAccount,
                pin: pc + ''
            }));

        dispatch(addBank(updatedBank));
        dispatch(saveInApp({key: 'bankAccountDetails', value: updatedBank}));
        // closeBankAccountDetails();
    };

    const closeRemoveModal = () => {
        showRemoveModal(false);
    };

    const removeBankAccount = () => {
        dispatch(removeAccountDetails());
        dispatch(removeBank());
        dispatch(saveInApp({key: 'bankAccountDetails', value: {}}));
        dispatch(cleanTimer());
        dispatch(setEmail({ email: '' }));
        dispatch(setFirstName({ firstName: '' }));
        dispatch(setLastName({ lastName: '' }));
        openBankAccountRemoved();
        showRemoveModal(false);
    };

    const handlerRemoveBank = () => {
        showRemoveModal(true);
    };

    const checkPin = () => {
        if(pinCode){
            return pinCode ? setActiveToggle(false) : setActiveButton(true);
        }
        showPinProtectionModal(true);
    };

    const closePinProtectionModal = (goPrevious) => {
        if(goPrevious){
            setShowSetPin(true);
        }
        showPinProtectionModal(false);
    };

    const modalPaidId = () => {
        changeTitle(!paidIdSection ? 'Bank Account PayID' : 'Bank Account Details');
        showPaidIdSection(!paidIdSection);
    };

    const changeAccountDetailsModal = (valueName) => {
        setModalValue(valueName)
    }

    const refreshSubscriptionDetails = () => {
        dispatch(setRefreshSubscriptionRequestStatus('sent'))
        dispatch(getPayeeSubscriberIdByPayeeIdAction());
        dispatch(getApiKeyByPayeeIdAction())
    }

    const getAccountDetailsObject = () => {
        switch (modalValue){
            case "Nickname" :
                return({
                    title: "Bank Account Nickname",
                    message: "Personalise your account name (e.g. Primary) - This nickname is only seen by you",
                    placeholder: "Bank Account Nickname",
                    inputValue: accountNickName,
                    maxLength: 32,
                    onSave: (input) => {
                        setAccountNickName(input);
                        setModalValue("");
                        setAccountDetailsModal(false);
                    },
                    onCancel: changeAccountDetailsModal
                })
            case "Reference" :
            return({
                    title: "Default Payment Reference",
                    message: "This reference will be pre-populated for each payment request (but can be edited before sending). It will appear in both your, and the payer’s, bank statements.",
                    placeholder: "Default Payment Reference",
                    inputValue: defaultRef,
                    prompt: "Payment reference can be up to 18 characters long. You can use letters and numbers.",
                    maxLength: 18,
                    onSave: (input) => {
                        setDefaultRef(input);
                        setModalValue("");
                        setAccountDetailsModal(false);
                    },
                    onCancel: changeAccountDetailsModal
                })
                default : setAccountDetailsModal(false)
        }
    }

    const checked = () => {
        pinCodeHas(true);
    };

    const closePinBlock = () => {
        showPinBlock(false);
    };

    const closeShowSetPin = () => {
        const pin = JSON.parse(localStorage.getItem('pinCode')) || false;
        if(pin){
            localStorage.setItem('bank-account', true);
            setBankAccount(true);
        }
        setShowSetPin(false);
    };

    const onToggleChange = () => {
        localStorage.setItem('bank-account', !JSON.parse(localStorage.getItem('bank-account')));
        setBankAccount(JSON.parse(localStorage.getItem('bank-account')))
    };

    const openDefaultSettings = () => {
        history.push('/default-payment-settings');
    };

    const closeRefreshPopup = () => {
        setRefreshPopup(false)
    };

    return (
        // <>
            showSetPin? <PinBlock closePinBlock={closeShowSetPin} onCloseButton={closeShowSetPin}/>
            :
            <>
                {refreshPopup &&
                    <PinSetModal
                        closeButton={true}
                        bottomButton={false}
                        closeModal={closeRefreshPopup}
                        title="Settings Confirmed"
                        content="Your app settings are configured correctly."
                    />
                }
                {
                    pinBlock && <PinBlock closePinBlock={closePinBlock} onCloseButton={() => history.goBack()} />
                }
                <div className="manage-bank-page">
                    {accountDetailsModal && <AddBankAccountDetailsModal modalDetailsObject={getAccountDetailsObject()} />}
                    <div className="position-relation">
                        {
                            removeModal && (
                            <RemoveItemModal removeItem={removeBankAccount} closeModal={closeRemoveModal}
                                            title={"Do you want to remove your bank account from BOPP? Please note to send payment requests a bank account needs to be added."}/>
                            )
                        }
                        {pinProtectionModal && <PinProtectionModal closeModal={closePinProtectionModal}/>}
                        {
                            saveChanges &&
                                <SelectionModal
                                title={"Do you want to save changes you made?"}
                                optionTop={"Save"}
                                optionBottom={"Don't Save"}
                                selectOptionTop={saveBankChanges}
                                selectOptionBottom={closeBankAccountDetails}
                                />
                        }
                            <div className={`details-header ${paidIdSection ? 'flex' : ''}`}>
                                {
                                    paidIdSection && (<img onClick={modalPaidId} src={BackArrow} alt="BackArrow"/>)
                                }
                                <div className="title-and-image">
                                    <div className="image">
                                        <img src={bankList.length !== 0 && (bankList.filter(item => item.accountName === bank.bankName)[0]?.logo_uri || DefaultIcon)}/>
                                    </div>
                                    <div className="header-block">
                                        <div className="details-header-name">{bank.bankName}</div>
                                        <div className="details-header-title">{title}</div>
                                    </div>
                                </div>
                                {
                                    !paidIdSection && (<img className="details-close-button" onClick={closeBankAccountDetails} src={CloseIcon} alt="" />)
                                }
                            </div>
                        <div className="manage-bank-wrapper">
                            <div className="bank-account-block manage-bank-block">
                                <div className="bank-account-info info">
                                    <div className="bank-account-info-header">
                                        <BankAccountName name={bank?.accountName} isBold={false} />
                                        <div>
                                            <img src={Trash} onClick={handlerRemoveBank}/>
                                        </div>
                                    </div>
                                    <div className="info-section">
                                        <p>Number</p>
                                        <span>{(String(bank.accountNumber).match(/.{4}/g) || []).join(' ')}</span>
                                    </div>
                                    <div className="info-section">
                                        <p>Sort Code</p>
                                        <span>{bank.sortCode}</span>
                                    </div>
                                </div>
                                <div className="bg-yellow yellow-block"/>
                            </div>
                            <div className="manage-bank-change-block">
                                <div className="account-group">
                                    <div className="left-side">
                                        <span>Protect with PIN</span>
                                        <p>
                                            PIN is required to view and
                                            manage your bank account.
                                        </p>
                                    </div>
                                    <div className="right-side">
                                        <CheckBox
                                            id="bank-account"
                                            onClick={checkPin}
                                            disabled={disabled}
                                            checked={bankAccount}
                                            onChange={onToggleChange}
                                        />
                                    </div>
                                </div>
                                <div className="default-request-settings-block" onClick={openDefaultSettings}>
                                    <span className="default-request-settings-block__text">Default request settings</span>
                                    <img src={BackArrow} alt='arrow' className="default-request-settings-block__icon" />
                                </div>
                                <div className="refresh-sub" >
                                    <Button
                                        color="black"
                                        handleClick={refreshSubscriptionDetails}
                                        title={refreshSubscriptionRequestStatus === 'sent' ? <img src={Preloader} alt="spinner"/> : 'Check Settings'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        // </>
    );
};

export default BankAccountDetails;
