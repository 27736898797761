import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

import {
  openNewPage,
  setStatusBarColor,
  removeAccountDetails,
  updateTimerDeadline,
} from "../../actions";
import Input from '../../components/Input';
import Button from '../../components/Button';
import { isDeadlineActual } from '../../helpers';
import BankAccountAdded from '../BankAccountAdded';
import colors from '../../assets/style.theme.scss';
import CloseIcon from "../../assets/images/close-icon.svg";
import { AuthCodeStatuses } from '../../reducers/kycReducer';
import { checkGeneralCodePair } from '../../sagas/kycWatcher';
import InfoIcon from '../../assets/images/info-icon-black.svg';
import ActivateBOPPWarning from '../KYCForm/ActivateBOPPWarning';
import './style.scss';

const LinkAccountDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const timerDeadline = useSelector(state => state.kyc.timerDeadline);
  const authCodeVerificationStatus  = useSelector(state => state.kyc.authCodeVerificationStatus);

  const isCodePairError = authCodeVerificationStatus === AuthCodeStatuses.codeCombinationError;
  const isVerificationSuccess = authCodeVerificationStatus === AuthCodeStatuses.sucessPair;
  const isAttemptsLoopActual  = isDeadlineActual(timerDeadline?.firstAttemptTimeDeadline);
  const isAttemptsOver = authCodeVerificationStatus === AuthCodeStatuses.tooManyAttempts;
  const isTimerActive  = isDeadlineActual(timerDeadline?.endTimeTimer);

  const [codes, setCodes] = useState({ authCode: '', securityCode: '' });

  useEffect(() => {
    dispatch(setStatusBarColor(colors.lightGrayBg));

    if(!isAttemptsLoopActual || isTimerActive) {
      dispatch(updateTimerDeadline({ firstAttemptTimeDeadline: null, attempts: 0 }));
    }

    return () => dispatch(removeAccountDetails());
  }, [])

  const openNewTab = (url) => {
    return dispatch(openNewPage(url));
  };

  const onCloseIcon = () => {
    history.goBack();
  }

  const updateCodes = (codes) => {
    setCodes(prevCodes => ({
      ...prevCodes,
      ...codes,
    }))
  }

  const handleCodes = (e) => {
    updateCodes({ [e.target.name]: e.target.value });
  };

  const checkCodes = () => {
    dispatch(checkGeneralCodePair({ ...codes, dispatch }));
  }

  const renderLink = () => {
    return (
      <>
      { 
        (isCodePairError || isAttemptsOver) 
        ? 
          <ActivateBOPPWarning userData={codes} setUserData={updateCodes}/> 
        :

      
      <div className="link-acc-details">
        <div className="link-acc-details__header">
          <div className="link-acc-details__title">Link Account Details</div>
          <img className="link-acc-details__close-button" onClick={onCloseIcon} src={CloseIcon} alt="" />
        </div>

        <div className="info-card">
          <img src={InfoIcon} alt="Info icon" className="info-card__icon"/>
          <p className="info-card__text">
            Onboarding for this bank is currently restricted to organisation accounts only. To link your bank account please enter your BOPP activation and security codes below.
          </p>
          <a onClick={() => openNewTab('https://help.bopp.io/')} className="info-card__link">Learn More</a>
        </div>

        <div className="link-acc-details__middle">
          <Input
            name="authCode"
            label="Activation Code"
            value={codes.authCode}
            handleChange={handleCodes}
          />

          <Input
            name="securityCode"
            label="Security Code"
            value={codes.securityCode}
            handleChange={handleCodes}
            classForCustomStyles="activate-warning__code-input"
          />

          <p className="link-acc-details__text link-acc-details__text--gray">
            If you do not have a security code please contact our <a onClick={() => openNewTab('https://bopp.io/contact-us')} className="link-acc-details__link" >Support team</a>.
          </p>
        </div>

        <div className="link-acc-details__bottom">
          <p className="link-acc-details__text">
            Your details will be used in line with our
          </p>
          <a onClick={() => openNewTab('https://bopp.io/legal')} className="link-acc-details__link" >Privacy Policy</a>

          <Button
            title="Confirm"
            handleClick={checkCodes}
            color="black"
            disabled={codes.authCode.length !== 8 || codes.securityCode.length !== 8}
          />
        </div>
      </div>
      }
    </>
    )
  }

  const renderSuccess = () => {
    return (
      <BankAccountAdded noAispFlow={true} />
    )
  }

  return (
    <>
      { isVerificationSuccess ? renderSuccess() : renderLink() }
      </>
  );
};

export default LinkAccountDetails;