import { Base62 } from "../utils/base62";

export class UcbCallBack {
    url;
    urlContent;
    params;
    isAisp;
    redirectUrl;

    constructor(url) {
        this.url = url;
        this.redirectUrl = undefined

        this.initContentUrl()
        this.initIsAispFlow()

        //if(this.isAisp) {
            let fields = this.params.get('state').split('-');
            fields.forEach((entry) => {
                var param = this.decodeParam(entry)

                if(this.isUrl(param)){
                    if(this.isAisp) {
                        this.redirectUrl = param + '/cb' + this.urlContent
                    } else {
                        this.redirectUrl = param + this.urlContent
                    }
                }
            });
        //}
    }

    initContentUrl() {
        const url = new URL(this.url);
        this.params = url.hash && url.hash.length>1 ? new URLSearchParams(url.hash.substr(1)) : url.searchParams;

        if(url.hash && url.hash.length) {
            this.urlContent = url.hash
        } else {
            this.urlContent = url.search
        }

        const result = this.replaceOccurrence(this.urlContent)
        this.urlContent = result
    }

    replaceOccurrence(word) {
        let indexes = this.getQuestionIndexesArray(word)
        let str = word
        if(indexes.length > 1) {
            str = word.substring(0, indexes[1]) + '#' + word.substring(indexes[1] + 1);
        } else if(indexes.length > 0 && indexes[0] != -1) {
            str = word.substring(0, indexes[0]) + '#' + word.substring(indexes[0] + 1);
        }

        return str
    }

    getQuestionIndexesArray(str) {
        let guess = '?'
        let indexes = []
        let index = str.indexOf(guess);
        indexes.push(index)
        while (index >= 0) {
            index = str.indexOf(guess, index + 1);
            if(index != -1) {
                indexes.push(index)
            }
        }

        return indexes
    }

    initIsAispFlow() {
        if(this.params.get('state') != null) {
            this.isAisp = this.params.get('state').startsWith('aisp-')
        } else {
            this.isAisp = false
        }
    }

    isUrl(param) {
        return param.startsWith('http')
    }

    decodeParam(param) {
        try {
            let decodeResult = Base62.decode(param)
            var outputString = new TextDecoder().decode(decodeResult);
            return outputString
        } catch (err) {
            return ''
        }
        
    }
}