import React from 'react';

import '../style.scss';

const BankUnavailableModal = ({ closeModal, handleAnotherChooseBank }) => (
  <>
    <div className="gray-background" />
    <div className="modal-window">
      <div className="background-color background-color__base" />
      <div className="modal-window-content unavailable-modal">
        <div className="modal-title__border">
          Bank Unavailable
        </div>
        <div className="modal-description">
          This bank is unavailable at the moment. Please, choose another bank or try again later.
        </div>
        <div className="modal-description-buttons">
          <button
            onClick={handleAnotherChooseBank}
            className="modal-buttons"
          >
            choose another bank
          </button>
          <button
            onClick={closeModal}
            className="modal-buttons modal-buttons__cancel"
          >
            CANCEL
          </button>
        </div>
      </div>
    </div>
  </>
);

export default BankUnavailableModal;
