import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';

import { setStatusBarColor } from "../../actions";
import colors from '../../assets/style.theme.scss';

import './index.scss';
import HeaderUniversal from '../../components/HeaderUniversal';
import Logo from "../../assets/images/bopp-logo.svg";

export default function LinkPaymentNotApp() {
    const dispatch = useDispatch();

    const callbakcURL = useSelector(state => state.payment.callbakcURL);

    useEffect(() => {
        dispatch(setStatusBarColor(colors.mainYellow));
    }, []);

    return (
        <div className="not-app">
            <HeaderUniversal logo={Logo} />

            <div className="not-app__main-background">
                <div className="content">
                    <span>
                        You are on this page because you opened our app in a browser.
                        You can proceed in mobile app by clicking on the button below:
                    </span>
                    <button onClick={()=>{window.location = "bopp://aisp/" + callbakcURL}}>
                        Open BOPP App
                    </button>
                </div>
            </div>
        </div>
    );
}