// outsource dependencies
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, memo } from "react";

// local dependencies
import Menu from '../../assets/images/menu-bars.svg';
import RequestIcon from "../../assets/images/request.svg";
import {
    showCamera,
    setPaymentInstructionMethod,
    showInvalidPaymentError,
    toggleMenu,
    setHomePage,
    hideCamera,
    setStatusBarColor
} from '../../actions';

import ErrorModal from "../Modal/ErrorModal";
import { resolvePermission } from "../../sagas/permissonSaga";
import usePagePermission from "../../hooks/usePagePermission";
import PermissionPopup from "../../components/PermissionPopup";
import HeaderUniversal from '../../components/HeaderUniversal';
import Button from '../../components/Button';
import {PermissionState, ScreenType, Status} from "../../static/CommonDefinitions";
import colors from '../../assets/style.theme.scss';
import './index.scss';

const QrScanner = memo(() => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [permissionPopup, setPermissionPopup] = useState(false);
    const isMenuVisible = useSelector(state => state.settings.isMenuVisible);
    const pinOnAppLaunch = useSelector(state => state.settings.pinOnAppLaunch);
    const { qrErrorModalShowed, paylinkUrl, boppButton, platform } = useSelector(state => ({
        qrErrorModalShowed: state.payment.qrErrorModalShowed,
        paylinkUrl: state.payment.paylinkUrl,
        boppButton: state.paymentPersist.boppButton,
        platform: state.payment.platform,
    }));
    const status = useSelector(state => state.payment.status);
    const {
        paymentInProcess,
        paymentTransferringToBank,
        paymentWaitingForConsent,
        paymentDetailsWaitingForCancel,
    } = useSelector(state => ({
        paymentInProcess: state.payment.paymentStatusScreen.paymentInProcess,
        paymentTransferringToBank: state.payment.paymentStatusScreen.transferringToBank,
        paymentWaitingForConsent: state.payment.paymentStatusScreen.waitingForConsent,
        paymentDetailsWaitingForCancel: state.payment.paymentStatusScreen.waitingForCancel,
    }));
    const { requiredPermission, permissionStatus } = usePagePermission(ScreenType.QrScanner);

    const isPaymentFlow = paymentInProcess || paymentTransferringToBank || paymentWaitingForConsent || paymentDetailsWaitingForCancel;

    useEffect(() => {
        if(permissionStatus !== PermissionState.Granted) {
            setPermissionPopup(true);
        } else {
            setPermissionPopup(false);
        }
    },[permissionStatus]);

    useEffect(()=> {
        dispatch(showCamera());
        dispatch(setPaymentInstructionMethod('paylink', 'qr'))
        dispatch(setStatusBarColor(colors.mainYellow));

        return () => {
            dispatch(hideCamera());
        }
    }, []);

    useEffect(() => {
        if(isMenuVisible || pinOnAppLaunch) {
            dispatch(hideCamera());
        } else {
            dispatch(showCamera());
        }
    }, [isMenuVisible, pinOnAppLaunch, permissionStatus])

    useEffect(() => {
        //if(status === Status.Success || status === Status.Failed || status === Status.Cancelled) {
        //    dispatch(hideCamera())
        //}

        if(status === '' && !pinOnAppLaunch && !isPaymentFlow){
            dispatch(showCamera());
        } else {
            dispatch(hideCamera())
        }
    }, [status, isPaymentFlow]);

    const closeModalError = () => {
        dispatch(showCamera());
        dispatch(showInvalidPaymentError(false))
    };

    const closePermissionPopup = () => {
        dispatch(resolvePermission(requiredPermission));
    };

    const changePage = () => {
        dispatch(setHomePage('request'));
    };

    const openNumCodeScreen = () => {
        dispatch(setPaymentInstructionMethod('paylink', 'otp'));
        history.push('/numeric-code');
    };

    return (
        <div className="qr-scanner-page">
            <HeaderUniversal
                title={!permissionPopup ? 'Scan a QR code to pay' : null}
                leftButtonIcon={RequestIcon}
                leftButtonEvent={changePage}
                customStylesLeftBtn={{ width: '32px', height: '32px', top: '37px', left: '26px' }}
                rightButtonIcon={Menu}
                rightButtonEvent={() => {dispatch(toggleMenu())}}
            />
            {
                permissionPopup &&
                <PermissionPopup
                    requiredPermission={requiredPermission}
                    handleContinue={closePermissionPopup}
                />
            }

            {permissionPopup &&
                <Button
                    title={permissionPopup ? 'Continue' : 'Or enter code'}
                    color={permissionPopup ? 'yellow' : 'gray'}
                    handleClick={permissionPopup ? closePermissionPopup : openNumCodeScreen}
                />
            }

            {
                qrErrorModalShowed &&
                <ErrorModal
                    closeModal={closeModalError}
                    title='Invalid QR Code'
                    content='Sorry, we’re unable to recognize this QR code.'
                />
            }
        </div>
    );
});

export default QrScanner;
