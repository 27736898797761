import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

import {
  openNewPage,
  removeAccountDetails,
  removeBank,
  saveInApp,
  setCheckUserAuthCodeStatus,
  updateTimerDeadline,
  setEmail,
  setStatusBarColor, setFirstName, setLastName,
} from '../../../actions';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Logo from '../../../assets/images/bopp-logo.svg';
import colors from '../../../assets/style.theme.scss';
import SelectionModal from '../../Modal/SelectionModal';
import CloseIcon from '../../../assets/images/close-icon.svg';
import WarningIcon from "../../../assets/images/warning.svg";
import { AuthCodeStatuses } from '../../../reducers/kycReducer';
import HeaderUniversal from '../../../components/HeaderUniversal';
import { getDeadlineTime, isDeadlineActual } from '../../../helpers';
import { checkGeneralCodePair, cleanTimer, processEnteredAuthCodeAction } from '../../../sagas/kycWatcher';
import './styles.scss';

const ActivateBOPPWarning = ({ goBack, setUserData, userData }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const bank = useSelector(state => state.payment.bank);
  const { authCodeVerificationStatus, timerDeadline }  = useSelector(state => ({
    authCodeVerificationStatus: state.kyc.authCodeVerificationStatus,
    timerDeadline: state.kyc.timerDeadline,
  }));

  const [closeModal, setCloseModal] = useState(false);
  const [authCodeAttempts, setAuthCodeAttempts] = useState({attempt: 1, endTime: {}});

  const isItNoAispFlow = authCodeVerificationStatus === AuthCodeStatuses.codeCombinationError || history.location.pathname.includes('/connect-account');
  const isVerificationSuccess = authCodeVerificationStatus === AuthCodeStatuses.sucessPair;
  const isAttemptsOver = authCodeVerificationStatus === AuthCodeStatuses.tooManyAttempts;
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    setIsButtonDisabled(userData.authCode.length !== 8 || (isItNoAispFlow && userData?.securityCode?.length !== 8));
  }, [userData.authCode, isItNoAispFlow, userData?.securityCode]);

  useEffect(() => {
    setIsButtonDisabled(true);
  }, []);

  const isTimerActive  = isDeadlineActual(timerDeadline?.endTimeTimer);
  let timeInterval;

  const removeBankAccount = () => {
    dispatch(removeAccountDetails());
    dispatch(removeBank());
    dispatch(saveInApp({key: 'bankAccountDetails', value: {}}));
    dispatch(cleanTimer());
    dispatch(setFirstName({ firstName: '' }));
    dispatch(setLastName({ lastName: '' }));
    dispatch(setEmail({ email: '' }));
  };

  var visibilityChange;

  function handleVisibilityChange() {
    checkTimer()
  }

  useEffect(() => {
    document.addEventListener(visibilityChange, handleVisibilityChange, false);

    return () => {
      document.removeEventListener(visibilityChange, handleVisibilityChange, false);
    }
  }, [])

  useEffect(() => {
    if(timerDeadline.endTimeTimer != null) {
        initializeTimer()
    }
  }, [timerDeadline.endTimeTimer])


  useEffect(() => {
    if (!isVerificationSuccess && !isAttemptsOver && timerDeadline?.attempts >= 3 && !isTimerActive) {
      dispatch(setCheckUserAuthCodeStatus(AuthCodeStatuses.tooManyAttempts));
      startTimer();
    }
  }, [authCodeVerificationStatus, timerDeadline, isVerificationSuccess])

  const startTimer = () => {
    const deadline = getDeadlineTime(5);
    dispatch(updateTimerDeadline({ ...timerDeadline, endTimeTimer: deadline, noAispFlow: isItNoAispFlow ? true : null }));
  }

  useEffect(() => {
    dispatch(setStatusBarColor(colors.lightGrayBg));

    if (!isNaN(Date.parse(new Date(timerDeadline.endTimeTimer))) && Date.parse(new Date(timerDeadline.endTimeTimer)) > Date.parse(new Date())) {
      dispatch(setCheckUserAuthCodeStatus(AuthCodeStatuses.tooManyAttempts));
    }
    return () => {
      clearInterval(timeInterval);
    }
  }, []);

  const onActivateButton = () => {
    setIsButtonDisabled(true);
    if (isItNoAispFlow) {
      return dispatch(checkGeneralCodePair({ ...userData, dispatch }));
    }

    dispatch(processEnteredAuthCodeAction({ authCode: userData.authCode, targertPayeeUniqueIdentifier: bank.id }))
  };

  const changeAccount = () => {
    history.push('/home-screen', 'show-retrive');
    dispatch(setCheckUserAuthCodeStatus(AuthCodeStatuses.notChecked));
    removeBankAccount();
  };

  const handleCodes = (e) => {
    setUserData({ [e.target.name]: e.target.value });
  };

  const openLink = (url) => {
    dispatch(openNewPage(url));
  };

  const removeBankAccountModal = () => {
    removeBankAccount();
    setCloseModal(false);
    history.push('/home-screen');
  }

  const openHome = () => {
    dispatch(setCheckUserAuthCodeStatus(AuthCodeStatuses.notChecked));
    history.push('/home-screen');
  }

  const onCloseButton = () => {
    isItNoAispFlow ? openHome() : setCloseModal(true)
  }

  const pageDetails = {
    codeExist: {
      title: 'Failure',
      message: 'Unfortunately we can’t find this activation code in our system. Please try again or visit our help page.',
      buttonText: 'Activate Now',
      moreLink: 'https://help.bopp.io/',
    },
    codeLinkedWithAnother: {
      title: 'Failure',
      message: 'This activation code is associated with another bank account. You can link another bank account or try again with another activation code.',
      buttonText: 'Activate Now',
      moreLink: 'https://help.bopp.io/',
    },
    bankLinkedWithAnother: {
      title: 'Failure',
      message: 'This bank account is already associated with another activation code. Please use the activation code already associated with this bank account.',
      buttonText: 'Activate Now',
      moreLink: 'https://help.bopp.io/',
    },
    codeCombinationError: {
      title: 'Please try again',
      message: 'We do not recognize that combination of activation and security codes. Please re-enter your codes.',
      buttonText: 'Try Again',
    },
    tooManyAttempts: {
      title: 'Failure',
      message: 'You have made too many attempts. Please wait and try again later.',
      buttonText: 'Activate Now',
    },
  }
  const targetPageDetails = pageDetails[authCodeVerificationStatus];

  function getTimeRemaining(endTime) {
    const total = endTime - Date.parse(new Date());
    const seconds = Math.floor( (total/1000) % 60 );
    const minutes = Math.floor( (total/1000/60) % 60 );

    return { total, minutes, seconds };
  }

  function updateTimer() {
    const t = getTimeRemaining(timerDeadline.endTimeTimer);

    setAuthCodeAttempts((prevAttempts) => ({ ...prevAttempts, endTime: {total: t.total, minutes: t.minutes, seconds: t.seconds}}));

    if (t.total <= 0) {
      checkTimer()
      clearInterval(timeInterval);
    }
  }

  function initializeTimer() {
    timeInterval = setInterval(updateTimer, 1000);
    updateTimer();
  }

  useEffect(() => {
    checkTimer()
  }, [authCodeAttempts])

  const checkTimer = () => {
    if (authCodeAttempts.endTime?.total <= 0) {
      setAuthCodeAttempts((prevAttempts) => ({ ...prevAttempts, endTime: 0}));
      dispatch(setCheckUserAuthCodeStatus(AuthCodeStatuses.notChecked));
      dispatch(setStatusBarColor(isItNoAispFlow ? colors.lightGrayBg : colors.mainYellow));
      if(goBack) goBack();
      setUserData({ authCode: '', securityCode: '' });
      dispatch(cleanTimer());
    }
  }

  return (
    <div className="activate-warning">
      <HeaderUniversal
        logo={Logo}
        rightButtonIcon={CloseIcon}
        customStylesLogo={{top: '30px'}}
        rightButtonEvent={onCloseButton}
        customStylesRightBtn={{ width: '32px', height: '32px', top: '33px' }}
      />
      <div className="messages">
        <img src={WarningIcon} alt="Failure icon" width={64} height={64} />

        <p className="title">
          {targetPageDetails?.title}
        </p>

        <p className="activate-warning__text activate-warning__text--mv-10">
          {targetPageDetails?.message}
        </p>

        {targetPageDetails?.moreLink &&
          <p onClick={() => { openLink(targetPageDetails?.moreLink) }} className="activate-warning__link">
            Learn More
          </p>}

          { !isAttemptsOver &&
            <div className="activate-warning__middle">
              <Input
                error={true}
                name="authCode"
                label="Activation Code"
                value={userData.authCode}
                handleChange={handleCodes}
              />

              { isItNoAispFlow &&
                <>
                  <Input
                    error={true}
                    name="securityCode"
                    label="Security Code"
                    handleChange={handleCodes}
                    value={userData.securityCode}
                    classForCustomStyles="activate-warning__code-input"
                  />
                  <p className="activate-warning__text activate-warning__text--gray">
                    If you do not have a security code please contact our <a onClick={() => openLink('https://bopp.io/')} className="activate-warning__link" >Support team</a>.
                  </p>
                </>
              }
            </div>
          }
      </div>

      <div className="bottom-part">
        {(!isAttemptsOver && isItNoAispFlow) &&
        <div className="activate-warning__bottom-text">
          <p className="activate-warning__text">
            Your details will be used in line with our
          </p>
          <a onClick={() => openLink('https://bopp.io/legal')} className="activate-warning__link" >Privacy Policy</a>
        </div>}
        {!isAttemptsOver &&
        <div className="buttons">
          <Button
            title={targetPageDetails?.buttonText}
            disabled={isButtonDisabled}
            handleClick={onActivateButton}
            color="black black--disabled"
          />

          {(authCodeVerificationStatus === AuthCodeStatuses.codeLinkedWithAnother || authCodeVerificationStatus === AuthCodeStatuses.bankLinkedWithAnother) &&
            <button
              onClick={changeAccount}
              className="button-like-text"
            >
              Link another account
            </button>}
        </div>}

        {isAttemptsOver && authCodeAttempts.endTime?.total >= 0 &&
          <p className="timer">
            {`Try again in ${authCodeAttempts.endTime.minutes}:${authCodeAttempts.endTime.seconds < 10 ? '0' : ''}${authCodeAttempts.endTime.seconds} ${authCodeAttempts.endTime.minutes >= 1 ? 'minutes' : 'seconds'}`}
          </p>}
      </div>

      {closeModal && <SelectionModal optionTop={"Leave Anyway"} optionBottom={"cancel"} selectOptionTop={removeBankAccountModal} selectOptionBottom={()=>{setCloseModal(false)}} topOptionType={"tip"} bottomOptionType={"cancel-outline"} title={"If you close this screen now your account details will not be saved and you will need to restart the process in order to use BOPP to send payment requests."} />}
    </div>
  )
}

export default ActivateBOPPWarning;
