import { createId, findFirstValidKeyInObject } from "../helpers";

export enum ErrorFlow {
    accounts = 651,
    kyc = 652,
    payments = 653,
    historyQuery = 654,
    mobileStorage = 655,
    other = 659,
  }

interface Error  {
    "@type": string;
    id: string;
    initiatorId: string;
    sequenceNumber: string;
    timestamp: Date;
    statusReason: {
      category: string;
      message: string;
      code: number;
    }
}    

export class ErrorObject {
    message: string;
    additionalObject: any;
    flow:ErrorFlow;
    serverObject: any;
    
    constructor(message: string, additionalObject: any, flow:ErrorFlow, serverObject: any) {
        this.message = message;
        this.additionalObject = additionalObject;
        this.flow = flow;
        this.serverObject = serverObject;
    }

    makeError(): Error {
        return {
            "@type": this.flow === ErrorFlow.historyQuery ? 'https://dvschema.io/activation#ActivityReportCreateRejected' : 'https://dvschema.io/activation#StatusReportRejected',
            id: createId(),
            initiatorId: this.getInitiatorId(),
            sequenceNumber: '',
            timestamp: new Date(),
            statusReason: {
              category: 'ClientError',
              message: this.getErrorMessage(),
              code: this.flow
            }
        }
    }

    getErrorMessage(): string {
        let additionalMessage = '- not defined';
        if(this.additionalObject) {
            if(typeof this.additionalObject === 'object') {
                additionalMessage = this.stringifyObject(this.additionalObject)
            } else {
                additionalMessage = this.additionalObject;
            }
        }

        return this.message + ' ' + additionalMessage;
    }

    stringifyObject (object: any) : string {
        let result = ''
        try {
            result = JSON.stringify(object, this.getCircularReplacer())
            if(result === '{}') {
                if(object instanceof Error) {
                    result = object.toString()
                }
            }

            return result
        } catch (error) {
            return object
        }
    }

    getCircularReplacer() {
        const seen = new WeakSet();
        return (key:string, value:any) => {
          if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
              return;
            }
            seen.add(value);
          }
          return value;
        };
      };

    getInitiatorId(): string {
        let initiatroId = '';
        if(this.serverObject) {
            if(typeof this.serverObject === 'object') {
                let obj = findFirstValidKeyInObject(this.serverObject, 'initiatorId')
                if(obj != null && typeof obj === 'string') {
                    initiatroId = obj
                }
            } 
        }

        return 'BOPP APP ' + initiatroId;
    }
}