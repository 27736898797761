import * as actionType from './actionTypes';

export const changeAmount = (amount) => ({
  type: actionType.SET_AMOUNT,
  payload: amount,
});

export const changeOrderNumber = (orderNumber) => ({
  type: actionType.SET_ORDER_NUMBER,
  payload: orderNumber,
});

export const sendMessage = (data) => ({
  type: actionType.SEND_POST_MESSAGE,
  payload: data,
});

export const stopPingPong = () => ({
  type: actionType.STOP_PING_PONG,
});

export const closePingPongSocket = () => ({
  type: actionType.CLOSE_PING_PONG_SOCKET,
});

export const handleMessage = (data, source, history) => ({
  type: actionType.HANDLE_POST_MESSAGE,
  payload: {
    data: data,
    source: source,
    history
  }
});

export const selectPaymentRequest = (payload) => ({
  type: actionType.SELECT_PAYMENT_REQUEST,
  payload,
});


export const handlePaymentStatus = (payload) => ({
  type: actionType.HANDLE_PAYMENT_STATUS,
  payload,
});

export const handleAccountDetailsStatus = (payload) => ({
  type: actionType.HANDLE_ACCOUNT_DETAILS_STATUS,
  payload,
});

export const clearAccountDetailsStatus = () => ({
  type: actionType.CLEAR_ACCOUNT_DETAILS_STATUS,
});

export const setPaymentAction = (payload) => ({
  type: actionType.SET_PAYMENT_ACTION,
  payload,
});

export const setPaymentInstructionMethod = (method, option) => ({
  type: actionType.SET_PAYMENT_INSTRUCTION_METHOD,
  payload: {
    method,
    option
  }
});

export const setPaymentRequestMethod = (method, option) => ({
  type: actionType.SET_PAYMENT_REQUEST_METHOD,
  payload: {
    method,
    option
  }
});

export const openBluetooth = () => ({
  type: actionType.OPEN_BLUETOOTH,
});

export const openLocation = () => ({
  type: actionType.OPEN_LOCATION,
});

export const setPlatform = (platform) => ({
  type: actionType.SET_PLATFORM,
  platform,
});

export const setDefaultScreenHeight = (payload) => ({
  type: actionType.SET_DEFAULT_SCREEN_HEIGHT,
  payload,
});

export const setHomePage = (home) => ({
  type: actionType.SET_HOME_PAGE,
  payload: home,
});

export const getPlatform = () => ({
  type: actionType.GET_PLATFORM,
});

export const getHome = () => ({
  type: actionType.GET_HOME,
});

export const setHome = (home) => ({
  type: actionType.SET_HOME,
  payload: home,
});

export const getBluetoothPermissionStatus = () => ({
  type: actionType.GET_BLUETOOTH_PERMISSION_STATUS,
});

export const replyRejected = (message) => ({
  type: actionType.REPLY_REJECTED,
  responseObject: message,
});
export const replyRejectedStatus = (context, response) => ({
  type: actionType.REPLY_REJECTED,
  payload: { context, response },
});

export const replyPendingChange = (message) => ({
  type: actionType.REPLY_PENDING_CHANGE,
  responseObject: message,
});

export const payeeActivityReportCancelled = (payload) => ({
  type: actionType.PAYEE_ACTIVITY_REPORT_CANCELLED,
  payload,
});

export const payerActivityReportCancelled = (payload) => ({
  type: actionType.PAYER_ACTIVITY_REPORT_CANCELLED,
  payload,
});

export const replyHistoryChange = (message) => ({
  type: actionType.REPLY_HISTORY_CHANGE,
  responseObject: message,
});

export const cancelPaymentRequest = (payload) => ({
  type: actionType.CANCEL_PAYMENT_REQUEST,
  payload,
});

export const replyPendingAccept = (message) => ({
  type: actionType.REPLY_PENDING_ACCEPT,
  responseObject: message,
});

export const replyAccepted = (message) => ({
  type: actionType.REPLY_ACCEPTED,
  responseObject: message,
});

export const readError = (message) => ({
  type: actionType.READ_ERROR,
  payload: { message },
});

export const replyRequestCancelled = (payload) => ({
  type: actionType.REPLY_REQUEST_CANCELLED,
  payload
});

export const replyCancelled = (message) => ({
  type: actionType.REPLY_CANCELLED,
  responseObject: message,
});

export const replyCancelledStatus = (context, response) => ({
  type: actionType.REPLY_CANCELLED,
  payload: { context, response },
});


export const changePendingAccept = (payload) => ({
  type: actionType.CHANGE_PENDING_ACCEPT,
  payload,
});

export const updateAccountDetails = (payload) => ({
  type: actionType.UPDATE_ACCOUNT_DETAILS,
  payload,
});

export const updateAccountDetailsBank = (payload) => ({
  type: actionType.UPDATE_ACCOUNT_DETAILS_BANK,
  payload,
});

export const changeDomesticPayment = (payload) => ({
  type: actionType.CHANGE_DOMESTIC_PAYMENT,
  payload,
});

export const createPaylinkRequest = (payload) => ({
  type: actionType.CREATE_PAYLINK_REQUEST,
  payload,
});

export const setCallbackUrl = (payload) => ({
  type: actionType.SET_CALLBACK_URL,
  payload,
});

export const setAccountLimit = (payload) => ({
  type: actionType.SET_ACCOUNT_LIMIT,
  payload,
});

export const setPayeeSubscriberIds = (payload) => ({
  type: actionType.SET_PAYEE_SUBSCRIBER_IDS,
  payload,
});

export const setCustomUrlPaymentSettings = (payload) => ({
  type: actionType.SET_CUSTOM_URL_PAYMENT_SETTINGS,
  payload,
});

export const paylinkRequestAccepted = (payload) => ({
  type: actionType.PAYLINK_REQUEST_ACCEPTED,
  payload,
});

export const savePaylinkUrl = (payload) => ({
  type: actionType.SAVE_PAYLINK_URL,
  payload,
});

export const createPaymentInstruction = (payload) => ({
  type: actionType.CREATE_PAYMENT_INSTRUCTION,
  payload,
});

export const changeWrongScreen = (payload) => ({
  type: actionType.CHANGE_WRONG_SCREEN,
  payload,
});

export const saveInApp = (payload) => ({
  type: actionType.SAVE_IN_APP,
  payload,
});

export const getFromApp = (payload) => ({
  type: actionType.GET_FROM_APP,
  payload,
});

export const cancelDomesticPayment = (payload) => ({
  type: actionType.CANCEL_DOMESTIC_PAYMENT,
  payload,
});

export const createAccountDetails = (payload) => ({
    type: actionType.CREATE_ACCOUNT_DETAILS,
    payload,
});

export const changeAccountDetails = (payload) => ({
    type: actionType.CHANGE_ACCOUNT_DETAILS,
    payload,
});

export const cancelAccountDetails = (payload) => ({
  type: actionType.CANCEL_ACCOUNT_DETAILS,
  payload,
});

export const selectBank = (payload) => ({
  type: actionType.SELECT_BANK,
  payload,
});

export const addBank = (payload) => ({
  type: actionType.ADD_BANK,
  payload,
});

export const updateBank = (payload) => ({
  type: actionType.UPDATE_BANK,
  payload,
});

export const removeBank = () => ({
  type: actionType.REMOVE_BANK,
});

export const removeUrlPayerBank = () => ({
  type: actionType.REMOVE_AUTH_URL_PAYER_BANK,
});

export const removeAccountDetails = () => ({
  type: actionType.REMOVE_ACCOUNT_DETAILS,
});

export const removeAccountDetailsUrl = () => ({
  type: actionType.REMOVE_ACCOUNT_DETAILS_AUTH_URL,
});

export const selectBankAccepted = (payload) => ({
  type: actionType.SELECT_BANK_ACCEPTED,
  payload,
});

export const selectBankAccountDetails = (payload) => ({
  type: actionType.SELECT_BANK_ACCOUNT_DETAILS,
  payload,
});

export const sendPin = (payload) => ({
  type: actionType.SET_PIN_CODE,
  payload
});

export const getPin = () => ({
  type: actionType.GET_PIN_CODE
});

export const changePinSet = () => ({
  type: actionType.CHANGE_PIN_SET
});

export const setPinValue = (payload) => ({
  type: actionType.SET_PIN_VALUE,
  payload
});

export const removePinItems = () => ({
  type: actionType.CLEAR_PIN_CODE
});

export const setPinOnLaunch = (payload) => ({
  type: actionType.SET_PIN_ON_LAUNCH,
  payload
});

export const getAppVersion = () => ({
  type: actionType.GET_APP_VERSION
});

export const setBuildNumber = (payload) => ({
  type: actionType.SET_APP_BUILD,
  payload
});

export const getTransactionCounter = () => ({
  type: actionType.GET_TRANSACTION_COUNTER
});

export const putTransactionsCounter = (payload) => ({
  type: actionType.PUT_TRANSACTION_COUNTER,
  payload
});

export const sendBankAccount = (payload) => ({
  type: actionType.SET_BANK_ACCOUNT,
  payload
});

export const sendActivity = (payload) => ({
  type: actionType.SET_ACTIVITY,
  payload
});

export const changePaymentStatusScreen = (payload) => ({
  type: actionType.CHANGE_PAYMENT_STATUS_SCREEN,
  payload
});

export const getHistoryObjectStatusAction = (payload) => ({
  type: actionType.GET_HISTORY_OBJECT_STATUS,
  payload
});

export const changeAccountDetailsScreen = (payload) => ({
  type: actionType.CHANGE_ACCOUNT_DETAILS_SCREEN,
  payload
});

export const setLaunch = (payload) => ({
  type: actionType.SET_BOPP_LAUNCH,
  payload
});

export const handleBankMessage = (payload) => ({
  type: actionType.HANDLE_BANK_MESSAGE,
  payload,
});


export const changeIsNullAmount = (payload) => ({
  type: actionType.CHANGE_ISNULL_AMOUNT,
  payload,
});

export const setBrowserBanking = () => ({
  type: actionType.BROWSER_BANKING,
});

export const setBrowserBankingAccountDetails = () => ({
  type: actionType.BROWSER_BANKING_ACCOUNT_DETAILS,
});

export const passBankUrlToNative = (payload) => ({
  type: actionType.PASS_BANKS_URL,
  payload
});

export const openCallBackPage = (payload) => ({
  type: actionType.OPEN_CALLBACK_PAGE,
  payload,
});

export const callbackPageOpened = (payload) => ({
  type: actionType.CALLBACK_PAGE_OPENED,
  payload,
});

export const openNewPage = (payload) => ({
  type: actionType.OPEN_NEW_PAGE,
  payload,
});

export const changeIsAddBankAccount = (payload) => ({
  type: actionType.CHANGE_IS_ADD_BANK_ACCOUNT,
  payload,
});

export const startOtpCalculator = (payload) => ({
  type: actionType.START_OTP_CALCULATOR,
  payload,
});

export const otpCalculated = (payload) => ({
  type: actionType.OTP_CALCULATED,
  payload,
});

export const getBankList = () => ({
  type: actionType.GET_BANK_LIST,
});

export const getAccountLimit = () => ({
  type: actionType.GET_ACCOUNT_LIMIT,
});

export const getPayeeSubscriberIdByPayeeIdAction = () => ({
  type: actionType.GET_PAYEE_SUSBCRIBER_ID_BY_PAYEE_ID,
});

export const getApiKeyByPayeeIdAction = () => ({
  type: actionType.GET_APIKEY_BY_PAYEE_ID,
});

export const runMigrationAction = () => ({
  type: actionType.RUN_MIGRATION,
});

export const setPaylinkSourceAction = (payload) => ({
  type: actionType.SET_PAYLINK_SOURCE,
  payload,
});

export const saveBankList = (payload) => ({
  type: actionType.SET_BANK_LIST,
  payload
});

export const addUsedBank = (payload) => ({
  type: actionType.ADD_USED_BANK_TO_LIST,
  payload,
});

export const setUsedBank = (payload) => ({
  type: actionType.SET_USED_BANK_TO_LIST,
  payload,
});

export const removeUsedBank  = (index) => ({
  type: actionType.REMOVE_USED_BANK_FROM_LIST,
  payload: {index}
});

export const getUsedBankList = () => ({
  type: actionType.GET_USED_BANK_LIST,
});

export const clearUsedListBank = () => ({
  type: actionType.CLEAR_USED_LIST_BANK,
});

export const clearPaylink = () => ({
  type: actionType.CLEAR_PAYLINK,
});

export const callShare = (payload) => ({
  type: actionType.CALL_SHARE,
  payload
});

export const getShare = () => ({
  type: actionType.GET_SHARE,
});

export const showCamera = () => ({
  type: actionType.SHOW_CAMERA,
});

export const hideCamera = () => ({
  type: actionType.HIDE_CAMERA,
});

export const clearPayerBank = () => ({
  type: actionType.CLEAR_PAYER_BANK,
});

export const showInvalidPaymentError = (payload) => ({
  type: actionType.INVALID_PAYMENT_ERROR,
  payload
});

export const setIsAppLaunchedFirstTime = (payload) => ({
  type: actionType.SET_IS_APP_LAUNCHED_FIRST_TIME,
  payload
});

export const getIsAppLaunchedFirstTime = () => ({
  type: actionType.GET_IS_APP_LAUNCHED_FIRST_TIME,
});

export const saveIsAppLaunchedFirstTime = () => ({
  type: actionType.SAVE_IS_APP_LAUNCHED_FIRST_TIME,
});

export const copyToClipboard = (data) => ({
  type: actionType.COPY_TO_CLIPBOARD,
  payload: {
    data: data
  }
});

export const clickBackButton = (payload) => ({
  type: actionType.CLICK_BACK_BUTTON,
  payload
});

export const clearPaylinkUrl = () => ({
  type: actionType.CLEAR_PAYLINK_URL,
});

export const setError = (error) => ({
  type: actionType.SET_ERROR,
  payload: error,
});

export const setRefreshSubscriptionRequestStatus = (payload) => ({
  type: actionType.SET_REFRESH_SUBSCRIPTION_REQUEST_STATUS,
  payload
});

export const setFailReasonText = (payload) => ({
  type: actionType.SET_FAIL_REASON_TEXT,
  payload
});

export const setIsPaymentSocketClosed = (payload) => ({
  type: actionType.SET_IS_PAYMENT_SOCKET_CLOSED,
  payload
});

export const setLatestChangeRequest = (payload) => ({
  type: actionType.SET_LATEST_CHANGE_REQUEST,
  payload
});

export const setIframeRedirectUrl = (payload) => ({
  type: actionType.SET_IFRAME_REDIRECT_URL,
  payload
});

export const checkEmail = (payload) => ({
  type: actionType.CHECK_EMAIL,
  payload
});

export const setCheckEmailStatus = (success) => ({
  type: actionType.SET_CHECK_EMAIL_STATUS,
  payload: {
    status: success
  }
});

export const checkEmailAndRefernceNumberPair = (payload) => ({
  type: actionType.CHECK_EMAIL_REFERENCE_NUMBER_PAIR,
  payload
});

export const setEmailAndRefernceNumberPairCheck = (payload) => ({
  type: actionType.SET_EMAIL_REFERENCE_NUMBER_PAIR_CHECK,
  payload
});

export const setEmail = (payload) => ({
  type: actionType.SET_EMAIL,
  payload
});

export const setFirstName = (payload) => ({
  type: actionType.SET_FIRTSNAME,
  payload
})

export const setLastName = (payload) => ({
  type: actionType.SET_LASTNAME,
  payload
})

export const setCheckUserAuthCodeStatus = (authCodeStatus) => ({
  type: actionType.SET_CHECK_AUTH_CODE_STATUS,
  payload: {
    codeStatus: authCodeStatus,
  }
});

export const setTimerDeadline = (timerDeadline) => ({
  type: actionType.SET_TIMER_DEADLINE,
  payload: {
    timerDeadline: timerDeadline,
  }
});

export const updateTimerDeadline = (timerDeadline) => ({
  type: actionType.UPDATE_TIMER_DEADLINE,
  payload: timerDeadline
});

export const checkUserSubscription = (payload) => ({
  type: actionType.CHECK_USER_SUBSCRIPTION,
  payload
});

export const showAccountDeactivated = (payload) => ({
  type: actionType.SHOW_MESSAGE_ACCOUNT_DEACTIVATED,
  payload,
});

export const showLimitReached = (payload) => ({
  type: actionType.SHOW_MESSAGE_LIMIT_REACHED,
  payload,
});

export const toggleMenu = () => ({
  type: actionType.TOGGLE_MENU
});

export const shareQrCode = (payload) => ({
  type: actionType.SHARE_QR,
  payload
});

export const changeFixedAmount = (payload) => ({
  type: actionType.CHANGE_FIXED_AMOUNT,
  payload
});

export const setStatusBarColor = (payload) => ({
  type: actionType.SET_STATUS_BAR_COLOR,
  payload
});



export const setPayerStep = (payload) => ({
  type: actionType.SET_PAYER_STEP,
  payload,
});

export const setFixedAmount = (payload) => ({
  type: actionType.SET_FIXED_AMOUNT,
  payload,
});

export const setAdditionalUserAgreement = (payload) => ({
  type: actionType.SET_ADDITIONAL_USER_AGREEMENT,
  payload,
});

export const getPaymentsHistory = () => ({
  type: actionType.GET_PAYMENTS_HISTORY,
});