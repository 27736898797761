import config from '../config'
import {version} from '../../package.json'
import * as actionType from '../actions/actionTypes';
import { PlatformType } from "../static/CommonDefinitions";

export const initialState = {
  platform: PlatformType.Web,
  home: config.initialHome,
  isAppLaunchedFirstTime: false,
  browserBanking: false,
  browserBankingAccountDetails: false,
  protection: {
    bankAccount: false,
    activity: false,
    boppLaunch: false
  },
  pinOnAppLaunch: JSON.parse(localStorage.getItem('bopp-launch')) || false,
  appBuild: '',
  pwaVersion: version,
  pinSet: false,
  isMenuVisible: false,
  defaultScreenHeight: 0,
};

export const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_PLATFORM:
      return {
        ...state,
        platform: action.platform,
      };
    case actionType.BROWSER_BANKING:
      return {
        ...state,
        browserBanking: true,
      };
    case actionType.SET_APP_BUILD:
      return  {
        ...state,
        appBuild: action.payload,
      };
    case actionType.BROWSER_BANKING_ACCOUNT_DETAILS:
      return {
        ...state,
        browserBankingAccountDetails: true,
      };
    case actionType.SET_PIN_VALUE: {
      return {
        ...state,
        protection: {
          ...action.payload
        },
      }
    }
    case actionType.CLEAR_PIN_CODE: {
      return {
        ...state,
        protection: {
          bankAccount: false,
          boppLaunch: false,
          activity: false,
        },
      }
    }
    case actionType.SET_PIN_ON_LAUNCH: {
      return {
        ...state,
        pinOnAppLaunch: action.payload,
      }
    }
    case actionType.SET_HOME:
      return {
        ...state,
        home: action.payload,
      };
    case actionType.SET_HOME_PAGE:
      return {
        ...state,
        home: action.payload,
      };
    case actionType.CHANGE_PIN_SET:
      return {
        ...state,
        pinSet: !state.pinSet,
      };
    case actionType.SET_IS_APP_LAUNCHED_FIRST_TIME:
      return {
        ...state,
        isAppLaunchedFirstTime: action.payload,
      };
    case actionType.TOGGLE_MENU:
      return {
        ...state,
        isMenuVisible: !state.isMenuVisible,
    };
    case actionType.SET_DEFAULT_SCREEN_HEIGHT:
      return {
        ...state,
        defaultScreenHeight: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default settingsReducer;
