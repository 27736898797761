import React, { 
    useState, 
    useEffect 
} from 'react';
import { useHistory } from "react-router-dom";
import { 
    useDispatch, 
    useSelector 
} from "react-redux";
import config from "../../config";
import ChooseBank from "../ChooseBank";
import {
    isInApp, 
    cssVhToPx
} from '../../helpers';
import TipModal from "../Modal/TipModal";
import Button from "../../components/Button";
import Lock from "../../assets/images/lock-icon.svg";
import SelectionModal from "../Modal/SelectionModal";
import colors from '../../assets/style.theme.scss';
import CheckInsideApp from "../../containers/CheckInsideApp";
import ChooseBankButton from "../../components/ChooseBankButton";
import CloseIcon from "../../assets/images/close-icon.svg";
import {
    Home,
    PlatformType
} from "../../static/CommonDefinitions";
import {
    changePaymentStatusScreen,
    getUsedBankList,
    openCallBackPage,
    openNewPage,
    passBankUrlToNative,
    setHome,
    setStatusBarColor,
    getBankList,
} from "../../actions";
import './style.scss';
import Popup from '../../components/Popup';
import BankAccountName from '../../components/BankAccountName';
import HeaderUniversal from '../../components/HeaderUniversal';
import { resolveInstructionOutcomingMessageAction } from '../../resolver/payment/createPaymentInstructionProcessor';
import { updateCreateInstructionState } from '../../reducers/pispSlice';
import { sendError } from '../../resolver/errorResolver';
import { 
    ErrorFlow, 
    ErrorObject 
} from '../../entities/errorObject';
import { notifyWentToBankMessageAction } from '../../resolver/payment/boppButtonNotifier';

let authUrlTimeoutId = null;

const PaymentRequestDetailsScreen = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const platform = useSelector(state => state.settings.platform)
    const activePaylink = useSelector(state => state.paylink.activePaylink);
    const createInstructionState = useSelector(state => state.pisp.createInstructionState);
    const boppButton = useSelector(state => state.paymentPersist.boppButton);
    const iframeRedirectUrl = useSelector(state => state.payment.iframeRedirectUrl)
    const [popUpQuestion, setPopUp] = useState(false);
    const [timeModal, showTimeModal] = useState(false);
    const [isShowTimeout, setIsShowTimeout] = useState(false);
    const [isInsideApp, setIsInsideApp] = useState(false);
    const [isOpenChooseBank, setIsOpenChooseBank] = useState(false);
    const [chooseFlowPopup, setChooseFlowPopup] = useState(false);
    const [showCancelPopup, setShowCancelPopup] = useState(false);

    useEffect(() => {
        resetCreateInstructionState()
        dispatch(getBankList())
        dispatch(getUsedBankList());
        dispatch(setStatusBarColor(colors.defaultBar));
        if (isInApp(["FBAN", "FBAV", "Instagram"]) && isInApp(["Android"]) && !isInApp(["iPhone", "iOS"])) setIsInsideApp(true);
    }, []);

    useEffect(() => {
        if (createInstructionState?.authUrlTimeout != undefined) {
            const fireTimeout = createInstructionState?.authUrlTimeout - new Date().getTime()
            if(fireTimeout < 0) {
                dispatch(sendError({error:new ErrorObject('AuthUrl timeout error, timeout should be in 8 minutes after now. Timeout date time is: ' + new Date(createInstructionState?.authUrlTimeout), createInstructionState?.authUrl, ErrorFlow.payments, null)}));
            } else {
                authUrlTimeoutId = setTimeout(() => {
                    setIsShowTimeout(true)
                }, fireTimeout);
            }
        }
    }, [createInstructionState]);

    useEffect(() => {
        if(createInstructionState?.authUrl && isShowTimeout) {
            showTimeModal(true);
            setIsShowTimeout(false)
        }
    }, [isShowTimeout]);

    const closePaymentDetails = () => {
        if(iframeRedirectUrl) {
            window.parent.postMessage({
                source:'BOPPAPP',
                action:'close',
            }, "*")
        } else {
            resetCreateInstructionState()
            history.push('/home-screen');
        }
    };

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 2
    });

    const openAgreement = () => {
        if (platform !== PlatformType.Web && platform !== PlatformType.Desktop) {
            return dispatch(openNewPage('https://bopp.io/user-agreement'));
        }
        return window.open('https://bopp.io/user-agreement', '_blank');
    };

    const handlePay = (flow) => {
        dispatch(passBankUrlToNative(createInstructionState.authUrl));

        if(platform === PlatformType.Web) {
            dispatch(changePaymentStatusScreen({waitingForConsent: true, transferringToBank: false, clickPayViaBank: true, clickCancelPayment: false}));
        } else {
            dispatch(changePaymentStatusScreen({transferringToBank: true, clickPayViaBank: true, clickCancelPayment: false, targetBank: createInstructionState.selectedBank.accountName}));
        }

        dispatch(setHome(Home.Pay));

        if(boppButton) {
            dispatch(notifyWentToBankMessageAction({paylink: activePaylink.paylink}));
        }

        if(flow === "DEV"){
            dispatch(openCallBackPage(createInstructionState.authUrl));
        } else {
            window.location.replace(createInstructionState.authUrl);
        }
    };

    const chooseBank = () => {
        setPopUp(false);
        setIsOpenChooseBank(true);
    };

    const closeChooseBankModal = (selectedBank) => {
        if(selectedBank){
            createPaymentInctruction(selectedBank)
        }
        setIsOpenChooseBank(false);
    };

    const refreshPage = () => {
        showTimeModal(false)
        createPaymentInctruction(createInstructionState?.selectedBank)
    };

    const createPaymentInctruction = (selectedBank) => {
        if(selectedBank) {
            dispatch(updateCreateInstructionState({
                isAuthUrlRequestSent: true,
                selectedBank: selectedBank,
                authUrl: '',
                authUrlTimeout: undefined
            }))
            dispatch(resolveInstructionOutcomingMessageAction({bankServiceId: selectedBank.api, paylink: activePaylink.paylink, iframeRedirectUrl:iframeRedirectUrl}))
        }
    };

    const changeBank = () => {
        if (!localStorage.getItem('bankingTipShown')) {
            setPopUp(true);
        } else {
            setIsOpenChooseBank(true);
        }
    };

    const handleClickPay = () => {
        resetCreateInstructionState()
        showTimeModal(false);
        
        if(iframeRedirectUrl) {
            window.parent.postMessage({
                source:'BOPPAPP',
                action:'authUrl',
                data:createInstructionState?.authUrl,
            }, "*")
        } else {
            if((boppButton && platform === PlatformType.Web) || platform === PlatformType.Desktop) {
                handlePay("DEV")
                return
            }
    
            config.appEnvironment === "DEV" ? setChooseFlowPopup(true) : handlePay("STANDARD"); 
        }
    };

    const isButtonDisabled = () => {
        return createInstructionState?.authUrl === '';
    }   

    const resetCreateInstructionState = () => {
        clearTimeout(authUrlTimeoutId)
        dispatch(updateCreateInstructionState({
            isAuthUrlRequestSent: false,
            selectedBank: undefined,
            authUrl: '',
            authUrlTimeout: undefined
        }))
    };  

    const renderPaymentDetails = () => {
        if (platform === PlatformType.Desktop) {
            return(
                <>
                    <div className='payment-details__desktop-background'>
                        <div className="payment-details__desktop-wrapper">
                            <div>
                            <div className="payment-details__content--request_desktop">
                                <p
                                    className="payment-details__content--title_desktop"
                                >
                                    You are creating a standing order to pay
                                </p>
                                <div className="payment-details__content--bank">
                                    <BankAccountName name={activePaylink.details.payeeName} />
                                </div>
                                <div
                                    className="payment-details__content--amount_desktop"
                                >
                                    <p>{formatter.format(activePaylink.details.firstPaymentAmount.value)}</p>
                                </div>
                                <p className="payment-details__content--ref_desktop">{activePaylink.details.paymentReference}</p>
                                <div style={{display:'flex', flexDirection:'column', width:'100%', alignItems:'center', justifyContent:'center', alignContent:'center'}}>
                                    <div style={{backgroundColor: '#8A8E94', height:'1px', width:'80%', opacity: 0.5}}/>
                                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'80%'}}>
                                        <p className="payment-details__desktop-so">
                                            Frequency
                                        </p>
                                        <p className="payment-details__desktop-so-bold">
                                            {activePaylink.details.frequency}
                                        </p>
                                    </div>
                                    <div style={{backgroundColor: '#8A8E94', height:'1px', width:'80%', opacity: 0.5}}/>
                                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'80%'}}>
                                        <p className="payment-details__desktop-so">
                                        First Payment Date
                                        </p>
                                        <p className="payment-details__desktop-so-bold">
                                            {activePaylink.details.firstPaymentDue}
                                        </p>
                                    </div>
                                    <div style={{backgroundColor: '#8A8E94', height:'1px', width:'80%', opacity: 0.5, marginBottom:'40px'}}/>
                                </div>
                            </div>
                        </div>
                            <div className="payment-details__footer">
                                <div className="payment-details__choose-bank">
                                    <ChooseBankButton onPress={changeBank} bankAccount={createInstructionState?.selectedBank} loading={createInstructionState?.isAuthUrlRequestSent}/>
                                </div>
                                <div
                                    className="payment-details__footer--terms-text_desktop"
                                >
                                    By clicking on 'Pay' you are agreeing to the BOPP
                                    {' '}
                                    <u><a onClick={openAgreement}>User Agreement</a></u>
                                </div>
                                <div className="payment-details__footer--pay">
                                    <Button
                                        className='payment-details__footer_pay_button'
                                        color="black"
                                        disabled={isButtonDisabled()}
                                        handleClick={handleClickPay}
                                        title={<span className="title"><img src={Lock} alt="lock" className="button-lock"/>Pay</span>}
                                    />
                                    <div
                                        className="payment-details__footer--terms-text_desktop"
                                    >
                                        {activePaylink.details?.taglineText}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    { isOpenChooseBank && <ChooseBank closeChooseBankModal={closeChooseBankModal} payer={true} pispDisabled={false}/>}
                </>
            )
        }

        const renderPayButtonTitle = () => {
            return (
                <span
                    className="payment-details-mobile__pay_button_title"
                    style={detailsScreenStyles['payment-details__pay_button_title']}
                >
                    <img
                        src={Lock}
                        alt="lock"
                        className='payment-details-mobile__pay_button_icon'
                        style={detailsScreenStyles['payment-details__pay_button_icon']}
                    />
                    Pay
                </span>
            )
        }

        return (
            <>
                <div className="payment-details-mobile">
                    <HeaderUniversal
                        rightButtonIcon={CloseIcon}
                        customStylesRightBtn={customStylesHeaderButton}
                        classForCustomStyles={'payment-details__header'}
                        rightButtonEvent={closePaymentDetails}
                    />
                    <div
                        className='payment-details-mobile__inner'
                        style={detailsScreenStyles['payment-details__inner']}
                    >
                        <span
                            className="payment-details-mobile__title"
                            style={detailsScreenStyles['payment-details__title']}
                        >
                            You are creating a standing order to pay
                        </span>
                        <div
                            className={'payment-details-mobile__card'}
                            style={detailsScreenStyles['payment-details__card']}
                        >
                            <BankAccountName alwaysSmallFont name={activePaylink.details.payeeName} />
                            <span
                                className='payment-details-mobile__amount'
                                style={detailsScreenStyles['payment-details__amount']}
                            >
                                {formatter.format(activePaylink.details.firstPaymentAmount.value)}
                            </span>
                            <span
                                className='payment-details-mobile__reference'
                                style={detailsScreenStyles['payment-details__reference']}
                            >
                                {activePaylink.details.paymentReference}
                            </span>
                            <div style={{display:'flex', flexDirection:'column', width:'100%', alignItems:'center', justifyContent:'center', alignContent:'center'}}>
                                    <div style={{backgroundColor: '#8A8E94', height:'1px', width:'80%', opacity: 0.5}}/>
                                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'80%'}}>
                                        <p className="payment-details__desktop-so-mobile">
                                            Frequency
                                        </p>
                                        <p className="payment-details__desktop-so-bold-mobile">
                                            {activePaylink.details.frequency}
                                        </p>
                                    </div>
                                    <div style={{backgroundColor: '#8A8E94', height:'1px', width:'80%', opacity: 0.5}}/>
                                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'80%'}}>
                                        <p className="payment-details__desktop-so-mobile">
                                            First Payment Date
                                        </p>
                                        <p className="payment-details__desktop-so-bold-mobile">
                                            {activePaylink.details.firstPaymentDue}
                                        </p>
                                    </div>
                                    <div style={{backgroundColor: '#8A8E94', height:'1px', width:'80%', opacity: 0.5, marginBottom:'5px'}}/>
                                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', width:'80%'}}>
                                    <p className="payment-details__desktop-so-mobile">
                                        Full standng order payment details will be shown in your bank app / online banking 
                                        </p>
                                        </div>
                                </div>
                        </div>
                        <div className='payment-details-mobile__bottom'>
                            <ChooseBankButton onPress={changeBank} bankAccount={createInstructionState?.selectedBank} loading={createInstructionState?.isAuthUrlRequestSent}/>
                            <span
                                className='payment-details-mobile__terms_text'
                                style={detailsScreenStyles['payment-details__terms_text']}
                            >
                                By clicking on 'Pay' you are agreeing to the BOPP <a onClick={openAgreement}>User Agreement</a>
                            </span>
                            <Button
                                color="black"
                                disabled={isButtonDisabled()}
                                handleClick={handleClickPay}
                                title={renderPayButtonTitle()}
                                className='payment-details-mobile__pay_button'
                                style={detailsScreenStyles['payment-details__pay_button']}
                            />
                            <span
                                className='payment-details-mobile__terms_text'
                                style={detailsScreenStyles['payment-details__powered_by_text']}
                            >
                                {activePaylink.details?.taglineText}
                            </span>
                        </div>
                    </div>
                </div>
                { isOpenChooseBank && <ChooseBank closeChooseBankModal={closeChooseBankModal} payer={true} pispDisabled={false}/>}
            </>
        )
    }


    const renderCancelPopup = () => {
        return showCancelPopup &&
        <Popup
            title='Cancel Payment'
            description='Are you sure you want to cancel payment?'
            bottomContent={
                <div className='payment-details__cancel_popup'>
                    <Button
                        title='Back to page'
                        color='black'
                        className='payment-details__cancel_popup_button'
                        handleClick={() => setShowCancelPopup(false)}
                    />
                    <span className='payment-details__cancel_popup_link' onClick={closePaymentDetails}>Cancel Payment</span>
                </div>
            }
        />
    }

    return (
        <>
            { popUpQuestion && <TipModal gotItCallback={chooseBank} tipMessage={"bankAppTip"}/> }
            { timeModal &&
            <SelectionModal
                title={"Sorry, this page has timed out."}
                optionTop={'REFRESH PAGE'}
                optionBottom={'CANCEL PAYMENT'}
                selectOptionTop={refreshPage}
                selectOptionBottom={closePaymentDetails}
            />
            }
            { chooseFlowPopup &&
            <SelectionModal
                title={"Choose Flow"}
                optionTop={"Dev flow"}
                optionBottom={"Standard Flow"}
                selectOptionBottom={()=>{setChooseFlowPopup(false); handlePay("STANDARD")}}
                selectOptionTop={()=>{setChooseFlowPopup(false); handlePay("DEV")}}
            />
            }
            {renderPaymentDetails()}
            { renderCancelPopup() }
            { isInsideApp && <CheckInsideApp/> }
        </>
    )
};

export default PaymentRequestDetailsScreen;

const detailsScreenStyles = {
    ['payment-details__inner']: {
        paddingTop: cssVhToPx(13.5),
    },
    ['payment-details__title']: {
        fontSize: cssVhToPx(2.1),
    },
    ['payment-details__card']: {
        marginTop: cssVhToPx(1.5),
        padding: `${cssVhToPx(3)} 0`,
    },
    ['payment-details__amount']: {
        fontSize: cssVhToPx(6),
        margin: `${cssVhToPx(0.6)} 0`,
    },
    ['payment-details__reference']: {
        fontSize: cssVhToPx(2.7),
    },
    ['payment-details__review_button']: {
        padding: `${cssVhToPx(1.8)} 0`,
    },
    ['payment-details__review_button_icon']: {
        width: cssVhToPx(3),
        height: cssVhToPx(3),
    },
    ['payment-details__review_button_title']: {
        fontSize: cssVhToPx(2.1),
    },
    ['payment-details__fee_notes']: {
        fontSize: cssVhToPx(2.1),
        marginTop: cssVhToPx(2.25),
    },
    ['payment-details__terms_text']: {
        fontSize: cssVhToPx(2.1),
        margin: `${cssVhToPx(1.5)} 0 ${cssVhToPx(2.7)}`,
    },
    ['payment-details__powered_by_text']: {
        fontSize: cssVhToPx(2.1),
        margin: `${cssVhToPx(1.5)} 0`,
    },
    ['payment-details__pay_button']: {
        height: cssVhToPx(8.4),
    },
    ['payment-details__pay_button_title']: {
        fontSize: cssVhToPx(2.7),
    },
    ['payment-details__pay_button_icon']: {
        height: cssVhToPx(2.4),
        width: cssVhToPx(2.4),
    },
}

const customStylesHeaderButton = {
    top: cssVhToPx(3),
    width: cssVhToPx(3.6),
    height: cssVhToPx(3.6),
};