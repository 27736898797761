import React from 'react';
import './style.scss';

const Button = ({ title, disabled = false, handleClick, color, style = {}, className = '' }) => {
    return(
        <button style={style} className={`button-wrapper ${color} ${className}`} disabled={disabled} onClick={handleClick}>
            {title}
        </button>
    )
};

export default Button;
