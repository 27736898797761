import React from 'react';
import './styles.scss';

const LoadingSpinnerComponent = ({size=20, color='black'}) => {
    return (
        <div className="loader-wrapper">
            <div className="loader-block">
                <div className="loader-item loader-item-first" style={{width:size, height:size, backgroundColor:color}}/>
                <div className="loader-item loader-item-second" style={{width:size, height:size, backgroundColor:color}}/>
            </div>
            <div className="loader-block">
                <div className="loader-item loader-item-fourth" style={{width:size, height:size, backgroundColor:color}} />
                <div className="loader-item loader-item-third" style={{width:size, height:size, backgroundColor:color}} />
            </div>
        </div>
    )
}

export default LoadingSpinnerComponent;