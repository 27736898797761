import React from 'react';
import classNames from 'classnames';

import './style.scss';

const HeaderUniversal = ({
  title,
  logo,
  leftButtonIcon,
  leftButtonEvent,
  rightButtonIcon,
  rightButtonEvent,
  customStylesTitle,
  customStylesLogo,
  customStylesLeftBtn,
  customStylesRightBtn,
  classForCustomStyles: custom,
  inlineStyles,
}) => {

  return (
    <div className={classNames("header-uni", {[custom]: custom})} style={inlineStyles}>
      {leftButtonIcon &&
      <img className={classNames("header-uni__left-icon", {[`${custom}--left-icon`]: custom})}
        src={leftButtonIcon}
        onClick={leftButtonEvent}
        style={customStylesLeftBtn}
      />}

      {logo &&
      <img className={classNames("header-uni__logo", {[`${custom}--logo`]: custom})}
        src={logo}
        style={customStylesLogo}
        
      />}

      {title &&
      <p className={classNames("header-uni__title", {[`${custom}--title`]: custom})} style={customStylesTitle}>
        {title}
      </p>}

      {rightButtonIcon &&
      <img className={classNames("header-uni__right-icon", {[`${custom}--right-icon`]: custom})}
        src={rightButtonIcon}
        onClick={rightButtonEvent}
        style={customStylesRightBtn}
      />}
    </div>
  )
};

export default HeaderUniversal;
