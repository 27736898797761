import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import {useDispatch } from 'react-redux';
import Circle from '../../../assets/images/Circle.svg';
import CheckIcon from '../../../assets/images/check-icon.svg';
import CloseIcon from '../../../assets/images/close-icon.svg';
import BackIcon  from '../../../assets/images/back-arrow.svg';
import BoppLogo from "../../../assets/images/bopp-logo.svg";
import FormSteps from '../FormSteps.js';
import Button from '../../../components/Button';
import './styles.scss';


const ChoosePlan = ({ changeStep, setUserData, userData }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState(userData.selectedPlanOption);

  const handleChoice = (e) => {
    const choice = e.target.id;
    setSelectedOption(choice);
    setUserData({ selectedPlanOption: choice });
  }

  const prevStep = () => {
    changeStep(FormSteps.choosePlan)
  }

  const closeScreen = () => {
    history.push('/home-screen', 'show-retrive');
  }

  const onButtonClick = () => {
    switch (selectedOption){
      case "personal":
        changeStep(FormSteps.emailScreen);
        break;
        case "business":
        changeStep(FormSteps.activateBusiness);
        break;
    }
  }

  return (
    <div className="choose-plan">
      <div className="choose-plan__content">
        <div className="choose-plan__header">
          <div>
            <img
                className="choose-plan__buttons"
                src={BackIcon}
                alt="Back"
                onClick={prevStep}/>
          </div>
          <div>
            <img src={BoppLogo} alt="BoppLogo"/>
          </div>
          <div>
            <img
                className="choose-plan__buttons"
                src={CloseIcon}
                alt="Close"
                onClick={closeScreen}/>
          </div>
        </div>
        <div>
          <p className="title">
            For what purpose do you plan to use BOPP?
          </p>

          <div id="business" onClick={handleChoice} className={"option option".concat(selectedOption === "business" ? "__selected" : "")}>
            Business or Not for Profit
            <img id="business" width={24} height={24} src={selectedOption==="business"? CheckIcon : Circle} alt="Circle"/>
          </div>

          <div id="personal" onClick={handleChoice} className={"option option".concat(selectedOption === "personal" ? "__selected" : "")}>
            Personal
            <img id="personal" width={24} height={24} src={selectedOption==="personal"? CheckIcon : Circle} alt="Circle"/>
          </div>
        </div>
      </div>
      <div className="button-wrapper">
        <Button title="Next" handleClick={onButtonClick} color="black black--disabled" disabled={selectedOption === ""} />
      </div>
    </div>
  )
}

export default ChoosePlan;
