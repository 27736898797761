// outsource dependencies
import Lottie from "react-lottie";
import { useDispatch } from 'react-redux';
import React, { useContext, useEffect, useState } from "react";
import 'pure-react-carousel/dist/react-carousel.es.css';
import { CarouselProvider, Slider, Slide, DotGroup, ButtonNext, CarouselContext } from 'pure-react-carousel';

// local dependencies
import Logo from '../../assets/images/bopp-logo.svg';
import CloseIcon from "../../assets/images/close-icon.svg";
import LoadingSpinnerComponent from "../../components/LoadingSpinnerComponent";
import FirstScreenImage from '../../assets/images/intro-screens/first-screen.svg';
import SecondScreenImage from '../../assets/images/intro-screens/second-screen.svg';
import ThirdScreenImage from '../../assets/images/intro-screens/third-screen.svg';
import FourthScreenImage from '../../assets/images/intro-screens/fourth-screen.svg';
import {
    setHomePage,
    setIsAppLaunchedFirstTime,
    hideCamera,
    saveIsAppLaunchedFirstTime,
    setStatusBarColor,
} from '../../actions';

import './styles.scss';
import colors from '../../assets/style.theme.scss';
import HeaderUniversal from "../../components/HeaderUniversal";
import classNames from "classnames";
import { cssAdaptivePixelsVh, cssAdaptivePixelsVw } from "../../helpers";

export default function IntroScreens() {
    const dispatch = useDispatch();
    const [showImages, hideImages] = useState('');
    const [showAnimation, hideAnimation] = useState('');
    const [move, moveBlocks] = useState(false);
    const [displayNone, setDisplayNone] = useState('');

    useEffect(() => {
        dispatch(setStatusBarColor(colors.mainYellow));
        dispatch(hideCamera());
    }, []);

    useEffect(() => {
        const hideImage = setTimeout(() => hideImages('hide'), 1000);
        return () => clearTimeout(hideImage);
    });

    useEffect(() => {
        const moveBlock = setTimeout(() => moveBlocks(true), 1000);
        return () => clearTimeout(moveBlock);
    });

    useEffect(() => {
        const hideAnimations = setTimeout(() => hideAnimation('hide-animation'), 2000);
        return () => clearTimeout(hideAnimations);
    });

    useEffect(() => {
        const displayNone = setTimeout(() => setDisplayNone('none'), 2000);
        return () => clearTimeout(displayNone);
    });

    const letsStart = () => {
        dispatch(setHomePage('request'));
        dispatch(setIsAppLaunchedFirstTime(false));
        dispatch(saveIsAppLaunchedFirstTime());
    };

    return (
        <div className="intro-screen-new">
            <HeaderUniversal
                logo={Logo}
                rightButtonIcon={CloseIcon}
                customStylesRightBtn={{ width: cssAdaptivePixelsVh(21), height: cssAdaptivePixelsVh(21), top: cssAdaptivePixelsVh(41)}}
                customStylesLogo={{ width: cssAdaptivePixelsVh(89), height: cssAdaptivePixelsVh(45.5), top: cssAdaptivePixelsVh(39)}}
                rightButtonEvent={letsStart}
                inlineStyles={styles['header']}
            />

            <div className="main-content">
                <CarouselProvider
                    naturalSlideWidth={100}
                    totalSlides={4}
                    isIntrinsicHeight={true}
                >
                   <CarouselContent onGotItButtonClick={letsStart} />
                </CarouselProvider>
            </div>
            {/*<div className={`animation ${showAnimation} ${displayNone}`}>*/}
            {/*    <img className={`animation-logo ${showImages}`} src={Logo} alt="Logo"/>*/}
            {/*    <div className={`preloader-block ${showImages}`}>*/}
            {/*        <LoadingSpinnerComponent />*/}
            {/*        <span>Loading..</span>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}


const SlideContent = ({renderTitle, description, image, imageInlineStyle,}) => {
    
    return (
        <div className="slide_content" style={styles['slide_content']}>
            <img src={image} style={imageInlineStyle} />
            { renderTitle() }
            <span className="slide_content__description" style={styles["slide_content__description"]}>{ description }</span>
        </div>
    )
}

const CarouselContent = ({onGotItButtonClick}) => {
    const context = useContext(CarouselContext);
    const [currentSlide, setCurrentSlide] = useState(context.state.currentSlide);

    useEffect(() => {
        const  onChange = () => setCurrentSlide(context.state.currentSlide);
        context.subscribe(onChange);
        
        return () => context.unsubscribe(onChange);
    }, [context]);

    const isLastSlide = currentSlide === 3;

    const buttonClassName = classNames(['intro-slider-block-button', isLastSlide && 'intro-slider-block-button--black']);
    
    const handleButtonClick = () => isLastSlide && onGotItButtonClick();

    return (
        <>
            <Slider>
                <Slide index={0} innerClassName="intro_screen_inner_slide">
                    <SlideContent
                        renderTitle={() => <span className="slide_content__title" style={styles["slide_content__title"]}>The new payment method to replace cash and card.</span>}
                        description="Get paid online or in person in seconds from anyone with a UK bank account."
                        image={FirstScreenImage}
                        imageInlineStyle={styles["slide_content__image--first"]}
                    />
                </Slide>
                <Slide index={1} innerClassName="intro_screen_inner_slide">
                    <SlideContent
                        renderTitle={() => <span className="slide_content__title" style={styles["slide_content__title"]}>Create custom pay requests</span>}
                        description="Getting paid by more than one person? Need to let the payer set the amount? No problem, you choose."
                        image={SecondScreenImage}
                        imageInlineStyle={styles["slide_content__image--second"]}
                    />
                </Slide>
                <Slide index={2} innerClassName="intro_screen_inner_slide">
                    <SlideContent
                        renderTitle={() => <span className="slide_content__title" style={styles["slide_content__title"]}>Share them how you choose</span>}
                        description="Share Paylinks or QR codes with your customers, friends and family via SMS, email, WhatsApp."
                        image={ThirdScreenImage}
                        imageInlineStyle={styles["slide_content__image--third"]}
                    />
                </Slide>
                <Slide index={3} innerClassName="intro_screen_inner_slide">
                    <SlideContent
                        renderTitle={() => <span className="slide_content__title" style={styles["slide_content__title"]}>Make it easy for people<br/>to pay you</span>}
                        description="They simply scan a QR Paycode or click a Paylink. Then confirm the payment in their bank app. Seamless and secure."
                        image={FourthScreenImage}
                        imageInlineStyle={styles["slide_content__image--fourth"]}
                    />
                </Slide>
            </Slider>
            <div className="intro-screen-new__bottom" style={styles["intro-screen-new__bottom"]}>
                <DotGroup style={styles["carousel__dot-group"]} />
                <ButtonNext slide={1} className={buttonClassName} style={styles["intro-slider-block-button'"]} disabled={false} onClick={handleButtonClick}>
                    <span className="sr-only">{ isLastSlide ? 'Got it!' : 'Next' }</span>
                </ButtonNext>
            </div>
        </>
    )
}

const styles = {
    ['header']: {
        height: cssAdaptivePixelsVh(70),
    },
    ['slide_content']: {
        padding: `0 ${cssAdaptivePixelsVw(12)}`,
    },
    ["slide_content__image--first"]: {
        width: cssAdaptivePixelsVh(260),
        height: cssAdaptivePixelsVh(248),
        marginTop: cssAdaptivePixelsVh(48),
        marginRight: cssAdaptivePixelsVw(13),
    },
    ["slide_content__image--second"]: {
        width: cssAdaptivePixelsVh(252),
        height: cssAdaptivePixelsVh(225),
        marginTop: cssAdaptivePixelsVh(71),
    },
    ["slide_content__image--third"]: {
        width: cssAdaptivePixelsVh(256),
        height: cssAdaptivePixelsVh(212),
        marginTop: cssAdaptivePixelsVh(84),
    },
    ["slide_content__image--fourth"]: {
        width: cssAdaptivePixelsVh(290),
        height: cssAdaptivePixelsVh(232),
        marginTop: cssAdaptivePixelsVh(65),
    },
    ["slide_content__title"]: {
        marginTop: cssAdaptivePixelsVh(30),
        marginBottom: cssAdaptivePixelsVh(15),
        fontSize: cssAdaptivePixelsVh(22),
    },
    ["slide_content__description"]: {
        fontSize: cssAdaptivePixelsVh(18),
    },
    ["intro-screen-new__bottom"]: {
        marginTop: cssAdaptivePixelsVh(13),
    },
    ["carousel__dot-group"]: {
        marginBottom: cssAdaptivePixelsVh(23),
    },
    ["intro-slider-block-button'"]: {
        width: cssAdaptivePixelsVw(335),
        height: cssAdaptivePixelsVh(56),
        fontSize: cssAdaptivePixelsVh(18),
    },
}