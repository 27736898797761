var get = require('lodash.get');
var has = require('lodash.has');

export function isObjectHasProperty(object: any, path: string): boolean {
    return has(object, path);
}

export function getObjectPropertyByPath(object: any, path: string): any {
    return get(object, path);
}

export function getPropertyFrom(response: any, propertyPath: string, propertyName: string, isMandatory: boolean = false): never | any {
    const path = propertyPath + '[' + propertyName + ']';
    const result = getObjectPropertyByPath(response, path);
    if(result === undefined)
        if(isMandatory) {
            throw new ReferenceError('Can not resolve ' + propertyName + ' for path: ' + path);
        } else {
            return undefined;
        }
    else {
        return result;
    }
}