import React from 'react';

import '../style.scss';

const RemoveItemModal = ({ removeItem, closeModal, title = "Are you sure you want to remove PIN?", removeItemTitle = "REMOVE", closeItemTitle = "CANCEL" }) => (
  <>
    <div className="gray-background" />
    <div className="modal-window">
      <div className="background-color background-color__error" />
      <div className="modal-window-content modal-window-content__remove-pin">
        <div className="modal-description">
          {title}
        </div>
        <div className="modal-description modal-description-buttons remove-pin-buttons">
          <button onClick={() => removeItem()} className="remove">{removeItemTitle}</button>
          <button onClick={() => closeModal()} className="cancel-remove">{closeItemTitle}</button>
        </div>
      </div>
    </div>
  </>
);

export default RemoveItemModal;
