// outsource dependencies
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// local dependencies
import Menu from '../../assets/images/menu-bars.svg';
import ChangeScreen from '../../assets/images/scan.svg';

import {
  toggleMenu,
  removeAccountDetails,
  changePendingAccept,
  setHomePage,
  showCamera,
  setStatusBarColor,
} from '../../actions';
import config from '../../config';
import colors from '../../assets/style.theme.scss';
import {Home, KYCVerificationStatus} from "../../static/CommonDefinitions";
import './style.scss';

import HeaderUniversal from '../../components/HeaderUniversal';
import BankAccountNotVerified from "../BankAccountNotVerified";
import AddBankAccount from '../AddBankAccount';
import NoRequisites from '../NoRequisites';

const HomeScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const bank = useSelector(state => state.payment.bank);
  const home = useSelector(state => state.settings.home);

  const [accountDetails, setAccountDetails] = useState( history.location.state === 'show-retrive' ? true : false);

  useEffect(() => {
    if(home === Home.Pay) {
      history.push('/qr-scanner');
    } else if(bank.bankAccountStatus === KYCVerificationStatus.Verified) {
      history.push('/get-paid');
    }
  }, [home, bank.bankAccountStatus]);

  useEffect(() => {
    if (!accountDetails) dispatch(setStatusBarColor(colors.mainYellow));
  }, [accountDetails])

  const openAccountDetails = () => {
    setAccountDetails(true);
  };

  const closeAccountDetails = () => {
    setAccountDetails(false);
    dispatch(removeAccountDetails());
  };

  const changePage = () => {
    dispatch(showCamera());
    dispatch(setHomePage('pay'));
  };

  return (
    <>
      <NoRequisites
        accountDetails={accountDetails}
        closeAccountDetails={closeAccountDetails}
      />

      <div className="home-screen">
        <HeaderUniversal
          rightButtonIcon={Menu}
          leftButtonIcon={ChangeScreen}
          leftButtonEvent={changePage}
          rightButtonEvent={() => {
            dispatch(toggleMenu());
          }}
        />

        {Object.entries(bank).length !== 0 && bank.constructor === Object && !accountDetails ? (
          <BankAccountNotVerified />
        ) : (
          <AddBankAccount openAccountDetails={openAccountDetails} />
        )}
      </div>
    </>
  );
};

export default HomeScreen;
