import nacl from 'tweetnacl';
import {
    encodeBase64,
    decodeBase64
} from 'tweetnacl-util';

export const getKeyPair = async (): Promise<nacl.BoxKeyPair> => {
    let kp = await _loadKeyPair()

    if (kp) {
        return kp
    } else {
        let keyPair: nacl.BoxKeyPair
        keyPair = nacl.box.keyPair()
        await _saveKeyPair(keyPair)
        return keyPair
    }
}

const KeyStorageIndex = "gift_bopp_key_index"
async function _loadKeyPair(): Promise<nacl.BoxKeyPair | undefined> {
    let secretKeyRaw = localStorage.getItem(KeyStorageIndex)
    if (!secretKeyRaw) {
        return undefined
    }
    let secretKey = decodeBase64(secretKeyRaw)
    let keyPair = nacl.box.keyPair.fromSecretKey(secretKey)
    return keyPair
}

async function _saveKeyPair(keyPair: nacl.BoxKeyPair): Promise<void> {
    let secretKeyRaw = encodeBase64(keyPair.secretKey)
    localStorage.setItem(KeyStorageIndex, secretKeyRaw)
}