import React from 'react';
import {useSelector} from "react-redux";
import { CarouselProvider, Slider, Slide, DotGroup, Dot } from 'pure-react-carousel';
import './style.scss';
import LockIcon from '../../../assets/images/modal-lock-icon.svg'
import {PlatformType} from "../../../static/CommonDefinitions";

function PaymentInfoDetails({onClickCallback}) {
    const boppButton = useSelector(state => state.paymentPersist.boppButton);
    const platform = useSelector(state => state.settings.platform);
    
    return (
        <>
            <div className="gray-background" onClick={onClickCallback}/>
            <div className={`info-modal-window ${platform === PlatformType.Desktop ? 'bopp-button': ''}`}>
                <div className="background-color background-color__base background-color__info" />
                <div className={`modal-window-content__info ${platform === PlatformType.Desktop ? 'desk': ''}`}>
                    <CarouselProvider
                    naturalSlideWidth={100}
                    naturalSlideHeight={170}
                    totalSlides={3}
                    >
                        <Slider>
                            <Slide index={0} >
                                <div className="slide-block">
                                    <img src={LockIcon}/>
                                    <div className="slide-block-content">
                                        {boppButton && platform === PlatformType.Desktop ?
                                            <>
                                                <div className="modal-title">
                                                    Is this payment request trustworthy?
                                                </div>
                                                <div className="intro-screen-header">
                                                    Anyone requesting a payment through BOPP is using an account that has been validated by their bank.
                                                </div>
                                                <div className="intro-screen-header">
                                                    You should only complete a payment request if it is something you are expecting or are aware of.
                                                </div>
                                            </> :
                                            <>
                                                <div className="modal-title">
                                                What is BOPP?
                                                </div>
                                                <div className="intro-screen-header">
                                                    BOPP is a brand new way to pay others and get paid. It makes bank-to-bank payments super easy and super secure.
                                                </div>
                                                <div className="intro-screen-header">
                                                    Anyone with a UK bank account can automatically pay using BOPP with no sign-up required.
                                                </div>
                                            </>
                                        }
                                        <DotGroup/>
                                    </div>
                                    <div className="slider-block-button">
                                        <Dot slide={1} className={`button ${platform === PlatformType.Desktop ? 'desk': ''}`}>
                                            {boppButton && platform === PlatformType.Desktop ? <span className="sr-only">GOT IT!</span> :
                                                <span className="sr-only">Next</span>
                                            }
                                        </Dot>
                                    </div>
                                </div>
                            </Slide>

                            <Slide index={1}>
                                <div className="slide-block">
                                    <img src={LockIcon}/>
                                    <div className="slide-block-content">
                                        <div className="modal-title">
                                            Can I trust BOPP?
                                        </div>
                                        <div className="intro-screen-header">
                                            Yes. We are regulated by the Financial Conduct Authority (FRN 826380).
                                        </div>
                                        <div className="intro-screen-header">
                                            When you use BOPP your bank login details are never exposed to us or anyone else.
                                        </div>
                                        <DotGroup/>
                                    </div>
                                    <div className="slider-block-button">
                                        <Dot slide={2} className="button" >
                                            {boppButton && platform === PlatformType.Desktop ? <span className="sr-only">GOT IT!</span> :
                                                <span className="sr-only">Next</span>
                                            }
                                        </Dot>
                                    </div>
                                </div>
                            </Slide>
                            <Slide index={2}>
                                <div className="slide-block">
                                    <img src={LockIcon}/>
                                    <div className="slide-block-content">
                                        <div className="modal-title">
                                            Is this payment request trustworthy?
                                        </div>
                                        <div className="intro-screen-header">
                                            Anyone requesting a payment through BOPP is using an account that has been validated by their bank.
                                        </div>
                                        <div className="intro-screen-header">
                                            You should only complete a payment request if it is something you are expecting or are aware of.
                                        </div>
                                        <DotGroup/>
                                    </div>
                                    <div className="slider-block-button" onClick={onClickCallback}>
                                        <Dot slide={1} className="button" >
                                            <span className="sr-only">GOT IT!</span>
                                        </Dot>
                                    </div>
                                </div>
                            </Slide>
                        </Slider>
                    </CarouselProvider>
                </div>
            </div>
        </>
    )
}

export default PaymentInfoDetails
