import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from "react-redux";
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import {
    removeAccountDetails,
    removeBank,
    saveInApp,
    setEmail, setFirstName, setLastName,
    setStatusBarColor,
} from '../../actions';
import BankAccountLabel from '../../components/BankAccountLabel';
import BankLogo from '../../assets/images/bank-icon-black.svg';
import HeaderUniversal from '../../components/HeaderUniversal';
import { AccountType } from '../../static/CommonDefinitions';
import Logo from "../../assets/images/bopp-logo.svg";
import CloseIcon from '../../assets/images/close-icon.svg';
import { cleanTimer } from '../../sagas/kycWatcher';
import colors from '../../assets/style.theme.scss';
import FormSteps from '../KYCForm/FormSteps';
import Button from '../../components/Button';
import Footer from '../../components/Footer';
import InfoModal from '../Modal/InfoModal';
import './style.scss'
import { isNotAPersonalSubscriptionType } from '../../helpers';

const BankAccountAdded = ({ changeStep, noAispFlow }) => {
    const dispatch = useDispatch()
    const history = useHistory();

    const bank = useSelector(state => state.payment.bank);
    const [confirmPopup, setConfirmPopup] = useState(false)

    useEffect(() => {
        dispatch(setStatusBarColor(colors.mainYellow));
    }, []);

    const removeBankAccount = () => {
        dispatch(removeAccountDetails())
        dispatch(removeBank())
        dispatch(saveInApp({key: 'bankAccountDetails', value: {}}))
        dispatch(setEmail({ email: '' }));
        dispatch(setFirstName({ firstName: '' }));
        dispatch(setLastName({ lastName: '' }));
        setConfirmPopup(false)
        dispatch(cleanTimer());
    }

    const confirmPopupProps = {
        message: "If you close this screen now your account details will not be saved and you will need to restart the process in order to use BOPP to send payment requests.",
        buttons: [{
            buttonText: "got it",
            buttonType: "tip",
            buttonOnClick: removeBankAccount
        },
        {
            buttonText: "cancel",
            buttonType: "cancel",
            buttonOnClick: () => {
                setConfirmPopup(false)
            }
        }]
    }

    const determinateNextStep = () => {
        if (noAispFlow) return history.push('/business-verified');
        if (bank.subscriptionType === AccountType.Business) {
            changeStep(FormSteps.activateBusiness);
        } else {
            changeStep(FormSteps.choosePlan);
        }
    }

    return (
        <>
            <div className={classNames("bank-added-kyc", {"bank-added-kyc--like-page": noAispFlow})}>
                <HeaderUniversal
                    logo={Logo}
                    rightButtonIcon={CloseIcon}
                    classForCustomStyles="bank-added-kyc__header"
                    rightButtonEvent={() => history.push('/home-screen')}
                />

                <div className="bank-added-kyc__container">
                    <h2 className="bank-added-kyc__title">Bank Account Added</h2>
                    <BankAccountLabel logo={bank?.logo ? bank.logo: BankLogo} accountNumber={bank.accountNumber} bankAccountName={bank.accountName} bankName={bank.bankName} sortCode={bank?.sortCode} />
                </div>

                {bank.subscriptionType === AccountType.Personal && <Footer
                    button={{buttonText: "Next", onButtonClick: determinateNextStep }}
                    message={"Before you request a payment please provide us with your contact details in case we need to get in touch (and to meet our legal requirements)."}
                />}
                {isNotAPersonalSubscriptionType(bank.subscriptionType) &&
                <div className="bank-added-kyc__button">
                    <Button title="Next" handleClick={determinateNextStep} color="black" />
                </div>}
            </div>

            {confirmPopup && <InfoModal {...confirmPopupProps}/>}
        </>
    );
};

export default BankAccountAdded;
