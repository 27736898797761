import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CloseIcon from '../../../assets/images/close-icon.svg';
import Button from '../../../components/Button';
import {PlatformType, ReferenceSettingType, TermType} from '../../../static/CommonDefinitions';
import {setPayerStep} from "../../../actions";
import {payerSteps} from '../../../reducers/paymentReducer';
import './style.scss';

const ReviewPaymentDetailsModal = (
    {
        showModal,
        closeModal,
        note,
        email,
        fullName,
        postCode,
        reference,
        address,
        amount,
    }
) => {
    const dispatch = useDispatch();
    const fixedAmount = useSelector(state => state.payment.fixedAmount);
    const platform = useSelector(state => state.settings.platform);
    const paymentRequest = useSelector(state => state.paymentPersist.paymentRequest);

    const notesType = paymentRequest.settings.notes.type;
    const referenceType = paymentRequest.settings.reference.type;
    const requestEmail = paymentRequest.settings.requestEmail;
    const notesCaption = paymentRequest.settings.notes.notesCaption;

    const nonEditable = fixedAmount && (referenceType === "SetByMe" || referenceType === "Autonumber") && notesType === "SetByMe" && !requestEmail.enabled;

    const moveToFirstScreen = () => {
        if (paymentRequest?.amountTerm === TermType.FixedAmount) {
            dispatch(setPayerStep(payerSteps.setAdditionalInfo));
        } else {
            dispatch(setPayerStep(payerSteps.setAmount));
        }
        closeModal();
    }

    const amountMessage = () => {
        return(
            <div className="payment-details-modal-section_value">
                £{amount}
            </div>
        )
    }

    const referenceMessage = () => {
        switch (referenceType) {
            case ReferenceSettingType.SetByMe:
                return paymentRequest.settings.reference.value;
            case ReferenceSettingType.Autonumber:
                return paymentRequest.settings.reference.value.replace('autonumber:', '');
            default:
                return reference;
        }
    }

    const notesMessage = () => {
        if (!paymentRequest.settings.notes.enabled) {
            return null;
        }

        return(
            <div className="payment-details-modal-section">
                <div className="payment-details-modal-section_title">
                    {notesType === "SetByMe" ? 'Notes from requester' : (notesCaption ? notesCaption : "Notes")}
                </div>
                <div className="payment-details-modal-section_value">
                    {note.trim() ? note : 'Not added'}
                </div>
            </div>
        )
    }

    const emailMessage = () => {
        if (!paymentRequest.settings.requestEmail.enabled) {
            return null;
        }
        return(
            <div className="payment-details-modal-section">
                <div className="payment-details-modal-section_title">
                    Your email
                </div>
                <div className="payment-details-modal-section_value">
                    {email ? email : "Not added"}
                </div>
            </div>

        )
    }

    const giftAidMessage = () => {
        const isGiftAidAdded = fullName && address && postCode;

        if (!paymentRequest.settings.giftAid.enabled) {
            return null;
        }
        return(
            <div className="payment-details-modal-section">
                <div className="payment-details-modal-section_title">
                    Gift Aid
                </div>
                {
                    isGiftAidAdded
                    ? <>
                        <div className="payment-details-modal-section_value">
                            {fullName}
                        </div>
                        <div className="payment-details-modal-section_value">
                            {address}
                        </div>
                        <div className="payment-details-modal-section_value">
                            {postCode}
                        </div>
                    </>
                    : <div className="payment-details-modal-section_value">
                        Not added
                    </div>
                }
            </div>
        )
    }

    return(
        <>
            <div className="gray-background" onClick={closeModal}/>
            <div className={platform === PlatformType.Desktop ? `payment-details-modal-window_desktop ${showModal ? null : 'none'}` : `payment-details-modal-window ${showModal ? null : 'none'}`}>
                <div className="payment-details-modal-content">
                    <div className="payment-details-modal-header">
                        <span className="payment-details-modal-header_title">Review Payment Details</span>
                        <img
                            className="payment-details-modal-header_img"
                            src={CloseIcon} alt="Close"
                            onClick={closeModal}/>
                    </div>
                    <div className="payment-details-modal-section">
                        <div className="payment-details-modal-section_title">
                            Amount
                        </div>
                        {amountMessage()}
                    </div>
                    <div className="payment-details-modal-section">
                        <div className="payment-details-modal-section_title">
                            Payment Reference
                        </div>
                        <div className="payment-details-modal-section_value">
                            {referenceMessage()}
                        </div>
                    </div>
                    {notesMessage()}
                    {/*<div className="payment-details-modal-section">*/}
                    {/*    <div className="payment-details-modal-section_title">*/}
                    {/*        Your kids name and age*/}
                    {/*    </div>*/}
                    {/*    <div className="payment-details-modal-section_value">*/}
                    {/*        Daniel, 12*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {emailMessage()}
                    {giftAidMessage()}
                </div>
                <div className={`payment-details-modal-footer ${nonEditable ? 'none' : null}`}>
                    <Button
                        title="Edit information"
                        color="transparent"
                        handleClick={moveToFirstScreen}
                    />
                </div>
            </div>
        </>
    )
}

export default ReviewPaymentDetailsModal
