import { 
    put, 
    takeLatest,
    select 
} from '@redux-saga/core/effects';
import * as actionType from '../actions/actionTypes';
import axios from 'axios'
import {
    setCheckEmailStatus,
    setEmailAndRefernceNumberPairCheck,
    setCheckUserAuthCodeStatus,
    saveInApp,
    setTimerDeadline,
    updateTimerDeadline as updateTimerDeadlineAction,
    updateBank,
    getPayeeSubscriberIdByPayeeIdAction,
    getApiKeyByPayeeIdAction,
} from '../actions';
import config from '../config';
import { 
    AccountType, 
    MessageType 
} from '../static/CommonDefinitions';
import { 
    getDeadlineTime, 
    isDeadlineActual 
} from '../helpers';
import { resolveMessage } from '../resolver/messageResolver';
import { AuthCodeStatuses } from '../reducers/kycReducer';
import { sendError } from './../resolver/errorResolver';
import { 
    ErrorFlow, 
    ErrorObject 
} from '../entities/errorObject';

export const CHECK_CODE_PAIR_FOR_GENERAL_ACC = 'CHECK_CODE_PAIR_FOR_GENERAL_ACC';
export const CLEAN_ATTEMPTS_TIMER = 'CLEAN_ATTEMPTS_TIMER';
export const PROCESS_ENTERED_AUTH_CODE = 'PROCESS_ENTERED_AUTH_CODE';

export const checkGeneralCodePair = (payload) => ({
    type: CHECK_CODE_PAIR_FOR_GENERAL_ACC,
    ...payload,
});

export const cleanTimer = () => ({
  type: CLEAN_ATTEMPTS_TIMER,
});

export const processEnteredAuthCodeAction = (payload) => ({
    type: PROCESS_ENTERED_AUTH_CODE,
    payload
  });

function* processEnteredAuthCode({ payload }) {
    const { authCode, targertPayeeUniqueIdentifier } = payload;
    const timerDeadline = yield select(state => state.kyc.timerDeadline);

    try {
        yield checkAuthCodeStatus(authCode)
        yield linkBankAccountWithAuthCode(authCode, targertPayeeUniqueIdentifier)
    } catch (error) {
        const code = error.errorCode;
        if (code === 453 ) {
            yield put(setCheckUserAuthCodeStatus(AuthCodeStatuses.bankLinkedWithAnother));
        } else if (code === 451 ) {
            yield put(setCheckUserAuthCodeStatus(AuthCodeStatuses.codeLinkedWithAnother));
        } else if (timerDeadline?.attempts >= 3) {
            yield put(setCheckUserAuthCodeStatus(AuthCodeStatuses.tooManyAttempts));
        } else if (code === 404 || code === 452) {
            yield put(setCheckUserAuthCodeStatus(AuthCodeStatuses.codeExist));
        }
        else {
            yield put(sendError({error:new ErrorObject('Error processing entered auth code', `error code is ${error.errorCode}`, ErrorFlow.kyc)}));
        } 
        yield handleTimerUpdate();
    }
}

function* checkAuthCodeStatus(authCode) {
    try {
        yield axios.get(`${config.subscriptionServiceUrl}/auth_codes/${authCode}?x-api-key=${config.zohoAccountsSubscriptionApiKey}`);
    } catch (error) {
        throw {errorCode: error.response.data.errorCode}
    }
}

function* linkBankAccountWithAuthCode(authCode, payeeId) {
    try {
        const { data } = yield axios.post(`${config.subscriptionServiceUrl}/auth_codes/${authCode}/payee?x-api-key=${config.zohoAccountsSubscriptionApiKey}`,
            {
                payeeUniqueIdentifier: payeeId,
                headers: {'Content-Type': 'application/json'},
            }
        );

        if (data.success) {
            yield put(updateBank({ subscriptionType: AccountType.Business}));
            yield put(setCheckUserAuthCodeStatus(AuthCodeStatuses.sucessPair));
            yield put(getPayeeSubscriberIdByPayeeIdAction());
            yield put(getApiKeyByPayeeIdAction())
        }
    } catch (error) {
        throw {errorCode: error.response.data.errorCode}
    }
}

function* didCheckEmailRequest(payload) {
    const { email, referenceId } = payload;

    try {
        const { data } = yield axios.post(`${config.zohoServiceUrl}/zoho/verify?x-api-key=${config.zohoAccountsApiKey}`,
                {
                    referenceNumber: referenceId,
                    email: email
                }
            );
        return data.success;
    } catch (error) {
        return false;
    }
}

function* checkEmailVerification({ payload }) {
    const response = yield didCheckEmailRequest(payload);

    if (response) {
        yield put(setCheckEmailStatus(response));
    } else {
        yield put(setCheckEmailStatus(false));
    }
}

function* checkEmailAndRefernceNumberPair({ payload }) {
    const response = yield didCheckEmailRequest(payload);

    if (response) {
        yield put(setEmailAndRefernceNumberPairCheck({status: response}));
    } else {
        yield put(setEmailAndRefernceNumberPairCheck({status: false}));
    }
}

function* updateTimerDeadline({ payload }) {
    const timerDeadline = yield select(state => state.kyc.timerDeadline);
    const updateAttempts = { ...timerDeadline, ...payload };

    yield put(setTimerDeadline(updateAttempts));
    yield put(saveInApp({key: 'timerDeadline', value: updateAttempts}));
}

function* cleanAttemptsTimer() {
    yield put(setTimerDeadline({}));
    yield put(saveInApp({key: 'timerDeadline', value: {}}));
}

function* setFirstAttemptTimestamp(timerDeadline) {
    yield put(updateTimerDeadlineAction({ ...timerDeadline, firstAttemptTimeDeadline: getDeadlineTime(5), attempts: 1 }));
}

function* attemptsCountUpdate(timerDeadline) {
    yield put(updateTimerDeadlineAction({ ...timerDeadline, attempts: timerDeadline.attempts + 1 }));
}

function* handleTimerUpdate() {
    const timerDeadline = yield select(state => state.kyc.timerDeadline);
    const isAttemptsLoopActual = isDeadlineActual(timerDeadline?.firstAttemptTimeDeadline);

    if (isAttemptsLoopActual && timerDeadline.attempts < 3) {
        yield attemptsCountUpdate(timerDeadline);
    } else {
        yield setFirstAttemptTimestamp(timerDeadline);
    }
}

function* checkUserSubscription({ payload: id }) {
    const bank = yield select((state) => state.payment.bank);

    try {
        const { data } = yield axios.get(`${config.subscriptionServiceUrl}/payee/${id}?x-api-key=${config.zohoAccountsPatyeeDetailsApiKey}`);

        if (data.success) {
            yield put(updateBank({ subscriptionType: AccountType.Business, subscriptionId: data.data._id}));
        }
    } catch (error) {
        const { error: message } = error.response.data;

        if (message === 'Subscription is deactiveated') {
            yield put(updateBank({ subscriptionType: AccountType.Business }));
        } else if (bank.AccountType?.toLowerCase() === AccountType.Business?.toLowerCase()) {
            yield put(updateBank({ subscriptionType: AccountType.Business }));
        } else {
            yield put(updateBank({ subscriptionType: AccountType.Personal }));
        }
    }
}

function* handleCheckGeneralCodePair({ authCode, securityCode, dispatch }) {
    const timerDeadline = yield select(state => state.kyc.timerDeadline);
    const bankServiceId = yield select(state => state.payment.accountDetails.bank.api);

    try {
        const { data } = yield axios.post(`${config.subscriptionServiceUrl}/security_codes/${securityCode}/activate?x-api-key=${config.zohoAccountsSubscriptionApiKey}`,
            {
                authCode,
                bankServiceId,
                headers: {'Content-Type': 'application/json'},
            }
        );

        if (data.success) {
            yield put(getPayeeSubscriberIdByPayeeIdAction())
            yield put(getApiKeyByPayeeIdAction())
            resolveMessage({socketUrl: config.accountsServerURL, message: { ...data.data, nonintegrated: true} }, MessageType.Server, dispatch);
            yield put(setCheckUserAuthCodeStatus(AuthCodeStatuses.sucessPair));
        }
    } catch (error) {
        if (timerDeadline?.attempts >= 3) {
            yield put(setCheckUserAuthCodeStatus(AuthCodeStatuses.tooManyAttempts));
        } else {
            yield put(setCheckUserAuthCodeStatus(AuthCodeStatuses.codeCombinationError));
        }

        yield handleTimerUpdate();
    }
}

export function* kycWatcher() {
    yield takeLatest(actionType.CHECK_EMAIL, checkEmailVerification);
    yield takeLatest(actionType.CHECK_EMAIL_REFERENCE_NUMBER_PAIR, checkEmailAndRefernceNumberPair);
    yield takeLatest(actionType.CHECK_USER_SUBSCRIPTION, checkUserSubscription);
    yield takeLatest(actionType.UPDATE_TIMER_DEADLINE, updateTimerDeadline);
    yield takeLatest(CHECK_CODE_PAIR_FOR_GENERAL_ACC, handleCheckGeneralCodePair);
    yield takeLatest(CLEAN_ATTEMPTS_TIMER, cleanAttemptsTimer);
    yield takeLatest(PROCESS_ENTERED_AUTH_CODE, processEnteredAuthCode);
}

/**
 * Error 404: Document not found
Error 451: Auth Code is associated with another bank account
Error 452:Subscription is deactiveated
Error 453:Payee is already assigned to subscription
 */
