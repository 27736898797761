import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import './style.scss';
import { cssVhToPx } from '../../helpers';

const SlideItem = ({children, right}) => {
    const rightRef = useRef()
    const [showRight, setShowRight] = useState(false)

    const [slideDirection, setSlideDirection] = useState(1)
    const [offsetStart, setOffsetStart] = useState(0)
    const [offsetLeft, setOffsetLeft] = useState(0)
    const rightWidth = cssVhToPx(9).slice(0, -2);


    const grab = ({touches}) => {
        const [touch] = touches
        setOffsetStart(touch.clientX)
        window.addEventListener('touchmove', slide)
        window.addEventListener('touchend', release)
        window.addEventListener('touchcancel', release)
    }

    const slide = ({touches}) => {
        const [touch] = touches
        const offsetLeft = (offsetStart - touch.clientX) * slideDirection
        if (offsetLeft >= rightWidth) {
            setShowRight(slideDirection > 0)
            setOffsetLeft(0)
        } else if (offsetLeft <= 0) {
            setShowRight(slideDirection < 0)
            setOffsetLeft(0)
        } else {
            setOffsetLeft(offsetLeft)
        }
    }

    const release = () => {
        setSlideDirection(slideDirection * -1)
        setOffsetLeft(0)
        window.removeEventListener('touchmove', slide)
        window.removeEventListener('touchend', release)
        window.removeEventListener('touchcancel', release)
    }

    return (
        <div className="slide-item" onTouchStart={grab}>
            <div
                style={{transform: offsetLeft ? `translateX(-${slideDirection > 0 ? offsetLeft : rightWidth - offsetLeft}px)` : (showRight ? `translateX(-${rightWidth}px)` : null)}}
            >
                {children}
            </div>
            {right && <div
                ref={rightRef}
                className={clsx('slide-item_right', showRight && 'slide-item_right__show', offsetLeft && 'slide-item_right__visible')}
                style={
                    {
                        transform: offsetLeft ? `translateX(-${slideDirection > 0 ? offsetLeft : rightWidth - offsetLeft}px)` : null,
                    }
                }
            >
                {right()}
            </div>}
        </div>
    );
}
export default SlideItem;