import React from "react";
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
    toggleMenu,
} from '../../actions';
import {getCircleLogo, onImageError} from "../../helpers";
import Bank from "../../assets/images/banks-logo/defoult-icon-black_circle.png";
import Logo from '../../assets/images/bopp-logo.svg';

const BankLogo = ({ logo, bankName, menu = false, customStyles, disabled = false }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const openBankDetails = () => {
        if(!disabled){
            history.push('/bank-account');
            if(menu) {
                dispatch(toggleMenu());
            }
        }
    }

    return <>
        { logo ?
            <img className="menu-bank-logo"
                style={customStyles}
                src={getCircleLogo(logo)}
                onError={(e)=>{onImageError(e, true)}}
                alt={`${bankName} logo`}
                onClick={openBankDetails}
            />
            : menu ?
                <img className="menu-bank" src={Logo} alt="BOPPLogo" style={customStyles}/>
                : <img className="menu-bank-logo" src={Bank} onClick={openBankDetails} alt="BOPPLogo" style={customStyles}/>
        }
    </>
};

export default BankLogo;
