import React, { 
  useEffect, 
  useState 
} from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import '../../components/CheckBrowser/style.scss';
import {
  changeDomesticPayment,
  changePaymentStatusScreen,
  setHome,
  cancelDomesticPayment,
  setCallbackUrl,
  handlePaymentStatus,
  setFailReasonText,
  setIframeRedirectUrl
} from "../../actions";
import GoogleStore from '../../assets/images/placeholders/download-google-store.svg';
import AppStore from '../../assets/images/placeholders/download-app-store.svg';
import Phones from '../../assets/images/placeholders/phones.png';
import ScreenLogo from '../../assets/images/bopp-logo.svg';
import HeaderUniversal from "./../../components/HeaderUniversal";
import { 
  Home, 
  PaymentRequestType 
} from './../../static/CommonDefinitions'
import { ConsentUrlObject } from '../../helpers/consentUrlObject';
import { setPaymentRequest } from '../../reducers/paymentPersistReducer';
import { sendError } from '../../resolver/errorResolver';
import { 
  ErrorFlow, 
  ErrorObject 
} from '../../entities/errorObject';
import { resolveChangeOutcomingMessageAction } from '../../resolver/payment/changePaymentInstructionProcessor';
import { getIdDomain } from '../../helpers';

const CbPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [parent, setParent] = useState(window.ReactNativeWebView || window.opener);
  const [currentUrl, setCurrentUrl] = useState(window.location.href);

  useEffect(() => {
    dispatch(changePaymentStatusScreen({waitingForConsent: true, transferringToBank: false, clickPayViaBank: true, clickCancelPayment: false}));
    if (parent) {
      sendMessage({
        type: 'bankMessage',
        value: `${currentUrl}`,
      });
      sendMessage({
        type: 'openNewTab',
        action: 'close',
        passData: `${currentUrl}`,
      });
    }

    if (!parent) {
      const consent = new ConsentUrlObject(currentUrl);

      if(consent.params.get('rUrl')) {
        dispatch(setIframeRedirectUrl(consent.params.get('rUrl')))
      }
      
      if(consent.state != null) {
        if(consent.aisp) {
          dispatch(setCallbackUrl(currentUrl))
          history.push("/aisp-not-app");
          return;
        }
      } else {
        dispatch(sendError({error:new ErrorObject('Filed to retrieve state value from bank response', currentUrl, ErrorFlow.payments)}));
        dispatch(changePaymentStatusScreen({ paymentInProcess: false , waitingForCancel:false, cancelled: false, transferringToBank:false, waitingForConsent:false}));
        dispatch(handlePaymentStatus({ status: 'failed' }));
        dispatch(setFailReasonText('Invalid Bank Consent. Please try again'));
        dispatch(setPaymentRequest(null))
        history.push('/status')
        return
      }

      if(consent.code == null || consent.previousId == null) {
        if(consent.error) {
          dispatch(sendError({error:new ErrorObject('Bank responded with access denied', currentUrl, ErrorFlow.payments)}));
        } else {
          dispatch(sendError({error:new ErrorObject('Code or previous ID values missing from bank response', currentUrl, ErrorFlow.payments)}));
        }
        dispatch(changePaymentStatusScreen({ paymentInProcess: false , waitingForCancel:false, cancelled: false, transferringToBank:false, waitingForConsent:false}));
        dispatch(handlePaymentStatus({ status: 'failed' }));
        dispatch(setFailReasonText('Invalid Bank Consent. Please try again'));
        dispatch(setPaymentRequest(null))
        history.push('/status')
        return
      }

      dispatch(setHome(Home.Pay));

      if (consent.code != null && consent.code !== 'reject') {
        dispatch(changePaymentStatusScreen( {
          paymentInProcess: true,
          waitingForConsent: false,
          transferringToBank: false,
          consentFromBank: false,
          clickPayViaBank: false,
          clickCancelPayment: false,
          waitingForCancel: false,
        }));

        if(getIdDomain(consent.previousId) == PaymentRequestType.Recurring) {
          dispatch(resolveChangeOutcomingMessageAction({
            idToken: consent.idToken,
            previousId: consent.previousId,
            consent: consent.code,
          })) 
        } else {
          dispatch(changeDomesticPayment({
            code: consent.code,
            idToken: consent.idToken,
            paymentMethod: 'BoppPaylink',
            previousId:consent.previousId,
          }));
        }
      } else {
        dispatch(changePaymentStatusScreen( {waitingForCancel: true, cancelled: true}));
        dispatch(cancelDomesticPayment({
          paymentMethod: 'BoppPaylink',
          previousId:consent.previousId,
        }));
      }
    }
  },[dispatch, currentUrl, parent]);

  const sendMessage = (data) => {
    const msgObj = {
      ...data,
    };
    msgObj.msgId = Math.floor((1 + Math.random()) * 0x10000).toString(16);

    if (window.ReactNativeWebView) {
      return parent.postMessage(JSON.stringify(msgObj))
    }

    return parent.postMessage(JSON.stringify(msgObj), "*")
  };

  return !parent ? (
    <>
    <div className='check-browser'>
      <HeaderUniversal logo={ScreenLogo} classForCustomStyles="check-browser__header" />

      <div className="check-browser__wrapper">
        <div className="check-browser__content">
          <div className="check-browser__text">
            Download the BOPP app so you can easily and securely send and receive payments
          </div>

          <a className="check-browser__button" href="https://apps.apple.com/app/id1513552626">
            <img className="check-browser__image" src={AppStore} alt="AppStore" />
          </a>
          <a className="check-browser__button" href="https://play.google.com/store/apps/details?id=com.agitate.bopp">
            <img className="check-browser__image" src={GoogleStore} alt="GoogleStore" />
          </a>

          <a href="https://bopp.io/" className="check-browser__more-link">
            Learn more about BOPP
          </a>
        </div>

        <div className="check-browser__phones">
          <img src={Phones} alt="A different screens of the BOPP app" className="check-browser__phones-image"/>
        </div>
      </div>
    </div>
    </>
  ) : null;
};

export default CbPage;
