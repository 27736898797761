import React from 'react';
import CloseIcon from '../../../assets/images/close-icon.svg';
import '../style.scss';

const PinSetModal = ({ closeModal, title, content, closeButton = true, bottomButton = false }) => (
  <>
    <div className="gray-background" />
    <div className="modal-window">
      <div className="background-color background-color__base" />
      <div className="modal-window-content modal-window-content__question">
          {closeButton ? <img onClick={closeModal} className="close-modal" src={CloseIcon} alt="CloseIcon" /> : null}
        <div className="modal-title modal-title__question modal-title__pin-title">
          {title}
        </div>
        <div className="modal-description modal-description__pin-description">
          {content}
        </div>
        {bottomButton ? <button className="modal-info-button" onClick={closeModal}>GOT IT</button> : null}
      </div>
    </div>
  </>
);

export default PinSetModal;
