import { sendError } from '../errorResolver';
import { 
    takeLatest, 
    put,
    select
} from 'redux-saga/effects';

import { 
    ErrorFlow, 
    ErrorObject 
} from '../../entities/errorObject';
import { getPropertyFrom } from '../../helpers/objectHelper';
import { 
    createId, 
    wrap 
} from '../../helpers';
import { IncomingMessagePayload } from '../messageResolver';
import { addLastPayment } from '../../reducers/paymentPersistReducer';
import { changePaymentStatusScreen } from '../../actions';
import { push } from 'connected-react-router';
import { paylinkStatusData } from '../../reducers/statusSlice';
import { sendStatusRequestMessageAction, sendWSMessage } from './statusRequestProcessor';

export const RESOLVE_INCOMING_MESSAGE = 'resolve/change/instruction/incoming/message';
export interface ResolveChangeIncomingMessage { type: typeof RESOLVE_INCOMING_MESSAGE; payload: IncomingMessagePayload }
export const resolveChangeIncomingMessageAction = (payload: IncomingMessagePayload): ResolveChangeIncomingMessage => ({
    type: RESOLVE_INCOMING_MESSAGE,
    payload,
})

export const RESOLVE_OUTCOMING_MESSAGE = 'resolve/change/instruction/outcoming/message';
export interface ResolveChangeOutcomingMessagePayload {
    originatingEntityId?: string,
    idToken: string,
    previousId: string,
    consent: string,
}
export interface ResolveChangeOutcomingMessage { type: typeof RESOLVE_OUTCOMING_MESSAGE; payload: ResolveChangeOutcomingMessagePayload }
export const resolveChangeOutcomingMessageAction = (payload: ResolveChangeOutcomingMessagePayload): ResolveChangeOutcomingMessage => ({
    type: RESOLVE_OUTCOMING_MESSAGE,
    payload,
})

function* resolveChangeIncomingMessage({ payload }: ResolveChangeIncomingMessage): any {
    try {
        const type = getPropertyFrom(payload, '', '@type', true);
        let isSuccess = false
        if(type === 'https://dvschema.io/activation#Changed') {
            isSuccess = true
        }

        const transactionId = getPropertyFrom(payload, 'state', 'id', true);
        const paylink = getPropertyFrom(payload, 'properties.paymentMethod', 'paylink', true);
        
        const savedPaylinks:paylinkStatusData[] = yield select(state => state.paylink.paylinks);
        const selectedPaylink = savedPaylinks.find(element => element.paylink === paylink)

        if(selectedPaylink !== undefined) {
            let payeeName = selectedPaylink?.details?.payeeName
            let firstPaymentAmount = selectedPaylink?.details?.firstPaymentAmount?.value
            let paymentReference = selectedPaylink?.details?.paymentReference

            let statusScreenDetails = {
                amount: firstPaymentAmount,
                accountName:  payeeName,
                orderNumber: paymentReference,
                transactionId: transactionId,
                redirectUrls: selectedPaylink?.details?.redirectUrls,
                isSuccess:isSuccess
            }
        
            yield put(addLastPayment(statusScreenDetails));
            yield put(changePaymentStatusScreen({ paymentInProcess: false }));
            yield put(push('/so-status'))
        } else {
            yield put(sendStatusRequestMessageAction({ paylink: paylink, isPayment:false}));  
            // throw error
        }
        
    } catch (error) {
        yield put(sendError({error:new ErrorObject('Error resolving PISP Incoming message', error, ErrorFlow.accounts, payload.message)}));
    }
}

function* resolveChangeOutcomingMessage(socket: WebSocket,  payload: ResolveChangeOutcomingMessage): any {
    const idToken = payload.payload.idToken;
    const previousId = payload.payload.previousId;
    const consent = payload.payload.consent;

    try {    
        let request = {
            "@type": "https://dvschema.io/activation#ChangeRequest",
            "id": createId(true),
            "entitySelector": {
                "entityType": "https://miapago.io/paymentinstruction/v0.1.0#PaymentInstruction",
                "propertiesSelector": {
                    "@type": "https://miapago.io/paymentinstruction/v0.1.0#PropertiesSelector",
                    "methodSelectors": [
                        {
                            "methodType": "BoppPaylink"
                        }
                    ]
                }
            },
            previousId,
            "properties": {
                "@type": "https://miapago.io/paymentinstruction/v0.1.0#Properties",
                "parties": {
                    "payment:Payer": "cri:NTgRQ2DIfWWuQIlromAFnR"
                },
                "paymentMethod": {
                    "@type": "https://miapago.io/paylink/v0.1.0#PaymentMethod",
                    "methodType": "BoppPaylink",
                    "linkedMethod": {
                        "@type": "https://miapago.io/ob/paymentmethods/v0.1.0#StandingOrder",
                        "methodType": "OpenBankingPayment",
                        consent,
                        idToken
                    }
                }
            },
            "timestamp": new Date(),
            "possibleResend": false
        }

        yield sendWSMessage(wrap('paylink-initiator', request))
    } catch (error) {
        yield put(sendError({error:new ErrorObject('Error sending change payment instruction', error, ErrorFlow.payments, null)}));
    }
}

export function* changePaymentInstructionProcessor({paylinkSocket}: any) {
  yield takeLatest(RESOLVE_INCOMING_MESSAGE, resolveChangeIncomingMessage);
  yield takeLatest(RESOLVE_OUTCOMING_MESSAGE, resolveChangeOutcomingMessage, paylinkSocket);
}