import React from 'react';
import classNames from 'classnames';
import SuccessIcon from '../../assets/images/check-icon-yellow.svg';
import styles from './styles.scss';

const Toast = ({ title, className }) => {
    const renderIcon = () => {
        return <img src={SuccessIcon} alt="" className={classNames([styles.toast__icon])} />
    }

    return (
        <div className={classNames(['toast', className])}>
            {renderIcon()}
            <span className="toast__title">{ title }</span>
        </div>
    )
}

export default Toast;