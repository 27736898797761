import { sendError } from './../resolver/errorResolver';
import { 
    takeLatest, 
    put, 
    select 
} from 'redux-saga/effects';
import {
    handleAccountDetailsStatus,
    addBank,
    changeIsAddBankAccount,
    selectBankAccountDetails,
    saveInApp,
    changeAccountDetailsScreen,
    checkUserSubscription,
    updateBank as updateBankAction,
    clearPayerBank
} from '../actions';
import {
    KYCVerificationStatus,
    AccountType,
    BankAccountScheme,
    AispActivationState,
    StatusAccountDetails
} from "../static/CommonDefinitions";
import config from '../config';
import {
    sortCodeRemoveDash, 
    stringToId
} from '../helpers';
import { 
    ErrorFlow, 
    ErrorObject 
} from '../entities/errorObject';
import { IncomingMessagePayload } from './messageResolver';

export const RESOLVE_AIPS_INCOMING_MESSAGE = 'resolve/aisp/incoming/message';

export interface ResolveAISPIncomingMessage { type: typeof RESOLVE_AIPS_INCOMING_MESSAGE; payload: IncomingMessagePayload }

export const resolveAISPIncomingMessageAction = (payload: IncomingMessagePayload): ResolveAISPIncomingMessage => ({
    type: RESOLVE_AIPS_INCOMING_MESSAGE,
    payload,
})
interface BankAccountDetails {
    accountDID: string;
    id: string;
    accountNumber: string;
    accountName: string;
    sortCode: string;
    accountNickName: string;
    orderNumber: string;
    accountSubType: string;
    accountType: string;
    bankName: string;
    logo: string;
    subscriptionType: AccountType;
    bankAccountStatus: KYCVerificationStatus;
    serviceId: string;
}

enum ActivationType {
    Rejected = "ChangeRejected",
    Cancelled = "Cancelled",
    ActivityReportCreated = "ActivityReportCreated",
    PendingAccept = "PendingAccept"
}

function* resolveAISPIncomingMessage({ payload }: ResolveAISPIncomingMessage): any {
    try {
        const { message } = payload;
        const nonintegrated = message.nonintegrated;
        if(nonintegrated) {
            yield addBankAccount(message)
            return
        }
        const response = nonintegrated ? message : message.params[0];
        const state = nonintegrated ? AispActivationState.AccountDetails : response.state.activationState;

        const activationType = response['@type'].substring(response['@type'].lastIndexOf('#') + 1)


        switch (activationType) {
            case ActivationType.PendingAccept:
                yield put(selectBankAccountDetails(response.state));

                break;
            case ActivationType.ActivityReportCreated:
                yield addBankAccount(response)

                break;
            case ActivationType.Rejected:
                yield rejectAddBankAccount()

                break;
            default:
                yield cancelAddBankAccount()
                break;
        }
    } catch (error) {
        yield put(sendError({error:new ErrorObject('Error resolving AISP Incoming message', error, ErrorFlow.accounts, payload.message)}));
        console.log('dispatchResolveAipsIncomingMessage ERROR: ', error);
    }
}

function* rejectAddBankAccount() {
    const authUrl: string | undefined = yield select(state => state.payment.accountDetails?.state?.authorizationURL);
    if(authUrl) {
        yield setStatusAndClear(StatusAccountDetails.Failed)
    } else {
        yield setStatusAndClear(StatusAccountDetails.Unavailable)
    }
}

function* cancelAddBankAccount() {
    yield setStatusAndClear(StatusAccountDetails.Failed)
}

function* setStatusAndClear(status: StatusAccountDetails) {
    yield put(handleAccountDetailsStatus({ statusAccountDetails: status }));
    yield put(clearPayerBank());
    yield put(changeIsAddBankAccount(false));
}

function* addBankAccount(response: any) {
    const nonintegrated = response.nonintegrated;
    const isClickedBackButton: boolean = yield select(state => state.payment.isClickedBackButton);

    if(config.kycFormEnabled && !nonintegrated) {
        yield put(checkUserSubscription(response.state.payeeUniqueIdentifier));
    }

    var error = {isError:false, reason:""}
    const bank: BankAccountDetails = yield getBankDetails(response);
    if (!bank) return;

    const sCode = sortCodeRemoveDash(bank.sortCode);

    try{
        if(bank.accountNumber.length!==8 || bank.accountName.trim().length===0 || sCode.length!==6){
            throw new Error()
        } else {
            yield put(addBank(bank));
            if(!config.kycFormEnabled) {
                console.log("KYC STATUS", config?.kycFormEnabled)
                yield put(updateBankAction({ bankAccountStatus: KYCVerificationStatus.Verified, subscriptionType: AccountType.Personal }));
            }
            if (!isClickedBackButton) { // Do we need this and why ?
                yield put(saveInApp({ key: 'bankAccountDetails', value: bank }));
                
                yield put(handleAccountDetailsStatus({ statusAccountDetails: StatusAccountDetails.Success }));
                yield put(changeIsAddBankAccount(false));
            }
        }
    } catch {
        let errorMessage = " Reason: ";
        errorMessage = bank?.accountNumber?.length!==8? errorMessage.concat("Account number length not equal to 8. "): errorMessage;
        errorMessage = bank?.accountName?.trim()?.length===0? errorMessage.concat("Account name couldn't be empty. "): errorMessage;
        errorMessage = sCode?.length!==6? errorMessage.concat("Account sort code length not equal to 6."): errorMessage;

        error.isError = true
        error.reason = errorMessage
    }

    if(error.isError) {
        yield put(changeAccountDetailsScreen({
            savingAccountDetails:false,
            addBankError: {
                title: "failed to save account details",
                message: "Sorry, we’re unable to link this bank account. Please try again with an alternative bank account.".concat(config.appEnvironment === "DEV"? error.reason: "")
            }
        }));
    }
}

function getAccountWithScheme(accounts: any, scheme: any): any {
    let nestedAccount = null
    for(let i = 0; i < accounts.length; i++) {
        if(accounts[i].SchemeName === scheme) {
            nestedAccount = accounts[i]
            break;
        }
    }

    return nestedAccount
}

function parseAccountDetails(account: any) {
    let sortCode = (String(account.Identification.slice(0, 6)).match(/.{1,2}/g) || []).join('-');
    let accountName = account.Name
    let accountNickName = account.Name
    // if Coutts, why it needed for Couts, is there any better way to do it ?
    if (sortCode === "18-00-02") {
        let tmpAccountName = accountName.split("-")
        if (tmpAccountName.length > 1) {
            accountName = tmpAccountName[tmpAccountName.length - 1]
        }
        let tmpAccountNickName = accountNickName.split("-")
        if (tmpAccountNickName.length > 1) {
            accountNickName = tmpAccountNickName[tmpAccountNickName.length - 1]
        }
    }

    return {sortCode, accountName, accountNickName}
}

function* getBankDetails(response: any): Generator<any, BankAccountDetails | undefined, any> {
    const { friendly_name, logo_uri, api } = yield select(state => state.payment.accountDetails.bank);

    if (response.nonintegrated) {
        const { payeeUniqueIdentifier, accountName, sortCode, accountType, accountNumber } = response;
        return {
            accountDID:'did:mia:ob:account:' + payeeUniqueIdentifier,
            id: payeeUniqueIdentifier,
            accountNumber,
            accountName,
            sortCode,
            accountNickName: '',
            orderNumber: '',
            accountSubType: '',
            accountType,
            bankName: friendly_name,
            logo: logo_uri,
            subscriptionType: AccountType.Business,
            bankAccountStatus: KYCVerificationStatus.Verified,
            serviceId: stringToId(api),
        }
    }

    const accounts = response.state.accounts;

    if(accounts?.length>1){
        yield put(changeAccountDetailsScreen({
            savingAccountDetails:false,
            addBankError: {
                title: "failed to save bank accounts",
                message: "Sorry, we’re unable to link more than one bank account.\n Please try again."
            }
        }));
    } else {
        const account = accounts[0];
        var nestedAccount = getAccountWithScheme(account.Account, BankAccountScheme.SortCodeAccountNumber);

        if(nestedAccount != null) {
            const details = parseAccountDetails(nestedAccount);

            return {
                accountDID:'did:mia:ob:account:' + response.state.payeeUniqueIdentifier,
                id: response.state.payeeUniqueIdentifier,
                accountNumber: nestedAccount.Identification.slice(6),
                accountName: details.accountName,
                sortCode: details.sortCode,
                accountNickName: details.accountNickName,
                orderNumber: '',
                accountSubType: account.AccountSubType,
                accountType: account.AccountType,
                bankName: friendly_name,
                logo: logo_uri,
                subscriptionType: AccountType.Unknown,
                bankAccountStatus: KYCVerificationStatus.None,
                serviceId:stringToId(response.properties.serviceId)
            }
        } else {
            yield put(changeAccountDetailsScreen({
                savingAccountDetails:false,
                addBankError: {
                    title: "failed to save bank accounts",
                    message: "Sorry, we’re unable to link this bank account. Please try again with an alternative bank account."
                        .concat(config.appEnvironment === "DEV"? " Can't find sort code" : "")
                }
            }));
        }
    }
}

export function* aispProcessor() {
  yield takeLatest(RESOLVE_AIPS_INCOMING_MESSAGE, resolveAISPIncomingMessage);
}