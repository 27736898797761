import { sendError } from '../errorResolver';
import { 
    takeLatest, 
    put,
    select
} from 'redux-saga/effects';

import { 
    ErrorFlow, 
    ErrorObject 
} from '../../entities/errorObject';
import { getPropertyFrom } from '../../helpers/objectHelper';
import { setPaymentRequest } from '../../reducers/paymentPersistReducer';
import { IncomingMessagePayload } from '../messageResolver';
import { resolveInstructionIncomingMessageAction } from './createPaymentInstructionProcessor';
import { 
    changeAmount, 
    changePaymentStatusScreen, 
    changeWrongScreen, 
    clearPaylinkUrl, 
    handlePaymentStatus, 
    readError, 
    replyAccepted, 
    replyCancelled, 
    replyHistoryChange, 
    replyPendingAccept, 
    replyPendingChange, 
    replyRejected, 
    setPaylinkSourceAction, 
    showAccountDeactivated, 
    showInvalidPaymentError, 
    showLimitReached 
} from '../../actions';
import { resolveChangeIncomingMessageAction } from './changePaymentInstructionProcessor';
import { resolveStatusResponseMessageAction } from './statusRequestProcessor';
import { ResponseObject } from '../../entities/responseObject';
import { 
    HISTORY_REPORT, 
    HISTORY_RESPONSE, STATUS_REPORT 
} from '../../entities/eventTypes';
import { PAYLINK_PAYMENT_REQUEST } from '../../entities/entityContexts';
import { setUpdateRequestSent } from '../../reducers/historyReducer';
import { resolveCancelIncomingMessageAction } from './cancelProcessor';

export const ResponseMessageType = Object.freeze({'Status':'Status', 'AuthUrl':'AuthUrl', 'Changed':'Changed', 'Cancelled':'Cancelled', 'Rejected':'Rejected'});

export const RESOLVE_PIPS_INCOMING_MESSAGE = 'resolve/pisp/incoming/message';

export interface ResolvePISPIncomingMessage { type: typeof RESOLVE_PIPS_INCOMING_MESSAGE; payload: IncomingMessagePayload }

export const resolvePISPIncomingMessageAction = (payload: IncomingMessagePayload): ResolvePISPIncomingMessage => ({
    type: RESOLVE_PIPS_INCOMING_MESSAGE,
    payload,
})

export const RESOLVE_PIPS_OUTCOMMING_MESSAGE = 'resolve/pisp/outcoming/message';
export interface ResolvePISPOutcomingMessagePayload {
    message: any
}

export interface ResolvePISPOutcomingMessage { type: typeof RESOLVE_PIPS_OUTCOMMING_MESSAGE; payload: ResolvePISPOutcomingMessagePayload }

export const resolvePISPOutcomingMessageAction = (payload: ResolvePISPOutcomingMessagePayload): ResolvePISPOutcomingMessage => ({
    type: RESOLVE_PIPS_OUTCOMMING_MESSAGE,
    payload,
})

function* resolvePISPIncomingMessage({ payload }: ResolvePISPIncomingMessage): any {
    try {
        let error = getPropertyFrom(payload, 'message', 'error');
        if(error) {
            yield put(sendError({error:new ErrorObject('Error resolving PISP Incoming message', error, ErrorFlow.payments, payload.message)}));
            yield put(changeWrongScreen(true));
            return;
        }

        const response = getPropertyFrom(payload, 'message.params', '1');
        let responseType = getResponseType(response)
        if(responseType === ResponseMessageType.Status) {
            yield put(resolveStatusResponseMessageAction(response))
        } else {
            if(isRecurryingPayment(response)) {
                if(responseType === ResponseMessageType.AuthUrl) {
                    yield put(resolveInstructionIncomingMessageAction(response))
                } else if(responseType === ResponseMessageType.Changed) {
                    yield put(resolveChangeIncomingMessageAction(response))
                } else if(responseType === ResponseMessageType.Cancelled) {
                    yield put(resolveCancelIncomingMessageAction(response))
                } else {
                    yield put(changeWrongScreen(true))
                    yield put(sendError({error:new ErrorObject('Unhandled PISP Incoming message', response, ErrorFlow.accounts, payload)}));
                }
            } else {
                let responseObject = new ResponseObject(response)
                const resptype = response['@type'].substring(response['@type'].lastIndexOf('#') + 1)

                if (resptype === 'StatusRejected') {
                    yield put(clearPaylinkUrl())
                    yield put(showInvalidPaymentError(true))
                }

                const responseContext = response['@type']

                if (response.state) {
                    yield put(readError(response));

                    const state = response.state.activationState;
                    const context = response.state['@type'].substring(0, response.state['@type'].lastIndexOf('#'));

                    yield put(setPaylinkSourceAction(response))

                    switch (state) {
                        case 'Pending:PendingInitialisation:PendingAccept':  // Deprecated
                        case 'Pending:Initialised:PendingAccept':
                            if (responseContext == HISTORY_REPORT || responseContext == HISTORY_RESPONSE) {
                                yield put(replyHistoryChange(responseObject));
                            } else {
                                yield put(replyPendingAccept(responseObject));
                            }
                        break;
                        case 'Activated:Accepted:PendingChange':
                        case 'Activated:Accepted:PendingActive':
                        case 'Activated:Processing:NonePending':
                            if (responseContext == HISTORY_REPORT || responseContext == HISTORY_RESPONSE) {
                                yield put(replyHistoryChange(responseObject));
                            } else {
                                yield put(replyAccepted(responseObject));
                            }
                        break;
                        case 'Pending:Processed:PendingChange': // Deprecated
                            if (responseContext == STATUS_REPORT) {
                                yield performSuccessStatus();
                            } else {
                                yield put(replyPendingChange(responseObject));
                            }
                        break;
                        case 'Activated:Processed:NonePending':
                            if (context == PAYLINK_PAYMENT_REQUEST && responseContext == STATUS_REPORT) {
                                yield put(replyAccepted(responseObject));
                            } else {
                                if (responseContext == STATUS_REPORT) {
                                    yield performSuccessStatus();
                                } else if (responseContext == HISTORY_REPORT || responseContext == HISTORY_RESPONSE) {
                                    yield put(replyHistoryChange(responseObject));
                                } else {
                                    yield put(replyPendingChange(responseObject));
                                }
                            }
                        break;
                        case 'Rejected':
                        case 'Rejected:Initialised':
                            if(response.state?.statusReason?.code === 26) {
                                const paymentInProcess:boolean = yield select(state => state.payment.paymentStatusScreen.paymentInProcess);
                                if(!paymentInProcess) {
                                    yield put(showLimitReached(true));
                                    return
                                } 
                            } else if (response.state?.statusReason?.code === 25 || response.state?.statusReason?.code === 150) {
                                yield put(showAccountDeactivated(true));
                                return
                            } else {
                                yield put(showAccountDeactivated(false));
                            }

                            if (responseContext == STATUS_REPORT) {
                                yield performFailStatus();
                            } else {
                                yield put(replyRejected(responseObject));
                            }
                        break;
                        case 'Cancelled:Processed:NonePending':
                        case 'Cancelled:Initialised:NonePending':
                        case 'Cancelled:Processing:NonePending':  
                            if (responseContext == HISTORY_REPORT || responseContext == HISTORY_RESPONSE) {
                                yield put(replyHistoryChange(responseObject));
                            } else {
                                yield put(replyCancelled(responseObject));
                            }
                        break;
                        case 'Cancelled:Accepted:NonePending':
                            yield performCancelStatus();
                        break;
                        default:
                        if (responseContext == HISTORY_REPORT || responseContext == HISTORY_RESPONSE) {
                            if(response?.lastReport) {
                                yield put(setUpdateRequestSent(false))
                            }
                        }
                        break;
                    }

                    if (response.state?.statusReason?.code !== 25 && response.state?.statusReason?.code !== 150) {
                        yield put(showAccountDeactivated(false));
                    }

                    if(response.state?.statusReason?.code !== 26) {
                        yield put(showLimitReached(false));
                    }
                } else {
                    if (responseContext == HISTORY_REPORT || responseContext == HISTORY_RESPONSE) {
                        yield put(replyHistoryChange(responseObject));
                    }
                    if(response?.lastReport) {
                        yield put(setUpdateRequestSent(false))
                    }
                }
            }
        } 
    } catch (error) {
        yield put(sendError({error:new ErrorObject('Error resolving PISP Incoming message', error, ErrorFlow.accounts, payload.message)}));
    }
}

function *performCancelStatus() {
    yield performUpdatePaymnetStatus('cancelled', { paymentInProcess: false , waitingForCancel:false, cancelled: true, waitingForConsent:false})
}

function *performFailStatus() {
    yield performUpdatePaymnetStatus('failed', { paymentInProcess: false , waitingForCancel:false})
}

function *performSuccessStatus() {
    yield performUpdatePaymnetStatus('success', { paymentInProcess: false , waitingForConsent:false, waitingForCancel:false})
}

function *performUpdatePaymnetStatus(status:string, statusScreenDetails:any) {
    yield put(changePaymentStatusScreen(statusScreenDetails));
    yield put(handlePaymentStatus({ status: status}));
    yield put(setPaymentRequest(null))
    yield put(changeAmount('0.00'));
}

export function isRecurryingPayment(response:any) : boolean {
    let methodType = getPropertyFrom(response, 'properties.paymentMethod.linkedMethod', '@type');
    if(methodType) {
        const resptype = methodType.substring(methodType.lastIndexOf('#') + 1)
        if(resptype === 'StandingOrder') {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}

export function getResponseType(payload:any) : string {
    if(payload['@type'] === "https://dvschema.io/activation#StatusReport") {
        return ResponseMessageType.Status;
    } else if(payload['@type'] === "https://dvschema.io/activation#PendingAccept") {
        return ResponseMessageType.AuthUrl;
    } else if(payload['@type'] === "https://dvschema.io/activation#Changed") {
        return ResponseMessageType.Changed;
    } else if(payload['@type'] === "https://dvschema.io/activation#Cancelled") {
        return ResponseMessageType.Cancelled;
    } else {
        return ResponseMessageType.Rejected;
    }
}

function* resolvePISPOutcomingMessage(socket: WebSocket,  payload: ResolvePISPOutcomingMessage): any {
    try {
       
    } catch (error) {
        yield put(sendError({error:new ErrorObject('Error resolving PISP Incoming message', error, ErrorFlow.accounts, null)}));
    }
}

export function* pispProcessor({paylinkSocket}: any) {
  yield takeLatest(RESOLVE_PIPS_INCOMING_MESSAGE, resolvePISPIncomingMessage);
  yield takeLatest(RESOLVE_PIPS_OUTCOMMING_MESSAGE, resolvePISPOutcomingMessage, paylinkSocket);
}