import React, {useState} from 'react';

import '../style.scss';
import CloseIcon from "../../../assets/images/close-icon.svg";
import RequestPayment from "../../../assets/images/request-payment.svg";

const TransactionCounterModal = ({closeTransactionModal}) => {
    const [nextModal, setOn] = useState(false);

    const showNextModal = () => {
        setOn(true);
    }

    return (
        <>
            <div className="gray-background"/>
            <div className="modal-window">
                <div className="background-color background-color__base"/>
                <div className="modal-window-content modal-window-content__question height-none">
                    <img onClick={closeTransactionModal} className="close-modal" src={CloseIcon} alt="CloseIcon"/>
                    <div className="modal-title modal-title__border">
                        {
                            nextModal ? (
                                <span>
                                    Great News!
                                </span>
                            ) : (
                                <span>
                                    Are you using BOPP for business purposes?
                                </span>
                            )
                        }
                    </div>
                    <div className="modal-description flex-column">
                        {
                            nextModal ? (
                                <span>
                                    We’re happy to have you onboard! You won't see this message again.
                                </span>
                            ) : (
                                <>
                                    <img src={RequestPayment} alt="RequestPayment"/>
                                    <span className="under-img">
                                        If you use BOPP for your business please register to ensure you don’t get
                                        blocked by
                                        our <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href="https://bopp.io/user-agreement">Fair Usage Policy</a>.
                                    </span>
                                </>
                            )
                        }
                    </div>
                    {
                        !nextModal && (
                            <div className="modal-description-buttons mb-10">
                                <a rel="noopener noreferrer" target="_blank"
                                   href="https://zfrmz.com/YErm2ov61X9yuXob4Lu2">
                                    <button className="modal-buttons">
                                        register business
                                    </button>
                                </a>
                                <button onClick={showNextModal} className="modal-buttons modal-buttons__cancel">
                                    I have registered
                                </button>
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    );
};

export default TransactionCounterModal;
