import React, { useEffect, useState } from 'react';
import {useDispatch} from "react-redux";

import Menu from "../../assets/images/menu-bars.svg";
import CloseIcon from '../../assets/images/close-icon.svg';
import './style.scss';
import colors from '../../assets/style.theme.scss';
import PinSetModal from '../../containers/Modal/PinSetModal';

import {sendPin, toggleMenu, setStatusBarColor} from "../../actions";
import Button from '../Button';
import HeaderUniversal from '../HeaderUniversal';
import NumPanel from '../NumPanel';
import { cssVhToPx } from '../../helpers';

export default function PinBlock({ closePinBlock, onCloseButton }) {
  const [pinFirstStep, setPin] = useState([]);
  const [error, showError] = useState(false);
  const [modal, showModal] = useState(false);
  const [disabled, disabledButton] = useState(true);
  const [checkPin, checkPinFlow] = useState(false);
  const [showWarning, disabledWarning] = useState(false);
  const [title, changeTitle] = useState('Set 4-digit PIN');
  const [pin, changePin] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const checkedPin = JSON.parse(localStorage.getItem('checkedPin'));

    if (localStorage.getItem('pinCode') && !checkedPin) {
      changeTitle('Enter PIN');
      checkPinFlow(true);
    }

    if (pin.length === 4) {
      disabledButton(false);
      if(!checkPin) {
        disabledWarning(true);
      }
    } else {
      disabledButton(true);
    }

    if (modal) {
      const timer_id = setTimeout(closePinBlock, 3000);
      return () => {
        console.log(timer_id)
        clearTimeout(timer_id)
      }
    }
  }, [pin, modal, closePinBlock]);

  useEffect(() => {
    dispatch(setStatusBarColor(colors.mainYellow));
  }, []);

  const arraysEqual = (firstArr, secondArr) => {
    if (firstArr === secondArr) {
      return true;
    }
    if (firstArr === null || secondArr === null) {
      return false;
    }
    if (firstArr.length !== secondArr.length) {
      return false;
    }

    for (let i = 0; i < firstArr.length; ++i) {
      if (firstArr[i] !== secondArr[i]) {
        return false;
      }
    }
    return true;
  };

  const resetPin = () => {
    setTimeout(()=>{
      showError(false);
      changePin([])
    },1000)
  }

  const buttonClick = (val) => {
    if (val !== '.') {
      if (pin.length <= 3) {
        changePin([...pin, val]);
      }
    }
  };

  const handleBackspaceClick = () => changePin([...pin.splice(0, pin.length - 1)]);
  const handleNumpanelClean = () => changePin([]);

  const handleConfirm = () => {
    if (pin.length === 4 && checkPin) {
      if (arraysEqual(pin, JSON.parse(localStorage.getItem('pinCode')))) {
        localStorage.setItem('checkedPin', true);
        closePinBlock();
      } else {
        showError(true);
        resetPin();
      }
    }

    if (pin.length === 4 && pinFirstStep.length === 0 && !checkPin) {
      changeTitle('Confirm PIN');
      disabledButton(true);
      setPin([...pin]);
      changePin([]);
    }
    if (pin.length === 4 && pinFirstStep.length === 4 && !checkPin) {
      if (arraysEqual(pin, pinFirstStep)) {
        showModal(true);
        localStorage.setItem('pinCode', JSON.stringify(pin));
        dispatch(sendPin(pin.join('')));
        localStorage.setItem('checkedPin', true);
      } else {
        showError(true);
        resetPin();
      }
    }
  };

  return (
    <div className="pin-container">
      <HeaderUniversal
        title={title}
        customStylesTitle={{ top: '76px' }}
        leftButtonIcon={onCloseButton && CloseIcon}
        leftButtonEvent={onCloseButton && onCloseButton}
        rightButtonIcon={Menu}
        rightButtonEvent={() => {dispatch(toggleMenu())}}
      />

      <div className="pin-content">
        <div className="pin-dots">
          {pin.map((digit, i) => (
            <div className={ error ? 'error' : '' } key={`${digit}${i}`} />
          ))}
        </div>

        <div className="numpanel-wrapper">
          <NumPanel
            onInput={buttonClick}
            onBackSpace={handleBackspaceClick}
            gridStyles={numpanelGridStyles}
            onClean={handleNumpanelClean}
          />
        </div>

        <div className="pin-block-footer">
          <div className="warning-block">
            {
                showWarning && (
                    <span>
                        Remember this PIN. If you forget it, you'll need to reinstall this app and all data will be erased
                    </span>
                )
            }
          </div>

          <Button title="Next" handleClick={handleConfirm} color="black disabled-grey" disabled={disabled}/>
        </div>
      </div>
      {
          modal && (
              <PinSetModal closeModal={closePinBlock} title={"PIN set"} content={"You successfully set your new protection PIN code."} />
              )
      }
    </div>
  );
}

const numpanelGridStyles = {
  gridGap: `${cssVhToPx(3.7)} 11vw`,
}
