import { 
  takeLatest, 
  put 
} from 'redux-saga/effects';
import { sendMessage } from '../actions';
import { 
  ErrorFlow, 
  ErrorObject 
} from '../entities/errorObject';
import { AdapterType } from '../static/CommonDefinitions';
import { sendError } from './../resolver/errorResolver';

export const ADAPTER_GET_STATE = 'adapter/get/state';

export const getAdapterState = (adapter) => ({
  type: ADAPTER_GET_STATE,
  adapter
});

function* dispatchGetAdapterState(payload) {
  // Request qnly existing adapters "none" means that something was wrong and do nothing in this case
  if(payload.adapter !== AdapterType.None)
  {
    try {
      yield put(sendMessage({ type: payload.adapter, get: 'status' }));
    } catch (error) {
      yield put(sendError({error:new ErrorObject('Error getting adapter state', error, ErrorFlow.other)}));
    }
  }
}

export function* hardwareSaga() {
  yield takeLatest(ADAPTER_GET_STATE, dispatchGetAdapterState);
}