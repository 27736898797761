import { v4 as uuidv4 } from 'uuid';
import { Base62 } from '../utils/base62';
import groupBy from "lodash.groupby";
import moment from "moment";
import axios from "axios";
import config from "../config";
import DefaultLogo from '../assets/images/banks-logo/defoult-icon-black.png';
import DefaultLogoCircle from '../assets/images/banks-logo/defoult-icon-black_circle.png';
import { 
  AccountType, 
  IdDomain, 
  PaymentDirection, 
  PaymentRequestType
} from '../static/CommonDefinitions';
import { store } from '../store';
import { sendError } from '../resolver/errorResolver';
import { getPaymentMetainfo } from '../sagas/paymentWatcher';
import { 
  ErrorFlow, 
  ErrorObject 
} from '../entities/errorObject';

let xDown = null;
let yDown = null;

export const wrap = (method, msg) => {
  const JSONRPCMsg = {
    jsonrpc: '2.0',
    method: method,
    params: [msg],
  };
  return JSON.stringify(JSONRPCMsg);
};

export const _append = (buffer1, buffer2) => {
  const tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
  tmp.set(new Uint8Array(buffer1), 0);
  tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
  return tmp;
};

export const createId = (SO = false) => {
  let scheme;
  if(SO) {
    scheme = Uint8Array.of('13', '1')
  } else {
    scheme = Uint8Array.of('13', '0')
  }
  const id = uuidv4(null, new Uint8Array(16))
  return `cri:${Base62.encode(_append(scheme, id))}`;
};

export const getIdDomain = (id) => {
  if(typeof id === 'string') {
    if(id.includes('cri:')) {
      const idString = id.replace('cri:','');
      const idBytes = Base62.decode(idString)
      if(idBytes[0] === 13 && idBytes[1] === 1) {
        return PaymentRequestType.Recurring
      } else if(idBytes[0] === 13 && idBytes[1] === 0) {
          return PaymentRequestType.Standard
      } else {
        throw new TypeError('Undefined id domain, must starts with [13, 0] or [13, 1], starts with: [' + idBytes[0] + ', ' + idBytes[1] + ']')  
      }
    } else {
      throw new TypeError('Undefined id, must contains cri: prefix, id: ' + id)
    }  
  } else {
    throw new TypeError('Invalid id, mmust be a string type, id: ' + id)
  }  
};

export const groupingByDate = (requests) => {
  const arr = requests.map(el => {
    const date = moment(el.timestamp).format("YYYYMMDD");
    return { ...el, timeGroup: '' + date };
  });

  return groupBy(arr, 'timeGroup');
};

export const sortByTime = (transactions) => {
  const sortedTransactions = transactions.slice().sort((a, b) => (
    moment(b.timestamp).unix() - moment(a.timestamp).unix()
  ));

  return sortedTransactions;
};

export const getTargetPayments = (payments, paymentIds) => {
  return paymentIds.reduce((acc, id) => ([
    ...acc,
    payments.find(payment => payment.id === id),
  ]), []);
};

export const returnDate = (date, separator = null, withoutTime = false) => {
  if(moment().format("DD MMM YYYY") === moment(date).format("DD MMM YYYY")){
    return "Today " + moment(date).format("H:mm");
  }

  if(separator) {
    return moment(date).format(
      withoutTime
        ? `DD${separator}MM${separator}YYYY`
        : `DD${separator}MM${separator}YYYY, H:mm`
    );
  }

  return moment(date).format("DD MMM YYYY, ddd");
};

export const returnShortTime = (dateTime) => {
  return moment(dateTime).format('H:mm')
};

export const replaceSpecialCharactersWithSpacesInString = (str) => {
  let pattern = new RegExp("[^a-zA-Z0-9]+", "g");

  return str.replace(pattern, ' ');
};

export const removeSpecialCharactersFromString = (str) => {
  let pattern = new RegExp("[^a-zA-Z0-9]+", "g");

  return str.replace(pattern, '');
};

export const getDeadlineTime = (deadlineTimeInMinutes) => {
  const currentTime = Date.parse(new Date());

  return new Date(currentTime + deadlineTimeInMinutes * 60 * 1000).getTime();
};

export const isDeadlineActual = (deadlineTime) => {
  return !isNaN(new Date(deadlineTime)) && new Date(deadlineTime) > Date.parse(new Date());
};

export const getCircleLogo = (linkToLogo) => {
  return linkToLogo
    ? linkToLogo.replace('.png', '_circle.png')
    : DefaultLogoCircle;
}

export const onImageError = (element, circle = false) => {
  element.target.onerror = null;
  circle
    ? element.target.src=`${getCircleLogo()}`
    : element.target.src=`${DefaultLogo}`;
};

export const formatAmount = (amount, currency = 'GBP') => {
  const poundFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  });

  return poundFormatter.format(amount);
}

export const sortCodeRemoveDash = (sortCode) => {
    if(sortCode != undefined) {
      const regex = /-/g;
      return sortCode.replace(regex, '');
    } else {
      return ""
    }
};

export const parseCallbackURL = (payload) => {
  let params;
  if(payload?.url){
    let url = new URL(payload.url);
    params = url.hash && url.hash.length>1 ? new URLSearchParams(url.hash.substr(1)) : url.searchParams;
  }else if (payload?.value) {
    let url = new URL(payload.value);
    params = url.hash && url.hash.length>1 ? new URLSearchParams(url.hash.substr(1)) : url.searchParams;
  }

  return params;
};

export const idToString = (id) => {
  if (typeof id !== 'string') throw new TypeError('Expected String');
  if (!id.startsWith('cri:')) throw new TypeError('Expected id string');
  let idString = id.slice(4, id.length)
  const decodeResult = Base62.decode(idString);
  var outputString = new TextDecoder().decode(decodeResult);
  return outputString;
};

export const stringToId = (string) => {
  if (typeof string !== 'string') throw new TypeError('Expected String');
  var bytes = new TextEncoder().encode(string)
  const decodeResult = Base62.encode(bytes);
  return 'cri:'+decodeResult;
};

export const getBankDetails = (id, bankList, notConvert) => {
  if (notConvert) return bankList?.find(bank => bank.api === id);

  return bankList?.find(bank => bank.api === idToString(id));
}

export const isInApp = (appSpecificUserAgents) => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  for (let i = 0; i <= appSpecificUserAgents.length; i++) {
      if (userAgent.indexOf(appSpecificUserAgents[i]) > -1) return true;
  }
}

export const checkLength = (lengthLimit, string) => {
  return string.length <= lengthLimit
    ? string
    : '...' + string.slice(-lengthLimit + 3);
}

export const filterIntegratedBanks = (banks) => {
  if(banks.length) {
    return banks.filter(bank => !bank?.api?.includes('general'));
  } else {
    return banks
  }
}

export const numberWithCommas = (num) =>  {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const checkIsBoppButtonPayment = (endToEndIdentification) =>  {
    return endToEndIdentification === 'BOPPButton'
}

export const isDocumentLoaded = () => {
  return document.readyState === "complete" || document.readyState === "interactive";
}
export const findFirstValidKeyInObject = (obj, keyToFind) => {
  let keys = findAllByKeyInObject(obj, keyToFind)
  if(keys.length > 0)
    return keys[0]
  else
    return null
}

export const findMetainfoObject = (resp) => {
  let obj = findFirstValidKeyInObject(resp, 'metainfo')
  if(obj != null) {
    let mio = getPaymentMetainfo(obj)
    console.log(mio)
    return getPaymentMetainfo(obj)
  } else {
    return {"encryption":{"serviceProvider":{"pk":"bopp-public-key"},"payer":{"pk":"payer-public-key"},"payee":{"pk":"payee-public-key"}},"reference":{"type":"SetByMe","setByMeValue":"BOPP payment","autonumberValue":""},"notes":{"enabled":false,"type":"SetByMe","notesValue":"","notesCaption":"","makeNotesMandatory":false},"requestEmail":{"enabled":false,"mandatory":false,"displayMarketingOptIn":false,"organizationName":""},"thankYouNote":{"enabled":false,"message":""},"giftAid":{"enabled":false,"payee":{}}}
  }
}

export const findAllByKeyInObject = (obj, keyToFind) => {
  return Object.entries(obj ? obj : {})
    .reduce((acc, [key, value]) => (key === keyToFind)
      ? acc.concat(value)
      : (typeof value === 'object')
      ? acc.concat(findAllByKeyInObject(value, keyToFind))
      : acc
    , [])
}

export const isPaymentOutcome = (request) => request.direction === PaymentDirection.OutCome;

export const isNotAPersonalSubscriptionType = (subscription) => {
  return subscription === AccountType.Business || subscription === AccountType.Charity
}

export const isPaymentReferenceValid = (reference) => {
  if (reference[0] === ' ') {
    return false;
  }

  if (!reference.length) {
      return true;
  }

  const consecutiveSpaces  = new RegExp(/\s\s/);
  const lettersNumbersSpaces = new RegExp(/^[\w\s]+$/);

  var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  if(format.test(reference)){
    return false
  }

  return reference.length <= 18 && !consecutiveSpaces.test(reference) && lettersNumbersSpaces.test(reference);
}

export const lastCharacterIsSpace = (str) => str[str.length - 1] === ' ';

export const isIosInstagramBrowser = () => isInApp(['Instagram']) && isInApp(["iPhone", "iOS"]);
const isIosInstagram = isIosInstagramBrowser();

const getPercent = (partialValue, totalValue) => (100 * partialValue) / totalValue;

export const cssVhToPx = (vh) => `${(isIosInstagram ?  document.documentElement.clientHeight : window.innerHeight) * vh / 100}px`;

const defaultWidth = 375;
const defaultHeight = 667;

export const cssAdaptivePixelsVh = (px) => {
  const targetHeight = isIosInstagram ?  document.documentElement.clientHeight : window.innerHeight;
  return `${Math.round(getPercent(px, defaultHeight) * targetHeight / 100)}px`;
}
export const cssAdaptivePixelsVw = (px) => `${Math.round(getPercent(px, defaultWidth) * window.innerWidth / 100)}px`;

export function createAmount(amount) {
  return  {
      value: amount,
      unit: "ISO4217a:GBP"
  }
}

export const isEmptyObject = (obj) => Object.keys(obj).length === 0; 

export function addParamToURL(url, key, param) {
  if(hasQueryParams(url)) {
    return url + '&' + key + '=' + param
  } else {
    return url + '?' + key + '=' + param
  }
}

function hasQueryParams(url) {
  return url.includes('?');
}