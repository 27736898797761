import React from 'react';

import '../style.scss';

const SelectionModal = ({ selectOptionTop, title, optionTop, optionBottom, selectOptionBottom, topOptionType="dark_submit", bottomOptionType="cancel" }) => (
  <>
    <div className="gray-background" />
    <div className="modal-window selection-modal">
      <div className="background-color background-color__base" />
      <div className="modal-window-content">
        <div className="modal-description">
          {title}
        </div>
        <div className="modal-description-buttons">
          <button
            onClick={selectOptionTop}
            className={`modal-buttons modal-buttons__${topOptionType}`}
          >
            {optionTop}
          </button>
          <button
            onClick={selectOptionBottom}
            className={`modal-buttons modal-buttons__${bottomOptionType}`}
          >
            {optionBottom}
          </button>
        </div>
      </div>
    </div>
  </>
);

export default SelectionModal;