import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useHistory} from "react-router-dom";
import {
  checkEmailAndRefernceNumberPair,
  openNewPage,
  setEmail as setEmailInReducer,
  setFirstName as setFirstNameInReducer,
  setLastName as setLastNameInReducer,
  setEmailAndRefernceNumberPairCheck,
} from '../../../actions/index.js';
import FormSteps from '../FormSteps.js';
import Button from '../../../components/Button/index.js';
import CloseIcon from "../../../assets/images/close-icon.svg";
import BackArrow from "../../../assets/images/back-arrow.svg";
import BoppLogo from "../../../assets/images/bopp-logo.svg"
import {lastNameMatch} from "../../../utils";
import './styles.scss';

function EmailScreen({ changeStep, setUserData, userData }) {
  const history = useHistory();
  const dispatch = useDispatch()
  const bank = useSelector(state => state.payment.bank);
  const emailAndRefernceNumberPairExists = useSelector(state => state.kyc.emailAndRefernceNumberPairExists);

  const [firstName, setFirstName] = useState(userData.firstName);
  const [lastName, setLastName] = useState(userData.lastName);
  const [email, setEmail] = useState(userData.email);
  const [checkbox, setCheckbox] = useState(userData.policyCheckbox);
  const [emailValidation, setEmailValidation] = useState(true);
  const [firstNameValidation, setFirstNameValidation] = useState(true);
  const [lastNameValidation, setLastNameValidation] = useState(true);

  const namePattern = new RegExp(/^.{1,40}$/)
  const emailPattern = new RegExp(/^([a-zA-Z0-9._-]+[a-zA-Z0-9]|[a-zA-Z0-9]|[a-zA-Z0-9]+[a-zA-Z0-9._+-])+@[a-zA-Z0-9-]+(?:\.[a-zA-Z]+)+[a-zA-Z]*$/, 'g');

  const emailCheckPattern = emailPattern.test(email);
  const firstNameCheckPattern = namePattern.test(firstName);
  const lastNameCheckPattern = namePattern.test(lastName);

  useEffect(() => {
    return () => {
      dispatch(setEmailAndRefernceNumberPairCheck({status: null}));
    }
  }, [])

  useEffect(() => {
    if (emailAndRefernceNumberPairExists!==null) {
      if (emailAndRefernceNumberPairExists) {
        changeStep(FormSteps.identityVerified);
      } else if(emailCheckPattern && firstNameCheckPattern && lastNameCheckPattern) {
        changeStep(FormSteps.ZohoForm);
      }
    }
  }, [emailAndRefernceNumberPairExists])

  const onButtonClick = () => {
    if (firstNameCheckPattern) {
      dispatch(setFirstNameInReducer({firstName: firstName.replace(/-/g, ' ')}))
    } else {
      setFirstNameValidation(false);
    }
    if (lastNameCheckPattern) {
      dispatch(setLastNameInReducer({lastName: lastName.replace(/-/g, ' ')}))
      if (!lastNameMatch(bank.accountName, lastName)) {
        changeStep(FormSteps.ActivationFailed)
        return;
      }
    } else {
      setLastNameValidation(false);
    }
    if (emailCheckPattern) {
      dispatch(setEmailInReducer({ email: email }))
      dispatch(checkEmailAndRefernceNumberPair({email: email, referenceId: bank.id}))
    } else {
      setEmailValidation(false);
    }
  }
  const onCheckboxClick = () => {
    setCheckbox(!checkbox);
    setUserData({ policyCheckbox: !checkbox });
  }

  const handleFirstName = (e) => {
    const userFirstName = e.target.value;
    setFirstName(userFirstName);
    setUserData({firstName: userFirstName});
    setFirstNameValidation(true);
  }

  const handleLastName = (e) => {
    const userLastName = e.target.value;
    setLastName(userLastName);
    setUserData({lastName: userLastName});
    setLastNameValidation(true);
  }

  const handleEmail = (e) => {
    const userEmail = e.target.value;
    setEmail(userEmail);
    setUserData({ email: userEmail });
    setEmailValidation(true);
  }

  const prevStep = () => {
    changeStep(FormSteps.choosePlan)
  }

  const closeScreen = () => {
    history.push('/home-screen', 'show-retrive');
  }

  const checkInputsFilled = () => {
    return !checkbox || email.length === 0 || firstName.length === 0 || lastName.length === 0
  }

  const openLink = (url) => {
    dispatch(openNewPage(url));
  }

  return (
    <div className="email-screen">
      <div className="messages">
        <div className="email-screen__header">
          <div>
            <img
                className="email-screen__button"
                src={BackArrow}
                alt="Back"
                onClick={prevStep}/>
          </div>
          <div>
            <img src={BoppLogo} alt="BoppLogo"/>
          </div>
          <div>
            <img
                className="email-screen__button"
                src={CloseIcon}
                alt="Close"
                onClick={closeScreen}/>
          </div>
        </div>
        <p className="title">
          Contact Information
        </p>
        <p className="subtitle">
          Please fill in your contact details in the form below.
        </p>
        <label
            htmlFor="firstName"
            className="email-label"
        >
          First Name
          <input
              id="firstName"
              type="text"
              className={`email-input ${firstNameValidation ? '' : 'email-input__invalid'}`}
              value={firstName}
              onChange={handleFirstName}
          />
        </label>
        <label
            htmlFor="lastName"
            className="email-label"
        >
          Last Name
          <input
              id="lastName"
              type="text"
              className={`email-input ${lastNameValidation ? '' : 'email-input__invalid'}`}
              value={lastName}
              onChange={handleLastName}
          />
        </label>
        <label
          htmlFor="userEmail"
          className="email-label"
        >
          Email Address
          <input
            id="userEmail"
            type="email"
            className={`email-input ${emailValidation ? '' : 'email-input__invalid'}`}
            value={email}
            onChange={handleEmail}
          />
        </label>
        <div className="policy-label">
          <input
              id="policyAcception"
              type="checkbox"
              className="policy-checkbox"
              checked={checkbox}
              onChange={onCheckboxClick}
          />
          <span className="checkmark"></span>
          I accept BOPP <span onClick={()=>{openLink("https://bopp.io/user-agreement")}} className="link">User Agreement</span> and <span onClick={()=>{openLink("https://bopp.io/legal")}} className="link">Privacy Policy</span>. <span>*</span>
        </div>
      </div>
      <div className="bottom-part">
        <div className="button-wrapper">
          <Button title="Next" handleClick={onButtonClick} color="black-narrow black-narrow--disabled" disabled={checkInputsFilled()} />
        </div>
      </div>
    </div>
  )
}

export default EmailScreen
