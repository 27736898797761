import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface amount {
    value: string;
    unit: string;
}

export interface createInstructionState {
    authUrl: string;
    isAuthUrlRequestSent: boolean;
    selectedBank?: string;
    authUrlTimeout?: number;
}

export interface pispState {
    createInstructionState: createInstructionState;
}

const initialState: pispState = {
    createInstructionState: {
        authUrl: '',
        isAuthUrlRequestSent: false,
    }
};

export const pispSlice = createSlice({
    name: 'pispSlice',
    initialState,
    reducers: {
        updateCreateInstructionState: (state, action: PayloadAction<createInstructionState>) => {
            state.createInstructionState = {...state.createInstructionState, ...action.payload};
        },
    }
});

export const {
    updateCreateInstructionState
} = pispSlice.actions;

export default pispSlice.reducer;