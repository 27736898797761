import { 
  takeLatest, 
  put 
} from 'redux-saga/effects';
import * as actionType from '../actions/actionTypes';
import { sendMessage } from '../actions';
import { store } from '../store';
import { PermissionState } from '../static/CommonDefinitions';
import { sendError } from './../resolver/errorResolver';
import { 
  ErrorFlow, 
  ErrorObject 
} from '../entities/errorObject';

function* setHomePageOnDevice(action) {
  yield put(sendMessage({ type: 'storage', set: 'home', state: action.payload }));
}

function* getVersion() {
  yield put(sendMessage({ type: 'appVersion', get: 'get' }));
}

function* showCamera() {
  if(store.getState().permissions.camera === PermissionState.Granted) {
    yield put(sendMessage({ type: 'scanQR', action: 'show' }));
  }
}

function* hideCamera() {
  yield put(sendMessage({ type: 'scanQR', action: 'hide' }));
}

function* openCallBackPageSaga(action) {
  yield put(sendMessage({
    type: 'openNewTab',
    action: 'open',
    url: action.payload
  }));
}

function* openNewPageSaga(action) {
  yield put(sendMessage({
    type: 'openInBrowser',
    payload: action.payload
  }));
}

function* saveInApp(action) {
  try {
    yield put(sendMessage({
      type: 'storage',
      set: 'string',
      key: action.payload.key,
      value: JSON.stringify(action.payload.value)
    }));
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error sending message for saving in app', error, ErrorFlow.mobileStorage)}));
  }
}

function* getFromApp(action) {
  yield put(sendMessage({
    type: 'storage',
    get: 'string',
    key: action.payload,
  }));
}

function* callShare(action) {
  try {
    yield put(sendMessage({
      type: 'share',
      title: action.payload.title,
      message: action.payload.message,
      url: action.payload.url,
      subject: action.payload.subject
    }));
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error sending message for call share', error, ErrorFlow.mobileStorage)}));
  }
}

function* shareQr(action) {
  yield put(sendMessage({
    type: 'shareQR',
    image: action.payload,
  }));
}

function* getIsAppLaunchedFirstTime() {
  yield put(sendMessage({ type: 'isAppLaunchedFirstTime', get :'value'}));
}

function* saveIsAppLaunchedFirstTime() {
  yield put(sendMessage({ type: 'isAppLaunchedFirstTime', set :'value'}));
}

function* handleCopyToClipboard(action) {
  try {
    yield put(sendMessage({ type: 'copyToClipboard', text: action.payload.data }));
  } catch (error) {
    yield put(sendError({error:new ErrorObject('Error sending message for copying to clipboard', error, ErrorFlow.mobileStorage)}));
  }
}

function* handleSetStatusBarColor(action) {
  yield put(sendMessage({ type: 'statusBarColor', color: action.payload }));
}

export function* permissionWatcher() {
  yield takeLatest(actionType.SET_HOME_PAGE, setHomePageOnDevice);
  yield takeLatest(actionType.SET_HOME, setHomePageOnDevice);
  yield takeLatest(actionType.OPEN_CALLBACK_PAGE, openCallBackPageSaga);
  yield takeLatest(actionType.GET_APP_VERSION, getVersion);
  yield takeLatest(actionType.OPEN_NEW_PAGE, openNewPageSaga);
  yield takeLatest(actionType.SAVE_IN_APP, saveInApp);
  yield takeLatest(actionType.GET_FROM_APP, getFromApp);
  yield takeLatest(actionType.SHOW_CAMERA, showCamera);
  yield takeLatest(actionType.HIDE_CAMERA, hideCamera);
  yield takeLatest(actionType.CALL_SHARE, callShare);
  yield takeLatest(actionType.SHARE_QR, shareQr);
  yield takeLatest(actionType.GET_IS_APP_LAUNCHED_FIRST_TIME, getIsAppLaunchedFirstTime);
  yield takeLatest(actionType.SAVE_IS_APP_LAUNCHED_FIRST_TIME, saveIsAppLaunchedFirstTime);
  yield takeLatest(actionType.COPY_TO_CLIPBOARD, handleCopyToClipboard);
  yield takeLatest(actionType.SET_STATUS_BAR_COLOR, handleSetStatusBarColor);
}
