import { createId } from "../helpers";
import { common } from ".";

interface CancelPaymentRequest extends common.Activation {
    previousId: string
    entitySelector:common.EntitySelector
}    

export class CancelPayment {
    id: string

    constructor(id: string) {
        this.id = id
    }

    createRequest() {
        return this.getRequestParams()
    }

    getRequestParams() : CancelPaymentRequest {
        return  {
            "@type": "https://dvschema.io/activation#CancelRequest",
            id: createId(),
            previousId: this.id,
            entitySelector: {
                entityType: "https://miapago.io/paylink/request/PaylinkRequest"
            },
            timestamp: new Date(),
            possibleResend: false
        }
    }
}