import { 
    takeLatest, 
    put, 
    select 
} from 'redux-saga/effects';
import Socket from '../sockets/socket';
import config from '../config';
import { GetHistoryObjectStatus } from '../entities/getHistoryObjectStatus';
import { 
    findMetainfoObject, 
    wrap 
} from '../helpers';
import { 
    addHistoryPayment, 
    addRequestStatusId, 
    removeRequestStatusId, 
    updateHistoryRequest 
} from '../reducers/historyReducer';
import { 
    PaymentReportState, 
    PaymentRequestStatus, 
    PaymentRequestStatusReason, 
    PaymentTrigger, 
    ReferenceSettingType 
} from '../static/CommonDefinitions';
import { 
    savePaymentToNative, 
    updateRequestInNative 
} from '../sagas/historyWatcher';
import { ErrorFlow, ErrorObject } from '../entities/errorObject';
import { sendError } from './errorResolver';

export const GET_HISTORY = 'get/history';
export interface GetHistoryPayload { id: string, isRequest:boolean }
export interface GetHistory { type: typeof GET_HISTORY; payload: GetHistoryPayload }
export const getHistoryAction = (payload: GetHistoryPayload): GetHistory => ({
    type: GET_HISTORY,
    payload,
})

export const RESOLVE_HISTORY_INCOMING_MESSAGE = 'resolve/history/incoming/message';
export interface ResolveHistoryIncomingMessage { type: typeof RESOLVE_HISTORY_INCOMING_MESSAGE; payload: any }
export const resolveHistoryIncomingMessageAction = (payload: any): ResolveHistoryIncomingMessage => ({
    type: RESOLVE_HISTORY_INCOMING_MESSAGE,
    payload,
})

function* resolveHistoryIncomingMessage({ payload }: ResolveHistoryIncomingMessage): any {
    try {
        let response = payload.params[1];
        const request = yield select(state => (state.history.paymentRequestStatueIds.find((req:string) => req === response?.previousRequestId)));
        if(request) {
            yield processRequestResponse(response)
            if(response.lastReport) {
                yield put(removeRequestStatusId(response?.previousRequestId))
            }
        } else {
            yield put(sendError({error:new ErrorObject('Error resolving history Incoming message', response, ErrorFlow.historyQuery, payload)}));    
        }
    } catch (error) {
        yield put(sendError({error:new ErrorObject('Error resolving history incoming message', error, ErrorFlow.historyQuery, payload)}));
        console.log('dispatchResolveAipsIncomingMessage ERROR: ', error);
    }
}

function* processRequestResponse(response: any ) {
    try {
        const { requests } = yield select(state => ({requests: state.history.requests}));
        const request = requests.find((element: any) => element.ids.paymentRequestId === response.state.id);

        let paymentsIds = []
        if(response?.reportState) {
            for(let i = 0; i < response.reportState.length; i++) {
            const metainfo = findMetainfoObject(response.reportState[i])
            let pay = {
                id:response.reportState[i].id,
                reportId:response.reportState[i].id,
                reportState:response.reportState[i].reportState,
                timestamp:response.reportState[i].lastUpdateTime,
                executorId:response.reportState[i].executorId,
                requestId:response.state.id,
                amount:response.reportState[i].amount,
                dirrection:"InCome",
                paymentMethod:"",
                payeeServiceId:{
                id:request.bankServiceId,
                accountName: request.account.accountName,
                reference: getReferenceFromMetaInfo(metainfo),
                },
                ...metainfo,
            }

            yield put(addHistoryPayment(pay))
            yield put(savePaymentToNative(pay));
            paymentsIds.push(response.reportState[i].id)
            }
        }
        else {
            paymentsIds = request.payments;
        }

        let req = {
            ids:{
            paymentRequestId: response.state.id,
            originatorId: request.ids.originatorId,
            activatingEntityId: request.ids.activatingEntityId,
            requestId:request.ids.requestId
            },
            totalPaid: response.state.received,
            payments: paymentsIds,
            status : PaymentRequestStatus.Open,
            statusReason:''
        }

        const { payments } = yield select(state => ({payments: state.history.payments}));
        const requestPayments = payments.filter((payment:any) => payment.requestId === req.ids.paymentRequestId);
        let completedPaymentsCount = getCompletedInstructionsCountForPayments(requestPayments);

        //Set status as closed automatically if payment trigger "OnInstruction" and there is payment for this request
        if(response.properties.paymentTerms.paymentTrigger === PaymentTrigger.OnInstruction && completedPaymentsCount > 0) {
            req.status = PaymentRequestStatus.Closed
            req.statusReason = PaymentRequestStatusReason.ClosedAutomatically
        }

        const xInstructionValue = +request.paymentSettings.xInstructionsValue;

        if (response.properties.paymentTerms.paymentTrigger === PaymentTrigger.EachInstruction && xInstructionValue) {
            if (completedPaymentsCount >= xInstructionValue) {
                req.status = PaymentRequestStatus.Closed;
                req.statusReason = PaymentRequestStatusReason.ClosedAutomatically;
            }
        }

        if(response?.state?.activationState?.includes('Cancelled')) {
            req.status = PaymentRequestStatus.Closed
            req.statusReason = PaymentRequestStatusReason.Cancelled
        }

        yield put(updateHistoryRequest(req))
        yield put(updateRequestInNative(req));
    } catch (error) {
        yield put(sendError({error:new ErrorObject('Error resolving history incoming message', error, ErrorFlow.historyQuery, response)}));

        console.log('dispatchResolveAipsIncomingMessage ERROR: ', error);
    }
}

export function getCompletedInstructionsCountForPayments(payments: any) : number {
    let count  = 0
    for(let i = 0; i < payments.length; i++) {
        if(payments[i].reportState === PaymentReportState.Activated || payments[i].reportState === PaymentReportState.Confirmed) {
            count ++;
        }
    }

    return count;
}

function getReferenceFromMetaInfo(metainfo:any) {
    if (metainfo.reference.referenceType === ReferenceSettingType.SetByMe) {
    return metainfo.reference.setByMeValue;
    } else {
    return metainfo.reference.referenceValue;
    }
}

function* sendHistoryRequest(socket: Socket, { payload }: GetHistory): any {
    try {
        const getHistoryObjectStatusRequest = new GetHistoryObjectStatus(payload.id, payload.isRequest);
        yield put(addRequestStatusId(getHistoryObjectStatusRequest.requestId))
        yield socket.send(wrap('paylink-initiator', getHistoryObjectStatusRequest.createRequest()));
    } catch (error) {
        yield put(sendError({error:new ErrorObject('Error getting history object status', error, ErrorFlow.historyQuery, null)}));
    }
}

export function* historyProcessor(dispatch:any) {
    const socket = new Socket(config.paylinkServerURL + '?x-bopp-api-key=' + config.wsApiKey, resolveHistoryIncomingMessageAction, dispatch);

    yield takeLatest(RESOLVE_HISTORY_INCOMING_MESSAGE, resolveHistoryIncomingMessage);
    yield takeLatest(GET_HISTORY, sendHistoryRequest, socket);
}