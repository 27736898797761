import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import classNames from 'classnames';
import moment from 'moment';
import Wallet from '../../assets/images/transparent-wallet.svg';
import BackArrow from '../../assets/images/back-arrow.svg';
import CalendarIcon from "../../assets/images/calendar-v2.svg";
import EmailIcom from "../../assets/images/transparent-email.svg";
import NotesIcon from "../../assets/images/notes.svg";
import GiftIcon from "../../assets/images/gift-aid.svg";
import { returnDate, formatAmount, idToString, getTargetPayments, onImageError} from '../../helpers';
import { PaymentRequestStatus, TermType, PaymentTrigger, NotesSettingType, ReferenceSettingType } from '../../static/CommonDefinitions';
import { setStatusBarColor, getBankList, cancelPaymentRequest } from '../../actions';
import colors from '../../assets/style.theme.scss';
import { toBool } from '../../utils';
import './style.scss';
import RequestPaymentItem from '../../components/RequestPaymentItem';
import HeaderUniversal from '../../components/HeaderUniversal';
import RemoveItemModal from '../Modal/RemoveItemModal';
import {setHistoryRequestCancelledFromHistory} from './../../reducers/historyReducer'

const RequestSummary = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: requestId } = useParams();

  const bankList = useSelector(state => state.payment.bankList);
  const { payments, requests } = useSelector(state => ({
    payments: state.history.payments,
    requests: state.history.requests,
  }));
  const [request, setRequest] = useState({});
  const [isRequestFound, setIsRequestFound] = useState(false);
  const [relatedPayments, setRelatedPayments] = useState([]);
  const [cancelRequestModal, showCancelRequestModal] = useState(false);
  const isRequestOpen = request?.status === PaymentRequestStatus.Open;

  useEffect(() => {
    dispatch(setStatusBarColor(colors.mainYellow));
    dispatch(getBankList());
  }, []);

  useEffect(() => {
    if (payments.length && isRequestFound) {
      setRelatedPayments(getTargetPayments(payments, request.payments));
    }
  }, [payments.length, isRequestFound]);

  useEffect(() => {
    let associatedRequest = requests.find(request => request.ids.paymentRequestId === requestId)
    if(associatedRequest) {
      setRequest(associatedRequest)
    }
}, [requests]);

  useEffect(() => {
    try {
      setIsRequestFound(toBool(Object.keys(request).length));
    } catch {}
}, [request]);

  const openRequest = () => {
    history.push(`/payee-request/${request.ids.paymentRequestId}`);
  };

  const cancelRequest = () => {
    showCancelRequestModal(false)
    dispatch(setHistoryRequestCancelledFromHistory(true))
    dispatch(cancelPaymentRequest( {id:request.ids.requestId, requestId:request.ids.paymentRequestId}))
  };

  const getBankName = (id) => {
    return bankList?.find(bank => bank.api === idToString(id))?.accountName;
  }

  const openPaymentSummary = (id) => {
    //history.push(`/payment-summary/${id}`);
  };

  const backToRequestHistory = () => {
    history.goBack();
  }

  const getReferenceSettingsType = () => {
    //Support new requests
    if(request.paymentSettings?.referenceType) {
      switch(request.paymentSettings.referenceType) {
        case ReferenceSettingType.SetByMe:
          return 'Set by me: ' + request.paymentSettings.reference;
        case ReferenceSettingType.SetByPayer:
          return 'Set by payer';
        case ReferenceSettingType.Autonumber:
          return 'Autonumber: ' + request.paymentSettings.referenceAutonumber;
      }
    } else if(request.paymentSettings?.reference){ // Support old request
      return request.paymentSettings.reference;
    } else {
      return 'BOPP Payment';
    }
  }

  return (
    <div className="request-summary">
      <HeaderUniversal
        title={'Request Summary'}
        leftButtonIcon={BackArrow}
        leftButtonEvent={backToRequestHistory}
        classForCustomStyles="request-summary__header"
      />

      { isRequestFound && (
        <>
          <div className="request-summary__top-content">
            <div className={`request-summary__status ${!isRequestOpen ? 'request-summary__status--closed' : ''}`}>
              {isRequestOpen ? 'Open' : 'Closed'}
            </div>

            {!isRequestOpen && <div className="request-summary__status-text">
              {request.statusReason}
            </div>}

            <div className="request-summary__amount">
              {formatAmount(request.totalPaid.value)}
            </div>

            <div className="request-summary__subtitle">
              Total Paid
            </div>
          </div>

          <div className="request-summary__middle-content">
            <div className="request-summary__details details-block">
              <div className="details-block__row">
                <div className="details-block__text details-block__text--gray">
                  Requested To:
                </div>

                <div className="details-block__text">
                  {request.account.accountName}
                </div>

                <img src={request.account.bankLogo} alt="bank logo" className="details-block__image" onError={(e)=>{onImageError(e)}}/>
              </div>
              <div className="details-block__row">
                <div className="details-block__text details-block__text--gray">
                  Created:
                </div>

                <div className="details-block__text">
                  {returnDate(request.timestamp, '/')}
                </div>
              </div>
              <div className="details-block__row">
                <div className="details-block__text details-block__text--gray">
                  Reference:
                </div>

                <div className="details-block__text">
                  { getReferenceSettingsType() }
                </div>
              </div>
            </div>
            <div className="request-summary__settings">
              <SettingsList settings={request.paymentSettings} amount={request.requestedAmount} />
            </div>
            <div className="request-summary__payments">
              <CollapsibleList paymentCount={request.payments.length} >
                {relatedPayments.map(transaction => (
                  <RequestPaymentItem
                    key={transaction.id}
                    date={transaction.timestamp}
                    transactionId={transaction.reportId}
                    withGiftAid={transaction?.giftAid?.enabled && !transaction?.giftAid?.skippedByPayer}
                    reference={transaction?.referenceType === ReferenceSettingType.SetByPayer ? transaction?.payeeServiceId?.reference : ''}
                    notes={transaction?.notes?.type === NotesSettingType.SetByPayer ? transaction?.notes?.notesValue : ''}
                    reportState={transaction.reportState}
                    amount={transaction.amount.value}
                    bankName={getBankName(transaction.executorId)}
                    handleClick={() => openPaymentSummary(transaction.id)}
                  />
                ))}
              </CollapsibleList>
            </div>
          </div>

          {isRequestOpen &&
            <div className="request-summary__buttons">
              <button
                className="request-summary__button simple-button simple-button--yellow"
                onClick={openRequest}
              >
                open request
              </button>

              <button
                className="request-summary__button simple-button"
                onClick={()=>{showCancelRequestModal(true)}}
              >
                Cancel request
              </button>
            </div>
          }
          { cancelRequestModal &&
            <RemoveItemModal removeItem={cancelRequest} closeModal={()=>{showCancelRequestModal(false)}} title={"Do you want to cancel this payment request? Cancelling payment request won't affect payments that you have already received."} removeItemTitle={"Yes, cancel payment request"} closeItemTitle={"No"}/>
          }

        </>
      )}
    </div>
  );
}

export default RequestSummary;

const SettingsList = ({ settings, amount }) => {
  const [opened, setOpened] = useState(false);
  const className = classNames([
    "settings-list",
    { "settings-list--opened": opened }
  ]);

  const formattedAmount = settings.amountTerm === TermType.AnyAmount ? '' : Number(amount.value).toFixed(2);
  let amountItemTitle = '';
  let expiryItemTitle = '';

  switch (settings.amountTerm) {
    case TermType.FixedAmount:
      amountItemTitle = `Fixed: £${formattedAmount}`;
      break;
    case TermType.AnyAmount:
      amountItemTitle = "Set by payer";
      break;
    case TermType.SuggestedAmount:
      amountItemTitle = `Suggested: £${formattedAmount}`;
      break;
    case TermType.MinAmount:
      amountItemTitle = `Min amount: £${formattedAmount}`;
      break;
  }

  switch(settings.paymentTrigger) {
    case PaymentTrigger.OnInstruction:
      expiryItemTitle = "Single use";
      break;
    case PaymentTrigger.EachInstruction:
      expiryItemTitle = "Multi use";
      break;
    case PaymentTrigger.XInstructions:
      expiryItemTitle = `After ${settings.xInstructionsValue} payments`;
      break;
  }

  if (settings.paymentExpiryDate) {
    expiryItemTitle += ` (or on ${moment(settings.paymentExpiryDate).format('MMM DD, YYYY')})`;
  }

  const getNoteSettingsType = () => {
    if(settings?.notes?.type) {
      switch(settings.notes.type) {
        case NotesSettingType.SetByMe:
          return 'Set by me: ' + settings.notes.notesValue;
        case NotesSettingType.SetByPayer:
          return "Set by payer";
      }
    } else {
      return '';
    }
  }

  return (
    <div className={className}>
      <div className="settings-list__header" onClick={() => setOpened(!opened)}>
        <span className="settings-list__header_title">Request Settings</span>
        <img src={BackArrow} className="settings-list__header_icon" />
      </div>
      <div className="settings-list__content">
        <SettingsListItem icon={Wallet} title={amountItemTitle} />
        <SettingsListItem icon={CalendarIcon} title={expiryItemTitle} />
        {
          settings?.requestEmail &&
            <SettingsListItem icon={EmailIcom} title="Request email" />
        }
        {
          settings?.notes?.enabled &&
            <SettingsListItem icon={NotesIcon} title={getNoteSettingsType()} />
        }
        {
          settings?.giftAid &&
            <SettingsListItem icon={GiftIcon} title="With Gift Aid" />
        }
      </div>
    </div>
  )
}

const SettingsListItem = ({ icon, title }) => {
  return (
    <div className="settings-list__item">
      <img src={icon} className="settings-list__item_icon" />
      <span className="settings-list__item_title">{ title }</span>
    </div>
  )
}

const CollapsibleList = ({ children, paymentCount }) => {
  const listWrapper = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleList = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="payment-list">
      <ListTrigger
          title={`${isOpen ? 'Hide' : 'Show'} associated payments`}
          paymentCount={paymentCount}
          isOpen={isOpen}
          handleClick={toggleList}
        />

      <div
        className="payment-list__wrapper"
        ref={listWrapper}
      >
        {children}
        { paymentCount > 10 &&
          <ListTrigger
            title="Hide associated payments"
            paymentCount={paymentCount}
            isOpen={true}
            handleClick={toggleList}
          />
        }
      </div>
    </div>
  )
};

const ListTrigger = ({ title, paymentCount, isOpen, handleClick }) => (
  <div
    className={`payment-list__trigger ${isOpen ? 'is-open' : 'is-closed'}`}
    onClick={handleClick}
  >
    <p className="payment-list__title">{title}</p>
    <p className="payment-list__count">{`Total: ${paymentCount}`}</p>
  </div>
);
