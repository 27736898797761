import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
  openNewPage,
  getAppVersion,
  setHomePage,
  toggleMenu,
  clearPaylink,
  changeAmount,
} from '../../actions';

import CloseIcon from '../../assets/images/menu/close-icon-white.svg';
import Logo from '../../assets/images/bopp-logo.svg';
import WalletIcon from '../../assets/images/menu/wallet.svg';
import ForwardIcon from '../../assets/images/menu/forward-icon.svg';
import BankIcon from '../../assets/images/bank-low-black.svg';
import InfoIcon from '../../assets/images/menu/info-circle.svg';
import LockIcon from '../../assets/images/lock-icon-black.svg';
import HistoryIcon from '../../assets/images/menu/history.svg';
import RequestIcon from '../../assets/images/menu/request-activity.svg';
import FAQIcon from '../../assets/images/menu/question-circle.svg';
import BankLogo from "../../components/BankLogo";
import DefaultBankLogo from "../../assets/images/banks-logo/defoult-icon-black_circle.png";
import config from '../../config'
import './styles.scss';
import { updateOneTimeSettings } from '../../sagas/paymentRequestSettingsSaga';
import { isEmptyObject } from '../../helpers';

const Menu = ({
  logs, isLogging,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const pwaVersion = useSelector((state) => state.settings.pwaVersion);
  const isMenuVisible = useSelector((state) => state.settings.isMenuVisible);
  const bank = useSelector((state) => state.payment.bank);
  const defaultPaymentSettings = useSelector(state => state.paymentRequestSettings.defaultPaymentSettings);

  useEffect(() => {
    if (isMenuVisible) {
      dispatch(toggleMenu());
    }
  }, [location.pathname]);

  useEffect(() => {
    dispatch(getAppVersion())
  }, [dispatch]);

  const openNewTab = (url) => {
    onMenuItem();
    return dispatch(openNewPage(url));
  };

  const close = () => {
    dispatch(toggleMenu());
  };

  const redirectToHome = () => {
    onMenuItem();
    dispatch(setHomePage('request'));
    history.push('/home-screen');
  }

  const onMenuItem = () => {
    dispatch(toggleMenu());

    if (history.location.pathname.includes('/payee-request')) {
      dispatch(clearPaylink());
      dispatch(changeAmount('0.00'));
      dispatch(updateOneTimeSettings(defaultPaymentSettings));
    }
  };

  const openRoute = (route, state) => {
    onMenuItem();
    history.push(route, state);
  }

  const onBankAccount = () => {
    bank?.accountNumber? openRoute('/bank-account', history.location.pathname) : redirectToHome()
  }

  const getBankLogo = () => {
    if (bank && !isEmptyObject(bank)) {
      return bank.logo || DefaultBankLogo;
    }
  }

  return (
    <>
      { isMenuVisible && <div className="gray-background-menu" />}
      <button onClick={close} className={`close-menu-button ${isMenuVisible ? 'open-menu-button' : 'hide-menu-button'}`}>
        <img className="icon" src={CloseIcon} alt="" />
      </button>

      <div className={`menu-block ${isMenuVisible ? 'open-menu-block' : 'hide-menu-block'}`}>
        <div className="menu-header">
          <BankLogo logo={ getBankLogo() } bankName={bank.bankName} menu={true}/>
        </div>

        <div className="menu-button-list">
          <div onClick={onBankAccount} className="menu-button">
            <img src={BankIcon} alt="BankIcon" />
            <span className="menu-link-text" style={{ marginLeft: '14px', paddingTop: '3px'}}>Bank Account</span>
          </div>

          <div className="menu-button" onClick={() => openRoute('/last-receipt')}>
            <img src={ForwardIcon} alt="BankIcon" />
            <span className="menu-link-text" style={{ marginLeft: '14px', paddingTop: '3px'}}>Most Recent Receipts</span>
          </div>

          <div className="menu-button" onClick={() => openRoute('/pin')}>
            <img style={{ marginLeft: '3px' }} src={LockIcon} alt="LockIcon" />
            <span className="menu-link-text" style={{ marginLeft: '17px' }}>PIN protection</span>
          </div>

          <div className="menu-button" onClick={() => openRoute('/payment-history')}>
            <img style={{ marginLeft: '3px' }} src={HistoryIcon} alt="FinanceActivity" />
            <span className="menu-link-text" style={{ marginLeft: '17px' }}>Money Sent & Received</span>
          </div>

          <div className="menu-button" onClick={() => openRoute('/request-history')}>
            <img style={{ marginLeft: '3px' }} src={RequestIcon} alt="RequestActivity" />
            <span className="menu-link-text" style={{ marginLeft: '17px' }}>Request Activity </span>
          </div>

          <div className="menu-button">
            <img style={{ marginLeft: '3px' }} src={InfoIcon} alt="InfoIcon" />
            <span className="menu-link-text" style={{ marginLeft: '17px' }}>
              <a onClick={() => openNewTab('https://bopp.io/legal')}>Legal</a>
            </span>
          </div>

          <div className="menu-button">
            <img style={{ marginLeft: '3px' }} src={FAQIcon} alt="FAQIcon" />
            <span className="menu-link-text" style={{ marginLeft: '17px' }}>
              <a onClick={() => openNewTab('https://help.bopp.io/portal/en/kb/bopp')}>FAQs</a>
            </span>
          </div>
        </div>
        <div className="menu-footer">
          <img className="bottom-logo" src={Logo} alt="" />
          <span className="version">{`Current Version: ${pwaVersion}`}</span>
        </div>
      </div>
    </>
  );
};

export default Menu;
