import { PaymentTrigger, ReferenceSettingType, TermType, NotesSettingType } from "../static/CommonDefinitions";

const SET_ONE_TIME_PAYMENT_SETTING = 'SET_ONE_TIME_PAYMENT_SETTING';
const SET_DEFAULT_PAYMENT_SETTINGS = 'SET_DEFAULT_PAYMENT_PAYMENT_SETTING'
const SET_PAYMENT_SETTING_ERROR = "SET_PAYMENT_SETTING_ERROR";
const SET_ONE_TIME_SETTING_CHANGES = "SET_ONE_TIME_SETTING_CHANGES";
const SET_EXPIRE_DATE_IS_SET_TO_PAST = "SET_EXPIRE_DATE_IS_SET_TO_PAST";
const RESET_TO_INITIAL_STATE = "RESET_TO_INITIAL_STATE";

export const setOneTimePaymentSettings = (payload) => ({
    type: SET_ONE_TIME_PAYMENT_SETTING,
    payload,
});

export const setDefaultPaymentSettings = (payload) => ({
    type: SET_DEFAULT_PAYMENT_SETTINGS,
    payload,
});

export const setPaymentSettingError = (payload) => ({
    type: SET_PAYMENT_SETTING_ERROR,
    payload,
});

export const setOneTimeSettingChanges = (payload) => ({
    type: SET_ONE_TIME_SETTING_CHANGES,
    payload,
})

export const setExpireDateIsSetToPast = (payload) => ({
    type: SET_EXPIRE_DATE_IS_SET_TO_PAST,
    payload,
});

export const resetToInitialState = () => ({
    type: RESET_TO_INITIAL_STATE,
})

export const initialPaymentSettings = {
    reference: {
        type: ReferenceSettingType.SetByMe,
        setByMeValue: 'BOPP payment',
        autonumberValue: ""
    },
    expire: {
        type: PaymentTrigger.OnInstruction,
        xInstructionsValue: "",
        onCertainDate: false,
        certainDate: null,
    },
    amount: {
        type: TermType.FixedAmount,
    },
    requestEmail: {
        enabled: false,
        mandatory: false,
        displayMarketingOptIn: false,
        organizationName: '',
    },
    notes: {
        enabled: false,
        type: NotesSettingType.SetByMe,
        notesValue: "",
        notesCaption: "",
        makeNotesMandatory: false,
    },
    thankYouNote: {
        enabled: false,
        message: "",
    },
    giftAid: {
        enabled: false,
    }
}

export const settingChangesDefaultValue = {
    reference: false,
    expire: false,
    amount: false,
    requestEmail: false,
    notes: false,
    thankYouNote: false,
    giftAid: false,
}

export const initialState = {
    oneTimePaymentSettings: initialPaymentSettings,
    oneTimeSettingChanges: settingChangesDefaultValue,
    defaultPaymentSettings: initialPaymentSettings,
    paymentSettingError: false,
    expireDateIsSetToPast: false,
};

export const paymentRequestSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ONE_TIME_PAYMENT_SETTING:
            return {
                ...state,
                oneTimePaymentSettings: {
                    ...state.oneTimePaymentSettings,
                    ...action.payload
                }
            };
        case SET_DEFAULT_PAYMENT_SETTINGS:
            return {
                ...state,
                defaultPaymentSettings: {
                    ...state.defaultPaymentSettings,
                    ...action.payload
                }
            };
        case SET_PAYMENT_SETTING_ERROR:
            return {
                ...state,
                paymentSettingError: action.payload
            };
        case SET_ONE_TIME_SETTING_CHANGES:
            return {
                ...state,
                oneTimeSettingChanges: { ...action.payload }
            };
        case SET_EXPIRE_DATE_IS_SET_TO_PAST:
            return {
                ...state,
                expireDateIsSetToPast: action.payload,
            };
        case RESET_TO_INITIAL_STATE:
            return {
                ...initialState
            }
        default:
            return state
    }
};

export default paymentRequestSettingsReducer;
